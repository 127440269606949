// import * as THREE from 'three'
import config from './utils/config';
import room from './framework/index'
import { wallDirectionVisibility } from './utils/compass'

function PointBoxDistance(position, boundingBox) {
  var minX = boundingBox.center.x - boundingBox.size.x * 0.5;
  var minY = boundingBox.center.y - boundingBox.size.y * 0.5;
  var minZ = boundingBox.center.z - boundingBox.size.z * 0.5;

  var maxX = boundingBox.center.x + boundingBox.size.x * 0.5;
  var maxY = boundingBox.center.y + boundingBox.size.y * 0.5;
  var maxZ = boundingBox.center.z + boundingBox.size.z * 0.5;

  var dx = Math.max(minX - position.x, 0, position.x - maxX);
  var dy = Math.max(minY - position.y, 0, position.y - maxY);
  var dz = Math.max(minZ - position.z, 0, position.z - maxZ);
  return Math.sqrt(dx * dx + dy * dy + dz * dz);
}

function GetWallOrder(camPosition,boundingBox, objects)
{
  // objects.
  var minX = boundingBox.center.x - boundingBox.size.x * 0.5;
  var minZ = boundingBox.center.z - boundingBox.size.z * 0.5;

  var maxX = boundingBox.center.x + boundingBox.size.x * 0.5;
  var maxZ = boundingBox.center.z + boundingBox.size.z * 0.5;
  
  var near = {};
  near.distance = minX - camPosition.x;
  near.wall = objects.leftWall;
  
  var far = {};
  far.distance = camPosition.x - maxX;
  far.wall = objects.rightWall;
  
  var left = {};
  left.distance = minZ - camPosition.z;
  left.wall = objects.farWall;
  
  var right = {};
  right.distance = camPosition.z - maxZ;
  right.wall = objects.entranceWall;

  
  var distances = [near, far, left, right];
  distances.sort((a, b) => (a.distance < b.distance) ? 1 : ((b.distance < a.distance) ? -1 : 0))

  return distances;

}


function SetRenderOrders(objects, tourObjects, baseNumber) {
  var childOrder = baseNumber;
  
  for (var i = 0; i < objects.length; i++) {
    objects[i].traverse(function (child) {
      if (child instanceof THREE.Mesh) {
        child.renderOrder = childOrder++;
      }
    });
  }

  return childOrder;
}

var PrevLocked = false;
var prevInside=false;
var prevCamPos;
var FramesSinceMove = 0;

function lerp(start, end, percent) {
  return start * (1.0 - percent) + end * percent;
}

function fadeOut(room)
{
  room.globals.objects.roofObject.material.transparent = false;
  room.scene.traverse(function (child) {
    if (child.material != undefined && child.material.uniforms != undefined && child.type == 0 && child != room.globals.objects.bookObject && child != room.globals.objects.roofObject) {
      child.material.blending = THREE.CustomBlending;
      child.material.blendEquation = THREE.AddEquation;
      child.material.blendSrc = THREE.SrcAlphaFactor;
      child.material.blendDst = THREE.OneMinusSrcAlphaFactor;
      child.material.blendSrcAlpha = null;
      child.material.blendDstAlpha = null;
      if(child.isTourObject) child.material.side = THREE.DoubleSide;
      var t = { t: 0 };
      new TWEEN.Tween(t)
      .to({ t: 1 }, 500)
      .easing(TWEEN.Easing.Cubic.Out)
      .onUpdate(function (object) {
        child.material.uniforms['minDistanceAlpha'].value = lerp(0, config.CloseUpTransparency, t.t);
        child.material.uniforms['transparency'].value = lerp(config.tourGlobalTransparency, 0, t.t);
      })
      .onComplete(() => {
        })        
        .start()
    }
  })  
  
}

function fadeIn(room)
{
  room.globals.objects.roofObject.material.transparent = true;
  room.scene.traverse(function (child) {
    if (child.material != undefined && child.material.uniforms != undefined && child.type == 0 && child != room.globals.objects.bookObject) {
      child.material.blending = THREE.CustomBlending;
      child.material.blendEquation = THREE.AddEquation;
      child.material.blendSrc = THREE.SrcAlphaFactor;
      child.material.blendDst = THREE.ZeroFactor;
      child.material.blendSrcAlpha = null;
      child.material.blendDstAlpha = null;
      if(child.isTourObject) child.material.side = THREE.FrontSide;
      var t = { t: 0 };
      new TWEEN.Tween(t)
      .to({ t: 1 }, 500)
      .easing(TWEEN.Easing.Cubic.Out)
      .onUpdate(function (object) {
        child.material.uniforms['minDistanceAlpha'].value = lerp(config.CloseUpTransparency, 0, t.t);
        child.material.uniforms['transparency'].value = lerp(0, config.tourGlobalTransparency, t.t);
      })
      .onComplete(() => {
      })
      .start()
    }
  }) 
}

export let animate = {
  on: true
}
export function render() {
  if (animate.on) requestAnimationFrame(render)
  
  room.controls.orbit.update()  
  if (room.state.view === 'ROOM') {
    room.camera.updateProjectionMatrix();
    room.camera.lookAt(room.globals.dolly.position)
  }


  TWEEN.update()

  // ____________
  // |           |
  // |  O     O  |
  // |  O     O  |  ^
  // |  O     O  |  | Z : Left and right walls
  // |  O     O  |  |
  // |___________|
  //      --> X : near and far walls
  
  var dummy = new THREE.Vector3();
  var camPosition = room.camera.getWorldPosition(dummy);
  var distanceRoom = PointBoxDistance(camPosition, config.pictureRoomBoundingBox);
  var inside = distanceRoom <= 0
  
  var center = new THREE.Vector3(config.pictureRoomBoundingBox.center.x, 0, config.pictureRoomBoundingBox.center.z);
  var size = new THREE.Vector3(config.pictureRoomBoundingBox.size.x, 0, config.pictureRoomBoundingBox.size.z);
  var halfSize = size.multiplyScalar(0.5);
  var corners = [];
  corners[0] = center.clone().add(new THREE.Vector3(halfSize.x, 0, halfSize.z));
  corners[1] = center.clone().add(new THREE.Vector3(halfSize.x, 0, -halfSize.z));
  corners[2] = center.clone().add(new THREE.Vector3(-halfSize.x, 0, halfSize.z));
  corners[3] = center.clone().add(new THREE.Vector3(-halfSize.x, 0, -halfSize.z));
  var camPosition2D = new THREE.Vector3(room.camera.position.x, 0, room.camera.position.z);
  for (var i = 0; i < room.scene.roomWalls.length; i++) {
    room.scene.roomWalls[i].camDistance = camPosition2D.distanceTo(room.scene.roomWalls[i].centerOfMass);
  }
  if(inside && !prevInside)
  {
    fadeIn(room);   
  }
  else if(!inside && prevInside)
  {
    fadeOut(room);
  }

  room.scene.roomWalls.sort((a, b) => (a.camDistance < b.camDistance) ? 1 : ((b.camDistance < a.camDistance) ? -1 : 0))
  
  var baseNumber = 0;
  baseNumber = SetRenderOrders(room.scene.roomWalls, room.globals.objects.tourObjects, baseNumber);

  
  if(!room.controls.orbit.wiggle)
  {
    if(prevCamPos != null && camPosition.x == prevCamPos.x && camPosition.y == prevCamPos.y && camPosition.z == prevCamPos.z)
    {
      FramesSinceMove++;
    }
    else
    {
      FramesSinceMove=0;
    }
  }
  
  if(FramesSinceMove > 8 * 60 && !room.controls.orbit.wiggle)
  {
    room.controls.orbit.startWiggle();
  }

  
  room.renderer.render(room.scene, room.camera);
	// room.composer.render();
  PrevLocked = room.state.locked;
  prevInside = inside;

  prevCamPos = camPosition.clone();
}


