import room from '../framework/index'
import config from "../utils/config"
import { fade, transitionFrontWallsToOpacity, transitionStaticObjectsOpacity, FadeButtonsDown, FadeButtonsUp } from '../utils/tweens'

const transitionHandler = () => {
  return {
    'TOUR': (pos) => transition('TOUR', 'ROOM_INSIDE', pos),
    'ROOM': () => transition('ROOM', 'ROOM_OUTSIDE'),
    'MAP_TOGGLE': createFoldMechanism()
  }
}

function MoveObject(object, targetPosition, targetRotation, delay, duration) {
  object.initialPosition = object.position.clone();
  object.initialRotation = object.rotation.clone();
  var t = new TWEEN.Tween(object.position)
    .to({ x: targetPosition.x, y: targetPosition.y, z: targetPosition.z }, duration)
    .delay(delay)
    .easing(TWEEN.Easing.Sinusoidal.Out)
    .start()
  var t = new TWEEN.Tween(object.rotation)
    .to({ x: targetRotation.x, y: targetRotation.y, z: targetRotation.z }, duration)
    .delay(delay)
    .easing(TWEEN.Easing.Sinusoidal.Out)
    .start()

  return t;
}

function MoveBackObject(object, delay, duration) {
  return MoveObject(object, object.initialPosition, object.initialRotation, delay, duration);
}

function createFoldMechanism() {

  // TODO - collect references from model to set up tween methods

  // deprecate
  // room.globals.pivots.map(pivot => {
  //   pivot.userData.isPanel = detectPanel(pivot.name)
  //   pivot.userData.toggleFold = foldTweenMap(pivot.name)
  // })

  // const panelPivots = room.globals.pivots.filter(pivot => pivot.userData.isPanel)
  // const wallPivots = room.globals.pivots.filter(pivot => !pivot.userData.isPanel)

  return () => {
    const action = [FOLD, UNFOLD]
    const GOTO_MAP = room.state.view === 'ROOM'
    const view = GOTO_MAP ? 'MAP' : 'ROOM'
    const debounce = room.controls.debounceState()
    const endState = debounce(view)

    return new Promise((resolve) => {
      // GOTO_MAP: true => UNFOLD | false => FOLD 
      action[~~(GOTO_MAP)]()
        .then(() => {
          resolve(endState())
          room.onAction({ type: 'TBC', trigger: "FOLD_DEBOUNCE", callback: (next) => next(view) })
        })
    })
  }
}

function UNFOLD() {
  return new Promise((resolve) => {
    var startTime = 0;

    room.events['PANELS']['SCENE'].gotoPanelState('CLOSE');

    //Turn on lines
    for (var i = 0; i < room.globals.lines.length; i++) {
      room.globals.lines[i].visible = true;
    }
    
    //Turn on text
    for (var i = 0; i < room.globals.texts.length; i++) {
      room.globals.texts[i].visible = true;
    }
    cameraMap['MAP_START']().start().onComplete(() => resolve())
    MoveObject(room.globals.dolly, { x: 0, y: 0, z: 0 }, { x: 0, y: 0, z: 0 }, startTime, config.unfoldSpeed);
    
    //turn off corners, floors...
    for (var i = 0; i < room.globals.mapModeObjects.toFadeOff.length; i++) {
      fade(room.globals.mapModeObjects.toFadeOff[i], -1, startTime - 400, config.unfoldSpeed * 0.005);
    }

    FadeButtonsDown(room);

    fade(room.globals.eastObjects.parent, config.tourTransparency, startTime, config.unfoldSpeed);
    MoveObject(room.globals.mapModeObjects.parents.Floor, { x: 0, y: 0, z: 42300.00 }, { x: 0.0080459713, y: -1.570797, z: 0 }, startTime, config.unfoldSpeed);
    MoveObject(room.globals.mapModeObjects.parents.Ceiling, { x: 800, y: -180, z: -64400 }, { x: -0.0101403673, y: 1.570797, z: 0 }, startTime, config.unfoldSpeed);
    MoveObject(room.globals.mapModeObjects.parents.MonksChamber, { x: 15600, y: -150700, z: 3000 }, { x: 0, y: 0, z: -1.570797 }, startTime, config.unfoldSpeed);


    MoveObject(room.globals.mapModeObjects.NorthWall, { x: -20800, y: 85700, z: -4700 }, { x: 0, y: 0, z: 1.570797 }, startTime, config.unfoldSpeed);
    MoveObject(room.globals.mapModeObjects.SouthWall, { x: -24480, y: -104200, z: -3000 }, { x: 0, y: 0, z: -1.570797 }, startTime, config.unfoldSpeed);
    MoveObject(room.globals.mapModeObjects.WestWall, { x: -20200, y: -322000, z: -3400 }, { x: 0, y: 0, z: 3.141594 }, startTime, config.unfoldSpeed);

    startTime += config.unfoldSpeed * 0.013;

    for (var i = 0; i < room.globals.mapModeObjects.toFadeIn.length; i++) {
      fade(room.globals.mapModeObjects.toFadeIn[i], config.tourTransparency, startTime + 400, config.unfoldSpeed);
    }

    MoveObject(room.globals.northObjects.panelLeftBack, { x: 33600 - 22000, y: 1600, z: -1400 }, { x: 0, y: 0, z: -3.141594 }, startTime, config.unfoldSpeed);
    MoveObject(room.globals.northObjects.panelLeftFront, { x: 12400 + 22000, y: 1800, z: 800 }, { x: 0, y: 0, z: 0 }, startTime, config.unfoldSpeed);
    MoveObject(room.globals.northObjects.panelRightBack, { x: -34700 + 22000, y: 1100, z: 1400 }, { x: 0, y: 0, z: -3.141594 }, startTime, config.unfoldSpeed);
    MoveObject(room.globals.northObjects.panelRightFront, { x: -13100 - 22000, y: 1900, z: 800 }, { x: 0, y: 0, z: 0 }, startTime, config.unfoldSpeed);
    fade(room.globals.northObjects.panelRightFront, config.tourTransparency, startTime, config.unfoldSpeed);
    fade(room.globals.northObjects.panelLeftFront, config.tourTransparency, startTime, config.unfoldSpeed);



    MoveObject(room.globals.southObjects.backLeft0, { x: -32700, y: 1900, z: -16800 }, { x: 0, y: 0, z: -3.141594 }, startTime, config.unfoldSpeed);
    MoveObject(room.globals.southObjects.frontLeft0, { x: -54800, y: 200, z: -15600 }, { x: 0, y: 0, z: 0 }, startTime, config.unfoldSpeed);
    MoveObject(room.globals.southObjects.backLeft1, { x: 11800, y: -400, z: -15600 }, { x: 0, y: 0, z: -3.141594 }, startTime, config.unfoldSpeed);
    MoveObject(room.globals.southObjects.frontLeft1, { x: -9900, y: 1800, z: -15900 }, { x: 0, y: 0, z: 0 }, startTime, config.unfoldSpeed);
    MoveObject(room.globals.southObjects.backRight0, { x: 121000, y: -100, z: -16200 }, { x: 0, y: 0, z: -3.141594 }, startTime, config.unfoldSpeed);
    MoveObject(room.globals.southObjects.frontRight0, { x: 142400, y: -100, z: -15400 }, { x: 0, y: 0, z: 0 }, startTime, config.unfoldSpeed);
    MoveObject(room.globals.southObjects.backRight1, { x: 95900, y: 800, z: -16200 }, { x: 0, y: 0, z: -3.141594 }, startTime, config.unfoldSpeed);
    MoveObject(room.globals.southObjects.frontRight1, { x: 98600, y: 500, z: -15900 }, { x: 0, y: 0, z: 0 }, startTime, config.unfoldSpeed);

    fade(room.globals.southObjects.frontLeft0, config.tourTransparency, startTime, config.unfoldSpeed);
    fade(room.globals.southObjects.frontRight0, config.tourTransparency, startTime, config.unfoldSpeed);

    MoveObject(room.globals.westObjects.panelLeftBack, { x: 1700, y: -22680 + 14000, z: -17100 }, { x: 0, y: 0, z: 3.141594 }, startTime, config.unfoldSpeed);
    MoveObject(room.globals.westObjects.panelLeftFront, { x: 2000, y: -9180 - 14000, z: -17000 }, { x: 0, y: 0, z: 0 }, startTime, config.unfoldSpeed);
    MoveObject(room.globals.westObjects.panelRightBack, { x: 2390, y: 22310 - 14000, z: -17340 }, { x: 0, y: 0, z: -3.141594 }, startTime, config.unfoldSpeed);
    MoveObject(room.globals.westObjects.panelRightFront, { x: 2430, y: 8620 + 14000, z: -17550 }, { x: 0, y: 0, z: 0 }, startTime, config.unfoldSpeed);
    fade(room.globals.westObjects.panelRightFront, config.tourTransparency, startTime, config.unfoldSpeed);
    fade(room.globals.westObjects.panelLeftFront, config.tourTransparency, startTime, config.unfoldSpeed);
    fade(room.globals.westObjects.middleBackground, config.tourTransparency, startTime, config.unfoldSpeed);

    MoveObject(room.globals.mapModeObjects.MCL1, { x: -34530, y: -37260, z: -3700 }, { x: 0, y: 0, z: 1.570797 }, startTime, config.unfoldSpeed);
    MoveObject(room.globals.mapModeObjects.MCR1, { x: 31900, y: -36100, z: -7600 }, { x: 0, y: 0, z: -1.570797 }, startTime, config.unfoldSpeed);
    MoveObject(room.globals.mapModeObjects.MCL2, { x: -27200, y: -36400, z: 36100 }, { x: 0, y: 0, z: 1.570797 }, startTime, config.unfoldSpeed * 0.35);
    MoveObject(room.globals.mapModeObjects.MCR2, { x: 19200, y: -36000, z: 37900 }, { x: 0, y: 0, z: -1.570797 }, startTime, config.unfoldSpeed * 0.35);
    MoveObject(room.globals.mapModeObjects.MCB2, { x: -3800, y: -35600, z: 35300 }, { x: 0, y: 0, z: 0 }, startTime, config.unfoldSpeed * 0.35);
    MoveObject(room.globals.mapModeObjects.MCCeiling, { x: 441, y: -14971, z: -75379 }, { x: -1.547741191, y: 0, z: 0 }, startTime, config.unfoldSpeed * 0.35);
    MoveObject(room.globals.mapModeObjects.MCF1, { x: 64900, y: -34200, z: -2500 }, { x: 0, y: 0, z: 3.141594 }, startTime, config.unfoldSpeed)
      .onComplete(() => {
        for (var i = 0; i < room.globals.pics.length; i++) {
          room.globals.pics[i].visible = true;
          room.globals.pics[i].material.opacity = 0;
        }
      });

    fade(room.globals.mapModeObjects.MCL1, config.tourTransparency, startTime, config.unfoldSpeed);
    fade(room.globals.mapModeObjects.MCL2, config.tourTransparency, startTime, config.unfoldSpeed);
    fade(room.globals.mapModeObjects.MCR1, config.tourTransparency, startTime, config.unfoldSpeed);
    fade(room.globals.mapModeObjects.MCR2, config.tourTransparency, startTime, config.unfoldSpeed);
    fade(room.globals.mapModeObjects.MCB2, config.tourTransparency, startTime, config.unfoldSpeed);
    fade(room.globals.mapModeObjects.MCCeiling, config.tourTransparency, startTime, config.unfoldSpeed);
    fade(room.globals.mapModeObjects.MCF1, -1, startTime, config.unfoldSpeed);

    // console.log(room.controls.orbit);
    room.controls.orbit.mouseButtons = {
      LEFT: THREE.MOUSE.PAN,
      MIDDLE: THREE.MOUSE.DOLLY,
      RIGHT: THREE.MOUSE.PAN
    }

  })
}

function FOLD() {
  return new Promise((resolve) => {
    room.controls.orbit.mouseButtons = {
      LEFT: THREE.MOUSE.ROTATE,
      MIDDLE: THREE.MOUSE.DOLLY,
      RIGHT: THREE.MOUSE.PAN
    }

    for (var i = 0; i < room.globals.lines.length; i++) {
      room.globals.lines[i].visible = false;
    }

    for (var i = 0; i < room.globals.texts.length; i++) {
      room.globals.texts[i].visible = false;
    }

    for (var i = 0; i < room.globals.pics.length; i++) {
      room.globals.pics[i].visible = false;
      room.globals.pics[i].material.opacity = 0;
    }
    //Move objects back
    cameraMap['MAP_END']().start().onComplete(() => resolve())
    var startTime = 0;
    for (var i = 0; i < room.globals.mapModeObjects.toFadeIn.length; i++) {
      fade(room.globals.mapModeObjects.toFadeIn[i], -1, startTime + 400, config.unfoldSpeed);
    }

  

    MoveBackObject(room.globals.northObjects.panelLeftBack, startTime, config.unfoldSpeed);
    MoveBackObject(room.globals.northObjects.panelLeftFront, startTime, config.unfoldSpeed);
    MoveBackObject(room.globals.northObjects.panelRightBack, startTime, config.unfoldSpeed);
    MoveBackObject(room.globals.northObjects.panelRightFront, startTime, config.unfoldSpeed);
    fade(room.globals.northObjects.panelRightFront, config.freeTransparency, startTime, config.unfoldSpeed);
    fade(room.globals.northObjects.panelLeftFront, config.freeTransparency, startTime, config.unfoldSpeed);



    MoveBackObject(room.globals.southObjects.backLeft0, startTime, config.unfoldSpeed);
    MoveBackObject(room.globals.southObjects.frontLeft0, startTime, config.unfoldSpeed);
    MoveBackObject(room.globals.southObjects.backLeft1, startTime, config.unfoldSpeed);
    MoveBackObject(room.globals.southObjects.frontLeft1, startTime, config.unfoldSpeed);
    MoveBackObject(room.globals.southObjects.backRight0, startTime, config.unfoldSpeed);
    MoveBackObject(room.globals.southObjects.frontRight0, startTime, config.unfoldSpeed);
    MoveBackObject(room.globals.southObjects.backRight1, startTime, config.unfoldSpeed);
    MoveBackObject(room.globals.southObjects.frontRight1, startTime, config.unfoldSpeed);
    fade(room.globals.southObjects.frontLeft0, config.freeTransparency, startTime, config.unfoldSpeed);
    fade(room.globals.southObjects.frontRight0, config.freeTransparency, startTime, config.unfoldSpeed);

    MoveBackObject(room.globals.westObjects.panelLeftBack, startTime, config.unfoldSpeed);
    MoveBackObject(room.globals.westObjects.panelLeftFront, startTime, config.unfoldSpeed);
    MoveBackObject(room.globals.westObjects.panelRightBack, startTime, config.unfoldSpeed);
    MoveBackObject(room.globals.westObjects.panelRightFront, startTime, config.unfoldSpeed);
    fade(room.globals.westObjects.panelRightFront, config.freeTransparency, startTime, config.unfoldSpeed);
    fade(room.globals.westObjects.panelLeftFront, config.freeTransparency, startTime, config.unfoldSpeed);
    fade(room.globals.westObjects.middleBackground, config.freeTransparency, startTime, config.unfoldSpeed);

    MoveBackObject(room.globals.mapModeObjects.MCL1, startTime, config.unfoldSpeed);
    MoveBackObject(room.globals.mapModeObjects.MCL2, startTime, config.unfoldSpeed);
    MoveBackObject(room.globals.mapModeObjects.MCR1, startTime, config.unfoldSpeed);
    MoveBackObject(room.globals.mapModeObjects.MCR2, startTime, config.unfoldSpeed);
    MoveBackObject(room.globals.mapModeObjects.MCB2, startTime, config.unfoldSpeed);
    MoveBackObject(room.globals.mapModeObjects.MCCeiling, startTime, config.unfoldSpeed);
    MoveBackObject(room.globals.mapModeObjects.MCF1, startTime, config.unfoldSpeed);
    fade(room.globals.mapModeObjects.MCL1, config.freeTransparency, startTime, config.unfoldSpeed);
    fade(room.globals.mapModeObjects.MCL2, config.freeTransparency, startTime, config.unfoldSpeed);
    fade(room.globals.mapModeObjects.MCR1, config.freeTransparency, startTime, config.unfoldSpeed);
    fade(room.globals.mapModeObjects.MCR2, config.freeTransparency, startTime, config.unfoldSpeed);
    fade(room.globals.mapModeObjects.MCB2, config.freeTransparency, startTime, config.unfoldSpeed);
    fade(room.globals.mapModeObjects.MCCeiling, config.freeTransparency, startTime, config.unfoldSpeed);
    fade(room.globals.mapModeObjects.MCF1, config.freeTransparency, startTime, config.unfoldSpeed);


    startTime += config.unfoldSpeed * 0.013;

    for (var i = 0; i < room.globals.mapModeObjects.toFadeOff.length; i++) {
      fade(room.globals.mapModeObjects.toFadeOff[i], 0, startTime + config.unfoldSpeed, config.unfoldSpeed * 0.5);
    }


    fade(room.globals.eastObjects.parent, config.freeTransparency, startTime, config.unfoldSpeed);
    MoveBackObject(room.globals.mapModeObjects.parents.Floor, startTime, config.unfoldSpeed);
    MoveBackObject(room.globals.mapModeObjects.parents.Ceiling, startTime, config.unfoldSpeed);
    MoveBackObject(room.globals.mapModeObjects.parents.MonksChamber, startTime, config.unfoldSpeed);

    MoveBackObject(room.globals.mapModeObjects.NorthWall, startTime, config.unfoldSpeed);
    MoveBackObject(room.globals.mapModeObjects.SouthWall, startTime, config.unfoldSpeed);
    MoveBackObject(room.globals.mapModeObjects.WestWall, startTime, config.unfoldSpeed);


    fade(room.globals.northObjects.panelRightFront, config.freeTransparency, 0, config.unfoldSpeed);
    fade(room.globals.northObjects.panelLeftFront, config.freeTransparency, 0, config.unfoldSpeed);
    fade(room.globals.eastObjects.parent, config.freeTransparency, 0, config.unfoldSpeed);
    fade(room.globals.westObjects.panelRightFront, config.freeTransparency, 0, config.unfoldSpeed);
    fade(room.globals.westObjects.panelLeftFront, config.freeTransparency, 0, config.unfoldSpeed);
    fade(room.globals.westObjects.middleBackground, config.freeTransparency, 0, config.unfoldSpeed);
    fade(room.globals.southObjects.frontLeft0, config.freeTransparency, startTime, config.unfoldSpeed);
    fade(room.globals.southObjects.frontRight0, config.freeTransparency, startTime, config.unfoldSpeed);
    fade(room.globals.mapModeObjects.MCL1, config.freeTransparency, 0, config.unfoldSpeed);
    fade(room.globals.mapModeObjects.MCL2, config.freeTransparency, 0, config.unfoldSpeed);
    fade(room.globals.mapModeObjects.MCR1, config.freeTransparency, 0, config.unfoldSpeed);
    fade(room.globals.mapModeObjects.MCR2, config.freeTransparency, 0, config.unfoldSpeed);
    fade(room.globals.mapModeObjects.MCB2, config.freeTransparency, 0, config.unfoldSpeed);
    fade(room.globals.mapModeObjects.MCCeiling, config.freeTransparency, 0, config.unfoldSpeed);
    fade(room.globals.mapModeObjects.MCF1, config.freeTransparency, 0, config.unfoldSpeed);

    MoveObject(room.globals.dolly, { x: 0, y: 0, z: 0 }, { x: 0, y: 0, z: 0 }, 0, config.unfoldSpeed);
    var t = new TWEEN.Tween(room.controls.orbit.target)
      .to({ x: 0, y: 0, z: 0 }, config.unfoldSpeed)
      .delay(0)
      .easing(TWEEN.Easing.Sinusoidal.Out)
      .start()

  })
}


function transition(view, tween, tweenParam) {
  return new Promise((resolve) => {
    const debounce = room.controls.debounceState()
    const endState = debounce(view)
    runTween(tween, tweenParam).then(() => resolve(endState()))
  })
}

function runTween(state, tweenParam) {
  return new Promise((resolve, reject) => {
    const action = cameraMap[state]
    if (action) action(tweenParam).start().onComplete(() => resolve())
    else reject()
  })
}

const cameraMap = {
  'ROOM_INSIDE': function (tourStartPos = config.tourStartPos) {
    // tourStartPos (passed from pictureRoomData tour initalCamera)

    return new TWEEN.Tween(room.camera.position)
      .to(tourStartPos, 3000)
      .easing(TWEEN.Easing.Cubic.Out)
  },
  'ROOM_OUTSIDE': function () {
    //Close all panels
    room.events['PANELS']['SCENE'].gotoPanelState('CLOSE');
    transitionFrontWallsToOpacity(config.freeTransparency, room);
    transitionStaticObjectsOpacity(config.freeTransparency, room);

    new TWEEN.Tween(room.globals.dolly.position)
      .to({ x: 0, y: 0, z: 0 }, 2000)
      .easing(TWEEN.Easing.Cubic.Out)
      .start()

    const [x, y, z] = config.startPoint
    return new TWEEN.Tween(room.camera.position)
      .to({ x, y, z }, 3000)
      .easing(TWEEN.Easing.Cubic.Out)
  },
  'MAP_START': function () {
    var fovValue = { t: config.fov[0] };
    new TWEEN.Tween(fovValue)
      .to({ t: config.unfoldCamFov }, config.unfoldSpeed * 1)
      .easing(TWEEN.Easing.Circular.Out)
      .onUpdate(function (object) {
        room.camera.fov = fovValue.t;
        room.camera.updateProjectionMatrix();
      }).start();

    return new TWEEN.Tween(room.camera.position)
      .to({ x: config.unfoldCamDistance, y: 0, z: 0 }, config.unfoldSpeed * 1)
      .delay(0)
      .easing(TWEEN.Easing.Sinusoidal.Out)
  },
  'MAP_END': function () {
    var fovValue = { t: config.unfoldCamFov };
    new TWEEN.Tween(fovValue)
      .to({ t: config.fov[0] }, config.unfoldSpeed * 1)
      .easing(TWEEN.Easing.Sinusoidal.Out)
      .onUpdate(function (object) {
        room.camera.fov = fovValue.t;
        room.camera.updateProjectionMatrix();
      }).start();

    return new TWEEN.Tween(room.camera.position)
      .to({ x: 1, y: 0, z: 0 }, config.unfoldSpeed * 1)
      .delay(0)
      .easing(TWEEN.Easing.Sinusoidal.Out)

  }
}




export default transitionHandler