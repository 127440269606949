const KEYMAP = {
  49: { key: '1', action: { trigger: 'TOUR', method: 'START', param: 't1', type: 'CUSTOM' } },
  50: { key: '2', action: { trigger: 'TRANSITION', method: 'ROOM' } },
  51: { key: '3', action: { trigger: 'PANELS', method: 'NORTH' } },
  52: { key: '4', action: { trigger: 'PANELS', method: 'SOUTH' } },
  53: { key: '5', action: { trigger: 'PANELS', method: 'WEST' } },
  54: { key: '6', action: { trigger: 'TRANSITION', method: 'MAP_TOGGLE' } },
  37: { key: 'left arrow', action: { trigger: 'TOUR', method: 'PREV' } },
  39: { key: 'right arrow', action: { trigger: 'TOUR', method: 'NEXT' } }
}
            
const start = [3.203,1.132, -1.848]
export default {
  mountPoint: null,     // existing domElement to mount canvas upon
  startPoint: start,    // initial camera position
  models: ['ROOM_V31'],  // load from /public/models
  // fov: [59.997275, 1.7777777778, 0.01, 100],
  fov: [54.5, window.innerWidth / window.innerHeight, 0.5, 2000],
  
  dollyPosition: {x:0.294, y:0.414, z:-0.104},  
  controls: {
    orbit: { min: 0.1, max: 10.5, minPolar: 1.2, maxPolar: 1.8 },
    zoom: { min: 1, max: 10 }
  },  
  blacklist: [],//["P64", "P65", "P67", "P68", "P97", "P102", "P103", "P104", "P110", "P119", "P122", "P129", "P137"],
  sandbox: false, // (use false to be consumed by web ui)
  showPicturePlanes: false, 
  showDolly: false,   
  customKeys: KEYMAP,
  hideBackWalls: false,  
  pictureRoomBoundingBox: {   
    center: { x: 0.3, y: 1, z: -0.1 },
    size: { x: 6.25, y: 3, z: 4.3 },
  },
  monksChamberBoundingBox: {
    center: { x: 0.15, y: 0.4482712, z: -8.679206 },
    size: { x: 6.25, y: 3.385141, z: 3.114486 },
  },
  tourStartPos: { x:3.203, y:1.132, z:-1.848},
  tourMinZoom: 1,
  tourMaxZoom: 2,
  
  CloseUpTransparency: -0.93,
  tourObjectFocusTransparency: 2,
  tourGlobalTransparency: 0.7,
  unfoldSpeed: 3000,
  unfoldCamDistance: 30,
  unfoldCamFov: 25,
  unfoldCamMaxZoom: 2,

  
  // nearWallName: "clean_nearwall",
  // farWallName: "clean_farwall",
  // rightWallName: "clean_rightwall",
  // leftWallName: "clean_leftwall",
  // roofName: "clean_roof",
  // floorName: "clean_floor",
  // leftCabinetNameTop: "clean_leftcabinet_2_top",
  // leftCabinetNameBottom: "clean_leftcabinet_2_bot",
  // rightCabinetNameTop: "clean_rightcabine_top",
  // rightCabinetNameBottom: "clean_rightcabine_bot",
  
  
  nearWallName: "geo_NearWall",
  farWallName: "geo_Farwall",
  rightWallName: "geo_RightWall",
  leftWallName: "geo_LeftWall",
  roofName: "geo_Roof",
  floorName: "geo_Floor",
  leftCabinetNameTop: "geo_LeftCabinet",
  rightCabinetNameTop: "geo_RightCabinet",
  assets_01: "geo_Assets01",
  assets_02: "geo_Assets02",
  assets_03: "geo_Assets03",
  assets_04: "geo_Assets04",
  assets_04: "geo_Assets05",
}
