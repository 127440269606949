import room from '../framework/index'
import config from "../utils/config"
import {transitionFrontWallsToOpacity, transitionStaticObjectsOpacity } from '../utils/tweens'

const transitionHandler = () => {
  return {
    'TOUR': (pos) => transition('TOUR', 'ROOM_INSIDE', pos),
    'ROOM': () => transition('ROOM', 'ROOM_OUTSIDE')
  }
}

function transition(view, tween, tweenParam) {
  return new Promise((resolve) => {
    const debounce = room.controls.debounceState()
    const endState = debounce(view)
    runTween(tween, tweenParam).then(() => resolve(endState()))
  })
}

function runTween(state, tweenParam) {
  return new Promise((resolve, reject) => {
    const action = cameraMap[state]
    if (action) action(tweenParam).start().onComplete(() => resolve())
    else reject()
  })
}

const cameraMap = {
  'ROOM_INSIDE': function (tourStartPos = config.tourStartPos) {
    return new TWEEN.Tween(room.camera.position)
      .to(tourStartPos, 3000)
      .easing(TWEEN.Easing.Cubic.Out)
  },
  'ROOM_OUTSIDE': function () {
    new TWEEN.Tween(room.globals.dolly.position)
      .to({ x: 0, y: 0, z: 0 }, 2000)
      .easing(TWEEN.Easing.Cubic.Out)
      .start()

    const [x, y, z] = config.startPoint
    return new TWEEN.Tween(room.camera.position)
      .to({ x, y, z }, 3000)
      .easing(TWEEN.Easing.Cubic.Out)
  }
}




export default transitionHandler