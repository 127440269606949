import template from '../templates/tour-carousel.html'
import { library } from '../pictureRoom/js/collections/pictureRoomData'
const assets = '/assets/img/picture-room'

export default {
    name: 'tour-carousel',
    template,
    props: ['collection'],
    data() {
        return {
            current: 0,
        }
    },
    computed: {
        slides() {
            const { sequence } = library.tours.find(tour => tour.id === this.collection)
            return sequence.map(pid => `${assets}/thumbs/${pid}.jpg`)
        }
    },
    methods: {
        background(index) {
            return `background-image: url(${this.slides[index]})`
        },
        next(index) {
            this.current = index //((this.current + 1) < this.related.length) ? this.current + 1 : 0
        },
        prev() {
            this.current = ((this.current - 1) < 0) ? this.related.length - 1 : this.current - 1
        },
    },
}