import Template from '../templates/player.html';
import VJS from 'video.js';
import SpinLoader from './SpinLoader';
// import isIos from 'is-ios'
const isIos = false
let options = {
    controls: false,
    autoplay: false,
    preload: true,
    poster: null,
    loop: false,
    fluid: true,
    controlBar: false
}

export default {
    name: 'player',
    props: ['meta', 'formats', 'controls'],
    route: {
        canReuse: false
    },
    components: [SpinLoader],
    template: Template,
    data () {
        return {
            player: null,
            canPlay: false
        }
    },
    computed: {
        isActive () {
            if (this.meta.name === this.$parent.core.videos.selected) {
                this.player.play();
                return true
            } else {
                if(this.player !== null) {
                    this.player.pause();
                    // allow transition to run before reseting
                    setTimeout(() => {
                        this.player.currentTime(0);
                    }, 400);
                }
                return false
            }
        },
        quality() {
            return (window.innerWidth > 1400) ? this.formats.hir : this.formats.std
        }
    },
    ready () {
        var vm = this;
        options.poster = (this.meta.poster !== null) ? this.meta.poster : false; 
        this.player = VJS(`player--${this.meta.name}`, options, function () {
            let p = this,
                loaded = 0;
            this.ready(() => {

                function vReady() {
                    vm.canPlay = true;
                    vm.$dispatch('video-ready', { name: vm.meta.name, label: vm.meta.label });
                }
                if(isIos) {
                    vReady();
                } else {
                    let wait = setInterval(() => {       
                        if (this.readyState() == 4) {
                            // vm.$parent.$parent.test = this.bufferedPercent();                        
                            vReady();
                            clearInterval(wait);
                        }
                    }, 50);
                }
            });
            
            // event listener
            this.on('ended', () => {
                vm.$dispatch('video-finished', { name: vm.meta.name });
            });
        });
    }
}