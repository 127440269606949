// import * as THREE from 'three'
import config from '../utils/config'
import modelFactory from './modelFactory'
import {
  CalculateCenterOfMass, NorthOpenTransition, NorthCloseTransition,
  SouthOpen0Transition, SouthClose0Transition, SouthOpen1Transition,
  SouthClose1Transition, WestOpenTransition, WestCloseTransition
} from '../utils/tweens.js'

const loader = new THREE.GLTFLoader();
const path = config.sandbox ? './model' : './assets/models'
// function loadModels(assetFactory, scene, complete) {


function SetTransparency(parent, transparency) {
  parent.traverse(function (child) {
    if (child.type == 0) {
      child.material.uniforms.transparency.value = transparency;
    }
  });
}

function loadModels(assetFactory, scene, complete, globals, room) {
  let loaded = 0
  return (target) => {
    loader.load(`${path}/${target}/${target}.txt`, (gltf) => {
      gltf.scene.traverse(assetFactory)
      gltf.scene.scale.set(1, 1, 1)
      scene.add(gltf.scene)
      loaded++
      if (loaded === config.models.length) {
        complete()

        scene.roomWalls = [];
        scene.monksWalls = [];
        globals.northObjects = {};
        globals.westObjects = {};
        globals.eastObjects = {};
        globals.southObjects = {};
        globals.mapModeObjects = {};
        globals.mapModeObjects.parents = {};
        globals.mapModeObjects.toFadeOff = [];
        globals.mapModeObjects.toFadeIn = [];
        globals.mapModeObjects.walls = {};
        scene.traverse(function (child) {
          if (
            // child.name == "PIVOT_EAST_WALL" || //East
            child.name == "_Obj_EB_t_" || child.name == "_Obj_EB_t_LEFT" || child.name == "_Obj_EB_t_RIGHT" || //East
            child.name == "PIVOT_PANEL_N_LEFT" || child.name == "PIVOT_PANEL_N_RIGHT" || child.name == "PIVOT_WALL_NB" || //North
            child.name == "PIVOT_PANEL_S_LEFT_1" || child.name == "PIVOT_PANEL_S_RIGHT_1" || child.name == "PIVOT_PANEL_S_LEFT_2" || child.name == "PIVOT_PANEL_S_RIGHT_2" || //South
            child.name == "PIVOT_PANEL_W_LEFT" || child.name == "PIVOT_PANEL_W_RIGHT" || child.name == "PIVOT_WALL_WB" ||//West   
            child.name == "_Obj_CORNERS_Y_t" || child.name == "_Obj_CORNERS_X_t" || child.name == "_Obj_CORNERS_X_t_02" || //Corners
            child.name == "_Obj_FLOOR_X_t" || child.name == "_Obj_FLOOR_X_t_02" || child.name == "_Obj_FLOOR_Y_t" || child.name == "_Obj_FLOOR_Y_t_02" || child.name == "_Obj_FLOOR_Z_t" || //Floor
            child.name == "_Obj_CEILING_ROOF_V02_t" || child.name == "_Obj_CEILING_NORTH_t" || child.name == "_Obj_CEILING_SOUTH_t" || child.name == "_Obj_CEILING_WEST_t" //Ceiling
          ) {
            var centerOfMass = CalculateCenterOfMass(child);
            if (child.name == "_Obj_EB_t" || child.name == "_Obj_EB_t_LEFT") centerOfMass.y -= 1;
            //if(child.name == "_Obj_EB_t" ) child.visible=false;

            child.centerOfMass = centerOfMass;

            //Reorder children
            var parents = new Set();
            child.traverse(function (grandChild) {
              if (grandChild.name.includes("Slice")) {
                parents.add(grandChild.parent);
              }
            });

            parents.forEach((parent) => {
              //Patch for some panels having ordering issues
              if (parent.name == "_Obj_SL2F_t" || parent.name == "_Obj_SR2F_t" || parent.name == "_Obj_NR1F_t") {
                parent.children.sort((a, b) => (a.name < b.name) ? 1 : ((b.name < a.name) ? -1 : 0))
              }
              else {
                parent.children.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
              }
            })

            scene.roomWalls.push(child);
          }
          if (
            child.name == "MC_CEILING" || child.name == "_Obj_MCB1_LEFT" || child.name == "_Obj_MCB1_MID" || child.name == "_Obj_MCB1_RIGHT" ||
            child.name == "PIVOT_MCB2" || child.name == "PIVOT_MCF1" || child.name == "PIVOT_MCL1" || //MC
            child.name == "PIVOT_MCL2" || child.name == "PIVOT_MCR1" || child.name == "PIVOT_MCR2" ||
            child.name == "_Obj_MC_FLOOR_X_BACK" || child.name == "_Obj_MC_FLOOR_X_FRONT"
          ) {
            var centerOfMass = CalculateCenterOfMass(child);
            child.centerOfMass = centerOfMass;

            var parents = new Set();
            child.traverse(function (grandChild) {
              if (grandChild.name.includes("Slice")) {
                parents.add(grandChild.parent);
              }
            });
            parents.forEach((parent) => {
              parent.children.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
            })

            scene.monksWalls.push(child);
          }

          //Parents
          if (child.name == "EXPORTS_FLOOR") {
            globals.mapModeObjects.parents.Floor = child;
          }
          if (child.name == "EXPORTS_CEILING") {
            globals.mapModeObjects.parents.Ceiling = child;
          }
          if (child.name == "EXPORTS_MONKS_CHAMBER") {
            globals.mapModeObjects.parents.MonksChamber = child;
          }

          //Fade off
          if (child.name == "CORNERS") {
            globals.mapModeObjects.toFadeOff.push(child);
          }
          if (child.name == "_Obj_FLOOR_Z_t_02") {
            globals.mapModeObjects.toFadeOff.push(child);
          }
          if (child.name == "EXPORTS_MONKS_CHAMBER_FLOOR") {
            globals.mapModeObjects.toFadeOff.push(child);
          }
          if (child.name == "_0015_MC_CEILING_Slice_") {
            globals.mapModeObjects.toFadeOff.push(child);
          }
          if (child.name == "_001_MC_CEILING_Slice_") {
            globals.mapModeObjects.toFadeOff.push(child);
          }

          //2. map walls
          if (child.name == "PIVOT_SOUTH_WALL") {
            globals.mapModeObjects.SouthWall = child;
          }
          if (child.name == "PIVOT_NORTH_WALL") {
            globals.mapModeObjects.NorthWall = child;
          }
          if (child.name == "MC_CEILING") {
            globals.mapModeObjects.McCeiling = child;
          }
          if (child.name == "PIVOT_MCB2") {
            globals.mapModeObjects.McCeiling = child;
          }
          if (child.name == "PIVOT_MCF1") {
            globals.mapModeObjects.McCeiling = child;
          }

          //MC Walls
          if (child.name == "PIVOT_MCL1") {
            globals.mapModeObjects.MCL1 = child;
          }
          if (child.name == "PIVOT_MCL2") {
            globals.mapModeObjects.MCL2 = child;
          }
          if (child.name == "PIVOT_MCR1") {
            globals.mapModeObjects.MCR1 = child;
          }
          if (child.name == "PIVOT_MCR2") {
            globals.mapModeObjects.MCR2 = child;
          }
          if (child.name == "PIVOT_MCB2") {
            globals.mapModeObjects.MCB2 = child;
          }
          if (child.name == "MC_CEILING") {
            globals.mapModeObjects.MCCeiling = child;
          }
          if (child.name == "PIVOT_MCF1") {
            globals.mapModeObjects.MCF1 = child;
          }

          //North
          if (child.name == "PIVOT_WALL_NB") {
            globals.northObjects.background = child;
            globals.mapModeObjects.toFadeIn.push(child)
          }
          if (child.name == "PIVOT_PANEL_N_LEFT") {
            child.userData.panel.transparencyOpen = () => NorthOpenTransition(room);
            child.userData.panel.transparencyClose = () => NorthCloseTransition(room);
          }
          if (child.name == "NL1F") {
            globals.northObjects.panelLeftFront = child;
          }
          if (child.name == "NL1B") {
            globals.northObjects.panelLeftBack = child;
            globals.mapModeObjects.toFadeIn.push(child)
          }
          if (child.name == "NR1F") {
            globals.northObjects.panelRightFront = child;
          }
          if (child.name == "NR1B") {
            globals.northObjects.panelRightBack = child;
            globals.mapModeObjects.toFadeIn.push(child)
          }

          //West
          if (child.name == "PIVOT_WEST_WALL") {
            globals.westObjects.parent = child;
            globals.mapModeObjects.WestWall = child;
          }
          if (child.name == "PIVOT_PANEL_W_LEFT") {
            child.userData.panel.transparencyOpen = () => WestOpenTransition(room);
            child.userData.panel.transparencyClose = () => WestCloseTransition(room);
          }
          if (child.name == "_Obj_WB_t_02") {
            globals.westObjects.middleBackground = child;
          }
          if (child.name == "_Obj_WB_t") {
            globals.westObjects.sideBackground = child;
            globals.mapModeObjects.toFadeIn.push(child)
          }
          if (child.name == "WL1F") {
            globals.westObjects.panelLeftFront = child;
          }
          if (child.name == "WL1B") {
            globals.westObjects.panelLeftBack = child;
            globals.mapModeObjects.toFadeIn.push(child)
          }
          if (child.name == "WR1F") {
            globals.westObjects.panelRightFront = child;
          }
          if (child.name == "WR1B") {
            globals.westObjects.panelRightBack = child;
            globals.mapModeObjects.toFadeIn.push(child)
          }

          //East
          if (child.name == "PIVOT_EAST_WALL") {
            globals.eastObjects.parent = child;
          }

          //South
          if (child.name == "PIVOT_PANEL_S_LEFT_1") {
            child.userData.panel.transparencyOpen = () => SouthOpen0Transition(room);
            child.userData.panel.transparencyClose = () => SouthClose0Transition(room);
          }
          if (child.name == "PIVOT_PANEL_S_LEFT_2") {
            child.userData.panel.transparencyOpen = () => SouthOpen1Transition(room);
            child.userData.panel.transparencyClose = () => SouthClose1Transition(room);
          }
          if (child.name == "SL2F") {
            globals.southObjects.frontLeft0 = child;
          }
          if (child.name == "SL2B") {
            globals.southObjects.backLeft0 = child;
            globals.mapModeObjects.toFadeIn.push(child)
          }
          if (child.name == "SL1F") {
            globals.southObjects.frontLeft1 = child;
            globals.mapModeObjects.toFadeIn.push(child)
          }
          if (child.name == "SL1B") {
            globals.southObjects.backLeft1 = child;
            globals.mapModeObjects.toFadeIn.push(child)
          }
          if (child.name == "SR2F") {
            globals.southObjects.frontRight0 = child;
          }
          if (child.name == "SR2B") {
            globals.southObjects.backRight0 = child;
            globals.mapModeObjects.toFadeIn.push(child)
          }
          if (child.name == "SR1F") {
            globals.southObjects.frontRight1 = child;
            globals.mapModeObjects.toFadeIn.push(child)
          }
          if (child.name == "SR1B") {
            globals.southObjects.backRight1 = child;
            globals.mapModeObjects.toFadeIn.push(child)
          }

          if (child.name == "EXPORTS_MONKS_CHAMBER") {
            globals.southObjects.MonksChamber = child;
          }

        });


        //Set initial material properties for objects
        //North wall
        SetTransparency(globals.northObjects.background, -1);
        SetTransparency(globals.northObjects.panelRightBack, -1);
        SetTransparency(globals.northObjects.panelLeftBack, -1);
        SetTransparency(globals.northObjects.panelRightFront, 0);
        SetTransparency(globals.northObjects.panelLeftFront, 0);

        //South wall
        SetTransparency(globals.southObjects.frontLeft0, 0);
        SetTransparency(globals.southObjects.frontRight0, 0);
        SetTransparency(globals.southObjects.backLeft0, -1);
        SetTransparency(globals.southObjects.backRight0, -1);
        SetTransparency(globals.southObjects.frontLeft1, -1);
        SetTransparency(globals.southObjects.frontRight1, -1);
        SetTransparency(globals.southObjects.backLeft1, -1);
        SetTransparency(globals.southObjects.backRight1, -1);

        //West wall
        SetTransparency(globals.westObjects.panelRightBack, -1);
        SetTransparency(globals.westObjects.panelLeftBack, -1);
        SetTransparency(globals.westObjects.sideBackground, -1);

        //Set buttons transparency

      }
    })
  }
}
export function loadAssets(room) {
  const assetFactory = modelFactory(room.globals, room)
  return new Promise((resolve) => {
    const loadHandler = loadModels(assetFactory, room.scene, resolve, room.globals, room)
    config.models.forEach(loadHandler)
  })
}

export function loadTests(globals, scene) {
  const boxGeom = new THREE.BoxGeometry(0.25, 0.25, -0.25);
  const material = new THREE.MeshBasicMaterial({ color: 'green', side: THREE.FrontSide });
  material.depthTest = false
  material.depthWrite = false
  material.depthFunc = THREE.NeverDepth
  const t1 = new THREE.Mesh(boxGeom, material)
  const t2 = new THREE.Mesh(boxGeom, material)
  const t3 = new THREE.Mesh(boxGeom, material)
  const t4 = new THREE.Mesh(boxGeom, material)
  const t5 = new THREE.Mesh(boxGeom, material)
  t1.userData = { id: 'P001', tour: { gimbal: 'EAST', panels: 'CLOSE', }, wall: 'NORTH' }
  t2.userData = { id: 'P002', tour: { gimbal: 'SOUTH', panels: 'CLOSE', }, wall: 'NORTH' }
  t3.userData = { id: 'P003', tour: { gimbal: 'WEST', panels: 'CLOSE', }, wall: 'NORTH' }
  t4.userData = { id: 'P004', tour: { gimbal: 'WEST', panels: 'CLOSE', }, wall: 'NORTH' }
  t5.userData = { id: 'P005', tour: { gimbal: 'NORTH', panels: 'CLOSE', }, wall: 'NORTH' }
  t1.position.set(-1.75, 0, 0) // E
  t2.position.set(0, 0, -1.75) // S
  t3.position.set(1.75, 0, -1) // W1
  t4.position.set(1.75, 0, 1) // W2
  t5.position.set(0, 0, 1.75) // N
  globals.tests = [t1, t2, t3, t4, t5]
  globals.tests.forEach(block => scene.add(block))
}

// }