/**
 * Inspector View
 * Loads text content details for relevant Object 
 */
import Template from '../templates/inspector--drawer.html'
import Content from '../data/content';
import core from '../data/core';

// import ViewMap from './ViewMap';
import ViewAbout from './ViewAbout';
import ViewFinder from './ViewFinder';

const { layout, state } = core

export default {
    name: 'inspector',
    template: Template,
    components: [ViewAbout, ViewFinder],
    route: {
        canReuse: false
    },
    props: ['data', 'poi'],
    data() {
        return {
            related: true,
            model: Content.find(this.$route.params.section, this.$route.params.detail),
            state,
            layout,
            currentComponent: 'view-about'
        }
    },
    methods: {
        exit() {
            window.history.back();
        }
    },
    ready() {
        // layout.modal = true;
        this.$on('map', () => {
            this.layout.overlay = true;
            this.currentComponent = 'view-map'
        })

        this.$on('abt', (data) => {
            this.state.set('inspect');
            this.layout.overlay = true;
            this.currentComponent = 'view-about'
        })

        this.$on('img', (data) => {
            // this.viewer = (data != undefined) ? data : null
            this.layout.overlay = true;
            this.currentComponent = 'view-finder'
            this.state.set('inspect'); // deprecate
        })
        this.layout.overlay = true;
        this.currentComponent = 'view-about';
        this.$on('close-inspect', () => {
            this.currentComponent = null
            this.layout.overlay = false
            this.$parent.inspecting = false
            this.layout.inspecting = false
            this.state.set('pointcloud')
            this.layout.modal = false;
            this.$router.back()
        })
    },
    beforeDestroy() {
        // layout.modal = false;
        this.$parent.inspecting = false
        this.layout.overlay = false;
    }
}