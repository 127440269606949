/**
 * VideoData : Video configs
 */

var path = 'assets/media/';

export default {
    current: {
        name: null,
        isReady: false,
        isPlaying: false,
    },
    meta: [
        {
            label: 'Picture Room',
            name: 'pictureroom',
            poster: `${path}poster.jpg`,
            width: 1280,
            height: 720,
            formats: [
                {
                    type: 'video/mp4',
                    src: `${path}Enter_PictureRoom.mp4`
                }
            ],
            srcs: {
                type: 'video/mp4',
                std: `${path}Enter_PictureRoom.mp4`,
                hir: `${path}hires/Enter_PictureRoom.mp4`
            }
        },
        {
            label: 'Drawing Office',
            name: 'drawingoffice',
            poster: `${path}poster.jpg`,
            width: 1280,
            height: 720,
            formats: [
                {
                    type: 'video/mp4',
                    src: `${path}Enter_DrawingOffice.mp4`
                }
            ],
            srcs: {
                type: 'video/mp4',
                std: `${path}Enter_DrawingOffice.mp4`,
                hir: `${path}hires/Enter_DrawingOffice.mp4`
            }
        },
        {
            label: 'Model Room',
            name: 'modelroom',
            poster: `${path}poster.jpg`,
            width: 1280,
            height: 720,
            formats: [
                {
                    type: 'video/mp4',
                    src: `${path}Enter_Modelroom_v1.mp4`
                }
            ],
            srcs: {
                type: 'video/mp4',
                std: `${path}Enter_Modelroom_v1.mp4`,
                hir: `${path}hires/Enter_Modelroom_v1.mp4`
            }
        },
        {
            label: 'Sepulchral Chamber',
            name: 'sepulchral',
            poster: `${path}poster.jpg`,
            width: 1280,
            height: 720,
            formats: [
                {
                    type: 'video/mp4',
                    src: path + 'Enter_Sarco_720.mp4'
                }
            ],
            srcs: {
                type: 'video/mp4',
                std: path + 'Enter_Sarco_720.mp4',
                hir: path + 'hires/' + 'Enter_Sarco_1080.mp4'
            }
        }
        // {
        //     name: 'Tour',
        //     route: "tour",
        //     poster: `${path}poster.jpg`,
        //     width: 1280,
        //     height: 720,
        //     formats: [
        //         {
        //             type: 'video/mp4',
        //             src: path + '/Enter_Soane_Tour_v1.mp4'
        //         }
        //     ],
        //     srcs: {
        //          type: 'video/mp4',
        //          std: path + 'Enter_Soane_Tour_v1.mp4',
        //          hir: path + 'hires/' +'Enter_Soane_Tour_v1.mp4'
        //     }
        // }
    ]
}

