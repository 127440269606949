import Entrance from './components/Entrance';
import Potree from './components/PotreeViewer2'
import Room from './components/Room';
import PictureRoom from './components/PictureRoom';
import DrawingOffice from './components/DrawingOffice';
import AboutPage from './components/AboutPage';
import DownloadPage from './components/DownloadPage';
import Inspector from './components/DrawerInspector';

import Core from './data/core';


export default function (router, app) {
    router.map({
        '/': {
            name: 'root',
            component: Entrance
        },
        '/section/:section': {
            name: 'section',
            component: Room,
            subRoutes: {
                '/:pointcloud': {
                    name: 'pointcloud',
                    component: Potree,
                    subRoutes: {
                        '/inspect/:detail': {
                            name: 'inspect',
                            component: Inspector
                        }
                    }
                }
            }
        },
        '/about': {
            name: 'about',
            component: AboutPage
        },
        '/downloads': {
            name: 'downloads',
            component: DownloadPage
        },
        '/picture-room': {
            name: 'pictureroom',
            component: PictureRoom,
        },
        '/drawing-office': {
            name: 'drawingoffice',
            component: DrawingOffice,
        },
    })

        .beforeEach((transition) => {
            Core.closePages();
            transition.next()
        })
        .afterEach((route) => {
            Core.state.set(route.to.name);
            //     // Core.history.add(route.to) // history is being added -> needs to be deprecated // but navigation width will diminish - until css is used to fix it
            //     // if(route.to.name !== 'root') 
            Core.history.push(route.to)
            Core.layout.mode(route.to)
        });

}