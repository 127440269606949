import Template from '../templates/view-drawing.html'
import TourCarousel from './TourCarousel'

// deprecate TourCarosel?

export default {
  name: 'view-drawing',
  template: Template,
  components: [TourCarousel],
  props: ['page', 'core', 'model'],
  data() {
    return {
      show: false,
      fragment: null
    }  
  },
  computed: {
    source() {
      const assets = '/assets/img/drawing-office'
      if (!this.model) return ''
      return `${assets}/thumbs/${this.model.soane_id}.jpg`
    },
    relatedDescription() {
      if(!this.model) return '';
      return this.model.relatedDescription;   
    },
    showImage()
    {
      if(this.model.id==='OBJ12')return false;
      return true;  
    },  
    showDetails()
    {
      if(this.model.id==='OBJ12')return false;
      return true;  
    }
  },
  ready() {
    this.core.drawingOffice.magnifyParent = null
  },
  methods: {
    magnify() {
      this.core.drawingOffice.magnifyParent = this.$parent
      this.$parent.currentComponent = 'view-magnify'
    },
    exitTour() {
      // works
      const { view } = this.core.drawingOffice
      if (view === 'TOUR') {
        // this.$parent.$parent.$parent.$dispatch('leave-tour')
        // this.$parent.$parent.$broadcast('leave-tour')
        // this.$parent.$parent.$emit('leave-tour')
        // this.$parent.$parent.$dispatch('close-drawing-inspector')
        this.$parent.$parent.$parent.$broadcast('exit-tour')
        // this.$parent.$parent.$dispatch('close-drawing-inspector')
      }  
    },
    gotoURL(href) {
      window.open(href)
    },
    toggleFullWidth() {
      this.$dispatch('toggleFullWidth')
    },
    next() {
      this.$parent.$parent.sandbox.app.onAction({ trigger: 'TOUR', method: 'NEXT', type: 'CTA' })
      this.core.drawingOffice.tour.hasStarted = true
    },
    prev() {
      if (this.core.drawingOffice.tour.isLast) this.core.drawingOffice.tour.isLast = false
      this.$parent.$parent.sandbox.app.onAction({ trigger: 'TOUR', method: 'PREV', type: 'CTA' })

    }
  },
  beforeDestroy() {
    if (this.fragment) this.fragment.$destroy;
  }
}