import template from '../templates/notice.html';

export default {
  name: 'notice',
  props: ['core'],
  template,
  data() {
    return {
      show: false,
      direction: ''
    }
  },
  computed: {
    fsMode() {
      return 'x--half' //(this.core.layout.full) ? 'x--full' : 'x--half'
    },
    message() {
      return `Panel doors are ${this.direction}`
    }

  },
  methods: {
    reveal() {
      this.show = true;
      setTimeout(() => {
        this.show = false;
        this.$dispatch('help-deactive')
      }, 2000);
    }
  },
  ready() {
    this.$on("panels-notice", (state) => {
      this.direction = state
      this.reveal()
    })
  }
}