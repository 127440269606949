import Template from '../templates/page-about.html'
import Core from '../data/core';
import ViewFinder from './ViewFinder';
// var GeminiScrollbar = require('gemini-scrollbar');
export default {
  name: 'about-page',
  template: Template,
  components: [ViewFinder],
  data() {
    return {
      core: Core,
      thumb: "/assets/img/_thumbs/sect.jpg",
      magnify: false,
      model: {
        img: {
          src: "/assets/img/_thumbs/sect.jpg",
          hir: "/assets/img/sect_high.jpg",
          w: 940,
          h: 529
        }
      },
      scale: (768 / 4705) + .25,
      viewer: 'view-finder'
    }
  },
  methods: {
    toggle() {
      this.magnify = !this.magnify
    },
    close() {
        Core.closePages()
    }
  },
  ready() {
    Core.layout.overlay = true;
  },
  beforeDestroy() {
    Core.layout.overlay = false;
  }
}