import pictureCollection from "../collections/pictureCollection"
import config from "../utils/config"
import { RoomOrbit, InspectOrbit } from "../utils/tweens"
class EventControls {
  constructor(parentScope) {
    this.pictureId = null
    this.listeners = []
    this.raycaster = new THREE.Raycaster()
    this.room = parentScope
    this.init()
  }
  init() {
    document.addEventListener('mousemove', this.HandleMouseMove.bind(this))
    // TODO - capture touches ^^

    // if (config.sandbox) document.addEventListener('click', (e) => this.panelDoorHandlerClick(e))
    if (config.sandbox) document.addEventListener('touchstart', (e) => this.panelDoorHandlerTouch(e))
    if (config.sandbox) document.addEventListener('keydown', (e) => this.handleKeys(e))

    this.orbit = new THREE.OrbitControls(this.room.camera, this.room.renderer.domElement);
    this.orbit.enablePan = false;
    this.orbit.minPolarAngle = config.controls.orbit.minPolar;
    this.orbit.maxPolarAngle = config.controls.orbit.maxPolar;
    this.orbit.minDistance = config.controls.orbit.min
    this.orbit.maxDistance = config.controls.orbit.max
    this.orbit.target = this.room.globals.dolly.position

    
  }

  updateViewport2() {
    const pageWidth = 768
    const mq = 1536
    const fullscreen = !this.room.renderer.domElement.classList.contains('half')
    const ems = 2.25 * 16
    const halfwidth = window.innerWidth / 2
    let dynamicWidth = halfwidth + ems
    if (!fullscreen && window.innerWidth > mq) {
      // ratio = (window.innerWidth - pageWidth) / window.innerWidth
      const diff = halfwidth - 728
      dynamicWidth = halfwidth + diff
    }
    const width = fullscreen ? window.innerWidth : dynamicWidth
    this.room.renderer.setSize(width, window.innerHeight);
    this.room.camera.aspect = (width) / window.innerHeight
    this.room.camera.updateProjectionMatrix()
  }
  // TODO - can deprecate
  updateViewport() {
    const isHalf = this.room.renderer.domElement.classList.contains('half')
    const ratio = isHalf ? .5 : 1
    const ems = 2.25 * 16
    const width = isHalf ? window.innerWidth * ratio + ems : window.innerWidth
    this.room.renderer.setSize(width, window.innerHeight);
    this.room.camera.aspect = (width) / window.innerHeight
    this.room.camera.updateProjectionMatrix()
  }
  getViewportDimensions(isFullWidth = true) {
    // isFullWidth - current app view
    const aspect = 0.5626
    const inspectorWidth = 768
    const viewportWidth = isFullWidth ? window.innerWidth : window.innerWidth - inspectorWidth
    const viewportHeight = viewportWidth * aspect
    const vHeightHalf = viewportHeight / 2 // needed IF pEndY is used
    const pStartY = (window.innerHeight / 2) - vHeightHalf
    const pEndY = pStartY + vHeightHalf // needed?
    return {
      x: 0,
      y: pStartY,
      width: viewportWidth,
      height: viewportHeight
    }
  }

  setViewport(full) {
    const { x, y, width, height } = this.getViewportDimensions(full)

    this.room.camera.aspect = width / height
    this.room.renderer.setSize(width, window.innerHeight)
    this.room.renderer.setViewport(0, y, width, height)
    this.room.camera.updateProjectionMatrix()
  }

  tweenFromVideo(callback) {
    let target = this.getViewportDimensions()
    if (window.innerWidth / window.innerHeight > 1.5) return this.updateViewport2()
    new TWEEN.Tween(target) // Create a new tween that modifies 'coords'.
      .delay(5000)
      .to({ y: 0, height: window.innerHeight }, 500)
      .easing(TWEEN.Easing.Quadratic.Out) // Use an easing function to make the animation smooth.
      .onUpdate(() => {
        this.room.camera.aspect = target.width / target.height
        this.room.renderer.setSize(target.width, window.innerHeight)
        this.room.renderer.setViewport(0, target.y, target.width, target.height)
        this.room.camera.updateProjectionMatrix()
      })
      .start()
      .onComplete(() => callback())
  }

  // use to set controls and debounce between interactions
  updateControls(action = null) {

    switch (this.room.state.controls) {
      case 'PAN':
        this.lockOrbit()
        this.usePan()
        return
      case 'ORBIT':
        this.lockPan()
        this.useOrbit(action)
        return
      default:
        this.lockOrbit()
        this.lockPan()
    }
  }
  handleKeys(e) {
    const keypress = config.customKeys[e.keyCode]
    if (!keypress || this.room.state.controls === 'TWEEN') return
    const { trigger, method, param, type } = keypress.action
    if (type === 'CUSTOM') return this.room.onAction({ type: 'CUSTOM', trigger, method, param })
    this.room.onAction({ type: 'CTA', trigger, method }).then(() => console.log('handlekeys onAction complete'))
  }
  clickHandler() {
    if (this.room.state.view === 'MAP' && this.pictureId) {
      const data = pictureCollection.find(pic => pic.id === this.pictureId)
      const callback = (next) => next({ data, message: 'open web ui image inspector' })
      this.room.onAction({ type: 'TBC', trigger: "VIEW_PICTURE", callback })
    }
  }

  panelDoorHandler(x, y) {
    this.raycaster.setFromCamera({
      x: x,
      y: y
    }, this.room.camera)

    //Find the target that is visible
    var targets = this.raycaster.intersectObjects(this.room.globals.buttonsArray)
    var target;
    for (var i = 0; i < targets.length; i++) {
      if (targets[i].object.material.uniforms.globalTransparency.value != 0 && targets[i].object.material.uniforms.transparency.value != 0) {
        target = targets[i];
        break;
      }   
    }  
  }

  panelDoorHandlerClick(event) {
    this.panelDoorHandler((event.clientX / this.room.renderer.domElement.clientWidth) * 2 - 1,
      -(event.clientY / this.room.renderer.domElement.clientHeight) * 2 + 1);

  }
  panelDoorHandlerTouch(event) { 
    this.panelDoorHandler((event.targetTouches[0].clientX / this.room.renderer.domElement.clientWidth) * 2 - 1,
      -(event.targetTouches[0].clientY / this.room.renderer.domElement.clientHeight) * 2 + 1);
  }

  HandleMapTouch(tx, ty) {
    if (this.room.state.controls == 'PAN') {
      this.raycaster.setFromCamera({
        x: (tx / this.room.renderer.domElement.clientWidth) * 2 - 1,
        y: -(ty / this.room.renderer.domElement.clientHeight) * 2 + 1
      }, this.room.camera)
      const [target] = this.raycaster.intersectObjects(this.room.globals.pics)
      if (target != undefined) {
        this.pictureId = target.object.userData.id
        if (this.prevTarget == null || this.prevTarget.object != target.object) {
          new TWEEN.Tween(target.object.material).to({ opacity: 0.2 }, 10).start()
          if (this.prevTarget != null) new TWEEN.Tween(this.prevTarget.object.material).to({ opacity: 0 }, 10).start()
          const data = pictureCollection.find(pic => pic.id === this.pictureId)
          const callback = (next) => next({ data, message: 'open web ui image inspector' })
          this.room.onAction({ type: 'TBC', trigger: "VIEW_PICTURE", callback })
        }
        setTimeout(() => { this.prevTarget = target }, 100)
      } else {
        if (this.prevTarget != null) {
          new TWEEN.Tween(this.prevTarget.object.material).to({ opacity: 0 }, 10).start()
          this.prevTarget = null
          this.pictureId = null
        }
      }
    }
  }
  HandleMouseMove(event) {
    if (this.room.state.controls == 'PAN') {
      this.raycaster.setFromCamera({
        x: (event.clientX / this.room.renderer.domElement.clientWidth) * 2 - 1,
        y: -(event.clientY / this.room.renderer.domElement.clientHeight) * 2 + 1
      }, this.room.camera)
      const [target] = this.raycaster.intersectObjects(this.room.globals.pics)
    }
  }
  debounceState() {
    const ctx = this.room
    return (view) => {
      const restore = view ? ctx.deriveState(view) : { ...ctx.state }
      // if (view == "TOUR" || view == undefined || view == "MAP") {
      //   console.log("UNLOCK 2");
      //   //While traveling
      //   this.orbit.minDistance = 0
      //   this.orbit.maxDistance = 100000000
      //   this.orbit.minPolarAngle = 0;
      //   this.orbit.maxPolarAngle = Math.PI;
      //   this.orbit.minAzimuthAngle = -6.28318530718;
      //   this.orbit.maxAzimuthAngle = 6.28318530718;;
      // }
      // else {
      //   //
      //   console.log("LOCK 2");
      //   this.orbit.minDistance = config.controls.orbit.min
      //   this.orbit.maxDistance = config.controls.orbit.max
      //   this.orbit.minPolarAngle = config.controls.orbit.minPolar;
      //   this.orbit.maxPolarAngle = config.controls.orbit.maxPolar;
      //   this.orbit.minAzimuthAngle = -6.28318530718;
      //   this.orbit.maxAzimuthAngle = 6.28318530718;
      // }

      ctx.lockControls()
      return () => ctx.applyState(restore)
    }
  }
  usePan() {
    this.orbit.enabled = true;
    this.orbit.enablePan = true
    this.orbit.enableRotate = false
    this.orbit.panSpeed = 6
    this.orbit.maxDistance = config.unfoldCamDistance;
  }
  lockPan() {
    this.orbit.enablePan = false
  }
  useOrbit(action = null) {
    this.orbit.enabled = true
    this.orbit.enableRotate = true;
    if (this.room.state.view === 'ROOM') {
      if (this.room.state.camera == "INSIDE") {
        // console.log("LOCK 1", this.room.state);
        this.orbit.target = this.room.globals.dolly.position;
        InspectOrbit(this.orbit, this.room.currentFocus);
      }
      else {
        // console.log("UNLOCK 1");
        RoomOrbit(this.orbit);
      }
    }

    // ever called?
    if (this.room.state.view === 'MAP') {
      // this.orbit.maxPolarAngle = -Math.PI / 2
      // this.orbit.minPolarAngle = Math.PI / 2
    }
  }
  lockOrbit() {
    this.orbit.enabled = false;
  }
  _zoom() {
    //   const event = d3.event;
    //   if (event.sourceEvent) {
    //     const new_z = event.transform.k;
    //     if (new_z !== this.room.camera.position.z) {
    //       const { clientX, clientY } = event.sourceEvent;
    //       // Project a vector from current mouse position and zoom level
    //       // Find the x and y coordinates for where that vector intersects the new zoom level.
    //       // Code from WestLangley https://stackoverflow.com/questions/13055214/mouse-canvas-x-y-to-three-js-world-x-y-z/13091694#13091694
    //       const vector = new THREE.Vector3(clientX / window.innerWidth * 2 - 1, -(clientY / window.innerHeight) * 2 + 1, 1);
    //       vector.unproject(this.room.camera);
    //       const dir = vector.sub(this.room.camera.position).normalize();
    //       const distance = (new_z - this.room.camera.position.z) / dir.z;
    //       const pos = this.room.camera.position.clone().add(dir.multiplyScalar(distance));
    //       // Set the this.camera to new coordinates
    //       this.room.camera.position.set(pos.x, pos.y, new_z);
    //     } else {
    //       // Handle panning
    //       const { movementX, movementY } = event.sourceEvent;
    //       // Adjust mouse movement by current scale and set this.camera
    //       const current_scale = getCurrentScale(this.room.camera);
    //       this.room.camera.position.set(this.room.camera.position.x - movementX / current_scale, this.room.camera.position.y +
    //         movementY / current_scale, this.room.camera.position.z);
    //     }
    //   }
  }
}

function getCurrentScale(camera) {
  var vFOV = camera.fov * Math.PI / 180
  var scale_height = 2 * Math.tan(vFOV / 2) * camera.position.z
  var currentScale = window.innerHeight / scale_height
  return currentScale
}



export default EventControls