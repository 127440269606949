import pictureCollection from "./pictureCollection"
const none = { id: 'ID NOT FOUND - missing P?', wall: null }

export function getPicture(target) {
  const data = pictureData.find(({ id }) => id === target)
  return data || { ...none }
}

const positionData = [
  { id: 'OBJ01', wall: 'WEST', tour: { meta: null, panels: 'CLOSE', gimbal: 'WEST', cameraPosition: {x:3.30101, y: 1.3167, z: -1.476963} } },
  { id: 'OBJ02', wall: 'EAST', tour: { meta: null, panels: 'CLOSE', gimbal: 'EAST', cameraPosition: {x:2.33320, y: 0.7763, z: -1.069724} } },
  { id: 'OBJ03', wall: 'EAST', tour: { meta: null, panels: 'CLOSE', gimbal: 'EAST', cameraPosition: {x: 2.28269267, y: 1.68746078, z: -0.5756969} } },
  { id: 'OBJ04', wall: 'SOUTH', tour: { meta: null, panels: 'CLOSE', gimbal: 'SOUTH', cameraPosition:null } },
  { id: 'OBJ05', wall: 'SOUTH', tour: { meta: null, panels: 'CLOSE', gimbal: 'SOUTH', cameraPosition:null } },
  { id: 'OBJ06', wall: 'SOUTH', tour: { meta: null, panels: 'CLOSE', gimbal: 'SOUTH', cameraPosition:null } },
  { id: 'OBJ07', wall: 'WEST', tour: { meta: null, panels: 'CLOSE', gimbal: 'WEST' , cameraPosition: {x:-1.4418, y: 1.37203, z: 1.14515} } },
  { id: 'OBJ07B', wall: 'WEST', tour: { meta: null, panels: 'CLOSE', gimbal: 'WEST', cameraPosition:null } },
  { id: 'OBJ08', wall: 'WEST', tour: { meta: null, panels: 'CLOSE', gimbal: 'WEST', cameraPosition:null } },
  { id: 'OBJ09', wall: 'WEST', tour: { meta: null, panels: 'CLOSE', gimbal: 'WEST' , cameraPosition: {x:-1.4556, y: 1.4735, z: -1.07665}} },
  { id: 'OBJ10', wall: 'WEST', tour: { meta: null, panels: 'CLOSE', gimbal: 'WEST' , cameraPosition: {x:-0.69343, y: 0.9690, z: -0.58167}}},
  { id: 'OBJ11', wall: 'NORTH', tour: { meta: null, panels: 'CLOSE', gimbal: 'NORTH', cameraPosition:null } },
  { id: 'OBJ12', wall: 'WEST', tour: { meta: null, panels: 'CLOSE', gimbal: 'WEST', cameraPosition: {x:0.6614, y: 1.0866, z: 0.18654}} },
  { id: 'OBJ17', wall: 'SOUTH', tour: { meta: null, panels: 'CLOSE', gimbal: 'SOUTH', cameraPosition: null} },
]  

const pictureData = positionData.map((data) => {
  const tourMeta = pictureCollection.find((tourInfo) => tourInfo.id === data.id)
  if (tourMeta) return { ...data, tour: { ...data.tour, meta: tourMeta } }
  return data  
})

//right -> north
//left -> south
//near -> east
//far -> west
