/**
 * Core : Main App data store
 */
import Content from './content'
import Downloads from './downloads'
import { library as pictureRoomLibrary} from '../pictureRoom/js/collections/pictureRoomData'
import { library as drawingOfficeLibrary} from '../DrawingOffice/js/collections/pictureRoomData'

export default {
    name: 'main-data-store',
    state: {  
        current: '',
        set(view) {
            // console.log('current view:', view);
            this.current = view;
            // if(this.firstLoad[view]) this.firstLoad[view] = false;
        },
        firstLoad: {
            root: true,
            section: true,
            pointcloud: true,
            fromDeepLink: false
        }
    },
   pictureRoom: {
        view: 'ROOM',
        mounted: false,
        tour: {
            id: null,
            index: 0,
            hasStarted: false,
            isLast: false,
            data: pictureRoomLibrary.tours
        },
        magnify: false
    },
   drawingOffice: {
        view: 'ROOM',
        mounted: false,
        tour: {
            id: null,
            index: 0,
            hasStarted: false,
            isLast: false,
            data: drawingOfficeLibrary.tours
        },  
        magnify: false
    },
    reuseRoute: true,
    videos: {
        selected: null,
        isPlaying: false,
        collection: {
            modelroom: {
                ready: false
            },
            sepulchral: {
                ready: false
            },
            pictureroom: { ready: false },
            drawingoffice: { ready: false }
        }
    },
    viewport: {
        width: window.innerWidth,
        height: window.innerHeight,
        portrait: ((window.innerWidth / window.innerHeight) < 1),
        unfit: (window.innerWidth < 440 && ((window.innerWidth / window.innerHeight) < 1))
    },
    pages: {
        indexView: false,
        pageViewer: false
    },
    history: [],
    // history: {
    //     journey: [],
    //     add (router) {
    //         switch (router.name) {                
    //             case 'section':
    //                 this.journey.push(historyObject(router.params.section, router.path, 'room', false))
    //             break;
    //             case 'pointcloud':
    //                 this.journey.push(historyObject(router.params.pointcloud, router.path, 'model', router.params.section))
    //             break;
    //             case 'inspect':
    //                 this.journey.push(historyObject(router.params.detail, router.path, 'object', router.params.section))
    //             break;
    //             default: 
    //                 this.journey.push(historyObject('Start', router.path, 'index', false))
    //             break;
    //         }
    //         journeyNormaliser(this.journey);
    //     }
    // },
    layout: {
        dropdown: false,
        modal: false,
        readmode: true,
        hires: (window.innerWidth > 1440), // not dynamic
        darkmode: false,
        viewSplit: true, // ???
        overlay: false, // deprecate
        inspecting: false,
        help: false,
        full: false,
        mode({ name }) {
            switch (name) {
                case 'section':
                    this.darkmode = true;
                    break;
                case 'pictureroom':
                    this.darkmode = true;
                    break;
                case 'drawingoffice':
                    this.darkmode = true;
                    break;
                default:
                    this.darkmode = false;
                    break;
            }
        }
    },
    Downloads,
    get(tgt) {
        return this[tgt];
    },
    put(tgt, val) {
        // console.log('from store',this);
        this[tgt] = val;
    },
    registerVideo(vid) {
        if(this.videos.collection[vid.name] != undefined) 
            this.videos.collection[vid.name].ready = true;
    }, 
    endVideo(name) {
        // console.log(name,'has ended');
        this.videos.isPlaying = false;
        this.videos.selected = null;
    },
    closePages() {
        if (this.pages.indexView || this.pages.pageViewer) {
            this.pages.indexView = false;
            this.pages.pageViewer = false;
        }
    }
}

// function historyObject (title, url, type, section) {
//     let thumb;
//     switch(type) {
//         case 'index':
//             thumb = 'assets/img/_thumbs/start_thumb.jpg'
//         break;
//         case 'room':
//             thumb = 'assets/img/_thumbs/start_thumb.jpg'//`assets/img/_thumbs/${title}_thumb.jpg`
//         break;
//         default:
//             thumb = Content.getThumb(section, title)[0].img.thumb
//         break;
//     }
//     return { title, url, type, thumb }
// }
// function journeyNormaliser (path) {
//     // h = 2 (for original) or h = 3 (for where let n = 2 is being used)
//     let h = 3;
//     if(path.length > h) {
//         let a = (path[path.length - 1].title == path[path.length - 3].title),
//             b = false;
//         if(a) b = (path[path.length - 2].title == path[path.length - 4].title);
//         if(a && b) {
//             path.splice(-2, 2)
//         }
//     }
//         // let n = 2;
//         // if(path.length > 5) n = 3;

//         // for(let i=2; i<=n; i++) {
//         //     let vars = [], tmp1 = [], tmp2 = [];
//         //     for(let j=1; j<=i; j++) {
//         //         // vars[j] = (path[path.length - (j)].title == path[path.length - (j + i)].title)
//         //         // check::human vs inhuman lengths at work here
//         //         if(path.length > (j + i)) tmp1.push(path[path.length - (j + i)].title)
//         //         if(path.length > j) tmp2.push(path[path.length - (j)].title)
//         //     }
//         //     tmp1.sort();
//         //     tmp2.sort();
//         //     if(tmp1.every((element, index) => element === tmp2[index])) { 
//         //         path.splice(-i, i)
//         //     }
//         //     // if (vars.every((bool) => (bool))) path.splice(-i, i)
//         // }
//     // }
// }
