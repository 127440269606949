import Vue from 'vue'
import Template from '../templates/view-about.html'
// import core from '../data/core'
import Carousel from './Carousel'
// import ViewMap from './ViewMap'

// const { layout } = core
export default {
    name: 'view-about',
    template: Template,
    components: [Carousel],
    props: ['data', 'layout'],
    data() {
        return {
            view: {
                about: true,
                facts: false
            },
            fragment: null
        }
    },
    methods: {
        switchView(view) {
            Object.keys(this.view).forEach(key => { if (this.view[key] === true) this.view[key] = false });
            this.view[view] = true;
        }
    },
    ready() {

        // {  x: 1.6408882972209426e-7, y: 0.27553223944937766, z: 2.2134333271294698e-7 }
        if (this.data.hasOwnProperty('transition')) this.$dispatch('model-transition', this.data.transition)

        this.fragment = Vue.extend({
            name: 'about',
            template: `<div class="frag">${this.data.template}</div>`,
            methods: {
                link(pass) {
                    // console.log('trying to trigger link',pass);
                },
                cast(view) {
                    // // target is optional
                    // console.log(target)
                    // let t = (target) ? target : null                   
                    this.$dispatch(view);
                }
            }
        })

        // this.test = new About({ el: this.$els.mountpoint })
    },
    beforeDestroy() {
        this.fragment.$destroy;
    }
}