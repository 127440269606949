import Template from '../templates/state-inspector-drawingoffice.html'
import { library } from '../DrawingOffice/js/collections/pictureRoomData'
// import ViewPicture from './ViewPicture' // need to seperate into 2 files
import ViewTour from './ViewTourDrawingOffice' // need to seperate into 2 files
import config from "../DrawingOffice/js/utils/config"
import { RoomOrbit, InspectOrbit } from "../DrawingOffice/js/utils/tweens"


export default {
  name: 'DrawingOfficeInspector',
  template: Template,
  components: [Poi, ViewTour],
  props: ['poi', 'core'],
  data() {
    return {
      currentComponent: 'view-tour-drawingoffice',
      dropdown: false,
      debounceFold: false,
    }
  },
  computed: {
    opaque() {
      return (this.currentComponent == 'view-tour-drawingoffice')
    },
    hideNav() {
      return this.core.layout.modal
    },
    darkenView() {
      return (this.core.layout.inspecting) ? 'darken' : ''
    },
    room() {
      return library.content['ROOM']
    },
    summary() {
      const id = this.core.drawingOffice.tour.id
      if (id) return library.tours.find(tour => tour.id === id)
      const { view } = this.core.drawingOffice
      const target = view === 'ROOM' ? 'ROOM'
        : view === 'TOUR' ? 'ROOM'
          : 'MAP'
      return library.content[target]
    },
    CTAlabel() {
      const { view } = this.core.drawingOffice
    return view === 'ROOM' ? ''
        : view === 'TOUR' ? 'Exit'
          : ''
    },
    page() {
      return {
        dropdrown: { ...this.room },
        label: this.CTAlabel,
        summary: { ...this.summary },
        template: null,
        startTour: (id) => {
          if (this.core.drawingOffice.tour.hasStarted) {
            // if already started - exit
            this.$dispatch('exit-tour')
            this.$parent.sandbox.app.controls.orbit.stopWiggle();
            setTimeout(() => {
              this.core.drawingOffice.tour.index = 0
              this.core.drawingOffice.tour.id = id
              this.core.drawingOffice.tour.hasStarted = false // hasnt navigated yet
              this.core.drawingOffice.view = 'TOUR'
              this.$parent.sandbox.app.onAction({ trigger: 'TOUR', method: 'START', param: id, type: 'CUSTOM' })
              setTimeout(() => {
                this.core.layout.full = !this.core.layout.full;
                this.$parent.sandbox.app.onAction({ type: 'CTA', trigger: "VIEWPORT", method: "TOGGLE" })
              }, 2000)
            }, 100)
          }
          else {

            this.$parent.sandbox.app.controls.orbit.stopWiggle();
            this.$parent.sandbox.app.controls.orbit.disableWiggle();
            const helpType = this.core.drawingOffice.view === 'ROOM' ? 'drawingoffice' : 'picturemap'
            this.core.state.current = helpType
            this.$parent.$broadcast("help", helpType);
            this.$parent.$emit("help", helpType);
            this.$parent.$dispatch("help", helpType);
            this.core.drawingOffice.tour.index = 0
            this.core.drawingOffice.tour.id = id
            this.core.drawingOffice.tour.hasStarted = false // hasnt navigated yet
            this.core.drawingOffice.view = 'TOUR'
            this.$parent.sandbox.app.onAction({ trigger: 'TOUR', method: 'START', param: id, type: 'CUSTOM' })
          }
        },
        cta: () => {
          const { view } = this.core.drawingOffice
          if (view === 'TOUR') this.$dispatch('exit-tour')
        },
        continueTour: () => {
          this.$parent.sandbox.app.controls.orbit.stopWiggle();
          if (!this.core.drawingOffice.tour.hasStarted) {
            setTimeout(() => { // delay button switch
              this.core.drawingOffice.tour.hasStarted = true
            }, 4000)            
          }
          this.$parent.sandbox.app.onAction({ trigger: 'TOUR', method: 'NEXT', type: 'CTA' })
        }
      }
    }
  },
  watch: {
    'hideNav': function (next, prev) {   
      this.$broadcast('model-nav', next);
    }
  },
  methods: {
    toggleFullWidth() {
      
      this.page.startTour('t1');
      this.$parent.toggleFullWidth()
    },
    close() {
      this.currentComponent = false; // might not be used ever
    },
    toggleDropdown() {
      this.dropdown = !this.dropdown
      this.core.layout.dropdown = this.dropdown
      if (!this.dropdown) {
        this.$parent.sandbox.app.controls.pictureId = null
        this.$parent.poi = null
      }
    },
  },
  ready() {

    this.core.layout.overlay = false;
    this.core.state.set('drawingoffice')

    this.$on('abt', () => {
      this.currentComponent = 'view-tour-drawingoffice'
      // this.core.state.set('inspect');
    })
    this.$on('leave-tour', () => {
      // exit softly
      RoomOrbit(this.$parent.sandbox.app.controls.orbit);
      this.$parent.sandbox.app.controls.orbit.enableWiggle();
      // this.$parent.sandbox.app.controls.orbit.maxDistance = config.controls.orbit.max
      this.core.layout.inspecting = false
      this.core.drawingOffice.view = 'ROOM'
      this.$parent.sandbox.app.onAction({ trigger: 'TRANSITION', method: 'ROOM', type: 'CTA' })
      this.$parent.toggleFullWidth()
    })
    this.$on('exit-tour', () => {
      // exit
      RoomOrbit(this.$parent.sandbox.app.controls.orbit);
      this.$parent.sandbox.app.controls.orbit.enableWiggle();

      // this.$parent.sandbox.app.controls.orbit.maxDistance = config.controls.orbit.max

      this.core.drawingOffice.tour.index = 0
      this.core.drawingOffice.tour.isLast = false
      this.core.drawingOffice.tour.id = null
      this.core.layout.inspecting = false
      this.core.drawingOffice.view = 'ROOM'
      // console.log(this.$parent.sandbox.app.controls);
      this.$parent.poi = null
      this.$parent.sandbox.app.controls.pictureId = null
      this.$parent.sandbox.app.onAction({ trigger: 'TRANSITION', method: 'ROOM', type: 'CTA' })
      if (!this.core.layout.full) this.$parent.toggleFullWidth()
    })
    this.$on('fold-debounce-complete', (t) => {
      const helpType = this.core.drawingOffice.view === 'ROOM' ? 'drawingoffice' : 'picturemap'
      this.core.state.current = helpType
      this.$parent.$broadcast("help", helpType);
      this.$parent.$emit("help", helpType);
      this.$parent.$dispatch("help", helpType);
      this.debounceFold = false
    })
    this.$on('goto-map', () => {
      if (!this.debounceFold) {
        this.debounceFold = true
        if (!this.core.layout.full) this.$parent.toggleFullWidth()
        this.$parent.sandbox.app.onAction({ trigger: 'TRANSITION', method: 'MAP_TOGGLE', type: 'CTA' })
        this.core.drawingOffice.view = 'MAP'
      }
    })
    this.$on('goto-room', () => {
      if (!this.debounceFold) {
        this.debounceFold = true
        this.$parent.sandbox.app.onAction({ trigger: 'TRANSITION', method: 'MAP_TOGGLE', type: 'CTA' })
        this.core.drawingOffice.view = 'ROOM'
      }
    })
  }
}