import Template from '../templates/view-tour.html'
import TourCarousel from './TourCarousel'

export default {
  name: 'view-tour',
  template: Template,
  components: [TourCarousel],
  props: ['page', 'core', 'model'],
  data() {
    return {
      show: false,
      fragment: null
    }
  },
  computed: {
    source() {
      const assets = '/assets/img/picture-room'
      if (!this.model) return ''
      return `${assets}/thumbs/${this.model.id}.jpg`
    },
    tourSummary() {
      return this.core.pictureRoom.view === 'TOUR' && !this.core.pictureRoom.tour.hasStarted
    },
    tourSelection() {
      return this.core.pictureRoom.view === 'ROOM' || this.core.pictureRoom.view === 'TOUR' && this.core.pictureRoom.tour.hasStarted
    },
  },
  methods: {
    exitTour() {
      const { view } = this.core.pictureRoom
      if (view === 'TOUR') {
        this.$parent.$parent.$dispatch('exit-tour')
        this.$parent.$parent.$dispatch('close-picture-inspector')
      }
    },
    toggleFullWidth() {
      this.$dispatch('toggleFullWidth')
    },
    next() {
      this.$parent.$parent.sandbox.app.onAction({ trigger: 'TOUR', method: 'NEXT', type: 'CTA' })
      this.core.pictureRoom.tour.hasStarted = true
    },
    prev() {
      this.$parent.$parent.sandbox.app.onAction({ trigger: 'TOUR', method: 'PREV', type: 'CTA' })
    }
  },

  beforeDestroy() {
    if (this.fragment) this.fragment.$destroy;
  }
}