export default function () {
  let count = 0
  return {
    NEXT: () => {
      count++
      return Promise.resolve(count)
    },
    PREV: () => {
      count--
      return Promise.resolve(count)
    },
    CURRENT: () => {
      return Promise.resolve(count)
    }
  }
}