/**
 * ViewFinder Component
 * Allows for zoomable views of images
 */
import Template from '../templates/view-finder.html';
import Viewer from 'viewerjs'

export default {
    name: 'view-finder',
    template: Template,
    props: ['data', 'layout', 'custom'],
    data() {
        return {
            img: {
                src: this.data.img.src,
                w: this.data.img.w,
                h: this.data.img.h
            },
            collection: (this.data.img.hasOwnProperty('collection')) ? this.data.img.collection : []
        }
    },
    computed: {
        ratio() {
            return this.img.w / this.img.h
        },
        landscape() {
            return (this.ratio > 1)
        },
        image() {
            return this.data.img.hir             
        }
    },
    ready() {

        this.layout.overlay = true;

        let vw = window.innerWidth,
            vh = window.innerHeight,
            // 441 / 1405
            scale = (this.landscape) ? vw / this.img.w : vh / this.img.h,
            min = (this.custom != undefined) ? this.custom : scale * 0.9;

        let options = {
            inline: true,
            button: false,
            navbar: (this.collection.length > 1),
            toolbar: false,
            tooltip: false,
            title: false,
            movable: true,
            minZoomRatio: min,
            maxZoomRatio: 1.5,
            url: 'data-original',
            built:  function (e) {
                // console.log('built',e.type);
            },
            viewed:  function (e) {              
              this.viewer.zoomTo(min);
            }
        };
        let viewer = new Viewer(document.querySelector('#viewfinder'), options)
        this.$dispatch('close-back')
    },
    beforeDestroy() {
        // ever called?
        this.layout.overlay = false;
    }
}