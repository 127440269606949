import Template from '../templates/video-players.html';
import Player from './Player';
import SpinLoader from './SpinLoader';

export default {
    name: 'video-players',
    props: ['data', 'core'],
    components: [Player, SpinLoader],
    template: Template,
    computed: {
        allReady() {
            return true
            // return (this.data.meta.length == this.core.videos.collection.length)
        }
    },
    ready () {
        
        this.$on('video-ready', (video) => {
            this.core.registerVideo(video)
        })

        this.$on('video-finished', (video) => {
            this.core.endVideo(video.name)
        })
    }
}