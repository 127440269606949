/**
 * Potree viewer instance
 */
import Template from '../templates/potree.html';

import SpinLoader from './SpinLoader';
import core from '../data/core';
import Collection from '../data/content';
import Inspector from './ModelInspector';
import Help from './help';
import V from '../libs/velo';

import PointCloud from '../libs/potree';

const {state, layout} = core

export default {
    name: 'Potree',
    route: {
        canReuse(transition) {
            return this.$parent.core.reuseRoute
        },
        activate(transition) {
            transition.next()
        }
    },
    template: Template,
    components: [SpinLoader, Inspector, Help],
    props: [],
    data() {
        return {
            pc: false,
            inspecting: false,
            poi: false,
            model: Collection.find(this.$route.params.section, this.$route.params.pointcloud),
            isActive: false,
            hasLoaded: false,
            layout,
            state
        }
    },
    created() {
        window.addEventListener('resize', this.resize)
    },
    computed: {
        showMaximize() {
            return ((this.layout.help) || (this.state.current == 'inspect') || (this.inspecting)) ? false : true;
        }
    },
    methods: {
        resize() {
            if(this.pc) this.pc.resize()
        },
        intersect(e) { // hover
            // if(layout.viewSplit || state.current != 'inspect') {
            if(!this.layout.full || state.current != 'inspect') {
                this.pc.intersect({x: e.clientX, y: e.clientY}, res => {
                    this.poi = (res.length > 0) ? getPoiTarget.call(this, res) : false;
                })
            }
            function getPoiTarget(response) {                
                let index = response[0].object.index
                return this.model.pointcloud.poi[index].target
            }
        },
        capture () { // click
            if(this.poi) {
                this.inspecting = true;
                this.$router.go({
                    name: 'inspect',
                    params: { detail: Collection.urlify(this.poi) }
                })
            }
        },
        toggleFullWidth() {
            this.layout.full = !this.layout.full;
            this.pc.setViewportFull(this.layout.full);
            this.pc.toggleChildren()
        }
    },
    ready () {
        let vm = this;
        this.pc = new PointCloud(this.model.pointcloud)
        this.pc.init(() => {
            vm.hasLoaded = true;
            vm.$broadcast('modelHasLoaded');
            vm.$el.appendChild(vm.pc.renderer.domElement);
            vm.isActive = true;    // may switch others using this to use hasLoaded insteasd
            if(vm.state.firstLoad.fromDeepLink) vm.pc.toggleChildren('off')            
        });
        this.$on('model-transition', (target) => {
            let start = this.pc.camera.position;
            V(step => {
                let x = start.x + (step * (target.x - start.x)),
                    y = start.y + (step * (target.y - start.y)),
                    z = start.z + (step * (target.z - start.z));
                vm.pc.camera.position.set(x,y,z)
            }, { duration:4000, delay:400, ease:"easeOutQuad" });
        })
        this.$on('back-button', () => {
            if(this.layout.full) {
                this.toggleFullWidth()
            } 
        })
        this.$watch('state.current',(now, prev) => {
            if(now == 'inspect') this.pc.toggleChildren('off')
            else this.pc.toggleChildren('on')
        }, true)
        this.$watch('layout.dropdown',(now, prev) => {
            if(now) this.pc.toggleChildren('off')
            else this.pc.toggleChildren('on')
        })
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.resize);
        this.isActive = false;
    }
}


