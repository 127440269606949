import pictureCollection from './pictureCollection'

const content = {
  ROOM: {
    label: `Drawing Office`,
    description: 
    [
      `This small mezzanine Drawing Office, above the Colonnade at Sir John Soane’s Museum, was at the heart of Soane’s architectural practice from the time of his move to 13 Lincoln’s Inn Fields in 1812 until his retirement in 1833. He designed it himself and modified it several times before it took the final form that survives today in 1824-25.`,
      `The Office is essentially like a table, a platform supported on columns, within the larger space at the back of the Museum, and is not attached to the main walls. Its design was described as ‘novel and ingenious’ in Soane’s time. Two long skylights above illuminate not only the long mahogany desks but also the Museum below. Here Soane’s pupils worked surrounded by more than 100 casts and models.`,
      `In 2022-23 this space was restored: the original colour scheme was reinstated, the casts were cleaned and re-hung in their original arrangement and more than 60 works, missing for many years, were brought back and replaced in their original positions. After almost 200 years, Soane’s Office, perhaps the earliest surviving architect’s drawing office in the world, can once again serve as a place of artistic inspiration.`
    ]
  },
  TOUR: {  
    label: `Tours`,
    title: `Take a tour of the Drawing Office`,
    description: [
      ``
    ]
  },
  MAP: {
    label: `Drawing Office`,
    title: `Take a tour of the Picture Room`,
    description: [`The Picture Room contains some of John Soane’s greatest treasures, including masterpieces by Hogarth, Canaletto, Turner and Piranesi as well as works by his contemporaries considered ‘modern’ in his lifetime. It also presents many watercolours of Soane’s own projects by Joseph Gandy, a freelance architect and artist who captures the effects achieved in Soane’s architecture in his wonderfully varied compositions, most of them originally intended for exhibition at the Royal Academy. `,
      `Choose one of five tours each featuring a selection of the wonderful paintings in Soane’s Picture Room and learn about his life, collection, and architecture.`]
  }
}




const tour1 = {
  id: `t1`,  
  label: `Tour 1`,
  title: `At work in Soane’s Drawing Office`,
  image: `/assets/img/drawing-office/thumbs/TourImage.jpg`,
  imageDesc: `Photo: Gareth Gardner, 2023`,
  intro: `In this small office between four and six pupils worked six days a week, 9am-8pm, supervised by Assistants. These boys (aged about 16) were articled/apprenticed to Soane for five years, their families paying a £157 premium for their education in the ‘Art, Profession and Business’ of architecture. They sat or stood at their drawing boards copying and re-copying architectural plans, elevations and sections as designs evolved, producing lecture drawings for Soane (1,000 survive: some of them six feet long) or making up accounts. To improve their draughtsmanship they practised lettering, shadowing and adding borders to drawings and learned the language of classical architecture by drawing the casts on the walls surrounding them. They studied construction, making site visits to draw buildings in progress or to measure built work and verify its cost. An echo of their voices can be heard in the entries they made in the Day Books, where they recorded what they did each day. Surviving ink-stained, worn, quill pens discovered at the back of Office drawers in the recent restoration are a testament to their labours.`,
  description: [
    `<p> In this small office between four and six pupils worked six days a week, 9am-8pm, supervised by Assistants. These boys (aged about 16) were articled/apprenticed to Soane for five years, their families paying a £157 premium for their education in the ‘Art, Profession and Business’ of architecture. They sat or stood at their drawing boards copying and re-copying architectural plans, elevations and sections as designs evolved, producing lecture drawings for Soane (1,000 survive: some of them six feet long) or making up accounts. </p> <p>To improve their draughtsmanship they practised lettering, shadowing and adding borders to drawings and learned the language of classical architecture by drawing the casts on the walls surrounding them. They studied construction, making site visits to draw buildings in progress or to measure built work and verify its cost.</p> <p>An echo of their voices can be heard in the entries they made in the Day Books, where they recorded what they did each day. Surviving ink-stained, worn, quill pens discovered at the back of Office drawers in the recent restoration are a testament to their labours.</p>`,
  ],
  sequence: ['OBJ01', 'OBJ02', 'OBJ03', 'OBJ17', 'OBJ04', 'OBJ05', 'OBJ06', 'OBJ07', 'OBJ08', 'OBJ09', 'OBJ10', 'OBJ11', 'OBJ12'], //South tour
  // sequence: ['OBJ01', 'OBJ02'], //South tour
  initalCamera: { x: 2.81, y: -0.8046, z: 0.083 }
}
export const library = {
  content,
  tours: [tour1],
  pictureCollection
}