/**
 * ViewFinder Component
 * Allows for zoomable views of images
 */
import Template from '../templates/view-magnify.html';
import Viewer from 'viewerjs'

export default {
  name: 'view-magnify',
  template: Template,
  props: ['poi', 'core', 'model'],
  computed: {
    ratio() {
      return 1//this.img.w / this.img.h
    },
    landscape() {
      return (this.ratio > 1)
    },
    image() {
      return this.source//this.data.img.hir
    },
    source() {
      const assets = '/assets/img/picture-room'
      if (!this.model) return ''
      return `${assets}/hires/${this.model.id}.jpg`
    }
  },
  ready() {
    // this.core.layout.overlay = true;

    let options = {
      inline: true,
      button: false,
      // navbar: (this.collection.length > 1),
      toolbar: false,
      tooltip: false,
      title: false,
      movable: true,
      minZoomRatio: .5,// landscape ? .1 : .5,
      maxZoomRatio: 2.5,
      url: 'data-original',
    };
    new Viewer(document.querySelector('#viewfinder'), options)
    this.$dispatch('close-back')
  }
}