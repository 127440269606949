/**
 * Inspector View
 * Loads text content details for relevant Object 
 */
import Template from '../templates/inspector--drawingOffice.html'
import { library } from '../drawingOffice/js/collections/pictureRoomData'
import ViewDrawing from './ViewDrawing'
// import ViewMap from './ViewMap';
// import ViewAbout from './ViewAbout';
import ViewMagnify from './ViewMagnify';

export default {
    name: 'drawing',
    template: Template,
    components: [ViewDrawing, ViewMagnify],
    route: {
        canReuse: false
    },
    props: ['core', 'poi'],
    data() {
        return {
            currentComponent: 'view-drawing',
        }
    },
    computed: {
        model() {
            const data = library.pictureCollection.find(({ id }) => this.poi === id)
            if (data) {
                this.currentComponent = null
                this.core.drawingOffice.magnify = false
                this.$nextTick(() => this.currentComponent = 'view-drawing')
            }
            return data
        }
    },
    ready() {
        this.$on('img', (data) => {
            this.core.layout.overlay = true;
            this.currentComponent = 'view-finder'
            this.core.state.set('inspect'); // deprecate
        })
    },
    beforeDestroy() {
        // layout.modal = false;
        this.$parent.inspecting = false
        this.core.layout.overlay = false;
    }
}