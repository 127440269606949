import Template from '../templates/entrance.html';
import Help from './help'
export default {
    name: 'Entrance',
    template: Template,
    components: [Help],
    props: ['core'],
    route: {
        activate: function (transition) {
            if (this.core.videos.isPlaying) {
                this.core.endVideo('route')
            }
            transition.next()
        }
    },
    data() {
        return {
            pictureRoom: true
        }
    },
    methods: {
        setVideo(path) {
            if (path === 'off') return
            this.core.videos.selected = path;
            this.core.videos.isPlaying = true;
        },
        // onHover() {
        //     this.pictureRoom = false
        // },
        // onExit() {
        //     this.pictureRoom = true
        // }
    },
    computed: {
        pictureLabel() {
            return this.pictureRoom ? 'Picture Room' : 'Coming soon'
        }
    }
}