/**
 * Pointclouds data collection
 */
const cloudpath = '/assets/pointclouds/';

export default {
    'pid_00': {        
        path: `${cloudpath}sarcophagus`,
        min: .25,
        max: 5,
        iconscalar: 1,
        fov: 60,
        render: "FIXED",
        pointsize: 2.3,
        pointCountScalar: 100,
        shape: "SQUARE",
        start: { x: -0.000003841058641506117,y: 4.034478969640136,z: 0.0000012342159733979164 },
        axisLock: false,
        poi: []
    },
    'pid_28': {        
        path: `${cloudpath}sarcophagus_detail`,
        min: .4,
        max: 2,
        iconscalar: 1,
        fov: 60,
        render: "FIXED",
        pointsize: 2,
        pointCountScalar: 2,
        shape: "SQUARE",
        start: {x: -1.3502727741382232,  y: 1.074315820013997,  z: 0.3951671852796145},
        axisLock: false,
        poi: []
    },
    'pid_09': { // cork
        path: `${cloudpath}vesta_cork`,
        start: { x: -0.6037392797387833, y: 0.3547626088617781, z: 0.8483672298303243 },
        min: .3,
        max: 2.5,
        iconscalar: .7,
        fov: 30,
        render: "FIXED",
        pointsize: 2,
        pointCountScalar: 2,
        shape: "SQUARE",
        axisLock: true,
        poi: [{
            vector: { x: -0.1236122807017544, y: 0.15566666666666665, z: 0.1766666666666667 },
            target: 'pid_13'
        }]
    },
    'pid_10': { // plaster
        path: `${cloudpath}fouquet`,
        start: { x: 0.11734208002944918, y: 0.18383853593009156, z: 0.4322633049585243 },
        min: .175,
        max: 1,
        iconscalar: .5,
        fov: 30,
        render: "FIXED",
        pointsize: 1.6,
        pointCountScalar: 2,
        shape: "SQUARE",
        axisLock: true,
        poi: [{
            vector: { x: 0.0401859649122807, y: 0.0263157894736842, z: 0.0654035087719298 },
            target: 'pid_13'
        }]
    },
    'pid_18': {
        path: `${cloudpath}pompeii`,
        start: { x: 2.2642102994215714, y: 1.2561711191964045, z: 1.3712944224495882 },
        min: .3,
        max: 4,
        iconscalar: 1,
        fov: 40,
        render: "FIXED",
        pointsize: 1,
        pointCountScalar: 100,
        shape: "SQUARE",
        axisLock: true,
        poi: [{
            vector: { x: 0.0087719298245614,y: 0.0789473684210526,z: -0.750526315789474 },
            target: 'pid_23'
        }]
    },
    'pid_24': {
        path: `${cloudpath}law_courts`,
        start: { x:0, y:.75, z:1 },
        min: .2,
        max: 1.8,
        render: "FIXED",
        pointsize: 1,
        pointCountScalar: 1000,
        shape: "SQUARE",
        iconscalar: .8,
        fov: 50,
        axisLock: true,
        poi: [
            {vector: { x: 0.019683817917797886,y: 0.04615767069325413,z: 0.0789473684210526 }, target: 'pid_26'},
            {vector: { x: -0.043859649122807,y: 0.04613897918005279,z: -0.0614035087719298 }, target: 'pid_25'},
            {vector: { x: -0.314561403508772,y: 0.046149122807,z: 0.149122807017544 }, target: 'pid_27'},
        ]
    }
}

