import Template from '../templates/ui-view.html';
// import Xsection from './Xsection';
import AboutPage from './AboutPage';
import DownloadPage from './DownloadPage';
// import MiniMap from './MiniMap';
import Collection from '../data/content';
// import isIos from 'is-ios'

import ScreenFull from 'screenfull'

export default {
    name: 'ui-view',
    props: ['core'],
    components: [AboutPage, DownloadPage],
    template: Template,
    data() {
        return {
            tab: null,
            related: null,
            fullscreen: false,
            fullscreenAble: false,
            blue: false, // help
            back: false // use true for going back to 'about' from a sub component in inspector
        }
    },
    computed: {
        state() {
            return this.core.state.current
        },
        tabview() {
            if (this.tab !== null) {
                return (this.tab == 'history') ? 'view--history' : 'view--index'
            } else {
                return ''
            }
        },
        stylebottom() {
            return (isIos) ? 'ios' : ''
        },
        rotateDevice() {
            return this.core.viewport.unfit
        },
        closeMode() {
            if (this.core.pictureRoom.view === 'MAP' && this.core.layout.overlay) return true
            if (this.core.state.firstLoad.fromDeepLink) return false
            return (this.tab != null || this.state == 'inspect' || this.core.pages.pageViewer || this.core.pages.indexView)
        },
        showSkip() {
            if (this.$route.name != 'pictureroom' && this.$route.name != 'drawingoffice') return this.core.videos.isPlaying
            if(this.$route.name == 'pictureroom') return this.core.videos.isPlaying && this.core.pictureRoom.mounted
            if(this.$route.name == 'drawingoffice') return this.core.videos.isPlaying && this.core.drawingOffice.mounted
        }
        // UI overlay shift dependant on current state view()
    },
    methods: {
        help() {
            this.blue = true;
            this.core.layout.help = true;
            this.$broadcast("help", this.core.state.current);
        },
        tabmenu(menu) {
            this.tab = (this.tab !== menu) ? menu : null;
        },
        toggleSplitScreen() {
            this.core.layout.viewSplit = !this.core.layout.viewSplit;
            setTimeout(() => {
                window.dispatchEvent(new Event('resize'));
            }, 350); // -> allow for css anim to pass.

        },
        toggleIndex() {
            this.core.pages.pageViewer = false
            this.core.pages.indexView = !this.core.pages.indexView;
        },
        togglePage(page) {
            let component = `${page}-page`;
            if (this.core.pages.indexView) this.core.pages.indexView = false;
            if (this.core.pages.pageViewer == component) {
                this.core.pages.pageViewer = false
            } else {
                this.core.pages.pageViewer = page + '-page';
            }
        },
        toggleFullscreen() {
            ScreenFull.toggle();
            this.fullscreen = ScreenFull.isFullscreen;
        },
        goback() {
            if (router.app.core.pictureRoom.tour.hasStarted) router.app.core.pictureRoom.tour.hasStarted = false // cleanup
            if (router.app.core.drawingOffice.tour.hasStarted) router.app.core.drawingOffice.tour.hasStarted = false // cleanup
            this.$broadcast('back-button') // used in pictureroom
            window.history.back();
            // remove current last - as is current route
            this.core.history.pop()
            if (this.core.history.length) {
                // if has history remaining get last and go
                const prev = this.core.history.pop() // removes from array
                this.$router.go(prev)
            } else this.$router.go('/')
        },
        // back() {
        //     console.log('back() [uiview]')
        // },
        closeView() {
            if (this.core.pictureRoom.magnifyParent) {
                this.core.pictureRoom.magnifyParent.currentComponent = 'view-picture'
                this.core.pictureRoom.magnifyParent = null
                return
            }
            if (this.core.drawingOffice.magnifyParent) {
                this.core.drawingOffice.magnifyParent.currentComponent = 'view-drawing'
                this.core.drawingOffice.magnifyParent = null
                return
            }
            this.$broadcast('back-button')
            this.$broadcast('close-inspect', 'broadcast')
            if (!this.back) {
                this.core.closePages();
                if (this.tabview != '') this.tab = null;
                if (this.state == 'inspect' && this.$route.params.detail === undefined) this.$broadcast('close-model-inspector');
                if (this.state == 'inspect' && this.$route.name === 'pictureroom') {
                    this.$broadcast('close-picture-inspector');
                    // window.history.back();
                    return
                }
                if (this.state == 'inspect' && this.$route.name === 'drawingoffice') {
                    this.$broadcast('close-drawing-inspector');
                    // window.history.back();
                    return
                }
                if (this.state == 'inspect' && this.$route.params.detail != undefined) window.history.back();
            } else {
                this.back = false;
            }
            if (this.core.pictureRoom.view === 'MAP') this.$broadcast('close-picture-inspector')
        },
        close() {
            this.core.closePages()
        },
        link(route) {
            if (this.core.pages.indexView) this.core.pages.indexView = false;
            if (this.core.pages.pageViewer) this.core.pages.pageViewer = false;
            if (this.core.state.firstLoad.fromDeepLink) this.core.state.firstLoad.fromDeepLink = false
            this.closeTabMenu();
            this.$router.go({
                name: route
            })
        },
        visitRelated(index) {
            let tgt = this.related[index],
                url = Collection.urlify(tgt.id);

            if (tgt.hasOwnProperty('pointcloud')) {
                this.core.reuseRoute = false;
                this.$router.go({ name: 'pointcloud', params: { pointcloud: url } })
            } else {
                this.core.reuseRoute = true;
                this.$router.go({ name: 'inspect', params: { detail: url } })
            }
            this.closeTabMenu();
        },
        skipVideo() {
            this.core.endVideo('route')
            this.$dispatch('help-deactive')
            if (this.$route.name === 'pictureroom') {
                this.$broadcast('skip-viewport-sync')
            }
            if (this.$route.name === 'drawingoffice') {
                this.$broadcast('skip-viewport-sync')
            }
        },
        closeTabMenu() {
            this.tab = null;
        }
    },
    watch: {
        'state': function (next, prev) {
            this.core.layout.inspecting = (next == 'inspect');
            if (this.core.state.firstLoad[next]) {
                setTimeout(() => {
                    this.help();
                    this.core.state.firstLoad[next] = false;
                }, 2500);
            }
        }
    },
    ready() {
        // TODO - setTimeout(() => this.skipVideo(), 5000)
        if (ScreenFull.enabled) {
            this.fullscreenAble = true;
            window.addEventListener(ScreenFull.raw.fullscreenchange, () => {
                this.fullscreen = ScreenFull.isFullscreen;
            });
        }
        this.$on('related', (data) => {
            this.related = Collection.getRelated(this.$route.params.section, data.group, data.pid)
            this.tab = 'index';
        })
        this.$on('help-deactive', () => {
            this.blue = false;
        })
        this.$on('close-back', () => {
            this.back = true;
        })
    }
}