import config from "../utils/config"

function clamp(value, min, max) {
  return Math.min(Math.max(value, min), max);
}

function lerp(start, end, percent) {
  return start * (1.0 - percent) + end * percent;
}

function Slerp(start, end, percent, clockWise) {
  var startv2 = new THREE.Vector2(start.x, start.z);
  var endv2 = new THREE.Vector2(end.x, end.z);

  var startv2Normalized = new THREE.Vector2(start.x, start.z).normalize();
  var endv2Normalized = new THREE.Vector2(end.x, end.z).normalize();

  var dot = startv2Normalized.dot(endv2Normalized);
  dot = clamp(dot, -1, 1);           // Robustness: Stay within domain of acos()
  var theta_0 = Math.acos(dot);  // theta_0 = angle between input vectors
  var theta = clockWise ? 6.2831853 - theta_0 * percent : theta_0 * percent;    // theta = angle between v0 and result 


  var ortho = new THREE.Vector2(-startv2.y, startv2.x);

  var result0 = startv2.multiplyScalar(Math.cos(theta));
  var result1 = ortho.multiplyScalar(Math.sin(theta));

  var resultv2 = result0.add(result1);

  var y = lerp(start.y, end.y, percent);
  return new THREE.Vector3(resultv2.x, y, resultv2.y);
}

function RaySphereIntersect(rayOrigin, rayDirection, spherePosition, sphereRadius) {
  var t0, t1;
  var radius2 = sphereRadius * sphereRadius;

  // geometric solution
  var L = spherePosition.clone().sub(rayOrigin);
  var tca = L.dot(rayDirection);
  if (tca < 0) return false;
  var d2 = L.dot(L) - tca * tca;
  if (d2 > radius2) return false;
  var thc = Math.sqrt(radius2 - d2);
  t0 = tca - thc;
  t1 = tca + thc;

  if (t0 > t1) {
    var tmp = t0;
    t0 = t1;
    t1 = tmp;
  }

  if (t0 < 0) {
    t0 = t1; // if t0 is negative, let's use t1 instead 
    if (t0 < 0) return false; // both t0 and t1 are negative 
  }

  return true;
}

function swing(pivot, align) {
  const to = align === 'right' ? -Math.PI / 2.2 : Math.PI / 2.2
  return toggler(pivot, to)
}

function toggler(pivot, to) {
  let open = true
  return (next) => {
    if (open) twist('z', pivot, to, next)
    else twist('z', pivot, 0, next)
    open = !open
  }
}

function twist(axis, p, target = 0, next) {
  p.open = !p.open;
  if (p.userData.panel.transparencyOpen != undefined && p.open) {
    p.userData.panel.transparencyOpen();
  }
  if (p.userData.panel.transparencyClose != undefined && !p.open) {
    p.userData.panel.transparencyClose();
  }
  new TWEEN.Tween(p.rotation)
    .to({ [axis]: target }, 2000)
    .easing(TWEEN.Easing.Cubic.Out)
    .start()
    .onUpdate(() => {
    })
    .onComplete(() => {
      var centerOfMass = CalculateCenterOfMass(p);
      p.centerOfMass = centerOfMass;
      next()
    })
}
function fade(panel, target, delay = 0, length = 400) {
  panel.traverse(function (child) {
    if (child.type == 0) {
      var t = new TWEEN.Tween(child.material.uniforms.transparency)
        .delay(400 + delay)
        .to({ value: target }, length)
        .easing(TWEEN.Easing.Cubic.Out)
        .start()
      // .onComplete(() => visible = !visible)
    }
  })
}

function slideCamera({ camera, dolly, targets }) {
  var initialDollyPos = new THREE.Vector3(dolly.position.x, dolly.position.y, dolly.position.z);
  var targetDollyPos = new THREE.Vector3(targets.dolly.x, targets.dolly.y, targets.dolly.z);

  var O = initialDollyPos.clone().add(targetDollyPos).multiplyScalar(0.5);

  var rayOrigin = initialDollyPos.clone();
  var rayDirection = targetDollyPos.clone().sub(rayOrigin);
  var intersect = RaySphereIntersect(rayOrigin, rayDirection, camera.getWorldPosition(), 0.1);

  var clockWise = true;

  var t = 0;
  var step = 10;
  if (intersect) {
    var minDistClockwise = 9999999;
    var minDistAntiClockwise = 9999999;
    for (var i = 0; i < step; i++) {
      t = i / step;
      var posClockwise = Slerp(initialDollyPos.clone().sub(O), targetDollyPos.clone().sub(O), t, true).add(O);
      var posAntiClockwise = Slerp(initialDollyPos.clone().sub(O), targetDollyPos.clone().sub(O), t, false).add(O);
      var distClockwise = posClockwise.length();
      var distAntiClockwise = posAntiClockwise.length();
      minDistClockwise = Math.min(distClockwise, minDistClockwise);
      minDistAntiClockwise = Math.min(distAntiClockwise, minDistAntiClockwise);
    }
    clockWise = minDistClockwise < minDistAntiClockwise ? false : true;
    if (Math.abs(minDistAntiClockwise - minDistClockwise) < 0.01) intersect = false;
  }

  if (intersect) {
    var t = { t: 0 };
    new TWEEN.Tween(t)
      .to({ t: 1 }, 2000)
      .easing(TWEEN.Easing.Cubic.Out)
      .onUpdate(function (object) {
        var currentPos = new THREE.Vector3(0, 0, 0);
        currentPos = Slerp(initialDollyPos.clone().sub(O), targetDollyPos.clone().sub(O), t.t, clockWise).add(O);
        dolly.position.setX(currentPos.x);
        dolly.position.setY(currentPos.y);
        dolly.position.setZ(currentPos.z);
      })
      .start()
  }
  else {
    new TWEEN.Tween(dolly.position)
      .to(targets.dolly, 2000)
      .easing(TWEEN.Easing.Cubic.Out)
      .start()
  }


  return new TWEEN.Tween(camera.position)
    .to(targets.camera, 2000)
    .easing(TWEEN.Easing.Cubic.Out)
    .start()
}


function transitionFrontWallsToOpacity(opacity, room) {

  //North
  room.globals.northObjects.panelLeftFront.traverse(function (child) {
    if (child.type == 0) {
      var t = new TWEEN.Tween(child.material.uniforms.transparency)
        .delay(0)
        .to({ value: opacity }, 2000)
        .easing(TWEEN.Easing.Cubic.Out)
        .start()
    }
  })
  room.globals.northObjects.panelRightFront.traverse(function (child) {
    if (child.type == 0) {
      var t = new TWEEN.Tween(child.material.uniforms.transparency)
        .delay(0)
        .to({ value: opacity }, 2000)
        .easing(TWEEN.Easing.Cubic.Out)
        .start()
    }
  })

  //West
  room.globals.westObjects.middleBackground.traverse(function (child) {
    if (child.type == 0) {
      var t = new TWEEN.Tween(child.material.uniforms.transparency)
        .delay(0)
        .to({ value: opacity }, 2000)
        .easing(TWEEN.Easing.Cubic.Out)
        .start()
    }
  })
  room.globals.westObjects.panelLeftFront.traverse(function (child) {
    if (child.type == 0) {
      var t = new TWEEN.Tween(child.material.uniforms.transparency)
        .delay(0)
        .to({ value: opacity }, 2000)
        .easing(TWEEN.Easing.Cubic.Out)
        .start()
    }
  })
  room.globals.westObjects.panelRightFront.traverse(function (child) {
    if (child.type == 0) {
      var t = new TWEEN.Tween(child.material.uniforms.transparency)
        .delay(0)
        .to({ value: opacity }, 2000)
        .easing(TWEEN.Easing.Cubic.Out)
        .start()
    }
  })

  //East
  room.globals.eastObjects.parent.traverse(function (child) {
    if (child.type == 0) {
      var t = new TWEEN.Tween(child.material.uniforms.transparency)
        .delay(0)
        .to({ value: opacity }, 2000)
        .easing(TWEEN.Easing.Cubic.Out)
        .start()
    }
  })

  //South
  room.globals.southObjects.frontLeft0.traverse(function (child) {
    if (child.type == 0) {
      var t = new TWEEN.Tween(child.material.uniforms.transparency)
        .delay(0)
        .to({ value: opacity }, 2000)
        .easing(TWEEN.Easing.Cubic.Out)
        .start()
    }
  })
  room.globals.southObjects.frontRight0.traverse(function (child) {
    if (child.type == 0) {
      var t = new TWEEN.Tween(child.material.uniforms.transparency)
        .delay(0)
        .to({ value: opacity }, 2000)
        .easing(TWEEN.Easing.Cubic.Out)
        .start()
    }
  })

  //Monks
  room.globals.southObjects.MonksChamber.traverse(function (child) {
    if (child.type == 0) {
      var t = new TWEEN.Tween(child.material.uniforms.transparency)
        .delay(0)
        .to({ value: opacity }, 2000)
        .easing(TWEEN.Easing.Cubic.Out)
        .start()
    }
  })
}

function transitionStaticObjectsOpacity(opacity, room) {
  fade(room.globals.mapModeObjects.parents.Floor, opacity, 0, 2000);
}

function CalculateCenterOfMass(object) {
  var x = 0;
  var y = 0;
  var z = 0;
  var num = 0;

  //Use targets to calculate center of mass of panels
  var objectToUse = (object.name.includes("PANEL")) ? "TARGET" : "Slice";
  object.traverse(function (child) {
    if (child instanceof THREE.Mesh && child.name.includes(objectToUse)) {
      num++;
      var dummy = new THREE.Vector3();
      var wp = child.getWorldPosition(dummy);
      x += wp.x;
      y += wp.y;
      z += wp.z;
    }
  });
  x /= num;
  y /= num;
  z /= num;
  return new THREE.Vector3(x, y, z);
}




function NorthOpenTransition(room) {

  var openOpacity = config.tourTransparency;
  var faintOpacity = -0.2;
  var offOpacity = -1

  fade(room.globals.northObjects.background, openOpacity, -200);
  // //Fade in
  fade(room.globals.northObjects.panelRightBack, openOpacity);
  fade(room.globals.northObjects.panelLeftBack, openOpacity);

  // //Fade out
  fade(room.globals.northObjects.panelRightFront, offOpacity);
  fade(room.globals.northObjects.panelLeftFront, offOpacity);

  fade(room.globals.eastObjects.parent, offOpacity);
  fade(room.globals.westObjects.panelRightFront, faintOpacity);
  fade(room.globals.eastObjects.parent, faintOpacity);

  //Fade up close buttons opacity
  {
    var t = new TWEEN.Tween(room.globals.buttonsReferences.nl1b.material.uniforms.transparency)
      .to({ value: 1 }, 500)
      .easing(TWEEN.Easing.Cubic.Out)
      .start()
  }
  {
    var t = new TWEEN.Tween(room.globals.buttonsReferences.nr1b.material.uniforms.transparency)
      .to({ value: 1 }, 500)
      .easing(TWEEN.Easing.Cubic.Out)
      .start()
  }
  //Fade down open button opacity
  {
    var t = new TWEEN.Tween(room.globals.buttonsReferences.nl1f.material.uniforms.transparency)
      .to({ value: 0 }, 500)
      .easing(TWEEN.Easing.Cubic.Out)
      .start()
  }
  
  //Fade down west button opacity
  {
    var t = new TWEEN.Tween(room.globals.buttonsReferences.wl1f.material.uniforms.transparency)
      .to({ value: 0 }, 500)
      .easing(TWEEN.Easing.Cubic.Out)
      .start()
  }
  {
    var t = new TWEEN.Tween(room.globals.buttonsReferences.wr1f.material.uniforms.transparency)
      .to({ value: 0 }, 500)
      .easing(TWEEN.Easing.Cubic.Out)
      .start()
  }
}

function NorthCloseTransition(room) {


  var offOpacity = -1
  var normalOpacity = (room.currentCamera == "OUTSIDE") ? config.freeTransparency : config.tourTransparency;

  // //Fade out
  fade(room.globals.northObjects.background, offOpacity, offOpacity, 1000);
  fade(room.globals.northObjects.panelRightBack, offOpacity);
  fade(room.globals.northObjects.panelLeftBack, offOpacity);

  // //Fade in
  fade(room.globals.northObjects.panelRightFront, normalOpacity);
  fade(room.globals.northObjects.panelLeftFront, normalOpacity);

  fade(room.globals.westObjects.panelRightFront, normalOpacity);
  fade(room.globals.eastObjects.parent, normalOpacity);

  //Fade down close buttons opacity
  {
    var t = new TWEEN.Tween(room.globals.buttonsReferences.nl1b.material.uniforms.transparency)
      .to({ value: 0 }, 300)
      .easing(TWEEN.Easing.Cubic.Out)
      .start()
  }
  {
    var t = new TWEEN.Tween(room.globals.buttonsReferences.nr1b.material.uniforms.transparency)
      .to({ value: 0 }, 300)
      .easing(TWEEN.Easing.Cubic.Out)
      .start()
  }

  //Fade up open button opacity
  {
    var t = new TWEEN.Tween(room.globals.buttonsReferences.nl1f.material.uniforms.transparency)
      .to({ value: 1 }, 300)
      .easing(TWEEN.Easing.Cubic.Out)
      .start()
  }

    //Fade up west button opacity
    if(!room.events.PANELS.SCENE.current[2]) //only fade up is south is also closed
    {
      {
        var t = new TWEEN.Tween(room.globals.buttonsReferences.wl1f.material.uniforms.transparency)
          .to({ value: 1 }, 500)
          .easing(TWEEN.Easing.Cubic.Out)
          .start()
      }
      {
        var t = new TWEEN.Tween(room.globals.buttonsReferences.wr1f.material.uniforms.transparency)
          .to({ value: 1 }, 500)
          .easing(TWEEN.Easing.Cubic.Out)
          .start()
      }
    }
}

function SouthOpen0Transition(room) {

  var offOpacity = -1
  var openOpacity = config.tourTransparency;
  var faintOpacity = -0.2;

  //Fade out Completely
  fade(room.globals.southObjects.frontLeft0, offOpacity);
  fade(room.globals.southObjects.frontRight0, offOpacity);
  fade(room.globals.southObjects.backLeft1, offOpacity);
  fade(room.globals.southObjects.backRight1, offOpacity);

  //Fade in
  fade(room.globals.southObjects.backLeft0, openOpacity);
  fade(room.globals.southObjects.backRight0, openOpacity);
  fade(room.globals.southObjects.frontLeft1, openOpacity, -200, 500);
  fade(room.globals.southObjects.frontRight1, openOpacity, -200, 500);


  //Fade out subtle
  fade(room.globals.westObjects.panelLeftFront, faintOpacity);
  fade(room.globals.eastObjects.parent, faintOpacity);


  //Fade up close buttons opacity
  {
    var t = new TWEEN.Tween(room.globals.buttonsReferences.sl2b.material.uniforms.transparency)
      .to({ value: 1 }, 300)
      .easing(TWEEN.Easing.Cubic.Out)
      .start()
  }
  {
    var t = new TWEEN.Tween(room.globals.buttonsReferences.sr2b.material.uniforms.transparency)
      .to({ value: 1 }, 300)
      .easing(TWEEN.Easing.Cubic.Out)
      .start()
  }
  //Fade up open button opacity 2
  {
    var t = new TWEEN.Tween(room.globals.buttonsReferences.sl1f.material.uniforms.transparency)
      .to({ value: 1 }, 300)
      .easing(TWEEN.Easing.Cubic.Out)
      .start()
  }

  //Fade down open button opacity
  {
    var t = new TWEEN.Tween(room.globals.buttonsReferences.sl2f.material.uniforms.transparency)
      .to({ value: 0 }, 300)
      .easing(TWEEN.Easing.Cubic.Out)
      .start()
  }

  
  //Fade down west button opacity
  {
    var t = new TWEEN.Tween(room.globals.buttonsReferences.wl1f.material.uniforms.transparency)
      .to({ value: 0 }, 500)
      .easing(TWEEN.Easing.Cubic.Out)
      .start()
  }
  {
    var t = new TWEEN.Tween(room.globals.buttonsReferences.wr1f.material.uniforms.transparency)
      .to({ value: 0 }, 500)
      .easing(TWEEN.Easing.Cubic.Out)
      .start()
  }

}

function SouthClose0Transition(room) {

  var offOpacity = -1
  var normalOpacity = (room.currentCamera == "OUTSIDE") ? config.freeTransparency : config.tourTransparency;

  fade(room.globals.southObjects.frontLeft0, normalOpacity);
  fade(room.globals.southObjects.frontRight0, normalOpacity);

  fade(room.globals.southObjects.backLeft0, offOpacity, -1);
  fade(room.globals.southObjects.backRight0, offOpacity, -1);
  fade(room.globals.southObjects.frontLeft1, offOpacity, -1);
  fade(room.globals.southObjects.frontRight1, offOpacity, -1);
  fade(room.globals.southObjects.backLeft1, offOpacity);
  fade(room.globals.southObjects.backRight1, offOpacity);

  fade(room.globals.westObjects.panelLeftFront, normalOpacity);
  fade(room.globals.eastObjects.parent, normalOpacity);


  //Fade down close buttons opacity
  {
    var t = new TWEEN.Tween(room.globals.buttonsReferences.sl2b.material.uniforms.transparency)
      .to({ value: 0 }, 300)
      .easing(TWEEN.Easing.Cubic.Out)
      .start()
  }
  {
    var t = new TWEEN.Tween(room.globals.buttonsReferences.sr2b.material.uniforms.transparency)
      .to({ value: 0 }, 300)
      .easing(TWEEN.Easing.Cubic.Out)
      .start()
  }

  //Fade down open button opacity 2
  {
    var t = new TWEEN.Tween(room.globals.buttonsReferences.sl1f.material.uniforms.transparency)
      .to({ value: 0 }, 300)
      .easing(TWEEN.Easing.Cubic.Out)
      .start()
  }

  //Fade up open button opacity
  {
    var t = new TWEEN.Tween(room.globals.buttonsReferences.sl2f.material.uniforms.transparency)
      .to({ value: 1 }, 300)
      .easing(TWEEN.Easing.Cubic.Out)
      .start()
  }

  
  //Fade up west button opacity
  if(!room.events.PANELS.SCENE.current[0]) //Only fade up is north is also closed.
  {
    {
      var t = new TWEEN.Tween(room.globals.buttonsReferences.wl1f.material.uniforms.transparency)
        .to({ value: 1 }, 500)
        .easing(TWEEN.Easing.Cubic.Out)
        .start()
    }
    {
      var t = new TWEEN.Tween(room.globals.buttonsReferences.wr1f.material.uniforms.transparency)
        .to({ value: 1 }, 500)
        .easing(TWEEN.Easing.Cubic.Out)
        .start()
    }
  }

}

function SouthOpen1Transition(room) {

  var offOpacity = -1
  var normalOpacity = (room.currentCamera == "OUTSIDE") ? config.freeTransparency : config.tourTransparency;
  var openOpacity = config.tourTransparency;
  var faintOpacity = -0.2;

  //Fade in
  fade(room.globals.southObjects.frontLeft0, offOpacity, normalOpacity, 800, 1000);
  fade(room.globals.southObjects.frontRight0, offOpacity, normalOpacity, 800, 1000);
  fade(room.globals.southObjects.backLeft0, offOpacity, normalOpacity, 800, 1000);
  fade(room.globals.southObjects.backRight0, offOpacity, normalOpacity, 800, 1000);
  fade(room.globals.southObjects.frontLeft1, offOpacity, normalOpacity, 800, 1000);
  fade(room.globals.southObjects.frontRight1, offOpacity, normalOpacity, 800, 1000);

  fade(room.globals.southObjects.backLeft1, openOpacity);
  fade(room.globals.southObjects.backRight1, openOpacity);

  fade(room.globals.westObjects.panelLeftFront, faintOpacity);
  fade(room.globals.eastObjects.parent, faintOpacity);

  // //fade down close buttons 1
  {
    var t = new TWEEN.Tween(room.globals.buttonsReferences.sl2b.material.uniforms.transparency)
      .to({ value: 0 }, 300)
      .easing(TWEEN.Easing.Cubic.Out)
      .start()
  }
  {
    var t = new TWEEN.Tween(room.globals.buttonsReferences.sr2b.material.uniforms.transparency)
      .to({ value: 0 }, 300)
      .easing(TWEEN.Easing.Cubic.Out)
      .start()
  }

  //fade down open button 2
  {
    var t = new TWEEN.Tween(room.globals.buttonsReferences.sl1f.material.uniforms.transparency)
      .to({ value: 0 }, 300)
      .easing(TWEEN.Easing.Cubic.Out)
      .start()
  }

  //fade up close button 2
  {
    var t = new TWEEN.Tween(room.globals.buttonsReferences.sr1b.material.uniforms.transparency)
      .to({ value: 1 }, 300)
      .easing(TWEEN.Easing.Cubic.Out)
      .start()
  }
  {
    var t = new TWEEN.Tween(room.globals.buttonsReferences.sl1b.material.uniforms.transparency)
      .to({ value: 1 }, 300)
      .easing(TWEEN.Easing.Cubic.Out)
      .start()
  }
}


function SouthClose1Transition(room) {

  var offOpacity = -1
  var normalOpacity = (room.currentCamera == "OUTSIDE") ? config.freeTransparency : config.tourTransparency;

  fade(room.globals.southObjects.frontLeft0, offOpacity);
  fade(room.globals.southObjects.frontRight0, offOpacity);
  fade(room.globals.southObjects.backLeft0, normalOpacity, 500);
  fade(room.globals.southObjects.backRight0, normalOpacity, 500);
  fade(room.globals.southObjects.frontLeft1, normalOpacity);
  fade(room.globals.southObjects.frontRight1, normalOpacity);
  fade(room.globals.southObjects.backLeft1, offOpacity);
  fade(room.globals.southObjects.backRight1, offOpacity);

  fade(room.globals.westObjects.panelLeftFront, normalOpacity);
  fade(room.globals.eastObjects.parent, normalOpacity);

  // //fade down close buttons 2
  {
    var t = new TWEEN.Tween(room.globals.buttonsReferences.sl1b.material.uniforms.transparency)
      .to({ value: 0 }, 300)
      .easing(TWEEN.Easing.Cubic.Out)
      .start()
  }
  {
    var t = new TWEEN.Tween(room.globals.buttonsReferences.sr1b.material.uniforms.transparency)
      .to({ value: 0 }, 300)
      .easing(TWEEN.Easing.Cubic.Out)
      .start()
  }
  //fade up close buttons 1
  {
    var t = new TWEEN.Tween(room.globals.buttonsReferences.sr2b.material.uniforms.transparency)
      .to({ value: 1 }, 300)
      .easing(TWEEN.Easing.Cubic.Out)
      .start()
  }
  {
    var t = new TWEEN.Tween(room.globals.buttonsReferences.sl2b.material.uniforms.transparency)
      .to({ value: 1 }, 300)
      .easing(TWEEN.Easing.Cubic.Out)
      .start()
  }

  //Fade up open button opacity 2
  {
    var t = new TWEEN.Tween(room.globals.buttonsReferences.sl1f.material.uniforms.transparency)
      .to({ value: 1 }, 300)
      .easing(TWEEN.Easing.Cubic.Out)
      .start()
  }

}

function WestOpenTransition(room, changeSideWalls) {

  var offOpacity = -1
  var openOpacity = config.tourTransparency + 0.5;

  fade(room.globals.westObjects.panelLeftBack, openOpacity);
  fade(room.globals.westObjects.panelRightBack, openOpacity);
  fade(room.globals.westObjects.panelRightFront, offOpacity);
  fade(room.globals.westObjects.panelLeftFront, offOpacity);
  fade(room.globals.westObjects.sideBackground, openOpacity);


  //Fade up close buttons opacity
  {
    var t = new TWEEN.Tween(room.globals.buttonsReferences.wl1b.material.uniforms.transparency)
      .to({ value: 1 }, 300)
      .easing(TWEEN.Easing.Cubic.Out)
      .start()
  }
  {
    var t = new TWEEN.Tween(room.globals.buttonsReferences.wr1b.material.uniforms.transparency)
      .to({ value: 1 }, 300)
      .easing(TWEEN.Easing.Cubic.Out)
      .start()
  }
  //Fade down open button opacity
  {
    var t = new TWEEN.Tween(room.globals.buttonsReferences.wl1f.material.uniforms.transparency)
      .to({ value: 0 }, 300)
      .easing(TWEEN.Easing.Cubic.Out)
      .start()
  }

  {
    var t = new TWEEN.Tween(room.globals.buttonsReferences.wr1f.material.uniforms.transparency)
      .to({ value: 0 }, 300)
      .easing(TWEEN.Easing.Cubic.Out)
      .start()
  }


  //Fade down north open button
  {
    var t = new TWEEN.Tween(room.globals.buttonsReferences.nl1f.material.uniforms.transparency)
      .to({ value: 0 }, 500)
      .easing(TWEEN.Easing.Cubic.Out)
      .start()
  }
  //Fade down south open button
  {
    var t = new TWEEN.Tween(room.globals.buttonsReferences.sl2f.material.uniforms.transparency)
      .to({ value: 0 }, 300)
      .easing(TWEEN.Easing.Cubic.Out)
      .start()
  }
  
}


function WestCloseTransition(room, changeSideWalls) {

  var offOpacity = -1
  var normalOpacity = (room.currentCamera == "OUTSIDE") ? config.freeTransparency : config.tourTransparency;

  fade(room.globals.westObjects.panelLeftBack, offOpacity);
  fade(room.globals.westObjects.panelRightBack, offOpacity);
  fade(room.globals.westObjects.sideBackground, offOpacity);
  fade(room.globals.westObjects.panelRightFront, normalOpacity);
  fade(room.globals.westObjects.panelLeftFront, normalOpacity);


  // //Fade down close buttons opacity
  {
    var t = new TWEEN.Tween(room.globals.buttonsReferences.wl1b.material.uniforms.transparency)
      .to({ value: 0 }, 300)
      .easing(TWEEN.Easing.Cubic.Out)
      .start()
  }
  {
    var t = new TWEEN.Tween(room.globals.buttonsReferences.wr1b.material.uniforms.transparency)
      .to({ value: 0 }, 300)
      .easing(TWEEN.Easing.Cubic.Out)
      .start()
  }

  //Fade up open button opacity
  {
    var t = new TWEEN.Tween(room.globals.buttonsReferences.wl1f.material.uniforms.transparency)
      .to({ value: 1 }, 300)
      .easing(TWEEN.Easing.Cubic.Out)
      .start()
  }
  {
    var t = new TWEEN.Tween(room.globals.buttonsReferences.wr1f.material.uniforms.transparency)
      .to({ value: 1 }, 300)
      .easing(TWEEN.Easing.Cubic.Out)
      .start()
  }

  
  //Fade up north open button
  {
    var t = new TWEEN.Tween(room.globals.buttonsReferences.nl1f.material.uniforms.transparency)
      .to({ value: 1 }, 500)
      .easing(TWEEN.Easing.Cubic.Out)
      .start()
  }
  //Fade up south open button
  {
    var t = new TWEEN.Tween(room.globals.buttonsReferences.sl2f.material.uniforms.transparency)
      .to({ value: 1 }, 300)
      .easing(TWEEN.Easing.Cubic.Out)
      .start()
  }
}

function FadeButtonsUp(room) {
  for (var i = 0; i < room.globals.buttonsArray.length; i++) {
    var t = new TWEEN.Tween(room.globals.buttonsArray[i].material.uniforms.globalTransparency)
      .to({ value: 1 }, 300)
      .easing(TWEEN.Easing.Cubic.Out)
      .start()
  }
}

function FadeButtonsDown(room) {
  for (var i = 0; i < room.globals.buttonsArray.length; i++) {
    var t = new TWEEN.Tween(room.globals.buttonsArray[i].material.uniforms.globalTransparency)
      .to({ value: 0 }, 300)
      .easing(TWEEN.Easing.Cubic.Out)
      .start()
  }
}

export {
  swing, fade, slideCamera, transitionFrontWallsToOpacity,
  transitionStaticObjectsOpacity, CalculateCenterOfMass,
  NorthOpenTransition, NorthCloseTransition, SouthOpen0Transition,
  SouthClose0Transition, SouthOpen1Transition, SouthClose1Transition,
  WestOpenTransition, WestCloseTransition, FadeButtonsUp, FadeButtonsDown
}