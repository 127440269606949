// import * as T from 'three'
import EventControls from './eventControls'
import { loadAssets } from '../factory/modelLoader'

  // import { GlitchPass } from 'https://threejs.org/examples/jsm/postprocessing/GlitchPass.js';

import config from '../utils/config'
const STATES = {
  VIEW: ['ROOM', 'MAP', 'TOUR'],
  CAMERA: ['INSIDE', 'OUTSIDE'],
  CONTROLS: ['LOCK', 'PAN', 'ORBIT']
}

class Room {
  constructor() {
    this.globals = { dolly: new THREE.Object3D(), tests: [], walls: {}, panels: [], pivots: [], pics: [], backgroundPlanes: {}, buttonsArray: [], buttonsReferences: {} }
    this.element = null
    this.events = {}
    this.currentFocus = null
    this.currentView = STATES.VIEW[0]
    this.currentCamera = STATES.CAMERA[1]
    this.currentControls = STATES.CONTROLS[2]
    this.currentLocked = false;

    this.globals.dolly.position.set(config.dollyPosition.x, config.dollyPosition.y, config.dollyPosition.z);

    const [fov, aspect, min, max] = config.fov
    this.camera = new THREE.PerspectiveCamera(fov, aspect, min, max)
    this.scene = new THREE.Scene();
    this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    this.renderer.domElement.id = "pictureroom"
    this.renderer.outputEncoding = THREE.sRGBEncoding
    this.renderer.gammaOutput = true;
    this.renderer.gammaFactor = 2.2;
    this.renderer.sortObjects = true;

    this.bufferScene = new THREE.Scene();
    this.bufferTexture = new THREE.WebGLRenderTarget( 1920, 1080, { minFilter: THREE.NearestFilter, magFilter: THREE.NearestFilter});

    const geometry = new THREE.PlaneGeometry( 1, 1 );
    var params = {
      "colorTexture": { value: this.bufferTexture.texture }
    };
    const material = new THREE.ShaderMaterial({
      uniforms: params,
      vertexShader: [
        'varying vec2 vUv;',
        'varying vec4 vViewPosition;',
        'float map(float value, float min1, float max1, float min2, float max2) {',
        '  return min2 + (value - min1) * (max2 - min2) / (max1 - min1);',
        '}',
        'void main()',
        '{',
        'vUv = uv;',
        'float bottomMargin = 69.0f / 1080.0f;',
        'float topMargin = 80.0f / 1080.0f;',
        'vUv.y = map(vUv.y, 0.0f, 1.0f, bottomMargin, 1.0f - topMargin);',
        'gl_Position = vec4(position * 2.0f, 1);',
        '}'
      ].join('\n'),
      fragmentShader: [
        'uniform sampler2D colorTexture;',
        
        'varying vec2 vUv;',
        'void main( void ) {',
        'gl_FragColor = texture(colorTexture, vUv);',
        '}'
      ].join('\n'),
    });
    const plane = new THREE.Mesh( geometry, material );
    this.bufferScene.add( plane );


    this.composer = new THREE.EffectComposer( this.renderer );
    // this.composer.setSize (1920, 1080)
    // this.composer.reset();
    const renderPass = new THREE.RenderPass( this.scene, this.camera );
    this.composer.addPass( renderPass );
    
    // const bloomPass = new THREE.UnrealBloomPass();

    // this.composer.addPass( bloomPass );

    
    // const gui = new dat.GUI();

    
    // const generalController = {
    //   radiusExponent: 1.7,
    //   minAlpha: 0.11,
    //   brightness: 0.0,
    //   contrast: 1.0,
    //   globalAlpha: 0,
    //   distanceBasedAlpha: 0
    // };
    // var scene = this.scene;
    // const generalChanger = function ( ) {
    //   scene.traverse(function (child) {
    //     if (child.material != undefined && child.material.uniforms != undefined && child.type == 0) {
    //       child.material.uniforms['radiusExponent'].value = generalController.radiusExponent;
    //       child.material.uniforms['minAlpha'].value = generalController.minAlpha;
    //       child.material.uniforms['transparency'].value = generalController.globalAlpha;
    //       child.material.uniforms['brightness'].value = generalController.brightness;
    //       child.material.uniforms['contrast'].value = generalController.contrast;
    //       child.material.uniforms['minDistanceAlpha'].value = generalController.distanceBasedAlpha;
    //       child.material.uniformsNeedUpdate=true;
    //     }
    //   })  
    // };
    // var generalFolder = gui.addFolder("General");
    // generalFolder.add( generalController, 'radiusExponent', 0.0, 10.0, 0.1 ).onChange(generalChanger);
    // generalFolder.add( generalController, 'minAlpha', 0.0, 1.0, 0.01 ).onChange(generalChanger);
    // generalFolder.add( generalController, 'globalAlpha', -1.0, 1.0, 0.01 ).onChange(generalChanger);
    // generalFolder.add( generalController, 'distanceBasedAlpha', -1.0, 1.0, 0.01 ).onChange(generalChanger);
    // generalFolder.add( generalController, 'brightness', -1.0, 1.0, 0.01 ).onChange(generalChanger);
    // generalFolder.add( generalController, 'contrast', 0.0, 10.0, 0.01 ).onChange(generalChanger);
    // generalChanger();

    
    // var transparency = gui.addFolder("Transparency");
    // var transparencyController =
    // {
    //     equation: THREE.AddEquation,
    //     blendSrc: THREE.SrcAlphaFactor,
    //     blendDst: THREE.OneMinusSrcAlphaFactor,
    //     blending: THREE.NormalBlending,
    // }
    // const transparencyChanger = function ( ) {
    //   scene.traverse(function (child) {
    //     if (child.material != undefined && child.material.uniforms != undefined) {
    //       child.material.blending = THREE.CustomBlending;
    //       child.material.blendEquation = transparencyController.equation;
    //       child.material.blendSrc = transparencyController.blendSrc;
    //       child.material.blendDst = transparencyController.blendDst;
    //       child.material.blendSrcAlpha = null;
    //       child.material.blendDstAlpha = null;  
    //     }
    //   })
    // };
    // transparency.add(transparencyController, 'equation', {Add: THREE.AddEquation, Subtract: THREE.SubtractEquation , ReverseSubtract: THREE.ReverseSubtractEquation, Min: THREE.MinEquation, Max: THREE.MaxEquation}).onChange(transparencyChanger);
    // transparency.add(transparencyController, 'blendSrc', {Zero: THREE.ZeroFactor, One: THREE.OneFactor, SrcColor: THREE.SrcColorFactor,OneMinusSrcColor: THREE.OneMinusSrcColorFactor, SrcAlpha: THREE.SrcAlphaFactor,OneMinusSrcAlpha: THREE.OneMinusSrcAlphaFactor, DstAlpha: THREE.DstAlphaFactor,OneMinusDstAlpha: THREE.OneMinusDstAlphaFactor, DstColor: THREE.DstColorFactor,OneMinusDstColor: THREE.OneMinusDstColorFactor, SrcAlphaSaturate:THREE.SrcAlphaSaturateFactor}).onChange(transparencyChanger);
    // transparency.add(transparencyController, 'blendDst', {Zero: THREE.ZeroFactor, One: THREE.OneFactor, SrcColor: THREE.SrcColorFactor,OneMinusSrcColor: THREE.OneMinusSrcColorFactor, SrcAlpha: THREE.SrcAlphaFactor,OneMinusSrcAlpha: THREE.OneMinusSrcAlphaFactor, DstAlpha: THREE.DstAlphaFactor,OneMinusDstAlpha: THREE.OneMinusDstAlphaFactor, DstColor: THREE.DstColorFactor,OneMinusDstColor: THREE.OneMinusDstColorFactor, SrcAlphaSaturate:THREE.SrcAlphaSaturateFactor}).onChange(transparencyChanger);
    // transparency.add(transparencyController, 'blending', {NoBlending: THREE.NoBlending,  NormalBlending: THREE.NormalBlending,  AdditiveBlending: THREE.AdditiveBlending, SubtractiveBlending: THREE.SubtractiveBlending,  MultiplyBlending: THREE.MultiplyBlending,  CustomBlending: THREE.CustomBlending}).onChange(transparencyChanger);
    // transparencyChanger();

    // const ssaoPass = new THREE.SSAOPass(this.scene, this.camera, window.innerWidth, window.innerHeight)
    // this.composer.addPass( ssaoPass );
    // const ssaoController = {
    //   enabled: true,
    // };
    // const ssaoChanger = function ( ) {
    //   ssaoPass.enabled = ssaoController.enabled;
    // };
    // var ssaoFolder = gui.addFolder("ssao");
    // ssaoFolder.add( ssaoController, 'enabled').onChange(ssaoChanger);
    

    // //
    // const bloomController = {
    //   enabled:true,
    //   threshold: 0.0,
    //   strength: 0.2,
    //   radius: 0.1,
    // };
    // const bloomPass = new THREE.UnrealBloomPass()
    // this.composer.addPass( bloomPass );
    // const bloomChanger = function ( ) {
    //   bloomPass.enabled = bloomController.enabled;
    //   bloomPass.threshold = bloomController.threshold;
    //   bloomPass.strength = bloomController.strength;
    //   bloomPass.radius = bloomController.radius;
    // };
    // var bloomFolder = gui.addFolder("Bloom");
    // bloomFolder.add( bloomController, 'enabled').onChange(bloomChanger);
    // bloomFolder.add( bloomController, 'threshold', 0.0, 1.0, 0.01 ).onChange(bloomChanger);
    // bloomFolder.add( bloomController, 'strength', 0, 1, 0.0001 ).onChange(bloomChanger);
    // bloomFolder.add( bloomController, 'radius', 0.0, 1, 0.001 ).onChange(bloomChanger);
    // bloomChanger();

    // //
    // const bokehController = {
    //   enabled:true,
    //   focus: 1.4,
    //   aperture: 0.007,
    //   maxblur: 0.005
    // };
    // const BokehPass = new THREE.BokehPass(this.scene, this.camera, {
    //   focus: bokehController.focus,
    //   aperture: bokehController.aperture,
    //   maxblur: bokehController.maxblur,
    //   enabled:true
    // });
    // this.composer.addPass( BokehPass );
    // const bokehChanger = function ( ) {
    //   // BokehPass.enabled = bokehChanger.enabled;
    //   BokehPass.uniforms[ 'focus' ].value = bokehController.focus;
    //   BokehPass.uniforms[ 'aperture' ].value = bokehController.aperture;
    //   BokehPass.uniforms[ 'maxblur' ].value = bokehController.maxblur;
    // };
    // var bokehFolder = gui.addFolder("Bokeh");
    // bokehFolder.add( bokehController, 'enabled').onChange(bokehChanger);
    // bokehFolder.add( bokehController, 'focus', 0.0, 10.0, 0.01 ).onChange(bokehChanger);
    // bokehFolder.add( bokehController, 'aperture', 0, 0.1, 0.0001 ).onChange(bokehChanger);
    // bokehFolder.add( bokehController, 'maxblur', 0.0, 0.1, 0.001 ).onChange(bokehChanger);
    // bokehChanger();
    
    
    // // //
    // // const FilmPass = new THREE.FilmPass()
    // // this.composer.addPass( FilmPass );
    // // const FilmController = {
    // //   enabled: true,
    // //   grayscale: 1,
    // //   noiseIntensity: 1.0,
    // //   scanlinesIntensity: 1.0,
    // //   scanlinesCount: 1.0,
    // // };
    // // const FilmChanger = function ( ) {
    // //   FilmPass.enabled = FilmController.enabled;
    // //   FilmPass.uniforms['grayscale'].value = FilmController.grayscale;
    // //   FilmPass.uniforms['nIntensity'].value = FilmController.noiseIntensity;
    //   FilmPass.uniforms['sIntensity'].value = FilmController.scanlinesIntensity;
    //   FilmPass.uniforms['sCount'].value  = FilmController.scanlinesCount;
    // };
    // var FilmFolder = gui.addFolder("Film");
    // FilmFolder.add( FilmController, 'enabled').onChange(FilmChanger);
    // FilmFolder.add( FilmController, 'grayscale').onChange(FilmChanger);
    // // FilmFolder.add( FilmController, 'grayscale', 0.0, 1, 0.01 ).onChange(FilmChanger);
    // FilmFolder.add( FilmController, 'noiseIntensity').onChange(FilmChanger);
    // FilmFolder.add( FilmController, 'scanlinesIntensity').onChange(FilmChanger);
    // FilmFolder.add( FilmController, 'scanlinesCount').onChange(FilmChanger);

    this.controls = new EventControls(this)
  }

  setup(target) {
    const element = document.querySelector(target)
    return new Promise((resolve) => {
      if (element) {
        element.appendChild(this.renderer.domElement)
      }
      else {
        this.renderer.domElement.id = "canvas"
        document.body.appendChild(this.renderer.domElement)
      }
      const [x, y, z] = config.startPoint
      if (config.showDolly) {
        const boxGeom = new THREE.BoxGeometry(0.25, 0.25, -0.25);
        const material = new THREE.MeshBasicMaterial({ color: 'red', side: THREE.FrontSide });
        this.globals.dolly = new THREE.Mesh(boxGeom, material)
        this.scene.add(this.globals.dolly);
      }


      this.camera.position.set(x, y, z)
      this.camera.lookAt(this.globals.dolly)
      
      this.isOnTour = 0;

      loadAssets(this).then(() => {
        resolve()
      })

    })

  }

  // use onAction to interact with the eventsMap
  useEvent(name, scope) {
    this.events[name] = scope
  }

  get state() {
    return {
      view: this.currentView,
      focus: this.currentFocus,
      camera: this.currentCamera,
      controls: this.currentControls,
      locked: this.currentLocked
    }
  }

  emit(trigger) {
    return (method, onComplete) => {
      this.onAction({ type: 'CTA', trigger, method })
        .then((data) => onComplete && onComplete(data))
    }
  }
  // incoming triggers
  onAction(action) {
    return new Promise((resolve) => {
      this.dispatcher(action)
        .then((response) => {
          if (action.type == "TBC" || (action.method == "ROOM")) this.currentLocked = false;
          else this.currentLocked = true;
          this.controls.updateControls(action)
          resolve(response)
        })
    })
  }

  dispatcher({ type, trigger, method, target, param, callback }) {
    return new Promise((resolve) => {
      // direct action
      if (type === 'CTA') this.events[trigger][method]().then(resolve)
      // action with param
      if (type === 'CUSTOM') this.events[trigger][method](param).then(resolve)
      // child action with subscription callback
      if (type === 'OBSERVER') {
        if (target.userData.actions.trigger) {
          this.events[target.userData.actions.trigger]
            // TODO - update API to: .subscribe({ callback, param }, resolve)
            .subscribe(target.userData.actions.method, resolve)
        }
      }
      if (type === 'TBC') {
        // TODO - update API to: .subscribe({ callback, param }, resolve)
        this.events[trigger].subscribe(callback, resolve, param)
      }
    })
  }

  deriveState(view) {
    if (!view) return false
    if (view === 'ROOM') return { view: 'ROOM', camera: 'OUTSIDE', controls: 'ORBIT' }
    if (view === 'MAP') return { view: 'MAP', camera: 'OUTSIDE', controls: 'PAN' }
    if (view === 'TOUR') return { view: 'ROOM', camera: 'INSIDE', controls: 'ORBIT' }
  }

  applyState({ view, camera, controls, locked }) {
    this.currentView = view
    this.currentCamera = camera
    this.currentControls = controls
    this.currentLocked = locked;
    return this.state
  }

  lockControls() {
    this.applyState({ ...this.state, controls: 'TWEEN', locked: false })
    this.controls.updateControls()
  }

  unmount() {
    // handle removing listeners & dom
    for (var i = this.scene.children.length - 1; i >= 0; i--) {
      this.scene.remove(this.scene.children[i]);
    }
    // console.log('unmount')
    // document.removeEventListener('mousemove', this.controls.HandleMouseMove.bind(this.controls))
  }

}


const compassRotations = {
  n: { x: 0, y: 0, z: 0 },
  e: { x: Math.PI / 2, y: -(Math.PI / 2), z: Math.PI / 2 },
  s: { x: Math.PI, y: 0, z: Math.PI },
  w: { x: 0, y: Math.PI / 2, z: 0 }
}

export default new Room()
