/**
 * TargetPoint Componenet
 * tracks the onPan command from the Room Section component.
 */
import Template from '../templates/target-point.html';
import Collection from '../data/content';
import V from '../libs/velo'
export default {
    name: 'target-point',
    template: Template,
    props: ['model', 'points', 'viewport'],
    data() {
        return {
            url: Collection.urlify(this.model.id)
        }
    },
    computed: {
        show() {
            if(this.points.hasOwnProperty('show')) {
                return this.points.show    
            } else {
                return true
            }
        },
        stylx() {
            return {
                transform: `translate(${this.points.transforms})`
            }
        },
        label() {            
            return Collection.find(this.$route.params.section, this.url).label
        },
        isMobile() {
            return (this.viewport.width < 740) ? 'x--mobile' : ''
        }
    },
    methods:{
        handleClick() {
            this.$router.go({
                name: 'pointcloud',
                params: { pointcloud: this.url }
            }) 
        }
    },
    ready() {
        setTimeout(() => {
            if(this.$el != undefined) this.$el.classList.add('x--flash')
        }, 4000);
    }
}