import Template from '../templates/page-download.html'
import Core from '../data/core';
// var GeminiScrollbar = require('gemini-scrollbar');
export default {
  name: 'download-page',
  template: Template,
  data() {
    return {
      list: Core.Downloads.collection
    }
  },
  methods: {
    close() {
        Core.closePages()
    }
  },
  ready() {
    Core.layout.overlay = true;
    // var myScrollbar = new GeminiScrollbar({
    //     element: this.$el
    // }).create();
  },
  beforeDestroy() {
    Core.layout.overlay = false;
  }
}