/**
 * 360 datasets
 */
export default {
    modelroom: {
        label: 'Model Room',
        about: [
            "Sir John Soane believed in the use of models in the architectural design process and often recommended their use to his students. He built up a collection of around 120 architectural models, one of the largest of its kind in the world.",
            "Many of his models were displayed in the Model Room, on a breath-taking, custom-made central stand. This room began life as Mrs Soane’s bedroom which Soane kept as it was for 19 years after her death in 1815 before converting it into the Model Room in 1834-35.",
            "After Soane’s death in 1837 this room became an office and it was only finally restored in 2015.  The restoration required years of archival research, studying views of the room in Soane’s day, descriptions, inventories, records of later alterations and the fabric of the building itself."
        ],
        datasets: [{
            id: 'pid_18',
            coords: [ // 70 - 87
                {show:true, x:978, y:284}, // 0
                {show:true, x:982.1484375, y:284.36328125},
                {show:true, x:986.45703125, y:284.734375},
                {show:true, x:990.671875, y:285.2109375},
                {show:true, x:994.80078125, y:285.7421875},
                {show:true, x:998.890625, y:286.42578125},
                {show:true, x:1002.7734375, y:287.078125},
                {show:true, x:1006.515625, y:288.3046875},
                {show:true, x:1010.13671875, y:289.0625},
                {show:true, x:1013.63671875, y:290.32421875},
                {show:true, x:1016.9921875, y:291.6484375},
                {show:true, x:1020.19140625, y:292.95703125},
                {show:true, x:1022.87890625, y:294.31640625},
                {show:true, x:1025.37109375, y:295.83984375},
                {show:true, x:1027.703125, y:297.3828125},
                {show:true, x:1029.89453125, y:298.87109375},
                {show:true, x:1031.78125, y:300.484375},
                {show:true, x:1033.40625, y:302.1484375},
                {show:true, x:1034.8671875, y:303.75},
                {show:true, x:1036.15625, y:305.41796875},
                {show:true, x:1036.80859375, y:307.10546875},
                {show:true, x:1037.2265625, y:308.8359375},
                {show:true, x:1037.421875, y:310.52734375},
                {show:true, x:1037.27734375, y:312.23046875},
                {show:true, x:1036.96484375, y:313.99609375},
                {show:true, x:1036.33203125, y:315.73828125},
                {show:true, x:1035.109375, y:317.46875},
                {show:true, x:1033.6640625, y:319.125},
                {show:true, x:1031.984375, y:320.76953125},
                {show:true, x:1030.08203125, y:322.40234375},
                {show:true, x:1027.859375, y:324.03125},
                {show:true, x:1025.3671875, y:325.58984375},
                {show:true, x:1022.73046875, y:327.19140625},
                {show:true, x:1019.4296875, y:328.60546875},
                {show:true, x:1015.97265625, y:330.015625},
                {show:true, x:1012.29296875, y:331.41796875},
                {show:true, x:1008.41796875, y:332.67578125},
                {show:true, x:1004.3125, y:333.9296875},
                {show:true, x:1000.01953125, y:334.6640625},
                {show:true, x:995.6640625, y:335.41015625},
                {show:true, x:991.07421875, y:336.03125},
                {show:true, x:986.4921875, y:336.57421875},
                {show:true, x:981.79296875, y:336.98828125},
                {show:true, x:976.546875, y:337.3828125},
                {show:true, x:971.29296875, y:337.62109375},
                {show:true, x:965.984375, y:337.83984375},
                {show:true, x:960.6953125, y:337.9140625},
                {show:true, x:955.359375, y:337.8359375},
                {show:true, x:950.11328125, y:337.671875},
                {show:true, x:944.8671875, y:337.4296875},
                {show:true, x:939.65234375, y:337.0234375},
                {show:true, x:934.953125, y:336.625},
                {show:true, x:930.28515625, y:336.07421875},
                {show:true, x:925.77734375, y:335.4453125},
                {show:true, x:921.328125, y:334.75390625},
                {show:true, x:917.125, y:333.98046875},
                {show:true, x:912.97265625, y:332.765625},
                {show:true, x:909.12109375, y:331.45703125},
                {show:true, x:905.4296875, y:330.13671875},
                {show:true, x:901.8984375, y:328.63671875},
                {show:true, x:898.625, y:327.21484375},
                {show:true, x:895.98046875, y:325.57421875},
                {show:true, x:893.52734375, y:324.0546875},
                {show:true, x:891.28515625, y:322.38671875},
                {show:true, x:889.20703125, y:320.7578125},
                {show:true, x:887.51171875, y:319.1015625},
                {show:true, x:886.03125, y:317.421875},
                {show:true, x:884.7890625, y:315.70703125},
                {show:true, x:884.171875, y:314.0078125},
                {show:true, x:883.78125, y:312.25390625},
                {show:false, x:883.6640625, y:310.484375}, // hide
                {show:false, x:883.859375, y:308.78515625},
                {show:false, x:884.29296875, y:307.0859375},
                {show:false, x:884.91015625, y:305.40234375},
                {show:false, x:886.18359375, y:303.77734375},
                {show:false, x:887.6015625, y:302.12109375},
                {show:false, x:889.35546875, y:300.47265625},
                {show:false, x:891.1875, y:298.859375},
                {show:false, x:893.3359375, y:297.33203125},
                {show:false, x:895.640625, y:295.87890625},
                {show:false, x:898.09375, y:294.36328125},
                {show:false, x:900.7890625, y:292.98046875},
                {show:false, x:904.01171875, y:291.6640625},
                {show:false, x:907.34765625, y:290.30859375},
                {show:false, x:910.8671875, y:289.0390625},
                {show:false, x:914.50390625, y:288.328125},
                {show:false, x:918.27734375, y:287.09375},
                {show:false, x:922.1484375, y:286.42578125}, // hide
                {show:true, x:926, y:285.77734375},
                {show:true, x:930.16796875, y:285.234375},
                {show:true, x:934.421875, y:284.6875},
                {show:true, x:938.66796875, y:284.28125},
                {show:true, x:942.984375, y:283.890625},
                {show:true, x:947.33984375, y:283.6484375},
                {show:true, x:951.671875, y:283.41796875},
                {show:true, x:956.109375, y:283.2578125},
                {show:true, x:960.50390625, y:283.15625},
                {show:true, x:964.94921875, y:283.28515625},
                {show:true, x:969.34375, y:283.45703125},
                {show:true, x:973.70703125, y:283.6640625}
            ]
        },{
            id: 'pid_10',
            coords: [
                {show:true, x:1435, y:263.5},
                {show:true, x:1463.16796875, y:273.36328125},
                {show:true, x:1492.63671875, y:283.24609375},
                {show:true, x:1521.45703125, y:293.9765625},
                {show:true, x:1550.5234375, y:309.11328125},
                {show:true, x:1577.4765625, y:323.14453125},
                {show:true, x:1606.5234375, y:339.1796875},
                {show:true, x:1636.50390625, y:355.73828125},
                {show:true, x:1665.52279663086, y:371.293121337891},
                {show:true, x:1691.54168701172, y:386.847961425781},
                {show:true, x:1717.56057739258, y:405.402801513672},
                {show:true, x:1738.57946777344, y:425.957641601563},
                {show:true, x:1760.99743652344, y:448.242797851563},
                {show:true, x:1786.11853027344, y:472.391235351563},
                {show:true, x:1808.12243652344, y:498.410766601563},
                {show:true, x:1826.90759277344, y:522.516235351563},
                {show:true, x:1842.25524902344, y:552.477172851563},
                {show:true, x:1852.5, y:584.5},
                {show:true, x:1853.5, y:621.250316101243},
                {show:true, x:1852.71422001609, y:659.428922838678},
                {show:true, x:1845.09489739063, y:697.774416118799},
                {show:true, x:1832.27498097352, y:738.120462020075},
                {show:true, x:1814.45566764806, y:777.465851992528},
                {show:true, x:1784.96940666169, y:817.811395752155},
                {show:true, x:1744.98296229612, y:863.656954768612},
                {show:true, x:1689, y:904.5},
                {show:true, x:1630, y:940.5},
                {show:false, x:1561, y:979.5},
                {show:false, x:1481, y:1015.5},
                {show:false, x:1383, y:1045},
                {show:false, x:1283, y:1068},
                {show:false, x:1170, y:1080},
                {show:false, x:1055, y:1084},
                {show:false, x:938, y:1083},
                {show:false, x:828, y:1081},
                {show:false, x:714.5, y:1071.5},
                {show:false, x:606.5, y:1058.5},
                {show:false, x:506.5, y:1039.5},
                {show:false, x:411.5, y:1005.5},
                {show:true, x:334.5, y:970},
                {show:true, x:266.5, y:933},
                {show:true, x:207.5, y:890},
                {show:true, x:162.500637430129, y:851.000652977205},
                {show:true, x:125.5, y:806},
                {show:true, x:100.500388676908, y:769.000621883052},
                {show:true, x:75.5, y:726},
                {show:true, x:69.5, y:682},
                {show:true, x:68.5, y:642},
                {show:true, x:71.3748931138504, y:611.000481959366},
                {show:true, x:78.25, y:578},
                {show:true, x:92.02734375, y:545.38671875},
                {show:true, x:101, y:518},
                {show:true, x:120.8681640625, y:489.6533203125},
                {show:true, x:139.5712890625, y:462.3212890625},
                {show:true, x:161.6220703125, y:438.6533203125},
                {show:true, x:185.2607421875, y:415.486328125},
                {show:true, x:213.8994140625, y:393.8193359375},
                {show:true, x:240.5556640625, y:374.1396484375},
                {show:true, x:267.6728515625, y:356.2998046875},
                {show:true, x:290.6494140625, y:342.8193359375},
                {show:true, x:318.2177734375, y:329.4091796875},
                {show:true, x:345.7861328125, y:315.4990234375},
                {show:true, x:381.3544921875, y:300.0888671875},
                {show:true, x:411.2724609375, y:288.2763671875},
                {show:false, x:439.6865234375, y:276.9833984375},
                {show:false, x:469.1513671875, y:266.3701171875},
                {show:false, x:497.0810546875, y:256.7958984375},
                {show:false, x:526.1552734375, y:247.8388671875},
                {show:false, x:555.4365234375, y:239.6787109375},
                {show:false, x:583.9404296875, y:233.2294921875},
                {show:false, x:611.68310546875, y:227.11669921875},
                {show:false, x:639.80810546875, y:221.54638671875},
                {show:false, x:667.87841796875, y:215.62060546875},
                {show:false, x:695.91748046875, y:210.97998046875},
                {show:false, x:723.92529296875, y:206.89404296875},
                {show:false, x:751.06201171875, y:202.87841796875},
                {show:false, x:778.00732421875, y:199.72998046875},
                {show:false, x:804.51513671875, y:198.90576171875},
                {show:false, x:832.30029296875, y:196.78857421875},
                {show:false, x:858.74560546875, y:195.02685546875},
                {show:false, x:886.36279296875, y:193.16748046875},
                {show:false, x:914.22998046875, y:192.77685546875},
                {show:false, x:940.74560546875, y:192.62451171875},
                {show:false, x:968.31982421875, y:192.67919921875},
                {show:false, x:994.79638671875, y:192.64794921875},
                {show:false, x:1022.34326171875, y:193.11669921875},
                {show:false, x:1050.27294921875, y:194.69091796875},
                {show:false, x:1076.69091796875, y:195.19873046875},
                {show:false, x:1104.32763671875, y:198.66748046875},
                {show:false, x:1132.26123046875, y:200.71435546875},
                {show:false, x:1158.76513671875, y:203.19091796875},
                {show:false, x:1186.69091796875, y:207.01904296875},
                {show:false, x:1214.62060546875, y:211.02685546875},
                {show:false, x:1242.55810546875, y:215.05419921875},
                {show:false, x:1270.51513671875, y:219.46826171875},
                {show:false, x:1297.14794921875, y:225.43310546875},
                {show:false, x:1325.19091796875, y:231.49951171875},
                {show:false, x:1355.22216796875, y:238.72998046875},
                {show:true, x:1381.21044921875, y:245.62060546875},
                {show:true, x:1406.28857421875, y:254.80029296875}
            ]
        },{
            id: 'pid_24',
            coords: [
                {show: true, x: 1527.375, y: 1091.5},
                {show: false, x: 1462.40947551715, y: 1091.75000094937},
                {show: false, x: 1397.44360723986, y: 1092.00000322174},
                {show: false, x: 1332.47802037381, y: 1092.25000441117},
                {show: false, x: 1267.50962211998, y: 1092.50001641942},
                {show: false, x: 1202.5480896034, y: 1092.75000200687},
                {show: false, x: 1137.57678560465, y: 1093.00002519703},
                {show: false, x: 1072.61802994175, y: 1093.25000009857},
                {show: false, x: 1007.64590087437, y: 1093.50002646377},
                {show: false, x: 942.685074358263, y: 1093.75000933438},
                {show: false, x: 877.722017598395, y: 1094.00000078744},
                {show: false, x: 812.75852323228, y: 1094.24999392449},
                {show: false, x: 747.796457730508, y: 1094.49998156298},
                {show: false, x: 682.828578363119, y: 1094.74999157444},
                {show: false, x: 617.869604922893, y: 1094.99996731403},
                {show: false, x: 552.897420118687, y: 1095.24999389372},
                {show: false, x: 487.930931208693, y: 1095.49999855441},
                {show: false, x: 422.968884517395, y: 1095.74998612051},
                {show: true, x: 358 ,y: 1096},
                {show: true, x: 325.25, y: 1062.5},
                {show: true, x: 293.0546875, y: 1019},
                {show: true, x: 272.56640625, y: 975.5},
                {show: true, x: 259.322265625, y: 938},
                {show: true, x: 248.578125, y: 897.5},
                {show: true, x: 242.765625, y: 859.01953125},
                {show: true, x: 241.578125, y: 823.75},
                {show: true, x: 245.078125, y: 785.96484375},
                {show: true, x: 250.078125, y: 754.21875},
                {show: true, x: 259.078125, y: 719.8671875},
                {show: true, x: 272.41796875, y: 689.27734375},
                {show: true, x: 285.87890625, y: 660.4375},
                {show: true, x: 302.05078125, y: 633.15234375},
                {show: true, x: 319.87890625, y: 606.8125},
                {show: true, x: 338.625, y: 583.921875},
                {show: true, x: 358.98046875, y: 561.24609375},
                {show: true, x: 379.73046875, y: 539.30859375},
                {show: true, x: 402.04296875, y: 519.91796875},
                {show: true, x: 425.26953125, y: 501.66015625},
                {show: true, x: 449.0078125, y: 484.57421875},
                {show: true, x: 472.8515625, y: 469.19140625},
                {show: true, x: 496.79296875, y: 453.8984375},
                {show: true, x: 520.15234375, y: 441.8671875},
                {show: true, x: 545.6796875, y: 428.6953125},
                {show: false, x: 570.4453125, y: 416.04296875},
                {show: false, x: 594.859512329102, y: 405.476226806641},
                {show: false, x: 617.773712158203, y: 393.909484863281},
                {show: false, x: 643.687911987305, y: 385.342742919922},
                {show: false, x: 668.102111816406, y: 377.276000976563},
                {show: false, x: 693.309143066406, y: 370.861938476563},
                {show: false, x: 720.570861816406, y: 364.951782226563},
                {show: false, x: 743.234924316406, y: 360.268188476563},
                {show: false, x: 769.523986816406, y: 356.881469726563},
                {show: false, x: 795.609924316406, y: 352.537719726563},
                {show: false, x: 818.852111816406, y: 348.756469726563},
                {show: false, x: 843.020080566406, y: 346.502563476563},
                {show: false, x: 869.5, y: 343.25},
                {show: false, x: 894.21875, y: 341.41015625},
                {show: false, x: 918.9140625, y: 340.0390625},
                {show: false, x: 943.6328125, y: 339.421875},
                {show: false, x: 968.265625, y: 339.2890625},
                {show: false, x: 992.890625, y: 339.75},
                {show: false, x: 1017.65234375, y: 340.93359375},
                {show: false, x: 1042.37109375, y: 342.6796875},
                {show: false, x: 1067.15625, y: 344.9296875},
                {show: false, x: 1091.81640625, y: 347.63671875},
                {show: false, x: 1116.5234375, y: 351.19140625},
                {show: false, x: 1141.2890625, y: 355.33203125},
                {show: false, x: 1166.05078125, y: 360.02734375},
                {show: false, x: 1190.69921875, y: 365.578125},
                {show: false, x: 1215.91796875, y: 371.8125},
                {show: false, x: 1240.57421875, y: 378.515625},
                {show: false, x: 1265.2890625, y: 386.19921875},
                {show: false, x: 1289.96484375, y: 394.6171875},
                {show: false, x: 1314.578125, y: 404.05859375},
                {show: false, x: 1339.15234375, y: 414.28515625},
                {show: true, x: 1363.69921875, y: 425.6171875},
                {show: true, x: 1388.15234375, y: 437.88671875},
                {show: true, x: 1412.62890625, y: 451.21484375},
                {show: true, x: 1436.99609375, y: 465.5390625},
                {show: true, x: 1460.62890625, y: 481.0390625},
                {show: true, x: 1484.12890625, y: 497.484375},
                {show: true, x: 1509.23916625977, y: 514.105880737305},
                {show: true, x: 1531.53604125977, y: 533.851974487305},
                {show: true, x: 1553.27822875977, y: 554.098068237305},
                {show: true, x: 1574.94229125977, y: 576.008224487305},
                {show: true, x: 1594.62979125977, y: 599.769943237305},
                {show: true, x: 1612.95010375977, y: 625.523849487305},
                {show: true, x: 1630.66104125977, y: 653.199630737305},
                {show: true, x: 1646.34854125977, y: 681.566818237305},
                {show: true, x: 1659.98135375977, y: 711.898849487305},
                {show: true, x: 1671.57510375977, y: 743.652755737305},
                {show: true, x: 1677.95401000977, y: 776.078536987305},
                {show: true, x: 1683.52822875977, y: 811.676193237305},
                {show: true, x: 1685.12588500977, y: 849.293380737305},
                {show: true, x: 1682.68447875977, y: 888.840255737305},
                {show: true, x: 1673.16885375977, y: 927.262130737305},
                {show: true, x: 1660.75088500977, y: 968.816818237305},
                {show: true, x: 1637.32119750977, y: 1004.3480682373},
                {show: true, x: 1611.32119750977, y: 1029.5},
                {show: true, x: 1566.32119750977, y: 1065}
            ]
        },{
            id: 'pid_09',
            coords: [
                {x:664.5, y:694, show: true},
                {x:647.6875, y:681.35546875, show: true},
                {x:630.44921875, y:666.5, show: true},
                {x:614.53515625, y:651.5, show: true},
                {x:601.15625, y:637, show: true},
                {x:591.01171875, y:622, show: true},
                {x:582.046875, y:606, show: true},
                {x:574.03515625, y:592, show: true},
                {x:568.53515625, y:579.5, show: true},
                {x:564.53515625, y:562.5, show: true},
                {x:563.197326660156, y:548.5546875, show: true},
                {x:564.599670410156, y:532.4375, show: true},
                {x:567.197326660156, y:515.5546875, show: true},
                {x:572.697326660156, y:503.0546875, show: true},
                {x:578.697326660156, y:490.0546875, show: true},
                {x:585.697326660156, y:477.0546875, show: true},
                {x:592.697326660156, y:465.0546875, show: true},
                {x:600.034790039063, y:454.658081054688, show: true},
                {x:610.372253417969, y:444.261474609375, show: false},
                {x:621.209716796875, y:434.364868164063, show: false},
                {x:631.862060546875, y:425.735961914063, show: false},
                {x:643.670654296875, y:417.814086914063, show: false},
                {x:656.709716796875, y:409.364868164063, show: false},
                {x:671.209716796875, y:401.364868164063, show: false},
                {x:686.709716796875, y:395.314086914063, show: false},
                {x:702.209716796875, y:387.364868164063, show: false},
                {x:719.209716796875, y:379.864868164063, show: false},
                {x:737.209716796875, y:374.364868164063, show: false},
                {x:754.527786254883, y:368.058395385742, show: false},
                {x:770.062942504883, y:363.402145385742, show: false},
                {x:785.777786254883, y:359.327926635742, show: false},
                {x:802.512161254883, y:356.355270385742, show: false},
                {x:818.027786254883, y:353.234176635742, show: false},
                {x:835.777786254883, y:351.206832885742, show: false},
                {x:852.527786254883, y:349.249801635742, show: false},
                {x:870.027786254883, y:347.308395385742, show: false},
                {x:887.777786254883, y:346.058395385742, show: false},
                {x:904.777786254883, y:345.031051635742, show: false},
                {x:922.777786254883, y:343.058395385742, show: false},
                {x:939.777786254883, y:343.038864135742, show: false},
                {x:957.777786254883, y:342.058395385742, show: false},
                {x:974.777786254883, y:342.085739135742, show: false},
                {x:992.777786254883, y:343.058395385742, show: false},
                {x:1010.77778625488, y:344.058395385742, show: false},
                {x:1028.77778625488, y:346.058395385742, show: false},
                {x:1045.79341125488, y:348.042770385742, show: false},
                {x:1062.77778625488, y:350.031051635742, show: false},
                {x:1080.76997375488, y:353.058395385742, show: false},
                {x:1097.71919250488, y:356.058395385742, show: false},
                {x:1114.69966125488, y:359.906051635742, show: false},
                {x:1130.92231750488, y:363.824020385742, show: false},
                {x:1147.71138000488, y:368.218551635742, show: false},
                {x:1163.85981750488, y:373.136520385742, show: false},
                {x:1179.85591125488, y:378.781051635742, show: false},
                {x:1195.71138000488, y:384.277145385742, show: false},
                {x:1210.77778625488, y:391.019332885742, show: false},
                {x:1225.78169250488, y:397.995895385742, show: false},
                {x:1241.69966125488, y:404.140426635742, show: false},
                {x:1255.89497375488, y:412.058395385742, show: false},
                {x:1269.91841125488, y:420.101364135742, show: false},
                {x:1282.96528625488, y:428.956832885742, show: false},
                {x:1295.73872375488, y:438.054489135742, show: true},
                {x:1307.13716125488, y:449.538864135742, show: true},
                {x:1319.04731750488, y:462.659957885742, show: true},
                {x:1328.02778625488, y:471.941207885742, show: true},
                {x:1334.04731750488, y:484.659957885742, show: true},
                {x:1340.54731750488, y:499.159957885742, show: true},
                {x:1345.04731750488, y:513.753707885742, show: true},
                {x:1348.03559875488, y:526.831832885742, show: true},
                {x:1350.56684875488, y:538.136520385742, show: true},
                {x:1350.54341125488, y:550.777145385742, show: true},
                {x:1349.56684875488, y:562.163864135742, show: true},
                {x:1346.63325500488, y:576.136520385742, show: true},
                {x:1342.37153625488, y:588.636520385742, show: true},
                {x:1337.65669250488, y:604.093551635742, show: true},
                {x:1329.41059875488, y:619.636520385742, show: true},
                {x:1319.57075500488, y:634.558395385742, show: true},
                {x:1306.56684875488, y:651.015426635742, show: true},
                {x:1293.04731750488, y:665.741989135742, show: true},
                {x:1277.16059875488, y:680.460739135742, show: true},
                {x:1259.94966125488, y:694.741989135742, show: true},
                {x:1237.96919250488, y:706.741989135742, show: true},
                {x:1213.04731750488, y:718.741989135742, show: true},
                {x:1187.04731750488, y:728.741989135742, show: true},
                {x:1159.04731750488, y:737.741989135742, show: true},
                {x:1129.06684875488, y:745.741989135742, show: true},
                {x:1098.04731750488, y:752.741989135742, show: true},
                {x:1067.07856750488, y:759.241989135742, show: true},
                {x:1031.58247375488, y:763.241989135742, show: true},
                {x:997.023880004883, y:767.245895385742, show: true},
                {x:960.988723754883, y:768.741989135742, show: true},
                {x:926.508255004883, y:769.241989135742, show: true},
                {x:890.609817504883, y:768.241989135742, show: true},
                {x:856.547317504883, y:764.241989135742, show: true},
                {x:824.047317504883, y:758.741989135742, show: true},
                {x:793.047317504883, y:750.741989135742, show: true},
                {x:763.047317504883, y:740.741989135742, show: true},
                {x:736.090286254883, y:729.741989135742, show: true},
                {x:710.047317504883, y:717.206832885742, show: true},
                {x:685.953567504883, y:706.241989135742, show: true}
            ]
        }]
    },
    sepulchral: {
        label: 'Sepulchral Chamber',
        about: [
            "This room contains one of Soane’s most spectacular items: the sarcophagus of Egyptian king Seti I.",
            "The sarcophagus was discovered in the Valley of the Kings by famed Italian explorer Giovanni Belzoni in 1817.",
            "Soane acquired the sarcophagus for the Museum in 1824, and installed it in the Chamber on four fluted stone columns, where it has remained ever since."
        ],
        datasets: [{
            id: 'pid_00',
            static: false,
            offsetY: -100,
            coords: [
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true },
                {x:960, y:440, show: true }
            ]
        },{
            id: 'pid_28',
            coords: [
                {x:854.417514826036 ,y:729.21012012621, show: true },
                {x:832.190205603807 ,y:723.548650955081, show: true },
                {x:810.716396282542 ,y:716.769251463744, show: true },
                {x:790.133523733518 ,y:708.939500012572, show: true },
                {x:770.568532804466 ,y:700.127336975117, show: true },
                {x:752.151250106564 ,y:690.405852720954, show: true },
                {x:735.000200216209 ,y:679.867381745807, show: true },
                {x:719.228203966727 ,y:668.588632203442, show: true },
                {x:704.929751884353 ,y:656.669314348114, show: true },
                {x:692.192915481616 ,y:644.199467675098, show: true },
                {x:681.049117646004 ,y:631.258727504093, show: true },
                {x:671.444871139518 ,y:617.936819215676, show: true },
                {x:663.385425767194 ,y:604.320750142387, show: true },
                {x:656.856136780192 ,y:590.501222956001, show: true },
                {x:651.834661823313 ,y:576.560131794313, show: true },
                {x:648.292397875438 ,y:562.572219416889, show: true },
                {x:646.190273311953 ,y:548.607982230644, show: true },
                {x:645.486736382014 ,y:534.73425907622, show: true },
                {x:646.136965142337 ,y:521.012941261256, show: true },
                {x:648.086006629997 ,y:507.500296540431, show: true },
                {x:651.275470185987 ,y:494.250483211223, show: true },
                {x:655.65114616324, y:481.315188284877, show: true },
                {x:661.146934042446 ,y:468.734769446268, show: true },
                {x:667.700764750774 ,y:456.553181790197, show: true },
                {x:675.246374820669 ,y:444.803823534162, show: true },
                {x:683.717704368134 ,y:433.511665921023, show: true },
                {x:693.047453156578 ,y:422.718759341032, show: true },
                {x:703.173446385806 ,y:412.429178918236, show: true },
                {x:714.029487645141 ,y:402.672086076595, show: true },
                {x:725.562489872314 ,y:393.448678703622, show: true },
                {x:737.704293267182 ,y:384.783521638145, show: true },
                {x:750.407742058888 ,y:376.677435848386, show: true },
                {x:763.621906602354 ,y:369.138887344472, show: true },
                {x:777.299379212006 ,y:362.176197977576, show: true },
                {x:791.393427924681 ,y:355.801584524513, show: true },
                {x:805.857111557375 ,y:350.024342363804, show: true },
                {x:820.63654630555, y:344.843004524576, show: true },
                {x:835.693837803163, y:340.245805875112, show: true },
                {x:850.98597353526, y:336.233760666727, show: true },
                {x:866.473445435052 ,y:332.801624121341, show: true },
                {x:882.116649108354 ,y:329.947184048467, show: true },
                {x:897.875288042171 ,y:327.664877548051, show: true },
                {x:913.727748464287 ,y:325.955596547176, show: true },
                {x:929.633880613713 ,y:324.815492718239, show: true },
                {x:945.558991286862 ,y:324.240640235596, show: true },
                {x:961.475538940519 ,y:324.229303987461, show: true },
                {x:977.351034423716 ,y:324.781216799308, show: true },
                {x:993.153295383343 ,y:325.891945159017, show: true },
                {x:1008.84409298742 ,y:327.551069459283, show: true },
                {x:1024.40089521572 ,y:329.760332746223, show: true },
                {x:1039.78429869667 ,y:332.502219944515, show: true },
                {x:1054.96893135165 ,y:335.775122811845, show: true },
                {x:1069.92732265682 ,y:339.578048935969, show: true },
                {x:1084.62274299813 ,y:343.885006880871, show: true },
                {x:1099.03022739285 ,y:348.702794606597, show: true },
                {x:1113.11863728557 ,y:354.016137521647, show: true },
                {x:1126.855602702, y:359.825872801389, show: true },
                {x:1140.20680105273 ,y:366.124605819862, show: true },
                {x:1153.13793643034 ,y:372.906071504692, show: true },
                {x:1165.60273323112 ,y:380.178225360774, show: true },
                {x:1177.56146911691 ,y:387.926332087793, show: true },
                {x:1188.97563554596 ,y:396.140919456488, show: true },
                {x:1199.81556643991 ,y:404.803175117419, show: true },
                {x:1210.02827653925 ,y:413.907450675607, show: true },
                {x:1219.57835515635 ,y:423.440312401142, show: true },
                {x:1228.41882369401 ,y:433.388811106804, show: true },
                {x:1236.50497012949 ,y:443.739942815841, show: true },
                {x:1243.78662397538 ,y:454.479520544103, show: true },
                {x:1250.20464172575 ,y:465.588155727882, show: true },
                {x:1255.71916305852 ,y:477.049158484273, show: true },
                {x:1260.25400108945 ,y:488.828599516583, show: true },
                {x:1263.77528484839 ,y:500.910101263005, show: true },
                {x:1266.21355140027 ,y:513.251405364091, show: true },
                {x:1267.52067451662 ,y:525.819099563742, show: true },
                {x:1267.6466904577 ,y:538.571311914871, show: true },
                {x:1266.54281727662 ,y:551.464331496787, show: true },
                {x:1264.1674125748 ,y:564.455025273218, show: true },
                {x:1260.47871597542 ,y:577.487617148131, show: true },
                {x:1255.43999579051 ,y:590.507900396022, show: true },
                {x:1249.02459520024 ,y:603.449820148447, show: true },
                {x:1241.20920206436 ,y:616.252945794185, show: true },
                {x:1231.97342050571 ,y:628.855960567443, show: true },
                {x:1221.31091039059 ,y:641.181977480159, show: true },
                {x:1209.21790127436 ,y:653.150685223544, show: true },
                {x:1195.70777258816 ,y:664.67343819391, show: true },
                {x:1180.79431263715 ,y:675.655756191899, show: true },
                {x:1164.5226327656 ,y:686.029536397325, show: true },
                {x:1146.95013313451 ,y:695.719397735795, show: true },
                {x:1128.16157351652 ,y:704.642487699193, show: true },
                {x:1108.25382932908 ,y:712.717835425005, show: true },
                {x:1087.34335136548 ,y:719.870505234501, show: true },
                {x:1065.55485338957 ,y:726.034623959798, show: true },
                {x:1043.01195787661 ,y:731.138928423144, show: true },
                {x:1019.86993520839 ,y:735.124558012409, show: true },
                {x:996.282084126569 ,y:737.952786803746, show: true },
                {x:972.3979250029, y:739.570442480378, show: true },
                {x:948.396895464342 ,y:739.965219685879, show: true },
                {x:924.434749347338 ,y:739.116395953035, show: true },
                {x:900.678147996654 ,y:737.028718520464, show: true },
                {x:877.286843730915 ,y:733.718243905929, show: true }
            ]
        }]
    }
}