import Vue from 'vue'
// import Vue from 'vue'
import VueRouter from 'vue-router'
// import Touch from 'vue-touch'
import RouterMap from './router-map'
import Root from './components/Root'
// import Scale from './directives/scale'
import BGI from './directives/backgroundImage'
// import {env} from './data/config'
// import VueAnimatedList from 'vue-animated-list'
/**
 * Vue plugin registrations
 */
Vue.use(VueRouter);
// Vue.use(VueAnimatedList);
// Vue.use(Touch);

/**
 * Global directives
 */
// Vue.directive('scale', Scale);
Vue.directive('bgi', BGI);

/**
 * Filters
 */
Vue.filter('humanise', (text) => {
    return text.replace(/_/g, ' ').replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); })
})
// remove kebab casing
Vue.filter('bbq', (text) => {
    return text.replace(/-/g, ' ').replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); })
})

/**
 * Transitions
 */

Vue.transition('slide', {
    enterClass: 'fadeInLeft',
    leaveClass: 'fadeOutDown'
})
Vue.transition('zoom', {
    enterClass: 'fadeInDown',
    leaveClass: 'zoomOutDown'
})
Vue.transition('page', {
    enterClass: 'fadeInDown',
    leaveClass: 'fadeOutRight'
})

Vue.transition('rotate', {
    enter: function (el, done) {
        setTimeout(() => {
            el.classList.add('rotateIn')
            setTimeout(() => {
                done();
            }, 400)
        }, 750);
    },
    leave: function (el) {
        el.classList.remove('rotateIn')
    }
})
/**
 * Routes
 */

const router = new VueRouter({
    hashbang: false,
    history: true,//(env.production)
});
RouterMap(router);
window.router = router
router.mode = 'hash'
router.start(Root, '#root');

window.onpopstate = history.onpushstate = function (e) {
    // console.log(router.app, window.location.href.split('/'))
    if (router.app.core.pictureRoom.tour.hasStarted) router.app.core.pictureRoom.tour.hasStarted = false // cleanup
    router.app.$router.go({ name: 'root' })
};