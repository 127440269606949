import config from './utils/config'
import room from './framework/index'
import { render, animate } from './render'
import setupInteractions from './controllers/index'

function build(target, callback) {

  function init(listeners) {
    setupInteractions(listeners)
    callback(room)
    // render()
    // setTimeout(() => {
    //   const viewHandler = room.emit('TRANSITION')
    //   viewHandler('ROOM', () => console.log('Tour mode'))
    // }, 5000)

  }

  // TODO - configure unmount - controlled by web app
  return {
    app: room,
    mount: (listeners) => room.setup(target).then(() => init(listeners)),
    unmount: () => {
      animate.on = false
      room.unmount()
    },
    test: (action, param) => console.log(action, param),
    start: () => {
      animate.on = true
      render()
    }
  }
}



// trigger room events from app
// const myAction = room.emit('COUNT')
// myAction('NEXT', (data) => console.log('complete', data))


if (config.sandbox) {
  // mount api
  const app = build(false, () => {
    // console.log('built sandbox')
    render(true)
  })
    .mount([
      { name: 'WATCH', callback: (res) => console.log(res) },
      { name: 'VIEW_PICTURE', callback: (res) => console.log(res) }
    ])
}

export default build  