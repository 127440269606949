// import * as THREE from 'three'
import config from './utils/config';
import room from './framework/index'
import { wallDirectionVisibility } from './utils/compass'
import {FadeButtonsDown, FadeButtonsUp} from './utils/tweens'

function PointBoxDistance(position, boundingBox) {
  var minX = boundingBox.center.x - boundingBox.size.x * 0.5;
  var minY = boundingBox.center.y - boundingBox.size.y * 0.5;
  var minZ = boundingBox.center.z - boundingBox.size.z * 0.5;

  var maxX = boundingBox.center.x + boundingBox.size.x * 0.5;
  var maxY = boundingBox.center.y + boundingBox.size.y * 0.5;
  var maxZ = boundingBox.center.z + boundingBox.size.z * 0.5;

  var dx = Math.max(minX - position.x, 0, position.x - maxX);
  var dy = Math.max(minY - position.y, 0, position.y - maxY);
  var dz = Math.max(minZ - position.z, 0, position.z - maxZ);
  return Math.sqrt(dx * dx + dy * dy + dz * dz);
}

function SetTransparency(parent, transparency) {
  parent.traverse(function (child) {
    if (child.type == 0) {
      child.material.uniforms.transparency.value = transparency;
    }
  });
}

function SetRenderOrders(objects, baseNumber) {
  var childOrder = baseNumber;
  for (var i = 0; i < objects.length; i++) {
    objects[i].traverse(function (child) {
      if (child instanceof THREE.Mesh) {
        child.renderOrder = childOrder++;
      }
    });
  }
  return childOrder;
}

var PrevLocked = false;
var prevInside=false;

export let animate = {
  on: true
}
export function render() {
  room.controls.orbit.update()
  
  if (room.state.view === 'ROOM') {
    room.camera.updateProjectionMatrix();
    room.camera.lookAt(room.globals.dolly.position)
    if (config.hideBackWalls) wallDirectionVisibility(room)
  }

  var changed = room.state.locked != PrevLocked
  if (changed && room.state.camera == "INSIDE") {
    if (room.state.locked) {
      var azimuthAngle = room.controls.orbit.getAzimuthalAngle()
      room.controls.orbit.minAzimuthAngle = azimuthAngle - 0.3;
      room.controls.orbit.maxAzimuthAngle = azimuthAngle + 0.3;
      var polarAngle = room.controls.orbit.getPolarAngle()
      room.controls.orbit.minPolarAngle = polarAngle - 0.3;
      room.controls.orbit.maxPolarAngle = polarAngle + 0.3;
      room.controls.orbit.minDistance = config.tourMinZoom;
      room.controls.orbit.maxDistance = config.tourMaxZoom;
    }
    else {
      room.controls.orbit.minAzimuthAngle = Infinity;
      room.controls.orbit.maxAzimuthAngle = Infinity;
    }
  }
  TWEEN.update()
  
  
  if(room.state.view != "MAP") //No need to sort on map view
  {
    var dummy = new THREE.Vector3();
    var camPosition = room.camera.getWorldPosition(dummy);
    var distancePictureRoom = PointBoxDistance(camPosition, config.pictureRoomBoundingBox);
    var distanceMonksChamber = PointBoxDistance(camPosition, config.monksChamberBoundingBox);
    var inside = distancePictureRoom == 0;
    if(inside != prevInside && room.state.camera == "OUTSIDE" && room.state.controls != "TWEEN")
    {
      if(inside)
      {
        FadeButtonsUp(room);
      }
      else
      {
        FadeButtonsDown(room);
      }
    }

    for (var i = 0; i < room.scene.roomWalls.length; i++) {
      var camDistance = camPosition.distanceTo(room.scene.roomWalls[i].centerOfMass);
      
      //When it's open, render with higher priority
      if(room.scene.roomWalls[i].open != undefined)
      {
        if(room.scene.roomWalls[i].open)
        {
          if(!inside)
          {
            camDistance += 1;
          }
          else
          {
            camDistance -= 2;
          }
        }
      }      
      room.scene.roomWalls[i].camDistance = camDistance;
    }

    for (var i = 0; i < room.scene.monksWalls.length; i++) {
      var camDistance = camPosition.distanceTo(room.scene.monksWalls[i].centerOfMass);
      room.scene.monksWalls[i].camDistance = camDistance;
    }
    room.scene.roomWalls.sort((a, b) => (a.camDistance < b.camDistance) ? 1 : ((b.camDistance < a.camDistance) ? -1 : 0))
    room.scene.monksWalls.sort((a, b) => (a.camDistance < b.camDistance) ? 1 : ((b.camDistance < a.camDistance) ? -1 : 0))
    
    var baseNumber = 0;
    if (distancePictureRoom < distanceMonksChamber) {
      baseNumber = SetRenderOrders(room.scene.monksWalls, baseNumber);
      baseNumber = SetRenderOrders(room.scene.roomWalls, baseNumber);
    }
    else {
      baseNumber = SetRenderOrders(room.scene.roomWalls, baseNumber);
      baseNumber = SetRenderOrders(room.scene.monksWalls, baseNumber);
    }

    prevInside = inside;
  }
  
  if(room.state.view == "MAP")
  {
    if(room.controls.orbit.target.z > 14)
    {  
      room.controls.orbit.target.setZ(14)
      room.camera.position.setZ(14)
    }
    if(room.controls.orbit.target.z < -33)
    {  
      room.controls.orbit.target.setZ(-33)
      room.camera.position.setZ(-33)
    }

    if(room.controls.orbit.target.y > 10)
    {  
      room.controls.orbit.target.setY(10)
      room.camera.position.setY(10)
    }
    if(room.controls.orbit.target.y < -3)
    {  
      room.controls.orbit.target.setY(-3)
      room.camera.position.setY(-3)
    }
  }

  room.renderer.render(room.scene, room.camera)
	// room.composer.render();
  if (animate.on) requestAnimationFrame(render)
  PrevLocked = room.state.locked;
}


