// import * as THREE from 'three'
import config from '../utils/config'
import { getPicture } from '../collections/pictureData'
import { swing, fade } from '../utils/tweens'
export default function ({ walls, panels, pivots, pics, backgroundPlanes, buttonsArray, buttonsReferences }, room) {
  var count = 1000;
  return (child) => {
    const descriptors = child.name.split('_')
    if (descriptors.some((part) => part === 'PIVOT')) {
      if (descriptors.some((part) => part === 'WALL')) {
        // add walls to pivots for unfolding
        pivots.push(child)
        if (descriptors.some((part) => part === 'NORTH' || part === 'EAST' || part === 'SOUTH' || part === 'WEST')) {
          const [pivot, face, wall] = descriptors
          walls[face] = child
        }
      }
    }
    var minZ = 0;
    var maxZ = 5;



    // enable ong transparency
    if (child.isMesh) {
      if (descriptors.some((part) => part === 'Slice')) {
        var tex = child.material.map;
        var params = {
          "colorTexture": { value: tex },
          "minZ": { value: minZ },
          "maxZ": { value: maxZ },
          "transparency": { value: 0.0 },
          "distanceBasedFactor": { value: 1.0 },
        };
        const material = new THREE.ShaderMaterial({
          uniforms: params,
          vertexShader: [
            'varying vec2 vUv;',
            'varying vec4 vViewPosition;',

            'void main()',
            '{',
            'vUv = uv;',
            'vViewPosition = modelViewMatrix * vec4( position, 1.0 );',
            'gl_Position = projectionMatrix * vViewPosition;',
            '}'
          ].join('\n'),
          fragmentShader: [
            'uniform sampler2D colorTexture;',
            'uniform float transparency;',
            'uniform float distanceBasedFactor;',
            'uniform float minZ;',
            'uniform float maxZ;',
            'varying vec2 vUv;',
            'varying vec4 vViewPosition;',
            'void main( void ) {',
            'vec4 color = texture2D( colorTexture, vUv );',
            'gl_FragColor = color;',
            'if(gl_FragColor.a < 0.5) discard;',
            'float depth = -vViewPosition.z;',
            'float minZOverMaxZ = minZ / maxZ;',
            'float t = clamp(depth / maxZ - minZOverMaxZ, 0.0f, 1.0f);',
            'depth = depth < minZ ? minZOverMaxZ :  minZOverMaxZ + mix(0.0f, 1.0f, t);',
            'gl_FragColor.a = depth * distanceBasedFactor + transparency;',
            // 'gl_FragColor = vec4(vUv, 0, 1);',
            '}'
          ].join('\n'),
        });

        child.material = material;

        child.material.transparent = true;
        child.material.side = THREE.DoubleSide;
        child.material.opacity = 0.7;
        child.material.depthWrite = false;
        child.material.alphaTest = 0.5;
        child.type = 0;
      }
      else {
        child.type = 1;
      }
    }


    // target panels to animate
    if (descriptors.some((part) => part === 'LEFT') && descriptors.some((part) => part === 'PANEL')) {
      // if (descriptors.some((part) => part === '2') && descriptors.some((part) => part === 'PANEL')) { //Sets the seconc south panel invisible
      //   // child.material.opacity = 0
      //   child.visible = false
      // }
      child.userData.panel = {
        toggle: swing(child, 'left'),
        hide: () => { fade(child, -0.5) },
        show: () => { fade(child, 0) }
      }
      child.open = false;
      panels.push(child)
    }
    if (descriptors.some((part) => part === 'RIGHT') && descriptors.some((part) => part === 'PANEL')) {
      // if (descriptors.some((part) => part === '2')) child.visible = false //child.material.opacity = 0
      child.userData.toggle = swing(child, 'right')
      child.userData.panel = {
        toggle: swing(child, 'right'),
        hide: () => { fade(child, -0.5) },
        show: () => { fade(child, 0) }
      }
      child.open = false;
      panels.push(child)
    }
    // target picture references
    if (descriptors.some((part) => part === 'TARGET')) {
      child.material = new THREE.MeshBasicMaterial({
        transparent: true,
        side: THREE.DoubleSide
      })

      child.renderOrder = count++;

      if (child.hasOwnProperty('material') && !config.showPicturePlanes) child.visible = false;

      const [target, id, face] = child.name.split('_')
      const { wall, tour } = getPicture(id)
      child.userData.id = id
      child.userData.wall = wall
      if (tour) {
        child.userData.tour = {
          ...tour,
          trigger: 'VIEW_PICTURE', // events listener
          method: null
          // method: callback => callback()
        }
      }
      child.userData.actions = {
        trigger: 'WATCH',
        method: (onComplete) => {
          setTimeout(() => onComplete({ name: 'picture', src: '/sda/sdfd.png' }), 3000)
        }
      }
      pics.push(child)
    }
    if (descriptors.some((part) => part === 'BUTTON')) {
      var tex = child.material.map;
      var params = {
        "colorTexture": { value: tex },
        "transparency": { value: 1.0 },
        "globalTransparency": { value: 0.0 },
      };
      const material = new THREE.ShaderMaterial({
        uniforms: params,
        vertexShader: [
          'varying vec2 vUv;',
          'varying vec4 vViewPosition;',
          'varying vec3 pos;',

          'void main()',
          '{',
          'vUv = uv;',
          'pos = position.xzy;',
          'pos.y *= -1.0f;',
          'vViewPosition = modelViewMatrix * vec4( position, 1.0 );',
          'gl_Position = projectionMatrix * (modelViewMatrix * vec4(0.0, 0.0, 0.0, 1.0) + vec4(pos.x, pos.y, 0, 0.0));',

          '}'
        ].join('\n'),
        fragmentShader: [
          'uniform sampler2D colorTexture;',
          'uniform float transparency;',
          'uniform float globalTransparency;',
          'varying vec2 vUv;',
          'varying vec4 vViewPosition;',
          'varying vec3 pos;',
          'void main( void ) {',
          'vec4 color = texture2D( colorTexture, vUv );',
          'gl_FragColor = color;',
          'gl_FragColor.a *= globalTransparency * transparency;',
          'if(gl_FragColor.a < 0.1) discard;',
          '}'
        ].join('\n'),
      });
      material.transparent = true;
      material.side = THREE.DoubleSide;
      child.material = material;
      child.material.uniforms.transparency.value = 0;

      buttonsArray.push(child);
      switch (child.name) {
        case "WL1F_BUTTON":
          child.material.uniforms.transparency.value = 1;
          buttonsReferences.wl1f = child;
          break;
        case "WR1F_BUTTON":
          child.material.uniforms.transparency.value = 1;
          buttonsReferences.wr1f = child;
          break;
        case "WL1B_BUTTON":
          buttonsReferences.wl1b = child;
          break;
        case "WR1B_BUTTON":
          buttonsReferences.wr1b = child;
          break;
        case "SL2F_BUTTON":
          child.material.uniforms.transparency.value = 1;
          buttonsReferences.sl2f = child;
          break;
        case "SR2B_BUTTON":
          buttonsReferences.sr2b = child;
          break;
        case "SL2B_BUTTON":
          buttonsReferences.sl2b = child;
          break;
        case "SL1F_BUTTON":
          buttonsReferences.sl1f = child;
          break;
        case "SR1B_BUTTON":
          buttonsReferences.sr1b = child;
          break;
        case "SL1B_BUTTON":
          buttonsReferences.sl1b = child;
          break;
        case "NL1F_BUTTON":
          child.material.uniforms.transparency.value = 1;
          buttonsReferences.nl1f = child;
          break;
        case "NL1B_BUTTON":
          buttonsReferences.nl1b = child;
          break;
        case "NR1B_BUTTON":
          buttonsReferences.nr1b = child;
          break;
        default:
          break;
      }
    }
  }
}
