import Template from '../templates/state-inspector.html'
import { library } from '../pictureRoom/js/collections/pictureRoomData'
// import ViewPicture from './ViewPicture' // need to seperate into 2 files
import ViewTour from './ViewTour' // need to seperate into 2 files


export default {
  name: 'PictureRoomInspector',
  template: Template,
  components: [Poi, ViewTour],
  props: ['poi', 'core'],
  data() {
    return {
      currentComponent: 'view-tour',
      dropdown: false,
      debounceFold: false,
    }
  },
  computed: {
    opaque() {
      return (this.currentComponent == 'view-tour')
    },
    hideNav() {
      return this.core.layout.modal
    },
    darkenView() {
      return (this.core.layout.inspecting) ? 'darken' : ''
    },
    room() {
      return library.content['ROOM']
    },
    summary() {
      const id = this.core.pictureRoom.tour.id
      if (id) return library.tours.find(tour => tour.id === id)
      const { view } = this.core.pictureRoom
      const target = view === 'ROOM' ? 'ROOM'
        : view === 'TOUR' ? 'ROOM'
          : 'MAP'
      return library.content[target]
    },
    CTAlabel() {
      const { view } = this.core.pictureRoom
      return view === 'ROOM' ? 'Explore'
        : view === 'TOUR' ? 'Exit'
          : 'Return to room'
    },
    page() {
      return {
        dropdrown: { ...this.room },
        label: this.CTAlabel,
        summary: { ...this.summary },
        template: null,
        startTour: (id) => {
          if (this.core.pictureRoom.tour.hasStarted) {
            // if already started - exit
            this.$dispatch('exit-tour')
            setTimeout(() => {
              this.core.pictureRoom.tour.index = 0
              this.core.pictureRoom.tour.id = id
              this.core.pictureRoom.tour.hasStarted = false // hasnt navigated yet
              this.core.pictureRoom.view = 'TOUR'
              this.$parent.sandbox.app.onAction({ trigger: 'TOUR', method: 'START', param: id, type: 'CUSTOM' })
              setTimeout(() => {
                this.core.layout.full = !this.core.layout.full;
                this.$parent.sandbox.app.onAction({ type: 'CTA', trigger: "VIEWPORT", method: "TOGGLE" })
              }, 2000)
            }, 2000)
          }
          else {
            this.core.pictureRoom.tour.index = 0
            this.core.pictureRoom.tour.id = id
            this.core.pictureRoom.tour.hasStarted = false // hasnt navigated yet
            this.core.pictureRoom.view = 'TOUR'
            this.$parent.sandbox.app.onAction({ trigger: 'TOUR', method: 'START', param: id, type: 'CUSTOM' })
          }
        },
        cta: () => {
          const { view } = this.core.pictureRoom
          if (view === 'TOUR') this.$dispatch('exit-tour')
          if (view === 'ROOM') this.$dispatch('goto-map')
          if (view === 'MAP') this.$dispatch('goto-room')
        },
        continueTour: () => {
          if (!this.core.pictureRoom.tour.hasStarted) {
            setTimeout(() => { // delay button switch
              this.core.pictureRoom.tour.hasStarted = true
            }, 4000)
          }
          this.$parent.sandbox.app.onAction({ trigger: 'TOUR', method: 'NEXT', type: 'CTA' })
        }
      }
    }
  },
  watch: {
    'hideNav': function (next, prev) {
      this.$broadcast('model-nav', next);
    }
  },
  methods: {
    toggleFullWidth() {
      this.$parent.toggleFullWidth()
    },
    close() {
      this.currentComponent = false; // might not be used ever
    },
    toggleDropdown() {
      this.dropdown = !this.dropdown
      this.core.layout.dropdown = this.dropdown
      if (!this.dropdown) {
        this.$parent.sandbox.app.controls.pictureId = null
        this.$parent.poi = null
      }
    },
  },
  ready() {

    this.core.layout.overlay = false;
    this.core.state.set('pictureroom')

    this.$on('abt', () => {
      this.currentComponent = 'view-tour'
      // this.core.state.set('inspect');
    })
    this.$on('leave-tour', () => {
      // exit softly
      console.log('leave tour')
      this.core.layout.inspecting = false
      this.core.pictureRoom.view = 'ROOM'
      this.$parent.sandbox.app.onAction({ trigger: 'TRANSITION', method: 'ROOM', type: 'CTA' })
    })
    this.$on('exit-tour', () => {
      // exit
      this.core.pictureRoom.tour.index = 0
      this.core.pictureRoom.tour.isLast = false
      this.core.pictureRoom.tour.id = null
      this.core.layout.inspecting = false
      this.core.pictureRoom.view = 'ROOM'
      this.$parent.poi = null
      this.$parent.sandbox.app.controls.pictureId = null
      this.$parent.sandbox.app.onAction({ trigger: 'TRANSITION', method: 'ROOM', type: 'CTA' })
      if (!this.core.layout.full) this.$parent.toggleFullWidth()
    })
    this.$on('fold-debounce-complete', (t) => {
      const helpType = this.core.pictureRoom.view === 'ROOM' ? 'pictureroom' : 'picturemap'
      this.core.state.current = helpType
      this.$parent.$broadcast("help", helpType);
      this.$parent.$emit("help", helpType);
      this.$parent.$dispatch("help", helpType);
      this.debounceFold = false
    })
    this.$on('goto-map', () => {
      if (!this.debounceFold) {
        this.debounceFold = true
        if (!this.core.layout.full) this.$parent.toggleFullWidth()
        this.$parent.sandbox.app.onAction({ trigger: 'TRANSITION', method: 'MAP_TOGGLE', type: 'CTA' })
        this.core.pictureRoom.view = 'MAP'
      }
    })
    this.$on('goto-room', () => {
      if (!this.debounceFold) {
        this.debounceFold = true
        this.$parent.sandbox.app.onAction({ trigger: 'TRANSITION', method: 'MAP_TOGGLE', type: 'CTA' })
        this.core.pictureRoom.view = 'ROOM'
      }
    })
  }
}