// import room from '../framework/index'
const d180 = Math.PI
const d90 = Math.PI / 2
const d45 = Math.PI / 4
const d22 = Math.PI / 8
const d12 = Math.PI / 16

const range = d12

const q1Start = d90 + d45
const q1Range = d90 - d45
const q2Range = -d90 + d45
const q3Range = -d180 + d45
const q4Range = d180 - d45

function _gimbalLock(target) {
  const map = {
    EAST: {
      rotation: ['x', 'y'],
      front: 'z',
      behind: 'x'
    },
    SOUTH: {
      rotation: ['y', 'z'],
      front: 'x',
      behind: 'z',
    },
    WEST: {
      rotation: ['x', 'y'],
      front: 'z',
      behind: 'x',
    },
    NORTH: {
      rotation: ['y', 'z'],
      front: 'x',
      behind: 'z'
    },
    UP: {
      rotation: ['y', 'z'],
      front: 'x',
      behind: 'z'
    },
  }
  if (!target) return map
  return map[target]
}
export function gimbalRotation(face) {
  if (face === 'EAST') return ['y', 'z']//['x', 'y']
  if (face === 'SOUTH') return ['x', 'y']//['y', 'z']
  if (face === 'WEST') return ['y', 'z']//['x', 'y']
  if (face === 'NORTH') return ['x', 'y']//['y', 'z']
  if (face === 'DOWN') return ['x', 'y']//['y', 'z']
  if (face === 'UP') return ['x', 'y']//['y', 'z']
}

export function gimbalLock(face) {
  if (face === 'EAST') return 'x'
  if (face === 'SOUTH') return 'z'
  if (face === 'WEST') return 'x'
  if (face === 'NORTH') return 'z'
  if (face === 'UP') return 'y'
  if (face === 'DOWN') return 'y'
}

// using for test orientations (green blocks - frogs)
export function getWallPosition() {
  return {
    EAST: [0, 0, 0], // E
    SOUTH: [0, 0, -1.75], // S
    WEST_01: [1.75, 0, -1], // W1
    WEST_02: [1.75, 0, 1], // W2
    NORTH: [0, 0, 1.75], // N
  }
}

// incorrectly mapped - should be e,s,w,n
const rangeMap = {
  n: d90,
  e: 0,
  s: -d90,
  w: -d180
}


const lessThanRange1 = rangeMap.n - range
const moreThanRange1 = rangeMap.n + range

const lessThanRange3 = rangeMap.s - range
const moreThanRange3 = rangeMap.s + range

export const ranger = rangeMap

const N_E = {
  q3: q3Range,
  q4: q4Range - range,
  n1: rangeMap.w - range,
  n2: q3Range + range,
  e: d180 - range
}

function getDirectionMap(a) {
  if (a < q1Start && a > q1Range) {
    if (a < lessThanRange1) {
      return { NORTH: false, EAST: true, WEST: false, SOUTH: true }
    }
    if (a > moreThanRange1) {
      return { NORTH: true, EAST: true, WEST: false, SOUTH: false }
    }
    return { NORTH: true, EAST: true, WEST: false, SOUTH: true }
  }
  if (a < q1Range && a > q2Range) {
    if (a > range) {
      return { NORTH: false, EAST: true, WEST: false, SOUTH: true }
    }
    if (a < -range) {
      return { NORTH: false, EAST: false, WEST: true, SOUTH: true }
    }
    return { NORTH: false, EAST: true, WEST: true, SOUTH: true }
  }
  if (a < q2Range && a > q3Range) {
    if (a > moreThanRange3) {
      return { NORTH: false, EAST: false, WEST: true, SOUTH: true }
    }
    if (a < lessThanRange3) {
      return { NORTH: true, EAST: false, WEST: true, SOUTH: false }
    }
    return { NORTH: true, EAST: false, WEST: true, SOUTH: true }
  }
  if (a < N_E.q3 || a > N_E.q4) {
    if (a > N_E.n1 && a < N_E.n2) {
      return { NORTH: true, EAST: true, WEST: true, SOUTH: false }
    }
    // if (a > rangeMap.w - range && a < q3Range + range) {
    //   return { NORTH: true, EAST: true, WEST: true, SOUTH: false }
    // }
    if (a > N_E.e) {
      return { NORTH: true, EAST: true, WEST: true, SOUTH: false }
    }
    return { NORTH: true, EAST: true, WEST: false, SOUTH: false }
  }

}

// original compass map
// const q1 = az >= Math.PI / 2 - diagonal && az < Math.PI / 2 + diagonal
// const q2 = az < Math.PI / 2 - diagonal && az > -Math.PI / 2 + diagonal
// const q3 = az < -Math.PI / 2 + diagonal && az > -Math.PI + diagonal
// const q4 = az < -Math.PI + diagonal || az > Math.PI - diagonal

export function wallDirectionVisibility(room) {
  const directionMap = getDirectionMap(room.controls.orbit.getAzimuthalAngle())
  for (const [key, value] of Object.entries(directionMap)) {
    room.globals.walls[key].visible = value
  }
  return directionMap
}