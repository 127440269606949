// import * as T from 'three'
import EventControls from './eventControls'
import { loadAssets, loadTests } from '../factory/modelLoader'
// import { EffectComposer } from 'https://threejs.org/examples/jsm/postprocessing/EffectComposer.js';

import config from '../utils/config'
const STATES = {
  VIEW: ['ROOM', 'MAP', 'TOUR'],
  CAMERA: ['INSIDE', 'OUTSIDE'],
  CONTROLS: ['LOCK', 'PAN', 'ORBIT']
}

function GetTextObject(font, name) {
  const geometry = new THREE.TextGeometry(name, {
    font: font,
    size: 80,
    height: 5,
    curveSegments: 12,
    bevelEnabled: true,
    bevelThickness: 1,
    bevelSize: 5,
    bevelOffset: 0,
    bevelSegments: 5
  });
  const material = new THREE.MeshBasicMaterial({ color: 'white', side: THREE.FrontSide });

  var textMesh = new THREE.Mesh(geometry, material)
  textMesh.scale.set(0.0012, 0.0012, 0.0012);
  textMesh.rotation.set(0, 1.5708, 0);
  textMesh.visible = false;
  return textMesh;
}

function GetLine(p1, p2) {
  const material = new THREE.LineBasicMaterial({
    color: 0xffffff,
    linewidth: 1
  });
  const points = [];
  points.push(p1);
  points.push(p2);
  const geometry = new THREE.BufferGeometry().setFromPoints(points);
  const line = new THREE.Line(geometry, material);
  line.visible = false;
  return line;
}

class Room {
  constructor() {
    this.globals = { dolly: new THREE.Object3D(), tests: [], walls: {}, panels: [], pivots: [], pics: [], backgroundPlanes: {}, buttonsArray: [], buttonsReferences: {} }
    this.element = null
    this.events = {}
    this.currentFocus = null
    this.currentView = STATES.VIEW[0]
    this.currentCamera = STATES.CAMERA[1]
    this.currentControls = STATES.CONTROLS[2]
    this.currentLocked = false;

    const [fov, aspect, min, max] = config.fov
    this.camera = new THREE.PerspectiveCamera(fov, aspect, min, max)
    this.scene = new THREE.Scene();
    this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    this.renderer.domElement.id = "pictureroom"
    this.renderer.outputEncoding = THREE.sRGBEncoding
    this.renderer.gammaOutput = true;
    this.renderer.gammaFactor = 2.2;
    this.renderer.sortObjects = true;

    // this.composer = new EffectComposer( this.renderer );
    // const renderPass = new RenderPass( this.scene, this.camera );
    // composer.addPass( renderPass );

    this.controls = new EventControls(this)

    // const context = this.renderer.getContext()
    // context.disable(context.DEPTH_TEST) // still used

  }

  setup(target) {
    const element = document.querySelector(target)
    return new Promise((resolve) => {
      if (element) {
        // if (target && !this.element) {
        // this.element = element
        element.appendChild(this.renderer.domElement)
      }
      else {
        this.renderer.domElement.id = "canvas"
        document.body.appendChild(this.renderer.domElement)
      }
      const [x, y, z] = config.startPoint
      if (config.showDolly) {
        const boxGeom = new THREE.BoxGeometry(0.25, 0.25, -0.25);
        const material = new THREE.MeshBasicMaterial({ color: 'red', side: THREE.FrontSide });
        this.globals.dolly = new THREE.Mesh(boxGeom, material)
        this.scene.add(this.globals.dolly);
      }

      //Text
      var scene = this.scene;
      this.globals.texts = [];
      var globals = this.globals;

      const path = config.sandbox ? './fonts' : '/assets/fonts'
      const fontLoader = new THREE.FontLoader();
      fontLoader.load(`${path}/Karla/Karla_Regular.json`, function (font) {
        var northTextMesh = GetTextObject(font, 'North Wall');
        northTextMesh.position.set(-2.16, -1.7, 14.22 + 0.6);
        scene.add(northTextMesh);
        globals.texts.push(northTextMesh);

        var eastTextMesh = GetTextObject(font, 'East Wall');
        eastTextMesh.position.set(-2.16, -1.7, 1.31 + 0.6);
        scene.add(eastTextMesh);
        globals.texts.push(eastTextMesh);

        var southTextMesh = GetTextObject(font, 'South Wall');
        southTextMesh.position.set(-2.16, -1.7, -2.9 + 0.6);
        scene.add(southTextMesh);
        globals.texts.push(southTextMesh);

        var southTextMeshv2 = GetTextObject(font, 'South Wall');
        southTextMeshv2.position.set(-2.16, -1.7, -21.95 + 0.6);
        scene.add(southTextMeshv2);
        globals.texts.push(southTextMeshv2);

        var westTextMesh = GetTextObject(font, 'West Wall');
        westTextMesh.position.set(-2.16, -1.7, -28.277 + 0.6);
        scene.add(westTextMesh);
        globals.texts.push(westTextMesh);

        var pcrTextMesh = GetTextObject(font, 'Picture Room Recess (behind south planes)');
        pcrTextMesh.position.set(-2.16, -1.7, -11.47 + 0.6);
        scene.add(pcrTextMesh);
        globals.texts.push(pcrTextMesh);
      });

      //Lines
      this.globals.lines = [];
      const line1 = GetLine(new THREE.Vector3(-2.16, 2.08, 14.99), new THREE.Vector3(-2.16, -1.75, 14.99));
      scene.add(line1);
      this.globals.lines.push(line1);
      const line2 = GetLine(new THREE.Vector3(-2.16, 2.08, 2.04), new THREE.Vector3(-2.16, -1.75, 2.04));
      scene.add(line2);
      this.globals.lines.push(line2);
      const line3 = GetLine(new THREE.Vector3(-2.16, 2.08, -2.14), new THREE.Vector3(-2.16, -1.75, -2.14));
      scene.add(line3);
      this.globals.lines.push(line3);
      const line4 = GetLine(new THREE.Vector3(-2.16, 2.08, -21.19), new THREE.Vector3(-2.16, -1.75, -21.19));
      scene.add(line4);
      this.globals.lines.push(line4);
      const line5 = GetLine(new THREE.Vector3(-2.16, 2.08, -27.537), new THREE.Vector3(-2.16, -1.75, -27.537));
      scene.add(line5);
      this.globals.lines.push(line5);
      const line6 = GetLine(new THREE.Vector3(-2.16, 2.08, -10.7), new THREE.Vector3(-2.16, -1.75, -10.7));
      scene.add(line6);
      this.globals.lines.push(line6);


      if (config.orientationTest) loadTests(this.globals, this.scene)
      // this.scene.add(this.globals.dolly)
      this.camera.position.set(x, y, z)
      this.camera.lookAt(this.globals.dolly)

      loadAssets(this).then(() => {
        this.scene.add(new THREE.AmbientLight(null))
        resolve()
      })

    })

  }

  // use onAction to interact with the eventsMap
  useEvent(name, scope) {
    this.events[name] = scope
  }

  get state() {
    return {
      view: this.currentView,
      focus: this.currentFocus,
      camera: this.currentCamera,
      controls: this.currentControls,
      locked: this.currentLocked
    }
  }

  emit(trigger) {
    return (method, onComplete) => {
      this.onAction({ type: 'CTA', trigger, method })
        .then((data) => onComplete && onComplete(data))
    }
  }
  // incoming triggers
  onAction(action) {
    return new Promise((resolve) => {
      this.dispatcher(action)
        .then((response) => {
          if (action.type == "TBC" || (action.method == "ROOM")) this.currentLocked = false;
          else this.currentLocked = true;
          this.controls.updateControls(action)
          resolve(response)
        })
    })
  }

  dispatcher({ type, trigger, method, target, param, callback }) {
    return new Promise((resolve) => {
      // direct action
      if (type === 'CTA') this.events[trigger][method]().then(resolve)
      // action with param
      if (type === 'CUSTOM') this.events[trigger][method](param).then(resolve)
      // child action with subscription callback
      if (type === 'OBSERVER') {
        if (target.userData.actions.trigger) {
          this.events[target.userData.actions.trigger]
            // TODO - update API to: .subscribe({ callback, param }, resolve)
            .subscribe(target.userData.actions.method, resolve)
        }
      }
      if (type === 'TBC') {
        // TODO - update API to: .subscribe({ callback, param }, resolve)
        this.events[trigger].subscribe(callback, resolve, param)
      }
    })
  }

  deriveState(view) {
    if (!view) return false
    if (view === 'ROOM') return { view: 'ROOM', camera: 'OUTSIDE', controls: 'ORBIT' }
    if (view === 'MAP') return { view: 'MAP', camera: 'OUTSIDE', controls: 'PAN' }
    if (view === 'TOUR') return { view: 'ROOM', camera: 'INSIDE', controls: 'ORBIT' }
  }

  applyState({ view, camera, controls, locked }) {
    this.currentView = view
    this.currentCamera = camera
    this.currentControls = controls
    this.currentLocked = locked;
    return this.state
  }

  lockControls() {
    this.applyState({ ...this.state, controls: 'TWEEN', locked: false })
    this.controls.updateControls()
  }

  unmount() {
    // handle removing listeners & dom
    for (var i = this.scene.children.length - 1; i >= 0; i--) {
      this.scene.remove(this.scene.children[i]);
    }
    // console.log('unmount')
    // document.removeEventListener('mousemove', this.controls.HandleMouseMove.bind(this.controls))
  }

}


const compassRotations = {
  n: { x: 0, y: 0, z: 0 },
  e: { x: Math.PI / 2, y: -(Math.PI / 2), z: Math.PI / 2 },
  s: { x: Math.PI, y: 0, z: Math.PI },
  w: { x: 0, y: Math.PI / 2, z: 0 }
}

export default new Room()
