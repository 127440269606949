import pictureCollection from "./pictureCollection"
const none = { id: 'ID NOT FOUND - missing P?', wall: null }

export function getPicture(target) {
  const data = pictureData.find(({ id }) => id === target)
  return data || { ...none }
}

const positionData = [
  { id: 'P31', wall: 'NORTH', tour: { meta: null, panels: 'CLOSE', gimbal: 'NORTH' } },
  { id: 'P32', wall: 'NORTH', tour: { meta: null, panels: 'NORTH_OPEN', gimbal: 'NORTH' } },
  { id: 'P33', wall: 'NORTH', tour: { meta: null, panels: 'CLOSE', gimbal: 'NORTH' } },
  { id: 'P34', wall: 'NORTH', tour: { meta: null, panels: 'CLOSE', gimbal: 'NORTH' } },
  { id: 'P35', wall: 'NORTH', tour: { meta: null, panels: 'CLOSE', gimbal: 'NORTH' } },
  { id: 'P36', wall: 'NORTH', tour: { meta: null, panels: 'CLOSE', gimbal: 'NORTH' } },
  { id: 'P37', wall: 'NORTH', tour: { meta: null, panels: 'CLOSE', gimbal: 'NORTH' } },
  { id: 'P38', wall: 'NORTH', tour: { meta: null, panels: 'NORTH_OPEN', gimbal: 'NORTH' } },
  { id: 'P39', wall: 'NORTH', tour: { meta: null, panels: 'CLOSE', gimbal: 'NORTH' } },
  { id: 'P40', wall: 'NORTH', tour: { meta: null, panels: 'NORTH_OPEN', gimbal: 'NORTH' } },
  { id: 'P41', wall: 'NORTH', tour: { meta: null, panels: 'NORTH_OPEN', gimbal: 'NORTH' } },
  { id: 'P42', wall: 'NORTH', tour: { meta: null, panels: 'NORTH_OPEN', gimbal: 'NORTH' } },
  { id: 'P43', wall: 'NORTH', tour: { meta: null, panels: 'NORTH_OPEN', gimbal: 'NORTH' } },
  { id: 'P44', wall: 'NORTH', tour: { meta: null, panels: 'NORTH_OPEN', gimbal: 'WEST' } },
  { id: 'P45', wall: 'NORTH', tour: { meta: null, panels: 'NORTH_OPEN', gimbal: 'WEST' } },
  { id: 'P46', wall: 'NORTH', tour: { meta: null, panels: 'NORTH_OPEN', gimbal: 'EAST' } },
  { id: 'P47', wall: 'NORTH', tour: { meta: null, panels: 'NORTH_OPEN', gimbal: 'EAST' } },
  { id: 'P48', wall: 'NORTH', tour: { meta: null, panels: 'CLOSE', gimbal: 'NORTH' } },
  { id: 'P49', wall: 'NORTH', tour: { meta: null, panels: 'CLOSE', gimbal: 'NORTH' } },
  { id: 'P50', wall: 'NORTH', tour: { meta: null, panels: 'NORTH_OPEN', gimbal: 'EAST' } },
  { id: 'P51', wall: 'NORTH', tour: { meta: null, panels: 'CLOSE', gimbal: 'NORTH' } },
  { id: 'P52', wall: 'SOUTH', tour: { meta: null, panels: 'CLOSE', gimbal: 'SOUTH' } },
  { id: 'P53', wall: 'NORTH', tour: { meta: null, panels: 'CLOSE', gimbal: 'NORTH' } },
  { id: 'P54', wall: 'NORTH', tour: { meta: null, panels: 'NORTH_OPEN', gimbal: 'EAST' } },
  { id: 'P55', wall: 'NORTH', tour: { meta: null, panels: 'CLOSE', gimbal: 'NORTH' } },
  { id: 'P56', wall: 'NORTH', tour: { meta: null, panels: 'CLOSE', gimbal: 'NORTH' } },
  { id: 'P57', wall: 'NORTH', tour: { meta: null, panels: 'CLOSE', gimbal: 'NORTH' } },
  { id: 'P58', wall: 'NORTH', tour: { meta: null, panels: 'CLOSE', gimbal: 'NORTH' } },
  { id: 'P59', wall: 'NORTH', tour: { meta: null, panels: 'CLOSE', gimbal: 'NORTH' } },
  { id: 'P60', wall: 'NORTH', tour: { meta: null, panels: 'CLOSE', gimbal: 'NORTH' } },
  { id: 'P61', wall: 'NORTH', tour: { meta: null, panels: 'CLOSE', gimbal: 'NORTH' } },
  { id: 'P62', wall: 'NORTH', tour: { meta: null, panels: 'CLOSE', gimbal: 'NORTH' } },
  { id: 'P63', wall: 'EAST', tour: { meta: null, panels: 'CLOSE', gimbal: 'EAST' } },
  { id: 'P64', wall: 'NORTH', tour: { meta: null, panels: 'CLOSE', gimbal: 'NORTH' } },
  { id: 'P65', wall: 'NORTH', tour: { meta: null, panels: 'CLOSE', gimbal: 'NORTH' } },
  { id: 'P66', wall: 'EAST', tour: { meta: null, panels: 'CLOSE', gimbal: 'EAST' } },
  { id: 'P67', wall: 'NORTH', tour: { meta: null, panels: 'CLOSE', gimbal: 'NORTH' } },
  { id: 'P68', wall: 'NORTH', tour: { meta: null, panels: 'CLOSE', gimbal: 'NORTH' } },
  { id: 'P69', wall: 'NORTH', tour: { meta: null, panels: 'CLOSE', gimbal: 'SOUTH' } },
  { id: 'P70', wall: 'NORTH', tour: { meta: null, panels: 'CLOSE', gimbal: 'NORTH' } },
  { id: 'P71', wall: 'NORTH', tour: { meta: null, panels: 'CLOSE', gimbal: 'NORTH' } },
  { id: 'P72', wall: 'NORTH', tour: { meta: null, panels: 'CLOSE', gimbal: 'NORTH' } },
  { id: 'P73', wall: 'SOUTH', tour: { meta: null, panels: 'CLOSE', gimbal: 'SOUTH' } },
  { id: 'P74', wall: 'SOUTH', tour: { meta: null, panels: 'CLOSE', gimbal: 'SOUTH' } },
  { id: 'P75', wall: 'NORTH', tour: { meta: null, panels: 'CLOSE', gimbal: 'NORTH' } },
  { id: 'P76', wall: 'NORTH', tour: { meta: null, panels: 'CLOSE', gimbal: 'NORTH' } },
  { id: 'P77', wall: 'NORTH', tour: { meta: null, panels: 'CLOSE', gimbal: 'NORTH' } },
  { id: 'P78', wall: 'SOUTH', tour: { meta: null, panels: 'CLOSE', gimbal: 'SOUTH' } },
  { id: 'P79', wall: 'SOUTH', tour: { meta: null, panels: 'SOUTH_0_OPEN', gimbal: 'EAST' } },
  { id: 'P80', wall: 'NORTH', tour: { meta: null, panels: 'CLOSE', gimbal: 'NORTH' } },
  { id: 'P81', wall: 'NORTH', tour: { meta: null, panels: 'SOUTH_0_OPEN', gimbal: 'EAST' } },
  { id: 'P82', wall: 'NORTH', tour: { meta: null, panels: 'SOUTH_0_OPEN', gimbal: 'SOUTH' } },
  { id: 'P83', wall: 'NORTH', tour: { meta: null, panels: 'CLOSE', gimbal: 'NORTH' } },
  { id: 'P84', wall: 'NORTH', tour: { meta: null, panels: 'CLOSE', gimbal: 'NORTH' } },
  { id: 'P85', wall: 'SOUTH', tour: { meta: null, panels: 'SOUTH_0_OPEN', gimbal: 'WEST' } },
  { id: 'P86', wall: 'NORTH', tour: { meta: null, panels: 'CLOSE', gimbal: 'NORTH' } },
  { id: 'P87', wall: 'SOUTH', tour: { meta: null, panels: 'SOUTH_0_OPEN', gimbal: 'WEST' } },
  { id: 'P88', wall: 'NORTH', tour: { meta: null, panels: 'SOUTH_0_OPEN', gimbal: 'SOUTH' } },
  { id: 'P89', wall: 'NORTH', tour: { meta: null, panels: 'CLOSE', gimbal: 'NORTH' } },
  { id: 'P90', wall: 'NORTH', tour: { meta: null, panels: 'CLOSE', gimbal: 'NORTH' } },
  { id: 'P91', wall: 'NORTH', tour: { meta: null, panels: 'SOUTH_1_OPEN', gimbal: 'EAST' } },
  { id: 'P92', wall: 'NORTH', tour: { meta: null, panels: 'CLOSE', gimbal: 'NORTH' } },
  { id: 'P93', wall: 'NORTH', tour: { meta: null, panels: 'CLOSE', gimbal: 'NORTH' } },
  { id: 'P94', wall: 'NORTH', tour: { meta: null, panels: 'SOUTH_1_OPEN', gimbal: 'WEST' } },
  { id: 'P95', wall: 'NORTH', tour: { meta: null, panels: 'CLOSE', gimbal: 'NORTH' } },
  { id: 'P96', wall: 'SOUTH', tour: { meta: null, panels: 'CLOSE', gimbal: 'SOUTH' } },
  { id: 'P97', wall: 'NORTH', tour: { meta: null, panels: 'CLOSE', gimbal: 'NORTH' } },
  { id: 'P98', wall: 'NORTH', tour: { meta: null, panels: 'CLOSE', gimbal: 'NORTH' } },
  { id: 'P99', wall: 'EAST', tour: { meta: null, panels: 'SOUTH_1_OPEN', gimbal: 'EAST' } },
  { id: 'P100', wall: 'NORTH', tour: { meta: null, panels: 'CLOSE', gimbal: 'NORTH' } },
  { id: 'P101', wall: 'NORTH', tour: { meta: null, panels: 'SOUTH_1_OPEN', gimbal: 'NORTH' } },
  { id: 'P102', wall: 'NORTH', tour: { meta: null, panels: 'SOUTH_1_OPEN', gimbal: 'NORTH' } },
  { id: 'P103', wall: 'NORTH', tour: { meta: null, panels: 'CLOSE', gimbal: 'NORTH' } },
  { id: 'P104', wall: 'NORTH', tour: { meta: null, panels: 'CLOSE', gimbal: 'NORTH' } },
  { id: 'P105', wall: 'NORTH', tour: { meta: null, panels: 'CLOSE', gimbal: 'NORTH' } },
  { id: 'P106', wall: 'NORTH', tour: { meta: null, panels: 'CLOSE', gimbal: 'NORTH' } },
  { id: 'P107', wall: 'NORTH', tour: { meta: null, panels: 'CLOSE', gimbal: 'NORTH' } },
  { id: 'P108', wall: 'SOUTH', tour: { meta: null, panels: 'SOUTH_1_OPEN', gimbal: 'SOUTH' } },
  { id: 'P109', wall: 'NORTH', tour: { meta: null, panels: 'CLOSE', gimbal: 'NORTH' } },
  { id: 'P110', wall: 'NORTH', tour: { meta: null, panels: 'CLOSE', gimbal: 'NORTH' } },
  { id: 'P111', wall: 'NORTH', tour: { meta: null, panels: 'CLOSE', gimbal: 'NORTH' } },
  { id: 'P112', wall: 'NORTH', tour: { meta: null, panels: 'CLOSE', gimbal: 'NORTH' } },
  { id: 'P113', wall: 'EAST', tour: { meta: null, panels: 'SOUTH_1_OPEN', gimbal: 'EAST' } },
  { id: 'P114', wall: 'NORTH', tour: { meta: null, panels: 'CLOSE', gimbal: 'NORTH' } },
  { id: 'P115', wall: 'NORTH', tour: { meta: null, panels: 'CLOSE', gimbal: 'NORTH' } },
  { id: 'P116', wall: 'NORTH', tour: { meta: null, panels: 'CLOSE', gimbal: 'NORTH' } },
  { id: 'P117', wall: 'NORTH', tour: { meta: null, panels: 'CLOSE', gimbal: 'NORTH' } },
  { id: 'P118', wall: 'NORTH', tour: { meta: null, panels: 'SOUTH_1_OPEN', gimbal: 'NORTH' } },
  { id: 'P119', wall: 'NORTH', tour: { meta: null, panels: 'CLOSE', gimbal: 'NORTH' } },
  { id: 'P120', wall: 'NORTH', tour: { meta: null, panels: 'CLOSE', gimbal: 'NORTH' } },
  { id: 'P121', wall: 'NORTH', tour: { meta: null, panels: 'CLOSE', gimbal: 'SOUTH' } },
  { id: 'P122', wall: 'NORTH', tour: { meta: null, panels: 'CLOSE', gimbal: 'NORTH' } },
  { id: 'P123', wall: 'NORTH', tour: { meta: null, panels: 'CLOSE', gimbal: 'NORTH' } },
  { id: 'P124', wall: 'NORTH', tour: { meta: null, panels: 'CLOSE', gimbal: 'NORTH' } },
  { id: 'P125', wall: 'NORTH', tour: { meta: null, panels: 'CLOSE', gimbal: 'NORTH' } },
  { id: 'P126', wall: 'NORTH', tour: { meta: null, panels: 'WEST_OPEN', gimbal: 'WEST' } },
  { id: 'P127', wall: 'NORTH', tour: { meta: null, panels: 'CLOSE', gimbal: 'NORTH' } },
  { id: 'P128', wall: 'NORTH', tour: { meta: null, panels: 'WEST_OPEN', gimbal: 'NORTH' } },
  { id: 'P129', wall: 'NORTH', tour: { meta: null, panels: 'CLOSE', gimbal: 'NORTH' } },
  { id: 'P130', wall: 'NORTH', tour: { meta: null, panels: 'CLOSE', gimbal: 'NORTH' } },
  { id: 'P131', wall: 'NORTH', tour: { meta: null, panels: 'CLOSE', gimbal: 'NORTH' } },
  { id: 'P132', wall: 'WEST', tour: { meta: null, panels: 'WEST_OPEN', gimbal: 'WEST' } },
  { id: 'P133', wall: 'NORTH', tour: { meta: null, panels: 'CLOSE', gimbal: 'NORTH' } },
  { id: 'P134', wall: 'WEST', tour: { meta: null, panels: 'WEST_OPEN', gimbal: 'WEST' } },
  { id: 'P135', wall: 'WEST', tour: { meta: null, panels: 'WEST_OPEN', gimbal: 'WEST' } },
  { id: 'P136', wall: 'NORTH', tour: { meta: null, panels: 'WEST_OPEN', gimbal: 'NORTH' } },
  { id: 'P137', wall: 'NORTH', tour: { meta: null, panels: 'CLOSE', gimbal: 'NORTH' } },
  { id: 'P138', wall: 'WEST', tour: { meta: null, panels: 'WEST_OPEN', gimbal: 'NORTH' } },
  { id: 'P139', wall: 'NORTH', tour: { meta: null, panels: 'CLOSE', gimbal: 'NORTH' } },
  { id: 'P140', wall: 'NORTH', tour: { meta: null, panels: 'CLOSE', gimbal: 'WEST' } },
  { id: 'P141', wall: 'NORTH', tour: { meta: null, panels: 'CLOSE', gimbal: 'NORTH' } },
  { id: 'P142', wall: 'WEST', tour: { meta: null, panels: 'CLOSE', gimbal: 'WEST' } },
  { id: 'P143', wall: 'WEST', tour: { meta: null, panels: 'CLOSE', gimbal: 'WEST' } },
  { id: 'P144', wall: 'WEST', tour: { meta: null, panels: 'CLOSE', gimbal: 'WEST' } },
  { id: 'P145', wall: 'WEST', tour: { meta: null, panels: 'CLOSE', gimbal: 'WEST' } },
  { id: 'P146', wall: 'WEST', tour: { meta: null, panels: 'CLOSE', gimbal: 'WEST' } },
  { id: 'P147', wall: 'NORTH', tour: { meta: null, panels: 'CLOSE', gimbal: 'WEST' } },
  { id: 'P148', wall: 'WEST', tour: { meta: null, panels: 'CLOSE', gimbal: 'WEST' } },
  { id: 'P149', wall: 'WEST', tour: { meta: null, panels: 'CLOSE', gimbal: 'WEST' } },
  { id: 'P209', wall: null },
]

const pictureData = positionData.map((data) => {
  const tourMeta = pictureCollection.find((tourInfo) => tourInfo.id === data.id)
  if (tourMeta) return { ...data, tour: { ...data.tour, meta: tourMeta } }
  return data
})


