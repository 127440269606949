import template from '../templates/carousel.html'
import content from '../data/content';

export default {
    name: 'carousel',
    template,
    props: ['related'],
    data() {
        return {
            current: 0,
            slides: []
        }
    },
    computed: {
        label() {
            return (this.slides.length > 0) ? this.slides[this.current].label : ''
        },
        summary() {
            return (this.slides.length > 0 && this.slides[this.current].hasOwnProperty('summary')) ? this.slides[this.current].summary : ''
        }
    },
    methods: {
        background(index) { 
            return `background-image: url(${this.slides[index].img.src})`
        },
        next() {            
            this.current = ((this.current + 1) < this.related.length) ? this.current + 1 : 0
        },
        prev() {
            this.current = ((this.current - 1) < 0) ? this.related.length - 1 : this.current - 1
        },
        link() {
            let url = content.urlify(this.related[this.current])
            this.$router.go({ name: 'inspect', params: { detail: url } })
        }
    },
    ready() {
        if(this.related.length > 0) { // protected by v-if
            content.collection[this.$route.params.section].forEach(model => {
                this.related.forEach(id => {
                    if(id === model.id) this.slides.push(model)
                })
            })
        }
    }
}