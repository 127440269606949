const KEYMAP = {
  49: { key: '1', action: { trigger: 'TOUR', method: 'START', param: 't1', type: 'CUSTOM' } },
  50: { key: '2', action: { trigger: 'TRANSITION', method: 'ROOM' } },
  51: { key: '3', action: { trigger: 'PANELS', method: 'NORTH' } },
  52: { key: '4', action: { trigger: 'PANELS', method: 'SOUTH' } },
  53: { key: '5', action: { trigger: 'PANELS', method: 'WEST' } },
  54: { key: '6', action: { trigger: 'TRANSITION', method: 'MAP_TOGGLE' } },
  37: { key: 'left arrow', action: { trigger: 'TOUR', method: 'PREV' } },
  39: { key: 'right arrow', action: { trigger: 'TOUR', method: 'NEXT' } }
}
const start = [2.81, -0.8046, 0.093]
export default {
  mountPoint: null,     // existing domElement to mount canvas upon
  startPoint: start,    // initial camera position
  models: ['ROOM_V42'],  // load from /public/models
  fov: [50, window.innerWidth / window.innerHeight, 0.5, 2000],
  controls: {
    orbit: { min: 0.1, max: 5.5, minPolar: 1, maxPolar: 2 },
    zoom: { min: 1, max: 10 }
  },
  blacklist: [],//["P64", "P65", "P67", "P68", "P97", "P102", "P103", "P104", "P110", "P119", "P122", "P129", "P137"],
  sandbox: false, // (use false to be consumed by web ui)
  showPicturePlanes: false,
  showDolly: false,
  customKeys: KEYMAP,
  orientationTest: false,
  hideBackWalls: false,
  pictureRoomBoundingBox: {
    center: { x: 0.05074763, y: 0.465187, z: 0 },
    size: { x: 4.4, y: 5.9, z: 3.9 },
  },
  monksChamberBoundingBox: {
    center: { x: 0.1437852, y: 0.4482712, z: -8.679206 },
    size: { x: 4.839908, y: 3.385141, z: 3.114486 },
  },
  tourStartPos: { x: 1.5, y: 0, z: 0 },
  tourMinZoom: 1,
  tourMaxZoom: 2.8,
  tourTransparency: 0.4,
  freeTransparency: 0.0,
  unfoldSpeed: 3000,
  unfoldCamDistance: 30,
  unfoldCamFov: 25,
  unfoldCamMaxZoom: 2,
}
