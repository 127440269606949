// import * as THREE from 'three'
import config from '../utils/config'
import modelFactory from './modelFactory'
import {
  CalculateCenterOfMass, NorthOpenTransition, NorthCloseTransition,
  SouthOpen0Transition, SouthClose0Transition, SouthOpen1Transition,
  SouthClose1Transition, WestOpenTransition, WestCloseTransition
} from '../utils/tweens.js'

// const loader = new THREE.OBJLoader();
const loader = new THREE.GLTFLoader();
const path = config.sandbox ? './model' : './assets/models'
// function loadModels(assetFactory, scene, complete) {


function loadModels(assetFactory, scene, complete, globals, room) {
  let loaded = 0
  return (target) => {
    loader.load(`${path}/${target}/${target}.txt`, (gltf) => {
      gltf.scene.traverse(assetFactory)
      scene.add(gltf.scene)
      // gltf.traverse(assetFactory)
      // scene.add(gltf)
      scene.updateMatrixWorld(true);
      loaded++
      if (loaded === config.models.length) {
        complete()
        
        scene.roomWalls = [];
        globals.objects = {};
        globals.objects.tourObjects = new Map();
        scene.traverse(function (child) {
          
          // console.log(child.name);
          //pass through all elements in the model
          if (
            child.name == config.nearWallName ||
            child.name == config.farWallName ||
            child.name == config.leftWallName || 
            child.name == config.rightWallName || 
            child.name == config.assets_01 || 
            child.name == config.assets_02 || 
            child.name == config.assets_03 || 
            child.name == config.assets_04 || 
            child.name == config.assets_05 || 
            child.name == config.leftCabinetTop || 
            child.name == config.rightCabinetTop || 
            child.name == config.rightCabinetBottom || 
            child.name == config.floorName || 
            child.name == config.roofName || 
            child.name.includes("OBJ") ||
            child.name.includes("WALL_CUT")
          ) {
              child.centerOfMass = CalculateCenterOfMass(child);
              scene.roomWalls.push(child);
          }
          
          //Keep track of each wall in the globals :
          // //North
          if (child.name == config.nearWallName) {
            globals.objects.entranceWall = child;
            // child.visible=false;
          }
          if (child.name == config.farWallName) {
            globals.objects.farWall = child;
            // child.visible=false;
          }
          if (child.name == config.floorName) {
            globals.objects.floorAndCupboards = child;
            // child.visible=false;            
          }
          if (child.name == config.leftWallName) {
            globals.objects.leftWall = child;
            // child.visible=false;
          }
          if (child.name == config.rightWallName) {
            globals.objects.rightWall = child;
            // child.visible=false;
          }
          if (child.name == config.roofName) {
            globals.objects.roof = child;
            // child.visible=false;
          }
          if(child.name == config.leftCabinetNameBottom)
          {
            globals.objects.leftCabinetBottom = child;
            // child.visible=false;
          }
          if(child.name == config.leftCabinetNameTop)
          {
            globals.objects.leftCabinetTop = child;
            
            child.geometry.computeBoundingBox();
            
            var BB = new THREE.Box3();
            BB.setFromCenterAndSize(new THREE.Vector3(-0.5,0,-1.25), new THREE.Vector3(4.5,1,1));
            room.controls.orbit.boxes.push(BB);
            
            // const helper = new THREE.Box3Helper( BB, 0xff0000 );
            // scene.add( helper );
            // child.visible=false;
          }
          if(child.name == config.rightCabinetNameTop)
          {
            globals.objects.rightCabinetBottom = child;
            
            var BB = new THREE.Box3();
            BB.setFromCenterAndSize(new THREE.Vector3(-0.5,0,1), new THREE.Vector3(4.5,1,1));
            room.controls.orbit.boxes.push(BB);

            // const helper = new THREE.Box3Helper( BB, 0xff00ff );
            // scene.add( helper );
            // child.visible=false;
          }
          if(child.name == config.rightCabinetNameBottom)
          {
            globals.objects.rightCabinetTop = child;
            // child.visible=false;
          }

          child.isTourObject=false;
          if(child.name.includes('OBJ'))
          {
            child.isTourObject=true;
            globals.objects.tourObjects.set(child.name, child);
          }
          
          if(child.name == 'OBJ12')
          {
            globals.objects.bookObject = child;
          }
          
          if(child.name == config.roofName)
          {
            globals.objects.roofObject = child;
          }
          
        });
        

        //Set initial material properties for objects
        // SetColor(globals.objects.entranceWall, new THREE.Color("rgb(255, 0, 0)"));
        // SetColor(globals.objects.farWall, new THREE.Color("rgb(0, 255, 0)"));
        // SetColor(globals.objects.leftWall, new THREE.Color("rgb(255, 255, 0)"));
        // SetColor(globals.objects.rightWall, new THREE.Color("rgb(255, 0, 255)"));
        
        // SetColor(globals.objects.roof, new THREE.Color("rgb(0, 255, 255)"));
        // SetColor(globals.objects.floorAndCupboards, new THREE.Color("rgb(0, 0, 255)"));
        // SetColor(globals.objects.rightCupboard, new THREE.Color("rgb(175, 128, 255)"));
        // SetColor(globals.objects.leftCupboard, new THREE.Color("rgb(255, 128, 50)"));



      }
    })

  }
}
export function loadAssets(room) {
  const assetFactory = modelFactory(room.globals, room)
  return new Promise((resolve) => {
    const loadHandler = loadModels(assetFactory, room.scene, resolve, room.globals, room)
    config.models.forEach(loadHandler)
  })
}
// }