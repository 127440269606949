// import * as THREE from 'three'
import config from '../utils/config'
import { getPicture } from '../collections/pictureData'
export default function ({ walls, pivots, pics}, room) {
  return (child) => {
    const descriptors = child.name.split('_')
    if (descriptors.some((part) => part === 'PIVOT')) {
      if (descriptors.some((part) => part === 'WALL')) {
        // add walls to pivots for unfolding
        pivots.push(child)
        if (descriptors.some((part) => part === 'NORTH' || part === 'EAST' || part === 'SOUTH' || part === 'WEST')) {
          const [pivot, face, wall] = descriptors
          walls[face] = child
        }
      }
    }
    var minZ = 0;
    var maxZ = 5;



    // enable ong transparency
    if (child.isMesh) {
      // if (descriptors.some((part) => part === 'Slice')) {

      //TEMP FIX
      //
      if(child.name == "OB15")
      {
        child.name = "OBJ15"
      }

        var tex = child.material.emissiveMap;
        if(tex == null && child.material.map != null) tex = child.material.map
        var alphaTex = child.material.emissiveMap;
        var params = {
          "colorTexture": { value: tex },
          "alphaTexture": { value: alphaTex },
          "minZ": { value: minZ },
          "maxZ": { value: maxZ },
          "transparency": { value: 0.0 },
          "color": { value: new THREE.Color("rgb(255,255,255)") },
          "distanceBasedFactor": { value: 0.75 },
          "minDistanceAlpha": { value: config.CloseUpTransparency },
          
          "radiusExponent": {value: 1.7},
          "minAlpha": {value: 0.11},
          "brightness": {value: 0.0},
          "contrast": {value: 1.0},
          "radialIntensity": {value: 0.0}
        };
        const material = new THREE.ShaderMaterial({
          uniforms: params,
          vertexShader: [
            'varying vec2 vUv;',
            // 'varying vec2 vUv2;',
            'varying vec4 vViewPosition;',
            'varying float radius;',

            'void main()',
            '{',
            'vUv = uv;',
            // 'vUv2 = uv2;',
            'vViewPosition = modelViewMatrix * vec4( position, 1.0 );',
            'gl_Position = projectionMatrix * vViewPosition;',
            'radius = length(gl_Position.xy / gl_Position.w);',
            '}'
          ].join('\n'),
          fragmentShader: [
            'uniform sampler2D colorTexture;',
            'uniform sampler2D alphaTexture;',
            'uniform float transparency;',
            'uniform float distanceBasedFactor;',
            'uniform vec3 color;',
            'uniform float minZ;',
            'uniform float maxZ;',
            'uniform float radiusExponent;',
            'uniform float minAlpha;',
            'uniform float radialIntensity;',
            'uniform float brightness;',
            'uniform float contrast;',
            'uniform float minDistanceAlpha;',

            'varying vec2 vUv;',
            'varying vec2 vUv2;',
            'varying vec4 vViewPosition;',
            'varying float radius;',
            'void main( void ) {',

            // 'vec4 alphaRgba = texture2D( alphaTexture, vUv );',
            'vec4 alphaRgba = texture2D( colorTexture, vUv );',
            // 'float alpha  = (alphaRgb.r + alphaRgb.g + alphaRgb.b) * 0.3333f;',
            'float alpha  = alphaRgba.a;',  
            
            'vec4 finalColor = texture2D( colorTexture, vUv );',
            'finalColor *= contrast;',
            'finalColor += brightness;',
            // 'finalColor = vec4(color, 1);',
            'gl_FragColor = vec4(finalColor.rgb , alpha);',
            // 'if(alpha < 0.1) discard;',
            
            'float depth = -vViewPosition.z;',
            'float minZOverMaxZ = minZ / maxZ;',
            'float t = clamp(depth / maxZ - minZOverMaxZ, 0.0f, 1.0f);',
            'depth = depth < minZ ? minZOverMaxZ :  minZOverMaxZ + mix(minDistanceAlpha, 1.0f, t);',
            'float distanceBasedAlpha = depth * distanceBasedFactor * 1.0f;',
            
            'float radialBasedAlpha = 1.0f - radius;',
            'radialBasedAlpha = clamp(pow(radialBasedAlpha, radiusExponent), minAlpha, 1.0f);',
            
            'gl_FragColor.a = radialIntensity * radialBasedAlpha + (1.0f - radialIntensity) * distanceBasedAlpha + transparency;',
            'gl_FragColor.a = clamp(gl_FragColor.a, 0.01f, 1.0f);',
            // 'gl_FragColor = texture2D( colorTexture, vUv );',
            '}'
          ].join('\n'),
        });
        
        child.material = material;
        
        child.material.side = THREE.DoubleSide;
        child.material.depthWrite = true;
        child.material.transparent = true;
        
        child.type = 0;
        if(child.name == config.leftCabinetNameBottom || child.name == config.leftCabinetNameTop || 
           child.name == config.rightCabinetNameTop  || child.name == config.assets_03  || child.name == config.assets_04  
           || child.name == 'OBJ12'
           )
        {
          child.material.transparent=false;
          child.type = 1
        }
        
        if(child.name.includes('OBJ'))
        {
          child.material.side = THREE.FrontSide;
        }  
    }

    // target picture references
    if (descriptors.some((part) => part === 'TARGET')) {
      child.type = 1;
      child.material = new THREE.MeshBasicMaterial({
        side: THREE.DoubleSide
      })

      if (child.hasOwnProperty('material') && !config.showPicturePlanes) child.visible = false;

      const [target, id, face] = child.name.split('_')
      const { wall, tour } = getPicture(id)
      child.userData.id = id
      child.userData.wall = wall
      if (tour) {
        child.userData.tour = {
          ...tour,
          trigger: 'VIEW_PICTURE', // events listener
          method: null
          // method: callback => callback()
        }
      }
      child.userData.actions = {
        trigger: 'WATCH',
        method: (onComplete) => {
          setTimeout(() => onComplete({ name: 'picture', src: '/sda/sdfd.png' }), 3000)
        }
      }
      pics.push(child)
    }
  }
}
