import Template from '../templates/spin-loader.html';

export default {
    name: 'SpinLoader',
    // route: {
    //     canReuse: false
    // },
    template: Template,
    props: ['ready'],
    data() {
        return {
            // display: true
        }
    },
    computed: {
        display() {
            return (this.ready) ? false : true;
        }
    },
    watch: {
        // 'ready': function (next, prev) {
        //     this.display = !next
        // }
    }
}