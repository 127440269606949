import room from '../framework/index'


function getDoorsByFace(face) {
  return (door) => {
    const parts = door.name.split('_')
    const match = parts.some((part) => part === face)
    if (match) return door.userData
    return false
  }
}

function getPanelsByLevel(level) {
  return (door) => {
    const parts = door.name.split('_')
    return parts.some((part) => part === level)
  }
}

function createPanelHandler(panels, face) {
  const doors = panels.filter(getDoorsByFace(face))
  // maybe direction in open / close
  return (param) => {
    const debounce = room.controls.debounceState()
    return new Promise((resolve) => {
      const endState = debounce()
      if (!face) return resolve()
      room.lockControls()
      doors.forEach(({ userData }) => {
        userData.panel.toggle((detail = 'onComplete param') => {
          endState()
          resolve('PANEL ANIM COMPLETE & STATE UPDATE ;)', detail)
        })
      })
    })
  }
}

function toggleCallback(update, endState, resolve, next) {
  return () => {
    update(next)
    endState()
    resolve()
  }
}

function sequenceHandler(panels) {
  let step = "OPEN_1ST_PANELS"
  const doors = panels.filter(getDoorsByFace('S'))
  const level1Panels = doors.filter(getPanelsByLevel('1'))
  const level2Panels = doors.filter(getPanelsByLevel('2'))
  function updateStep(next) {
    step = next
  }
  // maybe direction in open / close
  return (param) => {
    const debounce = room.controls.debounceState()
    return new Promise((resolve) => {
      const endState = debounce()
      room.lockControls()
      if (step === "OPEN_1ST_PANELS") level1Panels.forEach(door => door.userData.panel.toggle(toggleCallback(updateStep, endState, resolve, "OPEN_2ND_PANELS")))
      if (step === "OPEN_2ND_PANELS") level2Panels.forEach(door => door.userData.panel.toggle(toggleCallback(updateStep, endState, resolve, "CLOSE_2ND_PANELS")))
      if (step === "CLOSE_2ND_PANELS") level2Panels.forEach(door => door.userData.panel.toggle(toggleCallback(updateStep, endState, resolve, "CLOSE_1ST_PANELS")))
      if (step === "CLOSE_1ST_PANELS") level1Panels.forEach(door => door.userData.panel.toggle(toggleCallback(updateStep, endState, resolve, "OPEN_1ST_PANELS")))
    })
  }
}


// ACs
// store state of current panels
// each picture has a prefered panel view
// map all required steps in 'view name'
// handle transparencies between multiple layers
class initPanelStates {
  constructor(panels, globals) {
    const N = panels.filter(getDoorsByFace('N'))
    const W = panels.filter(getDoorsByFace('W'))
    const S = panels.filter(getDoorsByFace('S'))
    const S0 = S.filter(getPanelsByLevel('1'))
    const S1 = S.filter(getPanelsByLevel('2'))
    this.current = [false, false, false, false]
    this.panels = [N, W, S0, S1]
    this.globals = globals
    return this
  }
  gotoPanelState(target) {
    const debounce = room.controls.debounceState()
    const endState = debounce()
    const action = panelMap[target]

    // TODO update this.current panels!

    return new Promise((resolve) => {
      action.apply(this).then(() => {
        endState()
        resolve()
      })
    })
  }

}

//This.current :
//North, West, South 0, South 1

const panelMap = {
  NORTH_OPEN: function () {
    return new Promise((resolve) => {
      const nChange = this.current[0] != true
      const wChange = this.current[1] != false
      const s0Change = this.current[2] != false
      const s1Change = this.current[3] != false

      if (s1Change) {
        this.panels[3].forEach(door => door.userData.panel.toggle(resolve))
      }
      if (s0Change) {

        this.panels[2].forEach(door => door.userData.panel.toggle(resolve))
      }

      if (wChange) {
        this.panels[1].forEach(door => door.userData.panel.toggle(resolve))
      }
      if (nChange) {
        this.panels[0].forEach(door => door.userData.panel.toggle(resolve))
      }

      if ([nChange, wChange, s0Change, s1Change].some(change => change)) room.onAction({ type: 'TBC', trigger: "TOUR_PANELS_ACTIVE", callback: (next) => next('opening') })
      if ([nChange, wChange, s0Change, s1Change].every((state) => !state)) resolve()
      this.current = [true, false, false, false]
    })
  },
  CLOSE: function () {
    return new Promise((resolve) => {
      const nChange = this.current[0] != false
      const wChange = this.current[1] != false
      const s0Change = this.current[2] != false
      const s1Change = this.current[3] != false

      if (s1Change) {
      }
      if (s0Change) {
      }

      if (s1Change && s0Change) {
        // TODO - need to chain these
        // this always does s0Change first then s1Change (shouldnt this also need to be chained in reverse?)
        this.panels[2].forEach((door, i) => door.userData.panel.toggle(() => {
          if (i === 1) this.panels[3].forEach(door => door.userData.panel.toggle(resolve))
        }))
      }
      else {
        if (s0Change) this.panels[2].forEach(door => door.userData.panel.toggle(resolve))
      }
      if (wChange) {
        this.panels[1].forEach(door => door.userData.panel.toggle(resolve))
      }
      if (nChange) {
        this.panels[0].forEach(door => door.userData.panel.toggle(resolve))
      }

      if ([nChange, wChange, s0Change, s1Change].some(change => change)) room.onAction({ type: 'TBC', trigger: "TOUR_PANELS_ACTIVE", callback: (next) => next('closing') })
      if ([nChange, wChange, s0Change, s1Change].every((state) => state === false)) resolve()
      setTimeout(() => { this.current = [false, false, false, false]; }, 1300);

    })
  },
  WEST_OPEN: function () {
    return new Promise((resolve) => {
      const nChange = this.current[0] != false
      const wChange = this.current[1] != true
      const s0Change = this.current[2] != false
      const s1Change = this.current[3] != false

      if (s1Change) {
        this.panels[3].forEach(door => door.userData.panel.toggle(resolve))
      }
      if (s0Change) {

        this.panels[2].forEach(door => door.userData.panel.toggle(resolve))
      }
      if (wChange) {
        this.panels[1].forEach(door => door.userData.panel.toggle(resolve))
      }
      if (nChange) {
        this.panels[0].forEach(door => door.userData.panel.toggle(resolve))
      }

      if ([nChange, wChange, s0Change, s1Change].some(change => change)) room.onAction({ type: 'TBC', trigger: "TOUR_PANELS_ACTIVE", callback: (next) => next('opening') })
      if ([nChange, wChange, s0Change, s1Change].every((state) => !state)) resolve()
      this.current = [false, true, false, false]
    })
  },
  SOUTH_0_OPEN: function () {
    return new Promise((resolve) => {
      const nChange = this.current[0] != false
      const wChange = this.current[1] != false
      const s0Change = this.current[2] != true
      const s1Change = this.current[3] != false


      if (s1Change) {
        this.panels[3].forEach(door => door.userData.panel.toggle(resolve))
      }
      if (s0Change) {
        this.panels[2].forEach(door => door.userData.panel.toggle(resolve))
      }
      if (wChange) {
        this.panels[1].forEach(door => door.userData.panel.toggle(resolve))
      }
      if (nChange) {
        this.panels[0].forEach(door => door.userData.panel.toggle(resolve))
      }

      if ([nChange, wChange, s0Change, s1Change].some(change => change)) room.onAction({ type: 'TBC', trigger: "TOUR_PANELS_ACTIVE", callback: (next) => next('opening') })
      if ([nChange, wChange, s0Change, s1Change].every((state) => !state)) resolve()
      this.current = [false, false, true, false]
    })
  },
  SOUTH_1_OPEN: function () {
    return new Promise((resolve) => {
      const nChange = this.current[0] != false
      const wChange = this.current[1] != false
      const s0Change = this.current[2] != true
      const s1Change = this.current[3] != true

      if (s0Change && s1Change) {
        this.panels[3].forEach(door => door.userData.panel.toggle(resolve))
        this.panels[2].forEach(door => door.userData.panel.toggle(resolve))
      } else {
        if (s1Change) this.panels[3].forEach(door => door.userData.panel.toggle(resolve))
      }

      if (wChange) {
        this.panels[1].forEach(door => door.userData.panel.toggle(resolve))
      }
      if (nChange) {
        this.panels[0].forEach(door => door.userData.panel.toggle(resolve))
      }

      if ([nChange, wChange, s0Change, s1Change].some(change => change)) room.onAction({ type: 'TBC', trigger: "TOUR_PANELS_ACTIVE", callback: (next) => next('opening') })
      if ([nChange, wChange, s0Change, s1Change].every((state) => !state)) resolve()
      this.current = [false, false, true, true]
    })
  },
  SOUTH_1_CLOSE: function () {
    return new Promise((resolve) => {
      const nChange = this.current[0] != false
      const wChange = this.current[1] != false
      const s0Change = this.current[2] != true
      const s1Change = this.current[3] != false

      if (s1Change) {
        this.panels[3].forEach(door => door.userData.panel.toggle(resolve))
        SouthClose1Transition();
      }

      if (wChange) {
        this.panels[1].forEach(door => door.userData.panel.toggle(resolve))
      }
      if (nChange) {
        this.panels[0].forEach(door => door.userData.panel.toggle(resolve))
      }
      if ([nChange, wChange, s0Change, s1Change].some(change => change)) room.onAction({ type: 'TBC', trigger: "TOUR_PANELS_ACTIVE", callback: (next) => next('opening') })
      if ([nChange, wChange, s0Change, s1Change].every((state) => !state)) resolve()
      this.current = [false, false, true, false]
    })
  }

}


export default function panelController(panels, globals) {

  return {
    NORTH: createPanelHandler(panels, 'N'),
    SOUTH: sequenceHandler(panels),
    WEST: createPanelHandler(panels, 'W'),
    SCENE: new initPanelStates(panels, globals)
  }
}