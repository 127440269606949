import config from "../utils/config"

function clamp(value, min, max) {
  return Math.min(Math.max(value, min), max);
}

function lerp(start, end, percent) {
  return start * (1.0 - percent) + end * percent;
} 

function Slerp(start, end, percent, clockWise) {
  var startv2 = new THREE.Vector2(start.x, start.z);
  var startv2Normalized = new THREE.Vector2(start.x, start.z).normalize();
  var endv2Normalized = new THREE.Vector2(end.x, end.z).normalize();

  var dot = startv2Normalized.dot(endv2Normalized);
  dot = clamp(dot, -1, 1);           // Robustness: Stay within domain of acos()
  var theta_0 = Math.acos(dot);  // theta_0 = angle between input vectors
  var theta = clockWise ? 6.2831853 - theta_0 * percent : theta_0 * percent;    // theta = angle between v0 and result 


  var ortho = new THREE.Vector2(-startv2.y, startv2.x);

  var result0 = startv2.multiplyScalar(Math.cos(theta));
  var result1 = ortho.multiplyScalar(Math.sin(theta));

  var resultv2 = result0.add(result1);

  var y = lerp(start.y, end.y, percent);
  return new THREE.Vector3(resultv2.x, y, resultv2.y);
}

function RaySphereIntersect(rayOrigin, rayDirection, spherePosition, sphereRadius) {
  var t0, t1;
  var radius2 = sphereRadius * sphereRadius;

  // geometric solution
  var L = spherePosition.clone().sub(rayOrigin);
  var tca = L.dot(rayDirection);
  if (tca < 0) return false;
  var d2 = L.dot(L) - tca * tca;
  if (d2 > radius2) return false;
  var thc = Math.sqrt(radius2 - d2);
  t0 = tca - thc;
  t1 = tca + thc;

  if (t0 > t1) {
    var tmp = t0;
    t0 = t1;
    t1 = tmp;
  }

  if (t0 < 0) {
    t0 = t1; // if t0 is negative, let's use t1 instead 
    if (t0 < 0) return false; // both t0 and t1 are negative 
  }

  return true;
}

function swing(pivot, align) {
  const to = align === 'right' ? -Math.PI / 2.2 : Math.PI / 2.2
  return toggler(pivot, to)
}

function toggler(pivot, to) {
  let open = true
  return (next) => {
    if (open) twist('z', pivot, to, next)
    else twist('z', pivot, 0, next)
    open = !open
  }
}

function twist(axis, p, target = 0, next) {
  p.open = !p.open;
  if (p.userData.panel.transparencyOpen != undefined && p.open) {
    p.userData.panel.transparencyOpen();
  }
  if (p.userData.panel.transparencyClose != undefined && !p.open) {
    p.userData.panel.transparencyClose();
  }
  new TWEEN.Tween(p.rotation)
    .to({ [axis]: target }, 2000)
    .easing(TWEEN.Easing.Cubic.Out)
    .start()
    .onUpdate(() => {
    })
    .onComplete(() => {
      var centerOfMass = CalculateCenterOfMass(p);
      p.centerOfMass = centerOfMass;
      next()
    })
}


function slideCamera({ camera, dolly, targets}, orbit) {
  orbit.stopWiggle();
  FreeOrbit(orbit)

  var initialDollyPos = new THREE.Vector3(dolly.position.x, dolly.position.y, dolly.position.z);
  var targetDollyPos = new THREE.Vector3(targets.dolly.x, targets.dolly.y, targets.dolly.z);

  //Center between the initial and the target position
  var O = initialDollyPos.clone().add(targetDollyPos).multiplyScalar(0.5);


  var rayOrigin = initialDollyPos.clone();
  var rayDirection = targetDollyPos.clone().sub(rayOrigin);
  var intersect = RaySphereIntersect(rayOrigin, rayDirection, camera.getWorldPosition(), 0.1);
  
  var clockWise = true;
  var t = 0;
  var step = 10;
  if (intersect) {  
    var minDistClockwise = 9999999;
    var minDistAntiClockwise = 9999999;
    //Check all the positions that we'll go through, clockwise and anticlockwise, and calculate the one that goes the closest from the origin, so that we always pass inside the room 
    for (var i = 0; i < step; i++) {
      t = i / step;
      var posClockwise = Slerp(initialDollyPos.clone().sub(O), targetDollyPos.clone().sub(O), t, true).add(O);
      var posAntiClockwise = Slerp(initialDollyPos.clone().sub(O), targetDollyPos.clone().sub(O), t, false).add(O);
      var distClockwise = posClockwise.length();
      var distAntiClockwise = posAntiClockwise.length();
      minDistClockwise = Math.min(distClockwise, minDistClockwise);
      minDistAntiClockwise = Math.min(distAntiClockwise, minDistAntiClockwise);
    }
    clockWise = minDistClockwise < minDistAntiClockwise ? false : true;
    if (Math.abs(minDistAntiClockwise - minDistClockwise) < 0.01) intersect = false;
  }
  
  if (intersect) {
    var t = { t: 0 };
    new TWEEN.Tween(t)
      .to({ t: 1 }, 2000)
      .easing(TWEEN.Easing.Cubic.Out)
      .onUpdate(function (object) {
        var currentPos = new THREE.Vector3(0, 0, 0);
        currentPos = Slerp(initialDollyPos.clone().sub(O), targetDollyPos.clone().sub(O), t.t, clockWise).add(O);
        dolly.position.setX(currentPos.x);
        dolly.position.setY(currentPos.y);
        dolly.position.setZ(currentPos.z);
      })
      .start()
  }
  else {
    new TWEEN.Tween(dolly.position)
      .to(targets.dolly, 2000)
      .easing(TWEEN.Easing.Cubic.Out)
      .start()
  }


  return new TWEEN.Tween(camera.position)
    .to(targets.camera, 2000)
    .easing(TWEEN.Easing.Cubic.Out)
    .start()
}


function CalculateCenterOfMass(object) {
  var res = new THREE.Vector3()
  var num = 0;
  object.updateMatrixWorld(true);
  //Using worls position :
  //Use targets to calculate center of mass of panels
  // var objectToUse = (object.name.includes("PANEL")) ? "TARGET" : "Slice";
  // object.traverse(function (child) {
  //   if (child instanceof THREE.Mesh) {
  //     num++;
  //     var dummy = new THREE.Vector3();
  //     var wp = child.getWorldPosition(dummy);
  //     x += wp.x;
  //     y += wp.y;
  //     z += wp.z;
  //   }
  // });
  // x /= num;
  // y /= num;
  // z /= num;

  //Using bounding box : 
  object.updateMatrixWorld(true);
  object.traverse(function (child) {
    if (child instanceof THREE.Mesh) {
      num++;
      child.geometry.computeBoundingBox();      
      var middle = new THREE.Vector3(0,0,0);
      middle.x = (child.geometry.boundingBox.max.x + child.geometry.boundingBox.min.x) / 2;
      middle.y = (child.geometry.boundingBox.max.y + child.geometry.boundingBox.min.y) / 2;
      middle.z = (child.geometry.boundingBox.max.z + child.geometry.boundingBox.min.z) / 2;
      child.localToWorld( middle );
      res.add(middle);
    }
  });
  res.divideScalar(num)
  return res;
}




function FreeOrbit(orbit)
{
  // console.log("FreeOrbit");
  orbit.stopWiggle();
  orbit.update();
  orbit.minDistance = config.controls.orbit.min
  orbit.maxDistance = config.controls.orbit.max
  orbit.minPolarAngle = -6.28318530718;
  orbit.maxPolarAngle = 6.28318530718;
  orbit.minAzimuthAngle = -Infinity;
  orbit.maxAzimuthAngle = Infinity;
  // orbit.update();
}

function RoomOrbit(orbit)
{
  // console.log("RoomOrbit");
  orbit.stopWiggle();
  orbit.update();
  orbit.minDistance = config.controls.orbit.min
  orbit.maxDistance = config.controls.orbit.max
  orbit.minPolarAngle = config.controls.orbit.minPolar;
  orbit.maxPolarAngle = config.controls.orbit.maxPolar;
  orbit.minAzimuthAngle = -6.28318530718;
  orbit.maxAzimuthAngle = 6.28318530718;
  // orbit.update();
}

function InspectOrbit(orbit, focusObject)
{
  // console.log("InspectOrbit");
  orbit.stopWiggle();
  orbit.update();
  var azimuthAngle = orbit.getAzimuthalAngle()
  orbit.minAzimuthAngle = azimuthAngle - 0.5;
  orbit.maxAzimuthAngle = azimuthAngle + 0.5;
  var polarAngle = orbit.getPolarAngle()
  orbit.minPolarAngle = polarAngle - 0.3;
  orbit.maxPolarAngle = polarAngle + 0.3;
  if(focusObject != null)
  {
      orbit.minDistance = config.tourMinZoom;
      orbit.maxDistance = config.tourMaxZoom;
  }
  orbit.update();

}

export {
  swing, slideCamera, CalculateCenterOfMass,
  FreeOrbit, RoomOrbit, InspectOrbit
}