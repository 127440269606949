import room from '../framework/index'
import { gimbalLock, gimbalRotation } from '../utils/compass'
import { library } from '../collections/pictureRoomData'
import { slideCamera, transitionFrontWallsToOpacity, transitionStaticObjectsOpacity, FadeButtonsDown } from '../utils/tweens'
import config from "../utils/config"

function clamp(value, min, max) {
  return Math.min(value, Math.max(value, min));
}

function lerp(start, end, percent) {
  return start * (1.0 - percent) + end * percent;
}

function Slerp(start, end, percent) {
  var startv2 = new THREE.Vector2(start.x, start.z);
  var endv2 = new THREE.Vector2(end.x, end.z);

  var startv2Normalized = new THREE.Vector2(start.x, start.z).normalize();
  var endv2Normalized = new THREE.Vector2(end.x, end.z).normalize();

  var dot = startv2Normalized.dot(endv2Normalized);
  dot = clamp(dot, -1, 1);           // Robustness: Stay within domain of acos()
  var theta_0 = Math.acos(dot);  // theta_0 = angle between input vectors
  var theta = theta_0 * percent;    // theta = angle between v0 and result 

  var ortho = new THREE.Vector2(-startv2.y, startv2.x);

  var result0 = startv2.multiplyScalar(Math.cos(theta));
  var result1 = ortho.multiplyScalar(Math.sin(theta));

  var resultv2 = result0.add(result1);

  var y = lerp(start.y, end.y, percent);
  return new THREE.Vector3(resultv2.x, y, resultv2.y);
}


function RaySphereIntersect(rayOrigin, rayDirection, spherePosition, sphereRadius) {
  var t0, t1;
  var radius2 = sphereRadius * sphereRadius;

  // geometric solution
  var L = spherePosition.clone().sub(rayOrigin);
  var tca = L.dot(rayDirection);
  if (tca < 0) return false;
  var d2 = L.dot(L) - tca * tca;
  if (d2 > radius2) return false;
  var thc = Math.sqrt(radius2 - d2);
  t0 = tca - thc;
  t1 = tca + thc;

  if (t0 > t1) {
    var tmp = t0;
    t0 = t1;
    t1 = tmp;
  }

  if (t0 < 0) {
    t0 = t1; // if t0 is negative, let's use t1 instead 
    if (t0 < 0) return false; // both t0 and t1 are negative 
  }

  return true;
}

function genTweenConfig(picture) {
  const target = { ...picture.getWorldPosition() }
  const { gimbal } = picture.userData.tour
  const [a, b] = gimbalRotation(gimbal)
  const lock = gimbalLock(gimbal)
  var lockDist = 0.7;

  if (gimbal == "WEST") lockDist *= -1;
  if (gimbal == "NORTH") lockDist *= -1;
  if (target.z < -2) lockDist = target[lock] + 1.8;

  return {
    camera: room.camera,
    dolly: room.globals.dolly,
    targets: {
      dolly: target,
      camera: { [a]: target[a], [b]: target[b], [lock]: lockDist }
    }
  }
}

function handlePanels(picture) {
  return new Promise(resolve => {
    room.events['PANELS']['SCENE'].gotoPanelState(picture.userData.tour.panels).then(() => {
      // emit picture data
      const trigger = picture.userData.tour.trigger
      const data = { ...picture.userData }
      const callback = (next) => {
        next({ data, message: 'ressigned callback to enable data control' })
      }
      if (trigger) room.onAction({ type: 'TBC', trigger, callback })
      resolve()
    })
  })
}

//Fades walls back after intro
function fadeBackIn(index) {
  return new Promise(resolve => {
    if (index == 0) {
      transitionFrontWallsToOpacity(config.tourTransparency, room)
      transitionStaticObjectsOpacity(config.tourTransparency, room);
      setTimeout(() => { resolve() }, 2000);
    }
    else {
      resolve();
    }
  })
}

function beginTour({ initalCamera }) {
  return new Promise((resolve) => {
    room.onAction({ type: 'CUSTOM', trigger: 'TRANSITION', method: 'TOUR', param: initalCamera }).then(() => {
      resolve()
    })

    //Transition all front panels to very low opacity for intro
    transitionFrontWallsToOpacity(config.tourTransparency - 0.8, room)
    transitionStaticObjectsOpacity(config.tourTransparency - 0.8, room);
    FadeButtonsDown(room);
    {
      //Move camera dolly
      var initialDollyPos = room.globals.dolly.position.clone();
      var targetDollyPos = new THREE.Vector3(initalCamera[0], initalCamera[1], initalCamera[2]);
      var O = initialDollyPos.clone().add(targetDollyPos).multiplyScalar(0.5);

      var rayOrigin = initialDollyPos.clone();
      var rayDirection = targetDollyPos.clone().sub(rayOrigin);
      var intersect = RaySphereIntersect(rayOrigin, rayDirection, room.camera.getWorldPosition(), 0.5);
      if (intersect) {
        var t = { t: 0 };
        new TWEEN.Tween(t)
          .to({ t: 1 }, 2000)
          .easing(TWEEN.Easing.Cubic.Out)
          .onUpdate(function (object) {
            var currentPos = new THREE.Vector3(0, 0, 0);
            currentPos = Slerp(initialDollyPos.clone().sub(O), targetDollyPos.clone().sub(O), t.t).add(O);
            room.globals.dolly.position.setX(currentPos.x);
            room.globals.dolly.position.setY(currentPos.y);
            room.globals.dolly.position.setZ(currentPos.z);
          })
          .start()
      }
      else {
        new TWEEN.Tween(room.globals.dolly.position)
          .to(targetDollyPos, 2000)
          .easing(TWEEN.Easing.Cubic.Out)
          .start()
      }
    }
  })
}

const connectAppState = () => {
  let currentTour = null
  let index = -1
  let debounce = false

  function update() {
    const nextPictureId = currentTour.sequence[index]
    room.onAction({ type: 'TBC', trigger: "TOUR_UPDATE", callback: (next) => next(index) })
    if (currentTour.id !== 'tests') room.currentFocus = room.globals.pics.find(({ userData }) => userData.id === nextPictureId)
    // if (currentTour.id === 'tests') room.currentFocus = room.globals.tests.find(({ userData }) => userData.id === nextPictureId)
    return inspect()
  }
  function inspect() {

    return new Promise((resolve) => {
      fadeBackIn(index).then(() => {
        handlePanels(room.currentFocus)
          .then(() => slideCamera(genTweenConfig(room.currentFocus))
            .onComplete(() => {
              // room.currentFocus.material.opacity = 0
              debounce = false
              resolve()
            }))
      })

    })
  }
  function hasTourCompleted() {
    if (index === currentTour.sequence.length - 1) {
      // trigger listener
      room.onAction({ type: 'TBC', trigger: "TOUR_ENDED", callback: (next) => next({ currentTour }) })
    }
  }
  return {
    META: () => library.pictureRoom,
    START: (id) => {
      const newTour = library.tours.find(tour => tour.id === id)
      if (newTour) {  // reset
        currentTour = newTour
        room.currentFocus = null
        index = -1
      } else console.error('No Tour found', id)
      return beginTour({ ...currentTour })
    },
    STORE: () => {
      return Promise.resolve({ currentTour, index })
    },
    NEXT: () => {
      if (debounce) return Promise.resolve()
      debounce = true
      if (currentTour) {
        if (index === currentTour.sequence.length - 1) return Promise.resolve()
        index++
        hasTourCompleted()
        return update()
      }
      return Promise.resolve()
    },
    PREV: () => {
      if (debounce) return Promise.resolve()
      if (index <= 0) return Promise.resolve()
      debounce = true
      index--
      return update()
    }
  }
}


export default connectAppState