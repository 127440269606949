import template from '../templates/help.html';
import content from '../data/content';
import core from '../data/core';

const classes = {
  root: 'x--full',
  section: 'x--full',
  pointcloud: 'x--half',
  pictureroom: 'x--half'
}

export default {
  name: 'help',
  props: ['target'],
  template,
  data() {
    return {
      core,
      content,
      show: false,
    }
  },
  computed: {
    hasPage() {
      return (core.layout.overlay) ? "hidden" : "visible"
    },
    position() {
      return (classes[this.target] == 'x--full') ? 'x--full' : this.fsMode
    },
    fsMode() {
      return (this.core.layout.full) ? 'x--full' : 'x--half'
    },
    isRoute() {
      return (this.core.state.current == this.target)
    },
    message() {
      return content.help[this.target]
    }
  
  },
  methods: {
    reveal() {
      this.show = true;

      setTimeout(() => {
        this.show = false;
        this.$dispatch('help-deactive')
        this.core.layout.help = false;
      }, 7000);
    }
  },
  ready() {
    this.$on("help", (state) => {
      if (state === 'pictureroom' || state === 'drawingoffice' || state === 'picturemap') {
        this.target = state
        this.reveal()
        return
      }
      if (state == this.target) this.reveal();
    })
  }
}