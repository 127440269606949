import Template from '../templates/poi.html'
import Content from '../data/content'
export default {
  name: 'poi-inspector',
  template: Template,
  props: ['interested'],
  computed: {
    show() {
      if(!this.interested) {
        return ''
      } else {
        let model = (Content.collection[this.$route.params.section].filter(obj => this.interested == obj.id)[0]);
        return model.title // or label?
      }
    }
  }
}