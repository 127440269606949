import Template from '../templates/view-picture.html'
import TourCarousel from './TourCarousel'

// deprecate TourCarosel?

export default {
  name: 'view-picture',
  template: Template,
  components: [TourCarousel],
  props: ['page', 'core', 'model'],
  data() {
    return {
      show: false,
      fragment: null
    }
  },
  computed: {
    source()
    {
      console.log(this.core.drawingOffice.view, this.model)
      const assets = '/assets/img/picture-room'
      if (!this.model) return ''
      return `${assets}/thumbs/${this.model.id}.jpg`
    }
  },
  ready() {
    this.core.pictureRoom.magnifyParent = null
  },
  methods: {
    magnify() {
      this.core.pictureRoom.magnifyParent = this.$parent
      this.$parent.currentComponent = 'view-magnify'
    },
    exitTour() {
      // works
      const { view } = this.core.pictureRoom
      if (view === 'TOUR') {
        this.$parent.$parent.$parent.$dispatch('leave-tour')
        this.$parent.$parent.$broadcast('leave-tour')
        this.$parent.$parent.$emit('leave-tour')
        this.$parent.$parent.$dispatch('close-picture-inspector')
      }
    },
    gotoURL(href) {
      window.open(href)
    },
    toggleFullWidth() {
      this.$dispatch('toggleFullWidth')
    },
    next() {
      this.$parent.$parent.sandbox.app.onAction({ trigger: 'TOUR', method: 'NEXT', type: 'CTA' })
      this.core.pictureRoom.tour.hasStarted = true
    },
    prev() {
      if (this.core.pictureRoom.tour.isLast) this.core.pictureRoom.tour.isLast = false
      this.$parent.$parent.sandbox.app.onAction({ trigger: 'TOUR', method: 'PREV', type: 'CTA' })

    }
  },
  beforeDestroy() {
    if (this.fragment) this.fragment.$destroy;
  }
}