import Video from './VideoPlayers';
import UI from './UiView';
import Core from '../data/core';
import vData from '../data/video';
import Template from '../templates/root.html';
            
const minwidth = 440,
    maxwidth = 1440;

export default {
    name: 'Root',
    components: [Video, UI],
    props: [],
    template: Template,
    data() {
        return {
            core: Core,
            video: vData,
            debug: false,
            test: null  
        }
    },  
    created() {        
        this.core.viewport.unfit = screenRatio()
        // svg4everybody()  
    },
    ready() {
        // Window Event Listeners
        window.addEventListener('resize', (e) => {
            console.log("SCALING");
            this.$broadcast('scale')
            this.core.viewport.width = window.innerWidth;
            this.core.viewport.height = window.innerHeight;
            this.core.viewport.portrait = screenRatio()
            this.core.viewport.unfit = screenRatio()
            this.core.layout.hires = (window.innerWidth > maxwidth)
        });
        
        var w, h;
        let wait = setInterval(() => {       
            if(window.innerWidth != w || window.innerHeight != h) {
                console.log("SCALE");
                this.$broadcast('scale')
                this.core.viewport.width = window.innerWidth;
                this.core.viewport.height = window.innerHeight;
                this.core.viewport.portrait = screenRatio()
                this.core.viewport.unfit = screenRatio()
                this.core.layout.hires = (window.innerWidth > maxwidth)
            }

            w = window.innerWidth;
            h = window.innerHeight;
        }, 100);


        // responsive settings
        // this.$on('scale', () => {
        //     this.core.viewport.portrait = ((window.innerWidth / window.innerHeight) > 1)
        //     this.core.viewport.unfit = screenRatio()//(window.innerWidth < minwidth && this.core.viewport.portrait);
        //     this.core.layout.hires = (window.innerWidth > maxwidth)
        // })  

        let mql = window.matchMedia("(orientation: portrait)");

        mql.addListener((m) => {  
            this.core.viewport.portrait = (m.matches)
            this.core.viewport.unfit = screenRatio() //(r > window.innerHeight) // (window.innerWidth < minwidth);
        })
        this.$nextTick(() => {
            if (this.$route.name != 'root') {
                this.core.state.firstLoad.fromDeepLink = true
            }
        })   
    }
}
function screenRatio() {
    let r = window.innerHeight / window.innerWidth
    return (r > 1.1)
}
