import Template from '../templates/picture-room.html';
import build from '../pictureRoom/js/main'
import SpinLoader from './SpinLoader';
import PictureRoomInspector from './PictureRoomInspector';
import Picture from './PictureInspector';
import Help from './help';
import Notice from './notice';

export default {
  name: 'PictureRoom',
  route: {
    // canReuse(transition) {
    //   return true //this.$parent.core.reuseRoute
    // },
    activate(transition) {
      transition.next()
    },
  },
  template: Template,
  components: [PictureRoomInspector, Picture, Help, Notice, SpinLoader],
  props: ['core'],
  data() {
    return {
      sandbox: null,
      inspecting: false, // should use core
      poi: null, // preview inspector (on hover)
      hasLoaded: false,
      videoAspectSync: true,
    }
  },
  created() {
    const ro = new ResizeObserver(entries => {
      this.resizePage()
    })
    ro.observe(document.querySelector('html'));
    this.sandbox = build('#render_area', (room) => {
      this.core.pictureRoom.mounted = true
      this.hasLoaded = true
      // this.sandbox.start() // starts when loads via hash
    })
  },
  computed: {
    show() {
      return !this.core.videos.isPlaying //&& this.loaded)
    },
    highres() {
      return (this.core.layout.hires) ? '/hires' : ''
    },
    videoStateUpdate() {
      return this.core.videos.isPlaying
    },
    showMaximize() {
      // if (this.isMap && !this.core.layout.full) return true
      if (this.isMap && this.core.layout.full) return false
      return ((this.core.layout.help) || (this.core.state.current == 'inspect') || (this.inspecting)) ? false : true;
    },
    isTour() {
      // was using hasLoaded
      return this.core.pictureRoom.view === 'TOUR'
    },
    isMap() {
      return this.core.pictureRoom.view === 'MAP'
    },
    tourFirstImage() {
      return this.core.pictureRoom.tour.index === 0
    }
  },
  methods: {
    capture(e) {
      if (this.core.layout.dropdown) return
      if (this.isMap) {
        const h = window.innerWidth / 2
        const diff = h > 768 ? (h - 768) + h : h
        const outOfBounds = e.clientX > diff
        if (!this.core.layout.full && outOfBounds) return
        this.sandbox.app.controls.clickHandler()
      }
      if (this.core.pictureRoom.view === 'ROOM') {
        this.sandbox.app.controls.panelDoorHandlerClick(e)
      }
    },
    captureTouch(e) {
      if (this.core.layout.dropdown) return
      if (this.isMap) {
        const h = window.innerWidth / 2
        const diff = h > 768 ? (h - 768) + h : h
        const clientX = e.targetTouches[0].clientX
        const clientY = e.targetTouches[0].clientY
        const outOfBounds = clientX > diff
        if (!this.core.layout.full && outOfBounds) return
        this.sandbox.app.controls.HandleMapTouch(clientX, clientY)
      }
      if (this.core.pictureRoom.view === 'ROOM') {
        this.sandbox.app.controls.panelDoorHandlerTouch(e)
      }
    },
    resizePage() {
      if (this.videoAspectSync) {
        this.sandbox.app.controls.setViewport(this.core.layout.full)
      } else {
        this.sandbox.app.controls.updateViewport2()
      }
    },
    viewPicture() { // click
      if (this.poi) {
        if (this.core.layout.full) this.$emit('toggleFullWidth')
        this.inspecting = true // side page (A)
        this.core.layout.overlay = true; // white side square
        this.core.state.set('inspect'); // side page overlay (B)
      }
    },
    tourNavigate(type) {
      this.sandbox.app.onAction({ type: 'CTA', trigger: 'TOUR', method: type })
      this.core.pictureRoom.tour.hasStarted = true

      if (type === 'PREV' && this.core.pictureRoom.tour.isLast) this.core.pictureRoom.tour.isLast = false
    },
    toggleFullWidth() {
      this.$emit('toggleFullWidth')
    },
  },
  ready() {
    this.core.layout.full = true
    this.sandbox.mount([
      { name: 'WATCH', callback: (res) => console.log(res) },
      {
        name: 'VIEW_PICTURE', callback: ({ data }) => {
          this.poi = data.id
          this.viewPicture()
        }
      },
      {
        name: 'TOUR_ENDED', callback: (res) => {
          setTimeout(() => {
            this.core.pictureRoom.tour.isLast = true
          }, 1500)
        },
      },
      {
        name: 'TOUR_UPDATE', callback: (index) => {
          this.core.pictureRoom.tour.index = index
        },
      },
      {
        name: 'TOUR_PANELS_ACTIVE', callback: (direction) => {
          this.$broadcast('panels-notice', direction)
        },
      },
      {
        name: 'FOLD_DEBOUNCE', callback: (view) => {
          this.$broadcast('fold-debounce-complete', view)
        }
      }
    ])

    this.$on('back-button', () => {
      if (!this.core.layout.full) this.toggleFullWidth()
    })
    this.$on('toggleFullWidth', () => {
      this.core.layout.full = !this.core.layout.full;
      this.sandbox.app.onAction({ type: 'CTA', trigger: "VIEWPORT", method: "TOGGLE" })
    })

    this.$on('close-picture-inspector', (data) => {
      this.inspecting = false
      this.core.state.set('pictureroom');
      this.core.layout.overlay = false;
      if (this.core.layout.full) this.toggleFullWidth()
      // if (this.core.pictureRoom.tour.isLast) this.$broadcast('exit-tour')
      if (this.isMap) this.toggleFullWidth()
    })
    this.$on('skip-viewport-sync', () => {
      this.videoAspectSync = false
    })
  },
  watch: {
    'videoStateUpdate': function (canPlay) {
      if (!canPlay) {
        this.$broadcast("help", this.core.state.current);
        if (this.sandbox) this.sandbox.start()
        if (this.videoAspectSync) {
          // this.sandbox.app.controls.setViewport(this.core.layout.full)
          this.sandbox.app.controls.tweenFromVideo(() => {
            this.videoAspectSync = false
          })
        }
      }
    },
  },
  beforeDestroy() {
    // if (this.core.pictureRoom.view != 'ROOM') this.toggleFullWidth() // reset view width
    if (this.core.pictureRoom.view == 'TOUR') this.$broadcast('exit-tour')
    if (this.core.pictureRoom.view == 'MAP') this.sandbox.app.onAction({ trigger: 'TRANSITION', method: 'MAP_TOGGLE', type: 'CTA' })
    this.core.pictureRoom.view = 'ROOM'
    this.videoAspectSync = true // reset for video sync
    this.core.pictureRoom.mounted = false
    this.inspecting = false
    this.core.state.set('root');
    this.core.layout.overlay = false;
    this.sandbox.unmount()
  }
}


