import room from '../framework/index'
import createTourState from './tourController'
import createPanelState from './panelController'
import testController from './testController'
import transitionHandler from './stateControllers'

function watcher(callback) {
  return {
    subscribe: (method, resolve, param1) => method((param2) => {
      // TODO - clean params based on useage
      callback(param1 || param2) // onComplete call to instantiator
      resolve()       // end framework control lock
    })
  }
}

export default function (listeners = []) {
  // bind interaction events to framework
  room.useEvent('COUNT', testController())
  room.useEvent('TOUR', createTourState())
  room.useEvent('PANELS', createPanelState(room.globals.panels, room.globals))
  room.useEvent('TRANSITION', transitionHandler())
  room.useEvent('VIEWPORT', widthHandler())
  // expose internal events to customisable listeners
  listeners.forEach(({ name, callback }) => room.useEvent(name, watcher(callback)))
}


function widthHandler() {
  let full = true
  return {
    TOGGLE: () => {
      if (full) {
        room.renderer.domElement.classList.add('half')
        full = false
      } else {
        room.renderer.domElement.classList.remove('half')
        full = true
      }

      setTimeout(() => {
        room.controls.updateViewport2()
      }, 10)

      return Promise.resolve()
    }
  }
}