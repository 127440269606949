import pictureCollection from './pictureCollection'

const content = {
  ROOM: {
    label: `Picture Room`,
    description: [
      `The Picture Room is perhaps the most complex and theatrical space in Sir John Soane’s Museum. Designed by Soane in 1823-24, it reflects the last phase of his architectural style, a playful attempt to merge Classical and Gothic styles into a cohesive architectural language displaying both his inventiveness and his mastery of his profession. The ceiling, evoking 17th century interiors with what Soane described as 'arched canopies', and the fireplace, are particularly striking examples.`,
      `The room is not only ingenious but luxurious. The solid oak floor with a mahogany border, marble chimneypiece, mahogany dwarf bookcases inlaid with ebony and the elaborate brass columns and shelf all indicate that no expense was spared in fitting it out. `,
      `The Picture Room was designed to display three times as many pictures as would normally fit into in such a compact space. Three of the walls feature hinged panels which open to display further pictures within. In this way Soane inserted 118 paintings into a single room whereas the fledgling National Gallery, also conceived in 1824, boasted only 38 paintings in its collection.`
    ]
  },
  TOUR: {
    label: `Tours`,
    title: `Take a tour of the Picture Room`,
    description: [`The Picture Room contains some of John Soane’s greatest treasures, including masterpieces by Hogarth, Canaletto, Turner and Piranesi as well as works by his contemporaries considered ‘modern’ in his lifetime. It also presents many watercolours of Soane’s own projects by Joseph Gandy, a freelance architect and artist who captures the effects achieved in Soane’s architecture in his wonderfully varied compositions, most of them originally intended for exhibition at the Royal Academy. `,
      `Choose one of five tours each featuring a selection of the wonderful paintings in Soane’s Picture Room and learn about his life, collection, and architecture.`]
  },
  MAP: {
    label: `Picture Room`,
    title: `Take a tour of the Picture Room`,
    description: [`The Picture Room contains some of John Soane’s greatest treasures, including masterpieces by Hogarth, Canaletto, Turner and Piranesi as well as works by his contemporaries considered ‘modern’ in his lifetime. It also presents many watercolours of Soane’s own projects by Joseph Gandy, a freelance architect and artist who captures the effects achieved in Soane’s architecture in his wonderfully varied compositions, most of them originally intended for exhibition at the Royal Academy. `,
      `Choose one of five tours each featuring a selection of the wonderful paintings in Soane’s Picture Room and learn about his life, collection, and architecture.`]
  }
}


const tour0 = {
  id: `all_panels`,
  label: `Tour X`,
  title: `TEST TOUR`,
  intro: ``,
  description: [``],
  initalCamera: { x: 2.5, y: 0, z: 0 },
  sequence: ['P69', 'P79', 'P82', 'P91', 'P85', 'P74', 'P88', 'P94'] //South tour
  // sequence: ['P140', 'P126', 'P128', 'P136', 'P147'] //West tour
  // sequence: ['P42', 'P63', 'P96', 'P54', 'P44', 'P74', 'P144', 'P85'] //North tour 
}

const tour1 = {
  id: `t1`,
  label: `Tour 1`,
  title: `Soane's Life in Architecture`,
  intro: `The long life of John Soane, a bricklayer’s son who became the leading architect of his era and a knight of the realm, was dominated by architecture. Stepping into his Museum is like stepping into his mind, in which his life and work is enshrined and commemorated.  His life-long association with the Royal Academy, where he trained, and his architectural practice brought friendships not just with others in his profession but with artists, gentry, aristocracy and royalty. In the Picture Room, interspersed with celebrated paintings by Hogarth and Canaletto, are architectural drawings and portraits that introduce us to his professional and personal life and immerse us in his world. `,
  description: [`Soane started his architectural training aged 15 and was a student of architecture at the Royal Academy from 1771. He won their Gold Medal for architecture (1776) and was awarded a coveted ‘travelling studentship’ to take a Grand Tour (1778-80).  On his return he set up his own practice and married an heiress in 1784.  In 1788 he became architect to the Bank of England, a post bringing not only status but a regular professional income. Other official appointments followed: as Professor of Architecture at the RA (1806), surveyor to Chelsea Hospital (1807) and as an ‘attached’ architect to the Office of Works (1813).  His personal income was secured by his wife’s inheritance in 1790 – he wrote later that he would never have had to work again had he not wished to do so!  This fortune enabled him to rebuild 13 Lincoln’s Inn Fields as a setting for his collections. In the Picture Room views of his own projects by Joseph Michael Gandy, a gifted architectural draughtsman able to capture Soane’s architectural effects on paper, are integrated with fine art, giving architecture and painting an equal status and arranged as he left them as part of his enduring legacy.`],
  sequence: ['P81', 'P118', 'P101', 'P135', 'P134', 'P113', 'P142', 'P148', 'P87'],
  initalCamera: { x: 2.81, y: -0.8046, z: 0.083 }
}
const tour2 = {
  id: `t2`,
  label: `Tour 2`,
  title: `The Grand Tour: ‘seeing and examining the numerous and inestimable remains of Antiquity’`,
  intro: `Soane was enabled to take a Grand Tour by winning the Gold Medal for architecture at the Royal Academy in 1776. This led to an introduction to King George III who nominated him for a ‘travelling studentship’: £60 a year for three years awarded by the Academy to the most promising student in architecture and funded personally by the monarch. Soane, the bricklayer’s son from Goring-on-Thames, set out on the adventure of a lifetime in March 1778. He would always remember the day he set out for Rome as one of the most important moments of his architectural career.   `,
  description: [`Soane arrived in Rome in May 1778, writing home within a few days that he was ‘seeing and examining the numerous and inestimable remains of Antiquity’. His sketchbooks show the diligence with which he studied important ancient sites, many of which inspired his later work, including the Pantheon, the Temple of Vesta (Tivoli) and the Greek temples at Paestum. He travelled south to Naples and Sicily and also explored northern Italy including Florence and Venice. In his <span class='italic'>Memoirs</span> … (1835), Soane recalled his Grand Tour as: ‘… the most fortunate event of my life, for … I formed those connections to which I owe all the advantages I have since enjoyed’.  He was referring to the many friendships he made which led to later commissions. When Soane departed Italy on 9 May 1780 he left ‘with heart felt sorrow in firm hopes of seeing it again'.  He was never to do so.`, `When Soane created his house-museum (1808 onwards), he knew his RA students and architectural pupils might never see Rome since the Napoleonic Wars made Continental travel virtually impossible. He tried to evoke the spirit of the Grand Tour in the arrangement of his collections, so students could journey to Italy in their imaginations right here in the heart of London. `],
  sequence: ['P102', 'P66', 'P146', 'P138', 'P132', 'P32'],
  initalCamera: { x: 1.5, y: 0, z: 0 },
}
const tour3 = {
  id: `t3`,
  label: `Tour 3`,
  title: `Soane as a Collector of Contemporary Art `,
  intro: `Sir John Soane was an avid collector who sought to express not only his connoisseurship but also his personal interests through his purchases, which additionally frequently supported and advanced artists who were fellow Royal Academicians and friends. The provenance of Soane’s acquisitions was important to him. Preferably distinguished and generally significant, a painting’s previous associations added an extra layer of meaning to its placing within his House-Museum.`,
  description: [`The paintings Soane hung in his Picture Room seem an eclectic group. There are the celebrated satirical narratives by William Hogarth (1697-1764) and theatrical scenes such as that by Francis Danby (1793-1861). There are landscapes by J M W Turner (1775-1851), William Hodges (1744-1797) and Sir Augustus Wall Callcott (1779-1844). Soane bought numerous depictions of antique ruins, both real and imaginary by Giovanni Battista Piranesi (1720-1778) and Charles-Louis Clérisseau (1721-1820) and placed them in dialogue with watercolours by Joseph Michael Gandy (1771-1843) of Soane’s own realised and unrealised architectural projects. To these are added portraits by John Jackson (1778-1831) and Sir Francis Bourgeois (1753-1811). `,
    `Yet the apparently diverse paintings have significant connections. The artists were almost exclusively Soane’s near contemporaries. An 1842 <span class='italic'>Handbook to the Public Galleries of Art in and Near London</span>, notes that on display are ‘pictures by modern artists’ - one could say ‘modern art’ - rather than the celebrated art of sixteenth - and seventeenth-century Europe. Soane was also keen to promote home-grown talent. Except for the numerous works by Piranesi and Clérisseau, most of the paintings in the Picture Room are by British artists. A high proportion of these were Soane’s fellow Royal Academicians, the Academy being the nexus between Soane’s architectural education, his professional prestige and his social circle. Furthermore some of the artists such as Cosway and Turner became Soane’s life-long friends. Finally the paintings’ subjects reflect Soane’s perennial interests such as architecture, literature and the theatre. `,
    `Many of the individual paintings in the Picture Room share multiple connections, weaving meaning upon meaning until their subject, artist, exhibition at the Royal Academy, provenance and position in Soane’s collection create a complex narrative of friendship, connoisseurship, patronage, art and architecture that reflects the wider complexity of Soane’s collection.`],
  sequence: ['P108', 'P38', 'P55', 'P145', 'P143', 'P149', 'P50', 'P99'],
  initalCamera: { x: 1.5, y: 0, z: 0 },
}

const tour4 = {
  id: `t4`,
  label: `Tour 4`,
  title: `Soane’s Hogarths: A Rake’s Progress`,
  intro: `Possibly the most famous of the series’ painted by William Hogarth (1697 - 1764), <span class='italic'>A Rake’s Progress</span> (1734) was purchased by Sir John Soane in 1802 at Christies for 570 guineas. Hogarth’s eight paintings tell the story of Tom Rakewell, the son of a city merchant who inherits his father’s fortune. Hogarth presents the consequences of Tom’s moral choices (desertion, social climbing, extravagance, and the sins of the flesh) as being shame, debt, degradation and ultimately madness followed by death. Yet in typical Hogarthian style his detail, characterisation and wit convey a moral message in a humorous and palatable way. `,
  description: [`William Hogarth was one of the most talented British painters of the eighteenth century. He was particularly known for his innovative paintings in series, sequences that told a story. He himself named them ‘modern moral subjects’ and their novelty lay in the fact that they showed aspects of contemporary eighteenth-century life, rather than historical or biblical subjects. `, `These eight paintings were originally produced by Hogarth in 1734 as preparatory works for engravings published in 1735. Prospective subscribers would visit Hogarth at his studio in Leicester Fields (now Leicester Square) to view his paintings and decide whether to subscribe to a set of engravings. The engravings were highly lucrative for Hogarth. A set of prints of <span class='italic'>A Rake’s Progress</span> cost one-and-a-half guineas to subscribers or two guineas after publication. The antiquary and engraver George Vertue (1684–1756) noted that ‘daily Subscriptions came in, in fifty or a hundred pounds in a Week – there being no day but persons of fashion and Artists came to see these pictures.’ The tale of Tom Rakewell, his high life and low morals, clearly amused and interested Hogarth’s contemporaries. `],
  sequence: ['P40', 'P41', 'P42', 'P43', 'P44', 'P45', 'P46', 'P47'],
  initalCamera: { x: 1.5, y: 0, z: 0 },
}
const tour5 = {
  id: `t5`,
  label: `Tour 5`,
  title: `Soane’s Hogarths: <span class='italic'>The Humours of An Election</span>`,
  intro: `If <span class='italic'>A Rake’s Progress</span> is possibly the most famous of the painted series by William Hogarth (1697 - 1764), <span class='italic'>The Humours of an Election</span>, (1754-55), is certainly the most accomplished. Soane bought the four large oil paintings in their elaborate Rococo frames for 1,650 guineas from Christies in June 1823. The auctioneer, James Christie the Younger wittily announced that "As Returning Officer, I have the honour of declaring John Soane Esq. is the successful candidate in this warmly contested Election." The price was a high one in line with the scale and quality of the paintings: four months later Soane would purchase No. 14 Lincoln’s Inn Fields for just £1,500. `,
  description: [`<span class='italic'>The Humours of an Election</span> is a satire on the parlous state of the political nation in mid-eighteenth-century Britain, specifically parodying the 1754 Oxfordshire county election which was widely reported in the newspapers of the time and became a byword for bribery and corruption. In the 1750s votes in Britain were cast in public. Candidates would often ‘treat’ - or bribe - electors to vote for them, safe in the knowledge that the record of votes cast would show whether their bribe had been effective. `, `The party-political system was embryonic in the eighteenth-century. Governing factions were loosely divided into ‘Whig’ or ‘Tory’ with some politicians choosing to stand instead as ‘Independents’. Whigs were associated with the Protestant Succession, a policy of taxation, tolerance of religious dissenters, aristocratic landowning families and the financial interests of the wealthy middle classes. Toryism became identified with Anglicanism and the interests of small-to medium-scale country landowners.`, `Hogarth perfectly captures four key stages of the eighteenth-century electoral process and holds them up for scrutiny in so penetrating and humorous a fashion that they remain highly relevant and are often referred to today. `],
  sequence: ['P53', 'P56', 'P73', 'P78'],
  initalCamera: { x: 1.5, y: 0, z: 0 },
}

export const library = {
  content,
  tours: [tour0, tour1, tour2, tour3, tour4, tour5],
  pictureCollection
}