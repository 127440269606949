export default [
  {
    id: 'OBJ01',
    soane_id: `M1421`,
    title: `Model of the four orders of architecture`,
    artist: ``,
    date: ``,
    link: ``,
    medium: `Wood`,
    description: [`<p>From at least 1825 onwards, as Soane’s pupils reached the top of the staircase up to the Office they came face to face with this model, mounted on a balustrade. The model shows the Doric, Ionic, Corinthian and Composite orders of architecture, from left to right, illustrating their different bases, shafts, capitals and proportions. New pupils in Soane’s office usually spent their first few weeks drawing the orders and this model served as a reminder of their centrality to any architect’s training. However, this arrangement is also a typically idiosyncratic – even humorous - riff on the nature of the column, with the formality of these orders contrasting with the more freely inventive variations of the barley-twist baluster form in the balustrade below.</p><p>Instruction in the origins, history and use of the orders from Classical Greece and Rome through the Renaissance and to the present day, was a central theme of the lectures that Soane delivered at the Royal Academy as Professor of Architecture from 1806 until his death in 1837. The drawing below (SM 23/5/2) is just one of the many prepared by Soane’s pupils to illustrate the lectures. This one illustrates ‘Ionic orders as designed by the Ten Masters in Fréart's <span class='italic'>Parallèle’</span> [a celebrated French architectural treatise], set in an imaginary landscape.</p>`],
    relatedDescription: [`Soane office, Royal Academy lecture drawing to illustrate the Ionic order: the Ionic orders as designed by the Ten Masters in Fréart's <i>Parallèle</i>, set in a landscape, <i>c</i>. 1806-19`]
  },
  {
    id: 'OBJ02',
    soane_id: `M1234`,
    title: `Cast of a coffer from the Temple of  Mars Ultor, Rome,`,
    artist: ``,
    date: ``,
    link: ``,
    medium: `Plaster`,
    description: [`This large cast hangs above the entrance to the Office, suspended from nails by a complex web of copper wires, the cast itself plastered into a wooden frame on two sides. It provided Soane’s pupils with an accurate three-dimensional illustration of the mouldings around the coffers of the Temple of Mars Ultor, erected by Hadrian in the Forum of Augustus in 2 BC and dedicated to Mars the Avenger. Such casts were a vital counterpoint to published prints and one-dimensional drawings, that were the pupils’ only other source of information. Casts enabled the pupils to study light and shadow as well as form and proportion. The lecture drawing below (SM 26/3/7), a detail of the soffit of the same temple, shows flat in two dimensions the elements that the cast replicates in three dimensions.`],
    relatedDescription: [`Soane office, Royal Academy lecture drawing to illustrate decoration: detail of the soffit of the Temple of Mars Ultor, Rome, <i>c</i>. 1806 - 19`]
  },
  {
    id: 'OBJ03',
    soane_id: `M1242`,
    title: `Model of the façade of 13 Lincoln’s Inn Fields`,
    artist: ``,
    date: ``,
    link: ``,
    medium: `Wood`,
    description: [`<p>This small schematic model of the façade of Soane’s house was not, unusually, made as part of the design process, but to defend its design in court. During the construction of the façade in 1812 a prosecution was brought against Soane by the District Surveyor, William Kinnaird, alleging that Soane’s projecting ‘loggia’ on three floors, which projected 3 feet 6 inches beyond the frontages of the adjacent houses, contravened the Building Act. Soane presented this model in court, along with two others which do not survive, to illustrate that there were other existing ‘projections’ to buildings in Lincoln’s Inn Fields and that his should be permitted. He won his case, and the distinctive façade of No. 13 was constructed as planned.</p><p>The drawing below (SM Volume 83/15) is the earliest view that survives of Soane’s Office, dated 1812. It shows that this small model was displayed in the Office from that time: it can be seen perched on top of a ledge along with the other two models used in the court case.</p>`],
    relatedDescription: [`Soane office, <i>Sketch of the Upper Office 13 L.I. Fields – 1812</i>`]
  },
 
  {
    id: 'OBJ17',
    soane_id: `M1282`,
    title: `Cast of a section of egg and dart moulding`,
    artist: ``,
    date: ``,
    link: ``,
    medium: `Plaster`,
    description: [`<p>Like many forms of architectural ornament, egg and dart moulding takes its cue from the natural world. It takes the form of a prominent row of hanging eggs, with stems or spears between each one, the ‘darts’. The origins of the form are unclear, but it may represent life and death or male and female. Originating in ancient Greece but also used by the Romans, the egg and dart was one of the most widely used classical mouldings and was equally prevalent in eighteenth-century buildings inspired by the ruins of antiquity.</p><p>This cast is of the egg and dart moulding on the Temple of Castor and Pollux in the Forum, Rome, considered by many to be the most beautiful example of the Corinthian order in the world. Soane illustrated the order in his Royal Academy lectures, this drawing, SM 23/9/3, highlighting its importance to students of architecture by showing a student on a ladder, with his measuring rod, recording the capital’s proportions.</p><p> Soane’s collection includes twenty-one casts from the Temple of Castor and Pollux, more than any other building. Two are full-size casts of the cornice of the Temple and part of a capital. Drawing copies of these casts would have been an essential part of Soane’s pupils’ training.</p>`],
    relatedDescription: [`Soane office, Royal Academy lecture drawing to illustrate the use of the Corinthian order: view of a student on a ladder, with a measuring rod, measuring the Corinthian order of the Temple of Castor and Pollux (Temple of Jupiter Stator), Rome, undated`]
  },
  {
    id: 'OBJ04',
    soane_id: `M1305`,
    title: `Cast of a relief of two winged <i>erotes</i> and foliage`,
    artist: ``,
    date: ``,
    link: ``,
    medium: `Plaster`,
    description: [`<p>This wooden frame contains plaster casts of two of the decorative panels on the base of an ancient Roman candelabrum in the collection of the Borghese family in Rome. The panels are decorated with half-figure <i>erotes</i> (winged Gods associated with love), whose wings and legs gently merge into the foliage which surrounds them. A complete cast of the lower part of the candelabrum is displayed in the Sepulchral Chamber in the basement of Sir John Soane’s Museum. In later iconography such figures became the <i>putti</i> or cherubs so widely used in Renaissance decoration.</p><p> Soane set great store by drawing the remains of Antiquity as a means of forming a pupil’s taste. His cast collection was meant to aid this essential element of the pupils’ education. The image below (SM Volume 83/28) is a drawing of a panel from the same candelabrum, drawn in 1811 by George Bailey. Bailey entered Soane’s Office as a pupil in 1806. After his five years’ pupillage he remained as Soane’s assistant and, after Soane’s death in 1837, became the Museum’s first Curator, a post he held until his death in 1860.</p>`],
    relatedDescription: [`George Bailey, drawing of a decorative panel cast from the base of an ancient Roman candelabrum in the collection of the Borghese family in Rome, 1811`]
  },
  {
    id: 'OBJ05',
    soane_id: `M1331`,
    title: `Cast of a fragment of an antique votive relief`,
    artist: ``,
    date: ``,
    link: ``,
    medium: `Plaster`,
    description: [`<p>This cast was taken from an object originally in Charles Townley’s collection of classical antiquities, purchased by the British Museum in 1805 and still in their collection today. The original is made of terracotta and dates to between 50BC and 100AD. The relief shows two <span class='italic'>canephorae</span> making votive offerings, on either side of six-tiered candelabrum. In ancient times a <span class='italic'>canephora</span> was a basket carrier, a female figure supporting a basket – or sometimes a cushion – on her head. A votive offering was an object displayed or deposited in a temple or sacred place for religious purposes. Like the egg and dart moulding which can be seen ornamenting this cast, <span class='italic'>canephorae</span> were an extremely popular form of decoration in the late eighteenth century. The second tier of the delicate candelabrum between the figures is supported by sphinx-like creatures and it is surmounted by a pinecone, a symbol of fertility and regeneration.</p><p>The presentation drawing (SM Volume 111/59) of a design for a monument to John Holles, 1st Duke of Newcastle  (1662-1711), designed by the architect James Gibbs (1682-1754), in Soane’s collection, also shows two votive figures, set on angled pedestals carved  with gadrooning and acanthus ornament.</p>`],
    relatedDescription: [`James Gibbs, Early design for the monument to John Holles, 1st Duke of Newcastle, <i>c</i>.1721`]
  },
  {
    id: 'OBJ06',
    soane_id: `X18`,
    title: `Cast of a relief of a cinerary vase or urn`,
    artist: ``,
    date: ``,
    link: ``,
    medium: `Plaster`,
    description: [`<p>This piece is a cast of a relief of a cinerary urn – an urn intended to hold cremated human ashes. The urn has two handles in the form of panthers, shown crouching as if ready to spring. The urn is decorated with typical examples of antique ornament: foliage around the foot, gadrooning on the body and a Vitruvian scroll just below the neck. Given the purpose of a cinerary urn it is unsurprising that again the pinecone, the symbol of regeneration, should be atop the lid, indicating the potential for re-birth.</p><p>During the 2022-23 restoration of Soane’s Drawing Office, bills, architectural drawings and watercolours provided the evidence used to re-create the arrangement of works of art as it was when Soane died in 1837. The watercolour below (SM P113), of 1822 shows this cast <i>in situ</i> in the place it still occupies today.</p>`],
    relatedDescription: [`Joseph Michael Gandy, <i>Views of parts of the Museum &c. In Lincoln’s Inn Fields,</i> 1822`]
  },
  {
    id: 'OBJ07',
    soane_id: `M1348`,
    title: `Cast of part of an <span class='italic'>anta</span> capital`,
    artist: ``,
    date: ``,
    link: ``,
    medium: `Plaster`,
    description: [`<p>The source of this cast is the Erechtheion (421-406 BC) on the Acropolis in Athens, one of the most celebrated of all Greek temples. It is a cast of a part of an <span class='italic'>anta</span> capital, one not conforming to the main order of the building but usually found on a corner or on a projection from the building. The fragment is ornamented with, from the bottom, scrolling alternating lotus and palmette ornaments, bead and reel moulding, egg and dart moulding, another bead and reel and a leaf and dart moulding along the top edge.</p><p>As the British Museum acquired an original <span class='italic'>anta</span> capital of this form, from the east porch of the Erechtheion, in 1816, it is possible that this cast was made in London; it was certainly acquired by Soane in England.</p><p>It was Soane’s inspiration for a similar <span class='italic'>anta</span> capital he designed for use at the Bank of England. Also in Soane’s collection is a very fine plaster model of the Erechtheion SM MR24, one of twenty models by François Fouquet that Soane purchased in 1834. Dating to <span class='italic'>c</span>.1800-1834 the models present the ruins of antiquity reconstructed as Fouquet thought they would have been when first built, in their …’pristine condition, just as they left the hands of the architect’ and so are not always accurate when judged by modern archaeological standards.</p>`],
    relatedDescription: [`François Fouquet, Model of the Erechtheion on the Acropolis, Athens, 'restored', <i>c</i>.1800-1834`]
  },  
  {
    id: 'OBJ08',
    soane_id: `M1375`,
    title: `Model for the Bank of England, London, north  (Lothbury) front`,
    artist: ``,
    date: ``,
    link: ``,
    medium: `Painted wood`,
    description: [`<p>The Bullion Gateway was one of a pair of entrances, of identical design, on the Lothbury front of the Bank of England. Soane was Architect to the Bank of England for 45 years, a project he called ‘the pride and boast of my life’. This model is almost as built, with a pediment over the banded rustication and an austere treatment of the parapet wall; Soane has employed short, fluted pilasters, each surmounted with an antefix.</p><p>This record drawing below of the Lothbury façade, as built, (SM 12/1/2) shows one of the two identical gateways, the Bullion and Workhouse Gates. When built in 1796-7 Soane’s work on the façade was both praised and derided. One critic disliked the incised pilasters so much that he claimed, in an anonymous scurrilous pamphlet, that they were ‘scor’d like loins of pork’.</p><p>Whether one liked or disliked Soane’s style, his practice was rigorous. He was convinced that ‘many of the most serious disappointments that attend those who build would be avoided if models were previously made of the edifices proposed to be raised’, and to this end forty-four models of the Bank of England were prepared. When he closed his office at the Bank of England on his retirement in 1833 many of them were placed in the Drawing Office.</p>`],
    relatedDescription: [`Soane office, Bank of England, London: record drawing of the wall from Lothbury, showing the east corner and the gatehouse, undated`]
  },
  {
    id: 'OBJ09',
    soane_id: `M1372`,
    title: `Cast of a section of egg and dart moulding, in a timber frame`,
    artist: ``,
    date: ``,
    link: ``,
    medium: `Plaster`,
    description: [`<p>This cast is one of a number in the Office that illustrate different forms of the egg and dart moulding at different scales and in varying depths, illustrating its versatility and serving as a lesson for Soane’s pupils in its potential use.</p><p>Soane’s pupils were set numerous exercises in draughtsmanship and one of those recorded in the Office Day Books  was ‘drawing shadows’, requiring works of art to be illuminated with strong directional lighting to create shadows, using lamps or candles.  When this cast was taken down for conservation in 2022 the frame was found to have been burned at one corner.  In the absence of any evidence of a fire in the Office at any point in its history, it is possible that the cast’s frame fell victim to an accident during such an exercise.</p><p>The drawing below (SM Volume 83/19) is just such an exercise in the drawing of the shadows cast by two works that are still in the collection, cinerary urn SM M373 topped by cinerary vase SM M932. The drawing was made by Soane’s pupil George Underwood who has signed it lower right <span class='italic'>G.U. Jan. 30th 1811</span>.  Underwood made no entry in the Office Day Book on 30 January but from 26 to 29 January he was ‘Drawing vase’ for Mr Soane, surely this very accomplished watercolour.</p>`],
    relatedDescription: [`George Underwood, view of cinerary vase and urn,<i> January 30th 1811</i>`]
  },
  {
    id: 'OBJ10',
    soane_id: `M1367`,
    title: `Model for the Greek Doric vestibule at Bentley Priory, Stanmore, Middlesex`,
    artist: ``,
    date: ``,
    link: ``,
    medium: `Painted wood`,
    description: [`<p>In 1798 Soane designed a Greek Doric vestibule with a vaulted ceiling supported on four massive Tuscan columns to be added to the north front of Bentley Priory for the Hon. John James Hamilton, 1st Marquis of Abercorn (later 9th Earl of Abercorn). This comparatively simple model conveys its principal features and the sources of light (to be admitted through large arched windows filled with stained glass). It was a design intended to create a feeling of monumental scale in a small space, heralding the start of a carefully designed route through the house.</p><p>This model appears originally to have had a base, but this must have been removed when Soane hung it on the ceiling of his office, an unusual placing which enables the viewer to see the interior, which would otherwise be impossible to view. The drawing shown here, (SM Volume 59/91), gives a sense of the effects of light and shade in the vestibule.</p>`],
    relatedDescription: [`Soane office, Bentley Priory, Stanmore: interior perspective of the vestibule,<i> May 1st 1798</i>`]
  },
  {
    id: 'OBJ11',
    soane_id: `M1400`,
    title: `Cast of the face of Emperor Lucius Verus`,
    artist: ``,
    date: ``,
    link: ``,
    medium: `Plaster`,
    description: [`<p>This cast is of the face only of a marble bust of Lucius Verus (today in the Louvre Museum, Paris, MR 550; Ma 1170), dating from <i>c</i>.180 AD and excavated at the site of his villa in <span class='italic'>Acqua Traversa</span>, Rome, in 1674. The original was acquired by the French from the Borghese collection in 1807. This cast may have been taken either in Rome or in Paris: sadly, we do not know when or how it entered Soane’s collection.</p><p>Busts were often supported on shafts like the one below (SM M1016) whose carved tendrils and branches spring from two central stems which rise in opposite directions from a meeting point below the present bottom. A shaft in just this style supports the bust of Lucius Verus in the Canopus of the Villa Torlonia-Albani in Rome.</p><p>Lucius Verus was Roman Emperor from 161-169 AD, ruling alongside his adoptive brother Marcus Aurelius, and this cast of his distinctive bearded face is strikingly displayed at the centre of the north wall of Soane’s Office. He received his education from the celebrated <span class='italic'>grammaticus</span> [teacher] Marcus Cornelius Fronto and he is reported to have been an excellent student, which may be why Soane placed him so prominently in the space where his own pupils received their architectural education.</p>`],
    relatedDescription: [`Section of a Roman enriched shaft with ivy leaves, berries, tendrils and branches, probably Hadrianic (<i>c</i>.117-138 AD)`]
  },
  {
    id: 'OBJ12',
    soane_id: `UNKNOWN_ID`,
    title: `Soane Office Day Book and Quill`,
    artist: ``,
    date: ``,
    link: ``,
    medium: `Book`,
    description: [`<p>Lying open on the desk is one of the Office Day Books. In accounting, Day Books were in common use as far back as the 16th century, a ‘day book’ being synonymous with a ‘journal’ and providing a chronological account of money coming in and out.  However, in Soane’s office the Day Book had a broader function, recording the entirety of the daily business of the Office. His apprentices and clerks would enter the time they arrived and the work they were doing, with Soane sometimes adding to the entries in his own hand. Some financial transactions were also recorded, which would later be entered into a ledger or Journal (one job done by Soane’s pupils was to reconcile the two). Day Books for the Soane Office survive for an almost unbroken run of 45 years, providing an extraordinary window into his business practice.</p><p>Alongside the Day Book is an ink-stained quill pen. Quills were bought in bulk (500 at a time for a few shillings), the feathers presumably already washed, dried and trimmed of much of the feather itself. The tip then had to be shaped and cut to form a nib. One of Soane’s surviving drawing instruments is a quill-cutter, so this must have been a routine part of office life. The surviving quills are a tangible and evocative link to the many Soane pupils who passed through the office and their survival a miracle of serendipity.</p>`],
    relatedDescription: [``]
  }
]