// *
// * Soane Object Collection
// * with URL lookup table

import sarcophagus from '../templates/content/sarcophagus.html'
import belzoniPortrait from '../templates/content/belzoni-portrait.html'
import fragmentX73 from '../templates/content/fragment-x73.html'
import fragmentX59 from '../templates/content/fragment-x59.html'
import belzoniNarrative from '../templates/content/belzoni-narrative.html'
import belzoniPlate from '../templates/content/belzoni-plate.html'
import belzoniMap from '../templates/content/belzoni-map.html'
import sarcophagusInvite from '../templates/content/sarcophagus-invite.html'
import sarcophagusBill from '../templates/content/sarcophagus-bill.html'
import tivoliVesta from '../templates/content/tivoli-scan--vesta.html'
import tivoliVesta2 from '../templates/content/tivoli-scan--vesta2.html'
import raLectureVesta from '../templates/content/RA-lecture--vesta.html'
import soanePortrait from '../templates/content/soane-portrait.html'
import raLectureTivoli from '../templates/content/RA-lecture--tivoli.html'
import capriccioDrawing from '../templates/content/capriccio--drawing.html'
import tivoliBankOfEngland from '../templates/content/tivoli-bank-of-england.html'
import bankOfEngland from '../templates/content/bank-of-england--model.html'
import goldMedal from '../templates/content/gold-medal.html'
import pompeii from '../templates/content/pompeii.html'
import pompeiiFragment from '../templates/content/pompeii-fragment.html'
import soanePage39 from '../templates/content/soane-page-39.html'
import raLecturePompeii from '../templates/content/RA-lecture--pompeii.html'
import isisIllustration from '../templates/content/temple-of-isis--illustration.html'
import isisDrawing from '../templates/content/temple-of-isis--drawing.html'
import courts from '../templates/content/courts.html'
import courtsPlan from '../templates/content/courts--plan.html'
import courtsWatercolour from '../templates/content/courts--watercolour.html'
import courtsKingsBench from '../templates/content/courts--kings-bench.html'

import PointClouds from './pointclouds'

const path = '/assets/img/soane-objects/',
    type = '.jpg'
const lookup = {
    pid_00: 'sarcophagus-of-king-seti',
    pid_01: 'giovanni-belzoni-portrait',
    pid_02: 'fragment-x73',
    pid_03: 'fragment-x59',
    pid_04: 'frontispiece-from-expedition',
    pid_05: 'tomb-of-seti-illustration',
    pid_06: 'frontispiece-from-tomb-map',
    pid_07: 'invitation',
    pid_08: 'archive-bill',
    pid_09: 'temple-of-vesta-cork-model',
    pid_10: 'temple-of-vesta-fouquet-model',
    pid_11: 'temple-of-vesta-drawing',
    pid_12: 'john-soane-portrait',
    pid_13: 'corinthian-capital-drawing',
    pid_14: 'corinthian-colonnade-drawing',
    pid_15: 'bank-of-england-drawing',
    pid_16: 'bank-of-england-model',
    pid_17: 'john-soane-medal',
    pid_18: 'pompeii-model',
    pid_19: 'stucco-fragment',
    pid_20: 'john-soane-sketchbook-page',
    pid_21: 'roman-tombs-drawing',
    pid_22: 'temple-of-isis-illustration',
    pid_23: 'temple-of-isis-drawing',
    pid_24: 'law-courts-model',
    pid_25: 'law-courts-plan-drawing',
    pid_26: 'court-of-chancery',
    pid_27: 'kings-bench-court',
    pid_28: 'sarcophagus-detail',
}

export default {
    find(collection, item) {
        let id = '';
        Object.keys(lookup).forEach((name) => {
            if (lookup[name] == item) {
                id = name
            }
        });
        if (id.length > 0) {
            return this.collection[collection].filter(obj => id == obj.id)[0]
        } else {
            throw new Error(`${item} is not in the lookup table. You must pass id with urlify method.`)
        }
    },
    getThumb(section, title) {
        let pid;
        Object.keys(lookup).forEach(key => {
            if (lookup[key] == title) pid = key
        });
        return this.collection[section].filter((item) => {
            if (pid == item.id) return item
        })
    },
    getRelated(collection, group, omit) {
        let store = []
        this.collection[collection].forEach((piece) => {
            if (piece.group == group && piece.id != omit) {
                store.push(piece)
            }
        })
        return store
    },
    urlify(id) {
        return lookup[id]
    },
    getPid(url) {
        let seek = null
        Object.keys(lookup).forEach((key, index) => {
            if (lookup[key] === url) seek = key
        })
        return seek
    },
    help: {   
        root: "Click a room name above to start your journey..",
        section: "Drag left or right to explore the room.",
        pointcloud: "Rotate: drag | Pan: arrow keys | Zoom: scroll",
        detail: "inspector",
        pictureroom: "Take a tour, or Explore the room yourself using drag and zoom.",
        drawingoffice: "Drag left or right to explore the room.",
        picturemap: "Pan and zoom to navigate around. Click on images to show more info"
    },
    collection: {
        sepulchral: [
            {
                pointcloud: PointClouds['pid_00'],
                id: 'pid_00',
                smid: 'M470',
                type: 'Scanned Object',
                label: "Sarcophagus",
                title: "Sarcophagus of King Seti I",
                dimension: "81.3cm high x 284.4cm long x 111.8cm wide, sides between 4.5 and 11.4 cm thick",
                facts: {
                    material: "Alabaster",
                    madeby: "Unknown",
                    date: "circa 1370 BC",
                    aquired: "1824",
                    link: "http://collections.soane.org/object-m470"
                },
                img: {
                    thumb: path + '_thumbs/' + 'M470' + type,
                    src: path + 'M470' + type,
                    w: 1280,
                    h: 612
                },
                related: ["pid_02", "pid_03", "pid_04", "pid_05", "pid_06", "pid_07", "pid_08"],
                summary: "The magnificent alabaster sarcophagus of King Seti I.",
                template: sarcophagus
            }, {
                pointcloud: PointClouds['pid_28'],
                id: 'pid_28',
                smid: 'M470',
                type: 'Scanned Object',
                label: "Sarcophagus detail",
                title: "Detail of Sarcophagus of King Seti I",
                dimension: "81.3cm high x 284.4cm long x 111.8cm wide, sides between 4.5 and 11.4 cm thick",
                facts: {
                    material: "Alabaster",
                    madeby: "Unknown",
                    date: "circa 1370 BC",
                    aquired: "1824",
                    link: "http://collections.soane.org/object-m470"
                },
                img: {
                    thumb: path + '_thumbs/' + 'M470' + type,
                    src: path + 'M470' + type,
                    w: 1280,
                    h: 612
                },
                related: ["pid_02", "pid_03", "pid_04", "pid_05", "pid_06", "pid_07", "pid_08"],
                summary: "The magnificent alabaster sarcophagus of King Seti I.",
                template: sarcophagus
            }, {
                id: 'pid_01',
                smid: 'P162',
                type: 'Image',
                label: "Portrait of Giovanni Belzoni",
                title: "Portrait of Giovanni Belzoni, the Egyptian explorer, 1824",
                summary: null,
                dimension: '44cm high x 35cm wide',
                img: {
                    thumb: path + '_thumbs/' + 'P162' + type,
                    src: path + 'P162' + type,
                    hir: path + '_hires/' + 'P162' + type,
                    w: 1980,
                    h: 2556
                },
                template: belzoniPortrait
            }, {
                id: 'pid_02',
                smid: 'X73',
                type: 'Fragment',
                label: "Lid fragment",
                title: "Lid fragment",
                dimension: "(of Case) – 60cm high x 132cm wide x 13cm deep",
                img: {
                    thumb: path + '_thumbs/' + 'M470-LID' + type,
                    src: path + 'M470-LID' + type,
                    hir: path + '_hires/' + 'M470-LID' + type,
                    w: 1980,
                    h: 1157
                },
                summary: "Fragments from the lid of Seti’s sarcophagus.",
                template: fragmentX73
            }, {
                id: 'pid_03',
                smid: 'X59',
                type: 'Fragment',
                label: "Lid fragment",
                title: "Lid fragment",
                dimension: "(of case) – 17.7cm high x 27.5cm wide x 14.8cm deep",
                img: {
                    thumb: path + '_thumbs/' + 'X59' + type,
                    src: path + 'X59' + type,
                    hir: path + '_hires/' + 'X59' + type,
                    w: 1980,
                    h: 1290
                },
                summary: "Fragments of the sarcophagus lid presented to the Museum in 1910.",
                template: fragmentX59
            }, {
                id: 'pid_04',
                smid: '1675',
                type: 'Frontispiece',
                label: "Frontispiece and title page",
                title: "Frontispiece and title page from <span class=\"italic\">Narrative of the operations and recent discoveries... in Egypt and Nubia</span>.",
                dimension: '29cm high x 23cm wide',
                img: {
                    thumb: path + '_thumbs/' + 'Belzoni-Narative' + type,
                    src: path + 'Belzoni-Narative' + type,
                    hir: path + '_hires/' + 'Belzoni-Narative' + type,
                    w: 1980,
                    h: 1290
                },
                summary: "Frontispiece of Belzoni, taken from his book about his discoveries.",
                template: belzoniNarrative
            }, {
                id: 'pid_05',
                smid: '1674',
                type: 'Illustration',
                label: "Illustration of the tomb of Seti I",
                title: "Illustration of the tomb of Seti I from <span class='italic'>Plates illustrative of the researches... in Egypt and Nubia</span>.",
                dimension: '54cm high x 73cm wide',
                img: {
                    thumb: path + '_thumbs/' + 'Belzoni-Narrative-Plate' + type,
                    src: path + 'Belzoni-Narrative-Plate' + type,
                    hir: path + '_hires/' + 'Belzoni-Narrative-Plate' + type,
                    w: 1980,
                    h: 1447
                },
                summary: "Lithographic plate showing the location of Seti’s sarcophagus within his tomb.",
                template: belzoniPlate
            }, {
                id: 'pid_06',
                smid: '3947',
                type: 'Frontispiece',
                label: "Frontispiece (map) and title page, discovered by G. Belzoni.",
                title: "Frontispiece (map) and title page of <span class='italic'>Description of the Egyptian tomb, discovered by G. Belzoni</span>",
                dimension: '21cm high x 14cm wide',
                img: {
                    thumb: path + '_thumbs/' + 'Belzoni-Description' + type,
                    src: path + 'Belzoni-Description' + type,
                    hir: path + '_hires/' + 'Belzoni-Description' + type,
                    w: 1980,
                    h: 1177
                },
                summary: "Pamphlet and map from Belzoni’s 1821 exhibition.",
                template: belzoniMap
            }, {
                id: 'pid_07',
                smid: "Archives Private Correspondence XIV.G.5",
                type: '',
                label: "Invitation",
                title: "Invitation to one of three parties held to celebrate the Sarcophagus",
                dimension: "8cm high x 13cm wide",
                img: {
                    thumb: path + '_thumbs/' + 'Sarcophagus-Viewing-Invitation' + type,
                    src: path + 'Sarcophagus-Viewing-Invitation' + type,
                    hir: path + '_hires/' + 'Sarcophagus-Viewing-Invitation' + type,
                    w: 1980,
                    h: 1282
                },
                summary: "Invitation from 1825 to view the sarcophagus by lamplight at the Museum.",
                template: sarcophagusInvite
            }, {
                id: 'pid_08',
                smid: "Archives 7/7/46",
                type: 'Bill and receipt',
                label: "Lighting Bill",
                title: "Bill from William Collins for the lighting for the sarcophagus parties",
                dimension: '31cm high x 22cm wide',
                img: {
                    thumb: path + '_thumbs/' + 'Sarcophagus-Viewing-Bill' + type,
                    src: path + 'Sarcophagus-Viewing-Bill' + type,
                    hir: path + '_hires/' + 'Sarcophagus-Viewing-Bill' + type,
                    w: 1980,
                    h: 2649
                },
                summary: "Bill showing how Soane arranged lighting to create a theatrical atmosphere around the sarcophagus.",
                template: sarcophagusBill
            }
        ],
        modelroom: [
            {
                pointcloud: PointClouds['pid_09'],
                id: 'pid_09',
                smid: 'MR2',
                group: 'tivoli',
                type: 'Scanned Object',
                label: 'Temple of Vesta (Cork)',
                title: 'Cork Model of the Temple of Vesta, Tivoli',
                dimension: '39.5cm high x 52.3cm wide x 51.3cm deep',
                facts: {
                    material: "Cork",
                    madeby: "Giovanni Altieri",
                    date: "1770s",
                    aquired: "1804",
                    link: "http://collections.soane.org/object-mr2"
                },
                img: {
                    thumb: path + '_thumbs/' + 'MR2' + type,
                    src: path + 'scan-vesta_cork' + type,
                    w: 269,
                    h: 244
                },
                gps: {
                    location: [12.802226, 41.959817],
                    zoom: 15
                },
                related: ["pid_13", "pid_14", "pid_15", "pid_16"],
                summary: "One Soane’s favourite buildings, modelled in cork by Giovanni Altieri.",
                template: tivoliVesta
            }, {
                pointcloud: PointClouds['pid_10'],
                id: 'pid_10',
                smid: 'MR13',
                group: 'tivoli',
                type: 'Scanned Object',
                label: "Temple of Vesta (Plaster)",
                title: 'Plaster Model of the Temple of Vesta, Tivoli',
                summary: 'A finely detailed Plaster of Paris model, by François Fouquet.',
                dimension: '27cm high x 19.4cm wide x 19.4cm deep',
                img: {
                    thumb: path + '_thumbs/' + 'fouquet-model' + type,
                    src: path + 'scan-fouquet' + type,
                    w: 193,
                    h: 287
                },
                gps: {
                    location: [12.802226, 41.959817],
                    zoom: 15
                },
                facts: {
                    material: "Plaster of Paris",
                    madeby: "François Fouquet (1787-1870)",
                    date: "c.1800-30",
                    aquired: "1834, bought from the architect Edward Cresy",
                    link: "http://collections.soane.org/object-mr13"
                },
                related: ['pid_12', 'pid_13', 'pid_17'],
                template: tivoliVesta2
            }, {
                id: 'pid_11',
                smid: '19/7/4',
                group: 'tivoli',
                type: 'Drawing',
                label: "Temple of Vesta (Cork)",
                title: "RA Lecture Drawings of Roman buildings: Temple of Vesta, Tivoli",
                dimension: '61cm high x 95.5cm wide',
                img: {
                    thumb: path + '_thumbs/' + '19_7_4' + type,
                    src: path + '19_7_4' + type,
                    hir: path + '_hires/' + '19_7_4' + type,
                    w: 1980,
                    h: 1297
                },
                summary: "A drawing of the Temple of Vesta, by one of Soane’s pupils.",
                template: raLectureVesta
            }, {
                id: 'pid_12',
                smid: 'P228',
                group: '',
                type: 'Portrait',
                label: "Portrait of John Soane",
                title: 'Portrait of Sir John Soane aged 51, by William Owen, 1804',
                dimension: '108cm high x 87cm wide',
                gps: {
                    location: [12.802226, 41.959817],
                    zoom: 10
                },
                img: {
                    thumb: path + '_thumbs/' + 'P228' + type,
                    src: path + 'P228' + type,
                    hir: path + '_hires/' + 'P228' + type,
                    w: 1980,
                    h: 2531
                },
                summary: "A portrait of Soane, painted in 1804, looking at an illustration of the Temple of Vesta.",
                template: soanePortrait
            }, {
                id: 'pid_13',
                smid: '25/6/6',
                group: 'tivoli',
                type: 'Drawing',
                title: "RA Lecture Drawings to illustrate fancy orders",
                label: "RA Lecture Drawings to illustrate fancy orders",
                dimension: '68cm high x 44.5cm wide',
                transition: { x: -0.6329208439549345, y: 0.1833806332108746, z: -0.4596736649355852 },
                img: {
                    thumb: path + '_thumbs/' + '25_6_6' + type,
                    src: path + '25_6_6' + type,
                    hir: path + '_hires/' + '25_6_6' + type,
                    w: 1980,
                    h: 2995
                },
                gps: {
                    location: [12.802226, 41.959817],
                    zoom: 15
                },
                summary: "Combination of classical styles in a Royal Academy lecture drawing.",
                template: raLectureTivoli
            }, {
                id: 'pid_14',
                smid: 'Adam volume 56/120',
                group: 'tivoli',
                type: 'Drawing',
                label: "Capriccio showing a ruined circular colonnade of the Corinthian order",
                title: "<span class='italic'>Capriccio</span> showing a ruined circular colonnade of the Corinthian order",
                dimension: '27.2cm high x 20cm wide',
                transition: { x: 0.4495872132239076, y: 0.7109353762836919, z: 0.5011186675660044 },
                img: {
                    thumb: path + '_thumbs/' + 'Adam_56_120' + type,
                    src: path + 'Adam_56_120' + type,
                    hir: path + '_hires/' + 'Adam_56_120' + type,
                    w: 1980,
                    h: 2605
                },
                summary: "Imaginary scene (capriccio) of the classical buildings from different locations.",
                template: capriccioDrawing
            }, {
                id: 'pid_15',
                smid: 'P118',
                group: 'tivoli',
                type: 'Drawing',
                label: "The Bank of England, Perspective of the North-West 'Tivoli' corner",
                title: "The Bank of England, Perspective of the North-West 'Tivoli' corner",
                dimension: '64cm high x 85cm wide',
                transition: { x: -0.4378954523947232, y: 5.033618295732596e-17, z: 0.6957136464049795 },
                img: {
                    thumb: path + '_thumbs/' + 'P118' + type,
                    src: path + 'P118' + type,
                    hir: path + '_hires/' + 'P118' + type,
                    w: 1980,
                    h: 1535
                },
                summary: "Perspective of Soane’s ‘Tivoli’ corner at the Bank of England.",
                template: tivoliBankOfEngland
            }, {
                id: 'pid_16',
                smid: 'MP225',
                group: 'tivoli',
                type: 'Model',
                label: "Model of the Bank of England, 'Tivoli' corner",
                title: "Model of the Bank of England, 'Tivoli' corner",
                dimension: '61.5cm high x 50cm wide x 25cm deep',
                transition: { x: -0.8030970223037301, y: 5.033618295732594e-17, z: -0.17551375384559878 },
                img: {
                    thumb: path + '_thumbs/' + 'MP225' + type,
                    src: path + 'MP225' + type,
                    hir: path + '_hires/' + 'MP225' + type,
                    w: 1980,
                    h: 1405
                },
                summary: "A painted wood model of the Bank of England’s Tivoli Corner.",
                template: bankOfEngland
            }, {
                id: 'pid_17',
                smid: 'SDR21.3',
                group: 'tivoli',
                type: 'Coin',
                label: "Gold Medal",
                title: "Gold Medal",
                dimension: '6.3cm diameter',
                transition: { x: -0.3210452855333837, y: 2.3410987575584093e-17, z: -0.20762102784731443 },
                img: {
                    thumb: path + '_thumbs/' + 'Gold-Medal-back' + type,
                    src: path + 'Gold-Medal-back' + type,
                    hir: path + '_hires/' + 'Gold-Medal-back' + type,
                    collection: [{ src: `${path}_hires/Gold-Medal-front${type}` }, { src: `${path}_hires/Gold-Medal-back${type}` }],
                    w: 1980,
                    h: 1464
                },
                summary: "Presented to Soane for his ‘essential services to Architecture’.",
                template: goldMedal
            }, {
                pointcloud: PointClouds['pid_18'],
                id: 'pid_18',
                smid: 'MR1',
                group: 'pompeii',
                type: 'Scanned Object',
                label: "Model of Pompeii in 1820",
                title: "Model of Pompeii in 1820",
                summary: "Showing the city in 1820, following its partial excavation",
                dimension: '27cm high x 231cm wide x 248cm deep',
                facts: {
                    material: "Cork",
                    madeby: "Domenico Padiglione",
                    date: "circa 1820",
                    aquired: "1826",
                    link: "http://collections.soane.org/object-mr1"
                },
                related: ["pid_19", "pid_20", "pid_21", "pid_22", "pid_23"],
                img: {
                    thumb: path + '_thumbs/' + 'pompeii-model' + type,
                    src: path + 'pompeii' + type,
                    w: 308,
                    h: 207
                },
                gps: {
                    location: [14.498934, 40.746157],
                    zoom: 15
                },
                template: pompeii
            }, {
                id: 'pid_19',
                smid: 'L130',
                group: 'pompeii',
                type: 'Fragment',
                label: "Fragment L130",
                title: "Small piece of stucco from Pompeii",
                dimension: '5.5cm high x 6cm wide x 1.8 to 4 cm deep',
                img: {
                    thumb: path + '_thumbs/' + 'L130' + type,
                    src: path + 'L130' + type,
                    hir: path + '_hires/' + 'L130' + type,
                    w: 1980,
                    h: 1607
                },
                summary: "Small piece of stucco from Pompeii.",
                template: pompeiiFragment
            }, {
                id: 'pid_20',
                smid: 'volume 39/25v-26r',
                group: 'pompeii',
                type: '',
                label: "Pages from one of Soane’s sketchbooks showing a Villa near the Temple, Pompeii",
                title: "Pages from one of Soane’s sketchbooks showing a Villa near the Temple, Pompeii",
                dimension: '20.1cm x 13.4cm',
                img: {
                    thumb: path + '_thumbs/' + 'vol39_25v-26r' + type,
                    src: path + 'vol39_25v-26r' + type,
                    hir: path + '_hires/' + 'vol39_25v-26r' + type,
                    w: 1980,
                    h: 1385
                },
                summary: "Soane’s sketch of a villa in Pompeii.",
                template: soanePage39
            }, {
                id: 'pid_21',
                smid: '20/4/7',
                group: 'pompeii',
                type: 'Drawing',
                label: "RA Lecture Drawing of Roman tombs",
                title: "RA Lecture Drawing of Roman tombs: Pompeii: Street of the Tombs",
                dimension: '70.5cm high x 125.4cm wide',
                transition: { x: 0.2425167915495587, y: 0.35740954728383123, z: 0.9519714319406974 },
                img: {
                    thumb: path + '_thumbs/' + '20_4_7' + type,
                    src: path + '20_4_7' + type,
                    hir: path + '_hires/' + '20_4_7' + type,
                    w: 1980,
                    h: 1131
                },
                summary: "Drawing of Pompeii’s Street of the Tombs for a lecture at the Royal Academy.",
                template: raLecturePompeii
            }, {
                id: 'pid_22',
                smid: '3946',
                group: 'pompeii',
                type: 'Illustration',
                label: "Excavation of the Temple of Isis from Campi Phlegræi",
                title: "Excavation of the Temple of Isis from <span class='italic'>Campi Phlegræi</span> by Sir William Hamilton",
                dimension: '21.3cm high x 39.4cm wide',
                img: {
                    thumb: path + '_thumbs/' + 'Hamilton-W-Campi-Phlegraei-Pl-41' + type,
                    src: path + 'Hamilton-W-Campi-Phlegraei-Pl-41' + type,
                    hir: path + '_hires/' + 'Hamilton-W-Campi-Phlegraei-Pl-41' + type,
                    w: 1980,
                    h: 1144
                },
                summary: "Illustration depicting the excavation of the Temple of Isis.",
                template: isisIllustration
            }, {
                id: 'pid_23',
                smid: '45/3/4',
                group: 'pompeii',
                type: 'Drawing',
                label: "Temple of Isis, measured drawing",
                title: "Temple of Isis, measured drawing",
                dimension: '74.5cm high x 48.4cm wide',
                transition: { x: 0.0000010575145797806754, y: 2.4261414140389608, z: 0.000002183534995018454 },
                img: {
                    thumb: path + '_thumbs/' + '45_3_4' + type,
                    src: path + '45_3_4' + type,
                    hir: path + '_hires/' + '45_3_4' + type,
                    w: 1980,
                    h: 2995
                },
                summary: "Drawing of the plan and elevation of the Temple of Isis.",
                template: isisDrawing
            }, {
                pointcloud: PointClouds['pid_24'],
                id: 'pid_24',
                smid: 'MR41',
                group: 'law-courts',
                type: 'Scanned Object',
                label: "The Law Courts, Westminster",
                title: "The Law Courts, Westminster",
                summary: "Showing Soane’s ingenious design for the courts in Westminster.",
                dimension: '6.5cm high x 87cm wide x 67cm deep',
                related: ["pid_25", "pid_26", "pid_27"],
                facts: {
                    material: "Mahogany and limewood",
                    madeby: "Thomas and George Martyr",
                    date: "1827",
                    aquired: "1827",
                    link: "http://collections.soane.org/object-mr41"
                },
                img: {
                    thumb: path + '_thumbs/' + 'law-model' + type,
                    src: path + 'scan-law_courts' + type,
                    w: 308,
                    h: 247
                },
                gps: {
                    location: [12.802226, 41.959817],
                    zoom: 15
                },
                template: courts
            }, {
                id: 'pid_25',
                smid: '53/2/40',
                group: 'law-courts',
                type: 'Drawing',
                label: "Plan of part of the Law Courts",
                title: "Plan of part of the Law Courts",
                dimension: '60cm high x 88.5cm wide',
                transition: { x: -1.7694771694398157e-8, y: 0.9707970829100255, z: 9.706358077366949e-7 },
                img: {
                    thumb: path + '_thumbs/' + '53_2_40' + type,
                    src: path + '53_2_40' + type,
                    hir: path + '_hires/' + '53_2_40' + type,
                    w: 1980,
                    h: 1426
                },
                summary: "Colour-coded drawing of Soane’s Law Courts.",
                template: courtsPlan
            }, {
                id: 'pid_26',
                smid: 'P274',
                group: 'law-courts',
                type: 'Watercolour',
                label: "The Court of Chancery",
                title: "The Court of Chancery (interior view) by Joseph Michael Gandy",
                dimension: '72cm high x 94cm wide',
                transition: { x: -0.005255589662393399, y: 0.1214857269199509, z: 0.2882921353941372 },
                img: {
                    thumb: path + '_thumbs/' + 'P274' + type,
                    src: path + 'P274' + type,
                    hir: path + '_hires/' + 'P274' + type,
                    w: 1980,
                    h: 1551
                },
                summary: "Interior watercolour view of Soane’s Court of Chancery.",
                template: courtsWatercolour
            }, {
                id: 'pid_27',
                smid: '53/3/1',
                group: 'law-courts',
                type: 'Drawing',
                label: "The Court of King’s Bench",
                title: "(Design for) The Court of King’s Bench",
                dimension: '36cm high x 26cm wide',
                transition: { x: -0.8115547881538887, y: 0.5507015596490034, z: 0.2681014882761753 },
                img: {
                    thumb: path + '_thumbs/' + '53_3_1' + type,
                    src: path + '53_3_1' + type,
                    hir: path + '_hires/' + '53_3_1' + type,
                    w: 1980,
                    h: 2654
                },
                summary: "Soane’s design for the Court of King’s Bench.",
                template: courtsKingsBench
            }
        ]
    }
}
