export default [
  {
    id: `P31`,
    title: `Arch of Septimus Severus, Rome`,
    artist: `Giovanni Battista Piranesi (1720 - 1778)`,
    date: `1772`,
    link: `http://collections.soane.org/object-p31`,
    medium: `Etching`,
    description: []
  },
  {
    id: `P32`,
    title: `The Exterior of the Pantheon, Rome`,
    artist: `Giovanni Battista Piranesi (1720 - 1778)`,
    date: `1761`,  
    link: `http://collections.soane.org/object-p32`,
    medium: `Etching`,
    description: [`This etching by Piranesi, like that of the Colosseum, is from the <span class='italic'>Vedute di Roma</span>. It is one of four said by Soane to have been presented to him personally by Piranesi when they met in Italy in 1778, in the last year of the artist’s life.`,
      `The Pantheon is the largest and most complete temple surviving in Rome – its concrete dome remained the largest in the world until after World War II.  First constructed by Marcus Agrippa and later rebuilt by Hadrian in <span class='italic'>c.</span>118-128 AD, the temple survived because of its conversion for use as a church in 600 AD.  It was one of the major Roman monuments most admired and studied by architectural students on the Grand Tour and Soane was no exception.  His deep understanding and knowledge of the building was demonstrated many years later in an 1826 interview with George Wightwick (for a post in his Office) when he interrogated him about the intercolumniation [spaces between the columns]. `,
      `In his Royal Academy lectures Soane praised the Pantheon as ‘The glory of the ancient and the admiration of the modern world’. During his analysis of domes he criticised those of the Renaissance and remarked that the domes constructed by the ancient Romans ‘seem always to grow out of the substructure and to harmonise with it in the most gradual and pleasing manner, forming as it were a canopy to the entire edifice’ (lecture 6) – a comment that might be a description of the Pantheon, which provided the direct inspiration for his own Rotunda at the Bank of England (1794-95).  Indeed, he asked Gandy to produce an imaginary view of his new Rotunda as a Roman ruin in the year of its completion, 1798 (SM P127 <span class='italic'>Architectural ruins – a Vision</span>), underlining the connection between the two.  `]
  },
  {
    id: `P33`,
    title: `Remains of the monument to Cecilia Metella, Rome`,
    artist: `Giovanni Battista Piranesi (1720 - 1778)`,
    date: `1763`,
    link: `http://collections.soane.org/object-p33`,
    medium: `Etching`,
    description: []
  },  
  {
    id: `P34`,
    title: `Arch of Constantine`,
    artist: `Giovanni Battista Piranesi (1720 - 1778)`,
    date: `1771`,
    link: `http://collections.soane.org/object-p34`,
    medium: `Etching`,
    description: []
  },
  {
    id: `P35`,
    title: `A head from the cartoon for the tapestry of <span class='italic'>The Presentation in the Temple</span>`,
    artist: `Attributed to Tommaso Vincidor (1536) after Raphael (1483 - 1520)`,
    date: `<span class='italic'>c.</span>1524`,
    link: `http://collections.soane.org/object-p35`,
    medium: `Oil and tempera on paper`,
    description: []
  },
  {
    id: `P36`,
    title: `Copy of two heads from a fragment of a cartoon of the <span class='italic'>Massacre of the Innocents</span> designed by Raphael.`,
    artist: `John Flaxman (1755 - 1826)`,
    date: `1779-1825`,
    link: `http://collections.soane.org/object-p36`,
    medium: `Oil and tempera on paper`,
    description: []
  },
  {
    id: `P37`,
    title: `Portrait of a Lady (Mrs Parry the mother of Sir W.E. Parry, RN)`,
    artist: `John Jackson (1778 - 1831)`,
    date: `<span class='italic'>c.</span>1824`,
    link: `http://collections.soane.org/object-p37`,
    medium: `Oil on canvas`,
    description: []
  },
  {
    id: `P38`,
    title: `<span class='italic'>"The Passage Point", an Italian Composition</span>`,
    artist: `Sir Augustus Wall Callcott (1779 - 1844)`,
    date: `1829-30`,
    link: `http://collections.soane.org/object-p38`,
    medium: `Oil on canvas`,
    description: [`Sir Augustus Wall Callcott trained at the Royal Academy Schools from 1797 under the portraitist John Hoppner, but by the start of the nineteenth century had turned to landscape painting. A contemporary of  J M W Turner, both artists were interested in the effects of light and atmosphere, they respected each other’s style and were friends. Callcott’s work in the first decades of the nineteenth century transitioned from a rustic, pastoral, picturesque style, through a phase of historic landscapes in 1811-12 to marine paintings from 1815 onwards. By the 1820s Callcott’s pictures, more carefully and precisely executed, were often favoured over Turner’s increasingly flamboyant and eventually impressionistic style. At the end of the decade Soane chose a Calcott over a Turner to hang in the Picture Room.`,
      `In 1829 Soane commissioned Callcott’s <span class='italic'>The Passage Point: An Italian Composition.</span> Three years earlier he had rejected Turner’s <span class='italic'>Forum Romanum, for Mr Soane's Museum</span> (now at Tate) on the grounds that ‘the picture did not suit the place, or the place the picture’. Soane paid £500 for The Passage Point and a further £15. 4s to James Guillet for the gilt frame. One scholar suggests that the bold yellow hues and intense composition of the Turner was not to Soane’s taste, whereas The Passage Point was serene and ‘open in composition as well as cool and sweet in tone’.`,
      `Callcott’s painting, a fictive Italian landscape with an expanse of calm water in the foreground, surrounded by verdant land, dwellings and distant mountains, and Soane’s favourite building, the Temple of Vesta at Tivoli on the far right, was exhibited at the Royal Academy in 1830 and praised in the press as an ‘almost perfect work of art’. It was described as ‘a landscape upon a large scale, most sweetly coloured and composed.’`]
  },
  {
    id: `P39`,
    title: `Portrait of a Man`,
    artist: `John Hamilton Mortimer (1740 - 1779)`,
    date: `<span class='italic'>c.</span>1750-79`,
    link: `http://collections.soane.org/object-p39`,
    medium: `Chalk`,
    description: []
  },
  {
    id: `P40`,
    title: `<span class='italic'>A Rake's Progress I: The Heir</span>`,
    artist: `William Hogarth (1697 - 1764)`,
    date: `1734`,
    link: `http://collections.soane.org/object-p40`,
    medium: `Oil on canvas`,
    description: [`<span class='italic'>The Heir</span>, the first painting in <span class='italic'>A Rake's Progress</span>, is set in 1720 and presents Tom Rakewell, the son of a City moneylender who has recently inherited his father's fortune. He is standing in the middle of an undistinguished room in an old-fashioned house in the City of London that is testament to his father's miserliness. The maid is laying the fire with wood shavings, not logs, there is limited lighting and on the mantelpiece is a 'save-all': a small pan inserted into a candlestick to save the ends of candles. `,
      `Tom's father's meanness belied his large fortune, indicated by glittering silver plate and the chest in the foreground filled with moneybags labelled '1000', '2000' and '3000'.  A servant disturbs gold coins that the father has squirrelled away behind the rotten and splintering cornice which now tumble to the floor: Tom is a very rich young man but there are hints that he will be foolish and profligate. He is already being measured for a new suit and whilst his back is turned his lawyer, supposedly making an inventory of Tom's new possessions, is stealing from the bag of gold on the table. `,
      `By the door to the room is Sarah Young, the other main protagonist in the series. She is evidently pregnant and, it appears, from the gold ring in her left hand and the letters falling to the floor, that Tom proposed to her whilst in Oxford: 'To Mrs. Sarah Young in Oxford … Dearest Life … & marry you'. Sarah has arrived in good faith to meet her fiancé but Tom spurns her, offering her instead a bribe of gold coins to depart, leaving behind the letters and the ring. Tom's rejection of Sarah's faithful love is a major theme of the series. `],
  },
  {
    id: `P41`,
    title: `<span class='italic'>A Rake's Progress II: The Levée</span>`,
    artist: `William Hogarth (1697 - 1764)`,
    date: `1734`,
    link: `http://collections.soane.org/object-p41`,
    medium: `Oil on canvas`,
    description: [`The second painting in the series <span class='italic'>A Rake's Progress, The Levée</span> is set in a large luxurious house, decorated in the modern, classical style, overlooking one of the newly developed west London squares such as Berkeley Square that Tom has bought, or is renting. Seven years have passed as indicated by the date, 1727, on the silver punchbowl being presented by the jockey on his knees in the right-hand corner of the canvas. `,
      `The concept of the levée, (from the French verb lever, to rise), originated in late seventeenth-century France. The king would dress and breakfast in public, providing an opportunity for ordinary citizens to approach him and seek his patronage. Great aristocrats copied the practice, receiving purveyors of goods and services and transacting business at their own levées. That Tom, the son of a merchant, is aping the habits of monarchs and aristocrats conveys his shallow aspiration to climb the ranks of London society.`,
      `The jockey is just one of the 'hangers-on' who have gravitated to wealthy, foolish Tom. A dancing master holds a kit violin to accompany Tom's dancing lesson, a fencing master wears a black tricorne hat and next to him a prize-fighter holds a quarter-staff: it was fashionable for young gentlemen to learn to box during this period. Tom is learning the arts that will mark him out as a gentleman. `,
      `Tom's lifestyle is clearly expensive: the jockey suggests the high-class betting associated with horse-ownership and the hornsman behind him infers that Tom hunts and keeps a stable of horses. The unwholesome character at Tom's elbow 'Captain Hackum' has a letter of recommendation to Tom which states his 'sword may serve you' and suggests that Tom has been dabbling in the seedier side of life in Georgian London and may have need of a bodyguard. `]
  },
  {
    id: `P42`,
    title: `<span class='italic'>A Rake's Progress III: The Orgy</span>`,
    artist: `William Hogarth (1697 - 1764)`,
    date: `1734`,
    link: `http://collections.soane.org/object-p42`,
    medium: `Oil on canvas`,
    description: [`The hint in the previous painting that Tom was familiar with the unsavoury elements of London life is borne out by the setting of the third painting in <span class='italic'>A Rake's Progress, The Orgy</span>. The Rose Tavern, Drury Lane was a notorious brothel and Tom is depicted in an insalubrious room. The ceiling is smoke-stained, the mirror is broken and there is food, broken glass and the contents of a chamber pot strewn on the floor by Tom's feet. `,
      `Tom himself is dishevelled, his clothing in disarray, coat open, shirt and breeches unbuttoned. He is clearly drunk and enjoying the embrace of a pretty young prostitute, marked with the black spots which indicate she has syphilis. The box of mercury pills - a treatment for syphilis - that has fallen from Tom's pocket to the floor, suggests that he also has the disease. Tom is so drunk he doesn't realise that the woman has stolen his watch and is passing it to her accomplice behind his back. With few male clients to distract them one prostitute is drinking punch from the bowl and two others are arguing across the table, one spitting at the other. In the foreground the 'posture woman', or stripper, takes off her shoes in preparation for the exotic dance she will do for the company, standing on the silver salver that is being brought into the room by the innkeeper. `,
      `The paintings of Roman Caesars on the wall around the room have all been mutilated except that of the Emperor Nero, who, fittingly given the setting, watched Rome burn after a night of debauchery. Tom is presented as having reached a point of moral abandon. `]
  },
  {
    id: `P43`,
    title: `<span class='italic'>A Rake's Progress IV: The Arrest</span>`,
    artist: `William Hogarth (1697 - 1764)`,
    date: `1734`,
    link: `http://collections.soane.org/object-p43`,
    medium: `Oil on canvas`,
    description: [`Scene four in the series <span class='italic'>A Rake's Progress, The Arrest,</span> re-introduces Sarah Young, Tom's pregnant lover, to whom he had promised marriage before rejecting her in scene one. She is his sole and constant supporter throughout the series and his repudiation of her enduring care is his ultimate folly. `,
      `Unlike in the previous painting, here Tom is very finely attired in a full 'court' dress: breeches, silk stockings, a shirt, his coat is trimmed with gold braid and his wig powdered and neatly tied. It is March 1st, St David's Day, as we can tell by the leeks in the hats of two men. It is also Queen Caroline's birthday and a public reception is being held to mark the event. Tom's sedan chair is on St James's Street, approaching St James's Palace. He is attempting to visit the royal court in line with his aspirations to enter elite society. `,
      `However, his way is barred by two constables or bailiffs acting on behalf of the man carrying a fur muff to whom Tom owes money: he has spent his father's fortune, is bankrupt and risks being sent not to the royal court but the law courts and then to prison. `,
      `He is saved by the ever-loving Sarah Young, who is plainly dressed, the box of ribbons with her name on it suggesting that she is perhaps employed as a milliner, a respectable, but not a well-paid trade. Distressed at Tom's predicament she offers the bailiff her purse, presumably containing her life's savings, to prevent Tom's arrest. Her action is akin to that of an angel of mercy and offers Tom the opportunity to reform his ways and live an honest and moral life.`]
  },
  {
    id: `P44`,
    title: `<span class='italic'>A Rake's Progress V: The Marriage</span>`,
    artist: `William Hogarth (1697 - 1764)`,
    date: `1734`,
    link: `http://collections.soane.org/object-p44`,
    medium: `Oil on canvas`,
    description: [`In the fifth painting in the series <span class='italic'>A Rake's Progress, The Marriage</span>, just as in the first, Tom repudiates Sarah's love in favour of a life of ease and wealth. The setting is the shabby church of St Mary-le-Bone, located in the Regent's Park area which was then a long way from the small fashionable hub of London centred on St James's. It was known as an out-of-the-way place where one could be quietly married, with no fuss, by the vicar and his curate. It is here that Tom has come to be wedded to a rich woman in an elaborate jewelled dress who is elderly, lame and apparently has only one eye.`,
      `In Hogarth's time, by law, a woman's possessions generally became her husband's absolutely upon marriage. The clear inference is that Tom is marrying his bride exclusively for her money and the marriage will be a sham. This is the first of Tom's increasingly desperate attempts to retrieve his squandered fortune and represents the beginning of his descent into poverty and insanity. `,
      `A rumpus is occurring at the door of the church. Sarah Young who rescued Tom from arrest in the previous scene is standing holding their child, accompanied by her forceful mother who is shaking her fist at the pew keeper. Both are trying to enter the church and have the marriage stopped. At this seminal moment in the series, perhaps the last point at which Tom could achieve redemption by turning to Sarah, he is otherwise preoccupied, slyly glancing at his wife's pretty maid as he places the ring on his wife's finger. He ignores the commotion by the door and Sarah's pleas and whilst he gains a rich wife by his decision, he loses any chance of moral redemption.`]
  },
  {
    id: `P45`,
    title: `<span class='italic'>A Rake's Progress VI: The Gaming House</span>`,
    artist: `William Hogarth (1697 - 1764)`,
    date: `1734`,
    link: `http://collections.soane.org/object-p45`,
    medium: `Oil on canvas`,
    description: [`Scene six in <span class='italic'>A Rake's Progress, The Gaming House</span> presents Tom in a seedy Covent Garden gambling hell, rather than the illustrious St James's gentleman's gaming clubs he might previously have frequented. The gamblers may be playing Hazard, which was played with two dice and was popular in the eighteenth century. Most games played in 'hells' were based on luck, such as games with dice, whereas the elite clubs favoured card-based games of skill.`,
      `Tom is depicted in the foreground, still finely dressed in a blue coat with silver lacing and a waistcoat with coral lining, having just lost his second fortune which belonged to his wife. His pose is a precursor of the despair and madness that will follow. He is shown kneeling, his body taut with anger, frustration and despair, fist raised and clenched, his expression frenzied as he grinds his teeth at what he deems his ill-luck, but which is more accurately a reflection of his poor judgement, choosing to gamble in such a place. `,
      `The room is tightly packed and most characters are ruled by a love of money or gaming. To the right of the painting a finely dressed nobleman with gold lacing to his black coat waits for the plainly dressed moneylender to note that he has 'Lent to L[or]d Cogg £500' so that he can carry on gambling. Behind the moneylender a cleric hides his face from the watchman, presumably embarrassed at being found in a den of iniquity. A highwayman with long natural white hair, pistols and a mask in his pocket, sits in front of the fire, probably waiting to follow a winning gambler home and rob him. Folly, avarice, greed and despair abound. A black dog, traditionally a symbol of melancholy, barks at Tom, hinting at his imminent loss of reason. `]
  },
  {
    id: `P46`,
    title: `<span class='italic'>A Rake's Progress VII: The Prison</span>`,
    artist: `William Hogarth (1697 - 1764)`,
    date: `1734`,
    link: `http://collections.soane.org/object-p46`,
    medium: `Oil on canvas`,
    description: [`Scene seven of <span class='italic'>A Rake's Progress, The Prison</span> is set in the Fleet Prison for debtors, where Hogarth's own father was imprisoned when Hogarth was a child. Bankrupted by Tom's gambling, Tom and his wife have been incarcerated until his debts are paid. Seated to the left of the painting Tom is still dressed in the blue coat from the previous scene, now torn at the elbow and shoulder to reveal his shirt beneath, his wig is askew and he is harangued by his one-eyed wife, now scrawny and ill-kempt. Ironically poverty-stricken inmates of the Fleet had to pay fees, known as 'Garnish' for their food and firewood. The 'pot-boy' behind Tom is holding out his hand for payment before he will hand over the jug of beer. Therefore Hogarth depicts the inmates as preoccupied with schemes for making money. In the background an alchemist stokes a fire hoping to make a precious metal and the lawyer to the right of Sarah has devised a 'New / Scheme for / Paying ye. Debts / of ye. Nation'.`,
      `In a belated attempt to earn money to pay his creditors Tom has written a play which he sent to John Rich, the celebrated manager of Covent Garden theatre, but has received a letter stating, 'Sr. I have read your / Play and find it will / not do'. It is this rejection which has caused Tom's wide-eyed and foolish expression as he realises that all hope is irrevocably gone, he has no future. `,
      `The ever-faithful Sarah Young has come to visit Tom. She realises, as does he, that because of Tom's immoral living, weakness and poor choices all is lost and has fainted into the arms of one of the other inmates, whilst their child tugs angrily at her skirts. `]
  },
  {
    id: `P47`,
    title: `<span class='italic'>A Rake's Progress VIII: The Madhouse</span>`,
    artist: `William Hogarth (1697 - 1764)`,
    date: `1734`,
    link: `http://collections.soane.org/object-p47`,
    medium: `Oil on canvas`,
    description: [`The eighth and final scene in <span class='italic'>A Rake's Progess, The Madhouse</span> depicts Tom, chained, in 'Bedlam', the Royal Bethlehem Hospital, which in the eighteenth century was an asylum for those considered lunatic. Wearing only a loin cloth, Tom's young and physically strong body is at odds with the evident weakness of his mind, conveyed though his vacant expression. Tom is depicted in the pose of 'Melancholy Madness' which would have been familiar to Hogarth's contemporaries as the statue by Caius Gabriel Cibber which surmounted one of the stone gates of Bedlam between 1675 and 1815. The viewer is left in no doubt that the consequence of his immoral living, his obsession with pleasure and gambling and his desire to rise in society, is poverty, vacuity and will ultimately be death. `,
      `Hogarth portrays other witless inmates: behind Tom is a tailor with a tape-measure over his arm, kneeling before an imaginary client. A fiddler plays whilst balancing a book on his head and another inmate is dressed as the Pope with a paper hat and wooden staff mimicking the papal mitre and <span class='italic'>ferula</span> (staff). In the cells on the right, one man wears a crown of straw and holds a mock sceptre, clearly under the impression he is a royal personage. Two shafts of light illuminate and connect two key features of the painting: Tom in the foreground and two finely dressed ladies, their delicate laces picked out by the sunlight, in the background. At this time Bedlam opened its doors to paying visitors who viewed with interest and some revulsion, the spectacle of the deranged inmates. Ironically Tom has become a figure of fun for the aristocrats whose society he so desired. By contrast, Sarah Young, still by Tom's side, weeps for her lost love. `]
  },
  {
    id: `P48`,
    title: `<span class='italic'>Comus listening to the Incantations of Circe</span>`,
    artist: `Henry Howard (1769 - 1847)`,
    date: `1831`,
    link: `http://collections.soane.org/object-p48`,
    medium: `Oil on canvas`,
    description: []
  },
  {
    id: `P49`,
    title: `<span class='italic'>The great Temple of Ceres at Eleusis: a composition of Greek embellishments from Pausanias and other authors, and from discoveries made on the spot by the last mission of the Dilletanti Society</span>`,
    artist: `Joseph Michael Gandy (1771 - 1843)`,
    date: `1815 (exhib. RA)`,
    link: `http://collections.soane.org/object-p49`,
    medium: `Watercolour on paper`,
    description: []
  },
  {
    id: `P50`,
    title: `<span class='italic'>Scene from the Merchant of Venice: Belmont in the Garden of Portia's House</span>`,
    artist: `Francis Danby (1793 - 1861)`,
    date: `1827-28`,
    link: `http://collections.soane.org/object-p50`,
    medium: `Oil on canvas`,
    description: [`Following Edward Bird’s death Francis Danby became the leader of the ‘Bristol School’, a group who met regularly to sketch and who, unlike the London Sketching Society, didn’t set a specific theme for their drawing evenings. This freedom probably contributed to Danby’s development of what he termed his ‘poetical’ style of landscape painting. `,
      `The Victorian painter Richard Redgrave (1804-88) said of the first painting Danby exhibited in London, (<span class='italic'>The Upas, or Poison-Tree, in the Island of Java, c.</span>1820) that the subject ‘required a poetical mind … no mere landscape painter, no mere imitation of Nature, would suffice to picture to us the gloomy horrors of this land of fear’. The large-scale dark canvas didn’t sell and reflecting upon this in 1826 Danby wrote ‘I think I am almost cured of painting dark pictures, <span class='underline'>but I shall ever like them best</span>’.`,
      `Soane commissioned this painting costing £152 10s from Francis Danby via an intermediary, Sir Francis Chantrey, in May 1827 as a charitable gesture: Danby was very short of money. For Soane, known to love Shakespeare, Danby painted a scene from Shakespeare’s <span class='italics'>The Merchant of Venice</span> featuring Lorenzo and Jessica seated in the garden of Portia’s house. When exhibited at the Royal Academy in 1828 the catalogue included the Shakespearean verse describing the scene, which must have presented an extraordinary challenge even to a painter with a ‘poetical’ imagination: `,
      `<span class='italic'>How sweet the Moonlight sleeps upon this bank,/Here will we sit and let the sounds of music/creep in our ears—soft stillness of the night/Become the touches of sweet Harmony/Sit Jessica, look how the floor of Heav’n/is thick inlaid with patterns of bright gold.</span>`,
      `Danby threads moonlight of an almost luminous quality through this work - essentially another ‘dark painting’ - perfectly evoking the ‘soft stillness of the night’ and leaves Lorenzo and Jessica dappled and shaded, camouflaged within the landscape.`]
  },
  {
    id: `P51`,
    title: `Study for <span class='italic'>Différentes vues de Pesto...,</span> Plate III. The Basilica showing part of the west peristyle with the other two temples in the distance`,
    artist: `Giovanni Battista Piranesi (1720 - 1778)`,
    date: `<span class='italic'>c.</span>1777-78`,
    link: `http://collections.soane.org/object-p51`,
    medium: `Black and red chalk, pencil, brown and grey washes, pen and ink`,
    description: []
  },
  {
    id: `P52`,
    title: `The garden side of the mosque at Chunar in the Benares Province in India`,
    artist: `William Hodges (1744 - 1797)`,
    date: `1794 (exhib. RA). Based on drawings made in 1781`,
    link: `http://collections.soane.org/object-p52`,
    medium: `Gouache on paper supported by canvas`,
    description: []
  },
  {
    id: `P53`,
    title: `<span class='italic'>The Humours of An Election I: The Election Entertainment</span>`,
    artist: `William Hogarth (1697-1764)`,
    date: `1754-55`,
    link: `http://collections.soane.org/object-p53`,
    medium: `Oil on canvas`,
    description: [`The first scene in <span class='italic'>The Humours of an Election</span> presents the fictional town of Guzzledown, whose name instantly connotes the avarice of candidate and elector alike. <span class='italic'>An Election Entertainment</span> is being held by the Whig party. Behind the candidates, whose chairs are surmounted with branches of laurels, is an orange flag proclaiming 'Liberty and Loyalty'. The candidate on the right, wearing an olive-coloured coat with gold braid, is being importuned by two drunks, one dribbling on his shoulder, the other trying to shake his hand. The younger, exquisitely dressed candidate, is being embraced by a local woman with coarse features. It was unusual, in this period, for tradesmen to become so familiar with upper-class gentlemen but when seeking votes all social conventions were abandoned.`,
      `Corruption is evident: behind the candidates a beautiful young woman's sexual favours are apparently offered in exchange for a vote. In the left foreground a fat man in simple Quaker clothing piles up gloves and stockings to be used as bribes. On the far side of the room a Puritan is refusing gold coins, but his honesty brings no reward in Guzzledown: he is thin and his child's shoe has a hole in it. `,
      `The scene is densely populated, full of absurdity, chaos, greed and even violence. A brick thrown through the window has knocked the Whig Party Secretary backwards, he is about to fall to the floor, along with the ledger he has been keeping recording 'Sure Votes' of which there is only one and 'Doubtful Votes' of which there are many: Hogarth is commenting on the unreliability of votes secured via bribes. `,
      `By contrast, in the bottom right-hand corner of the canvas, Hogarth offers a finely executed Dutch-style still-life in miniature, with plates, jug, lobster and oyster shells attesting to his painterly skill. `]
  },
  {
    id: `P54`,
    title: `Study for <span class='italic'>Différentes vues de Pesto...,</span> Plate VII. The interior of the Basilica looking east, with a section of the south peristyle, the central columns of the <span class='italic'>
cella</span> and the <span class='italic'>pronaos</span> in the distance.`,
    artist: `Giovanni Battista Piranesi (1720 - 1778)`,
    date: `<span class='italic'>c.</span>1777-78`,
    link: `http://collections.soane.org/object-p54`,
    medium: `Pencil, brown and grey washes, pen and ink `,
    description: []
  },
  {
    id: `P55`,
    title: `View of the mosque at Fatehpur Sikri, near Agra, India`,
    artist: `William Hodges (1744 - 1797)`,
    date: `1794 (exhib RA). Based on drawings made 23-26 March 1783.`,
    link: `http://collections.soane.org/object-p55`,
    medium: `Gouache on paper supported by canvas`,
    description: [`William Hodges made his name as the official artist associated with Captain Cook’s second voyage to the South Pacific between 1772 and 1775, making sketches of landscapes as diverse as the Antarctic, the lush scenery of New Zealand and the Pacific Islands, which he published on his return. Between 1779 and 1783 Hodges travelled in India and was at the mosque at Fatehpur Sikri, near Agra, between 23 and 26 March 1783. In a record of his travels published in 1793, he commented on the people at the market, the cavalry officers and the elephants carrying heavy baggage. He described the mosque as designed in ‘a high style of Moorish Architecture’ but noted that ‘only the main gate is left standing to give any idea of its former grandeur’.`,
      `Hodges’ style, as early as the 1770s and 1780s, particularly in the drawings he made whilst on his travels, was unrestrained and marked by a freedom of execution. Some of Hodges’ travel drawings were so innovative that they were considered unfinished by his contemporaries, yet they seem to presage the naturalism of the nineteenth century, in which landscapes and people were presented in a more realistic way. Hodges was elected to the Royal Academy in 1786 and would have known Soane in that context. The painting was in Soane’s possession by the time it was exhibited at the Royal Academy in 1794. `]
  },
  {
    id: `P56`,
    title: `<span class='italic'>The Humours of An Election II: Canvassing for Votes</span>`,
    artist: `William Hogarth (1697 - 1764)`,
    date: `1754-55`,
    link: `http://collections.soane.org/object-p56`,
    medium: `Oil on canvas`,
    description: [`Set in the countryside, <span class='italic'>Canvassing for Votes</span>, the second scene in <span class='italic'>The Humours of an Election</span> depicts three inns, representing the three political groups that existed during the eighteenth-century: Whigs, Tories and Independents. `,
      `'The Crown', the Whigs' base, indicates their association with the 'court' interest. A panel labelled 'The Excise Office' refers to the Whigs' taxation policy. A rabble armed with sticks is trying to pull down the sign and topple the taxes it represents but the man sawing at its support little realises he will topple along with the sign: the mob below are equally unaware that they will be crushed. Hogarth is implying that the political intervention of the mob can be not only ill-informed but also catastrophic. `,
      `The prosperous-looking 'Royal Oak' is the Tory headquarters. In a first-floor room Tory gentlemen have congregated, but their candidate has abandoned them. Dressed in a buff-coloured coat and golden waistcoat, he has rushed down from the balcony, where he was conversing with two ladies, to buy trinkets for them. Neglecting his political duties, he ignores a letter being handed to him by a servant, entirely preoccupied by the charming women.`,
      `'The Portobello' inn, to the right, represents the Independents' political views, rejection of party affiliations and factional interests, and is the sole example of the potential for political integrity in the scene. An Independent MP, renowned for his honest views, Admiral Vernon, won a famous victory against the Spanish at Portobello with just six ships, indicated by the six pieces of clay pipe laid out on the table. `,
      `Hogarth portrays not only the political parties, but also the electorate as foolish or negligent and corrupt. A farmer is offered silver coins by the landlord of the 'Royal Oak', and a gold guinea and invitation to dinner by the landlord of 'The Crown.' He accepts both bribes, becoming complicit in the deterioration of the democratic political system. `]
  },
  {
    id: `P56`,
    title: `<span class='italic'>The Humours of An Election II: Canvassing for Votes</span>`,
    artist: `William Hogarth (1697 - 1764)`,
    date: `1754-55`,
    link: `http://collections.soane.org/object-p56`,
    medium: `Oil on canvas`,
    description: []
  },
  {
    id: `P57`,
    title: `An Architectural Composition (Design for Theatrical Scenery)`,
    artist: `Francesco Galli Bibiena (1659 - 1739)`,
    date: `<span class='italic'>c.</span>1669-1739`,
    link: `http://collections.soane.org/object-p57`,
    medium: `Pencil, brown and grey washes, pen and ink on paper`,
    description: []
  },
  {
    id: `P58`,
    title: `An Architectural Composition (Design for Theatrical Scenery)`,
    artist: `Francesco Galli Bibiena (1659 - 1739)`,
    date: `<span class='italic'>c.</span>1669-1739`,
    link: `http://collections.soane.org/object-p58`,
    medium: `Pencil, brown and grey washes, pen and ink on paper`,
    description: []
  },
  {
    id: `P59`,
    title: `An Architectural Composition (Design for Theatrical Scenery)`,
    artist: `Francesco Galli Bibiena (1659 - 1739)`,
    date: `<span class='italic'>c.</span>1669-1739`,
    link: `http://collections.soane.org/object-p59`,
    medium: `Pencil, brown and grey washes, pen and ink on paper`,
    description: []
  },
  {
    id: `P60`,
    title: `<span class='italic'>A Hen defending her Chickens from the Attack of a Cat</span>`,
    artist: `Sir Peter Francis Bourgeois (1756 - 1811)`,
    date: `<span class='italic'>c.</span>1766 - 1811`,
    link: `http://collections.soane.org/object-p60`,
    medium: `Oil on canvas`,
    description: []
  },
  {
    id: `P61`,
    title: `<span class='italic'>A view of the Rialto, Venice</span>`,
    artist: `Antonio Canaletto (1697 - 1768)`,
    date: `<span class='italic'>c.</span>1734-35`,
    link: `http://collections.soane.org/object-p61`,
    medium: `Oil on canvas`,
    description: []
  },
  {
    id: `P62`,
    title: `<span class='italic'>Milton dictating to his daughters</span>`,
    artist: `Richard Westall (1765 - 1836)`,
    date: `1802`,
    link: `http://collections.soane.org/object-p62`,
    medium: `Watercolour  `,
    description: []
  },

  {
    id: `P`,
    title: `An Election IV: Chairing the Member`,
    artist: ``,
    date: ``,
    link: ``,
    medium: ``,
    description: [`The final scene of The Humours of an Election portrays the eighteenth-century election practice of Chairing the Member, with the two winning Tory candidates being carried around town by their supporters on elaborate chairs. In typically Hogarthian style, the candidate in the foreground is starting to topple, the characters around him creating a complex vignette of interlinked mishaps. A goose flies over the candidate's head: by comparison Lebrun's celebrated painting The Battle of Arbela depicted an eagle flying above the head of a victorious Alexander the Great, but this new MP is no Alexander. The whole is an analogy for the precarious state of political power won through bribery and corruption rather than through honest votes. `,
      `In front of the procession two men are fighting. A sailor with a peg-leg wearing Whig colours and a blue coat is brandishing a club at a white-shirted, ginger-haired country labourer, presumably supporting the Country or Tory interests, who in turn has swung his threshing tool behind his head to attack the sailor and accidentally hit one of the chair-men. As the candidate tumbles, his wife, dressed in peach and lace, watching the procession from behind the church wall, 'faints away' and needs to be supported by her Black maidservant. The graveyard's consecrated ground has been desecrated by two cheeky chimney sweeps, their hands and bodies be-smirched with soot. They have scavenged a human skull-and-crossbones and perched on the gate-pier in front of the church to display it to the procession below, complete with spectacles.`,
      `Between the brawling labourer and the toppling chair, a sow and her piglets are charging towards the stream and have knocked a countrywoman over. This is a reference to the miracle of the Gadarene Swine - when Jesus cast the demons that possessed a madman into the bodies of pigs who then ran down a steep cliff into the sea. It indicates the mindless flight to danger and destruction implicit in the state of the rotten and venal British political system.`]
  },
  {
    id: `P63`,
    title: `<span class='italic'>The Piazza di San Marco, Venice</span>`,
    artist: `Antonio Canaletto (1697 - 1768)`,
    date: `<span class='italic'>c.</span>1734-35`,
    link: `http://collections.soane.org/object-p63`,
    medium: `Oil on canvas`,
    description: []
  },
  {
    id: `P64`,
    title: `<span class='italic'>Architectural ruins: the interior of a vaulted hall</span>`,
    artist: `Charles Louis Clérisseau (1721 - 1820)`,
    date: `1764`,
    link: `http://collections.soane.org/object-p64`,
    medium: `Gouache on paper`,
    description: []
  },
  {
    id: `P65`,
    title: `Architectural ruins with water`,
    artist: `Charles Louis Clérisseau (1721 - 1820)`,
    date: `1771 [signed bottom LH Corner according to green box HD writing]`,
    link: `http://collections.soane.org/object-p65`,
    medium: `Gouache on paper`,
    description: []
  },
  {
    id: `P66`,
    title: `<span class='italic'>Riva degli Schiavoni, Venice</span>`,
    artist: `Antonio Canaletto (1697 - 1768)`,
    date: `<span class='italic'>c.</span>1734-35`,
    link: `http://collections.soane.org/object-p66`,
    medium: `Oil on canvas`,
    description: [`Soane visited Venice in 1779 on a trip to northern Italy from Rome with his friend Rowland Burdon. Sadly, although he praised the perfection of Palladio’s Venetian churches, Soane recorded no general impressions of the city then or in his later lectures. He did, however, acquire three Canaletto paintings. Perhaps a picture is ‘worth a thousand words’ as this magnificent canvas is one of Canaletto’s greatest works, effortlessly reproducing the impact of the architectural scenery, maritime activity and glorious light of Venice.  Canaletto cleverly adjusts the relative positions of buildings along the wide Riva degli Schiavoni to ensure that all are visible.  What appears an accurate topographical view is in fact a composite one, from several viewpoints, yet utterly convincing in its precision, down to every detail of rigging, costume, and stonework. Canaletto’s enjoyment of the everyday life of the city is evident in the depiction of the citizens, accompanied by numerous small dogs, on the quayside.  `,
      `Soane probably saw this picture in the collection of Alderman Beckford at Fonthill in the 1790s before acquiring it at the Beckford sale in 1807 – the magnificent frame may be from its Fonthill period.  Soane initially hung the painting at his official residence at the Royal Hospital, Chelsea, there being no space large enough at Lincoln’s Inn Fields until the construction of his first Picture Room in 1819.  In Soane’s second Picture Room it hangs directly above the fireplace where he placed it, providing a striking eye-catcher at the end of the vista along the Colonnade as visitors approach. It is almost as if the mantlepiece is a windowsill and the painting a real view out over the sunlit lagoon towards the Doge’s Palace and the domes of St Mark’s – an effect that surely evoked for Soane vivid memories of his Grand Tour.  `]
  },
  {
    id: `P67`,
    title: `<span class='italic'>Architectural ruins</span>`,
    artist: `Charles Louis Clérisseau (1721 - 1820)`,
    date: `<span class='italic'>c.</span>1760-70s`,
    link: `http://collections.soane.org/object-p67`,
    medium: `Gouache on paper`,
    description: []
  },
  {
    id: `P68`,
    title: `<span class='italic'>Architectural ruins</span>`,
    artist: `Charles Louis Clérisseau (1721 - 1820)`,
    date: `1771`,
    link: `http://collections.soane.org/object-p68`,
    medium: `Gouache on paper`,
    description: []
  },
  {
    id: `P69`,
    title: `Study for <span class='italic'>Différentes vues de Pesto...,</span> Plate XII. The interior of the Temple of Neptune from the north-east (with the peristyle partly removed) showing the <span class='italic'>pronaos</span> and internal colonnades. `,
    artist: `Giovanni Battista Piranesi (1720 - 1778)`,
    date: `<span class='italic'>c.</span>1777-78`,
    link: `http://collections.soane.org/object-p69`,
    medium: `Pencil, brown and grey washes, pen and ink `,
    description: []
  },
  {
    id: `P70`,
    title: ` Study for <span class='italic'>Différentes vues de Pesto...,</span> Plate XI. The Temple of Neptune from the south-west`,
    artist: `Giovanni Battista Piranesi (1720 - 1778)`,
    date: `<span class='italic'>c.</span>1777-78`,
    link: `http://collections.soane.org/object-p70`,
    medium: `Pencil, brown and grey washes, bodycolour, pen and ink `,
    description: []
  },
  {
    id: `P71`,
    title: `Study for <span class='italic'>Différentes vues de Pesto...,</span> Plate II. The Basilica from the east`,
    artist: `Giovanni Battista Piranesi (1720 - 1778)`,
    date: `<span class='italic'>c.</span>1777-78`,
    link: `http://collections.soane.org/object-p71`,
    medium: `Black chalk, pencil, brown and grey washes, pen and ink `,
    description: []
  },
  {
    id: `P72`,
    title: `Study for <span class='italic'>Différentes vues de Pesto...,</span> Plate X. The Temple of Neptune from the north-east with the Basilica to the left`,
    artist: `Giovanni Battista Piranesi (1720 - 1778)`,
    date: `<span class='italic'>c.</span>1777-78`,
    link: `http://collections.soane.org/object-p72`,
    medium: `Red chalk, pencil, brown and grey washes, pen and ink, white highlights`,
    description: []
  },
  {
    id: `P73`,
    title: `<span class='italic'>The Humours of An Election III: The Polling</span>`,
    artist: `William Hogarth (1697-1764)`,
    date: `1754-55`,
    link: `http://collections.soane.org/object-p73`,
    medium: `Oil on canvas`,
    tour: {
      title: `An Election III: The Polling`,
      description: [`The third painting in <span class='italic'>The Humours of an Election</span>, The Polling, depicts the chaos and duplicity, according to Hogarth, apparently associated with the polling of votes. The booth is decorated with the blue Tory flag on the left and the orange Whig flag on the right. The two candidates sit on chairs on corresponding sides of the booth. Between them, the constable who should be ensuring a fair and orderly election has fallen asleep, suggesting parallels with the wider somnolence of appropriate electoral controls. The Tory side is thronged with electors who have cast their votes, but the candidate is scratching his head looking at a pile of papers, the uppermost inscribed 'Bill', horrified at what the election may have cost him in bribes.`,
        `Waiting electors are evidently ineligible or in no fit state to exercise their judgement, neither circumstance a concern to the duplicitous party agents. A man who has lost his reason doesn't know who he is voting for but is nonetheless carried on a chair to the poll. Another Whig is also carried to the poll, but the grey-blue tinge to his face suggests that he may be dying (or even dead) and not able to vote. The tips of four staves, visible in the right-hand bottom corner of the painting, suggest a physical barrier of thugs, perhaps ready to prevent too many voters of the 'wrong' party – whichever that may be – from reaching the poll. `,
        `To the left of the polling booth is a broken-down coach carrying Britannia signifying the nation. The two coachmen, symbolic of the nation's parliamentary leaders, are incapable of repairing the coach, being preoccupied with a game of cards. The political state is revealed as flawed, broken, corrupt and arguably beyond repair both physically at the poll and allegorically in the guise of Britannia's carriage.`]
    },
  },
  {
    id: `P74`,
    title: `Study for <span class='italic'>Différentes vues de Pesto...,</span> Plate XIII. The Temple of Neptune from within the peristyle at the west end, looking south`,
    artist: `Giovanni Battista Piranesi (1720 - 1778)`,
    date: `<span class='italic'>c.</span>1777-78`,
    medium: `Black chalk, pencil, brown and grey washes, pen and ink, white highlights `,
    link: `http://collections.soane.org/object-p74`,
    description: []
  },
  {
    id: `P75`,
    title: `Study for <span class='italic'>Différentes vues de Pesto...,</span> Plate VI. The Basilica looking west with the <span class='italic'>pronaos</span> in the foreground and the Temple of Neptune to the right`,
    artist: `Giovanni Battista Piranesi (1720 - 1778)`,
    date: `<span class='italic'>c.</span>1777-78`,
    medium: `Red and black chalk, pencil, brown and grey washes, pen and ink `,
    link: `http://collections.soane.org/object-p75`,
    description: []
  },
  {
    id: `P76`,
    title: ` Study for <span class='italic'>Différentes vues de Pesto...,</span> Plate V. The interior of the Basilica, looking north, showing the <span class='italic'>pronaos</span> with the Temple of Neptune in the distance`,
    artist: `Giovanni Battista Piranesi (1720 - 1778)`,
    date: `<span class='italic'>c.</span>1777-78`,
    medium: `Red and black chalk, pencil, brown and grey washes, pen and ink `,
    link: `http://collections.soane.org/object-p76`,
    description: []
  },
  {
    id: `P77`,
    title: `Study for <span class='italic'>Différentes vues de Pesto...,</span> Plate XIV. The interior of the Temple of Neptune, looking south-west, showing the inner side of the <span class='italic'>opisthodomos</span>, or rear porch`,
    artist: `Giovanni Battista Piranesi (1720 - 1778)`,
    date: `<span class='italic'>c.</span>1777-78`,
    medium: `Red chalk, pencil, brown and grey washes, pen and ink`,
    link: `http://collections.soane.org/object-p77`,
    description: []
  },
  {
    id: `P78`,
    title: `<span class='italic'>The Humours of an Election IV: Chairing the Member</span>`,
    artist: `William Hogarth (1697-1764)`,
    date: `1754-55`,
    medium: `Oil on canvas`,
    link: `http://collections.soane.org/object-p78`,
    description: [`The final scene of <span class='italic'>The Humours of an Election</span> portrays the eighteenth-century election practice of <span class='italic'>Chairing the Member</span>, with the two winning Tory candidates being carried around town by their supporters on elaborate chairs. In typically Hogarthian style, the candidate in the foreground is starting to topple, the characters around him creating a complex vignette of interlinked mishaps. A goose flies over the candidate’s head: by comparison Lebrun’s celebrated painting <span class='italic'>The Battle of Arbela</span> depicted an eagle flying above the head of a victorious Alexander the Great, but this new MP is no Alexander. The whole is an analogy for the precarious state of political power won through bribery and corruption rather than through honest votes. `,
      `In front of the procession two men are fighting. A sailor with a peg-leg wearing Whig colours and a blue coat is brandishing a club at a white-shirted, ginger-haired country labourer, presumably supporting the Country or Tory interests, who in turn has swung his threshing tool behind his head to attack the sailor and accidentally hit one of the chair-men. As the candidate tumbles, his wife, dressed in peach and lace, watching the procession from behind the church wall, ‘faints away’ and needs to be supported by her Black maidservant. The graveyard’s consecrated ground has been desecrated by two cheeky chimney sweeps, their hands and bodies be-smirched with soot. They have scavenged a human skull-and-crossbones and perched on the gate-pier in front of the church to display it to the procession below, complete with spectacles.`,
      `Between the brawling labourer and the toppling chair, a sow and her piglets are charging towards the stream and have knocked a countrywoman over. This is a reference to the miracle of the Gadarene Swine - when Jesus cast the demons that possessed a madman into the bodies of pigs who then ran down a steep cliff into the sea. It indicates the mindless flight to danger and destruction implicit in the state of the rotten and venal British political system.`]
  },
  {
    id: `P79`,
    title: `<span class='italic'>An architectural study:  Subject, the national debt redemption office, and the cenotaph to the memory of the late Rt. Hon. W. Pitt </span>`,
    artist: `Joseph Michael Gandy (1771 - 1843)`,
    date: `1823`,
    medium: `Watercolour on paper`,
    link: `http://collections.soane.org/object-p79`,
    description: []
  },
  {
    id: `P80`,
    title: `<span class='italic'>Interior of the New 3<span class='superscript'>1/2</span>% Reduced Annuities Office at the Bank of England during construction in 1818</span>`,
    artist: `Joseph Michael Gandy (1771 - 1843)`,
    date: `1818`,
    medium: `Watercolour on paper`,
    link: `http://collections.soane.org/object-p80`,
    description: []
  },
  {
    id: `P81`,
    title: `<span class='italic'>Architectural Visions of early fancy, in the gay morning of Youth, and Dreams in the even'g of life</span>`,
    artist: `Joseph Michael Gandy (1771 - 1843)`,
    date: `1820 (exhib. RA)`,
    link: `http://collections.soane.org/object-p81`,
    medium: `Watercolour on paper`,
    description: [`The romantic title of this work, <span class='italic'>Architectural Visions of early fancy, in the gay morning of youth, and dreams in the even’g of life,</span> is inscribed on a rock in the right foreground, surrounded by Roman fragments.  It is a clue to the very personal character of this watercolour, which brings together Soane’s unbuilt works, setting them in a picturesque mountainous landscape beneath a dramatic sky.  Top left is Soane’s design for a mausoleum to his friend James King, drowned in a boating accident on the Thames in June 1776. The 22-year-old Soane, a student at the Royal Academy, had been invited on the outing, but declined due to the pressure of working on his Triumphal Bridge design for the RA Gold Medal competition. His refusal was fortuitous because: ‘as, like my regretted friend, I could not swim, the circumstance … preserved me from a watery grave’.  The placing of this poignant monument at almost the highest and most remote point of the composition perhaps reflects the fact that from this distant early moment of chance preservation, all his subsequent architectural projects flowed. Below it is the Triumphal Bridge itself, which won Soane the coveted Gold Medal for Architecture. This led to the award of a ‘travelling studentship’ by the Academy which enabled Soane to spend two formative years in Italy (1778-80).  In the right foreground (left of the title), is a triumphal arch, Soane’s design for a ceremonial ‘grand national’ entrance to London at Hyde Park Corner. Above it, are designs for a British Senate House and (standing out in white) a ‘National Monument’ commemorating the dead of the Napoleonic Wars. These unbuilt large-scale public buildings are a reminder of Soane’s unfulfilled wish to aggrandise London with a succession of grand neo-classical structures to rival those of Napoleon’s Paris. `]
  },
  {
    id: `P82`,
    title: `<span class='italic'>Bird’s-eye view of a design for a Royal Palace, from studies made in Rome, in 1779 </span>[by] J. Soane `,
    artist: `Joseph Michael Gandy (1771 - 1843)`,
    date: `1828 (exhib. RA)`,
    link: `http://collections.soane.org/object-p82`,
    medium: `Watercolour on paper`,
    description: []
  },
  {
    id: `P83`,
    title: `<span class='italic'>A design for a national entrance into the metropolis, intended to combine the classical simplicity of the Grecian architecture, the magnificence of the Roman architecture and the fanciful intricacy and playful effects of the Gothic architecture</span>`,
    artist: `Joseph Michael Gandy (1771 - 1843)`,
    date: `1826 (exhib. RA)`,
    link: `http://collections.soane.org/object-p83`,
    medium: `Watercolour on paper`,
    description: []
  },

  {
    id: `P84`,
    title: `<span class='italic'>Design, shewing part of the exterior and interior of the Bank of England</span>`,
    artist: `Joseph Michael Gandy (1771 - 1843)`,
    date: `1822`,
    link: `http://collections.soane.org/object-p84`,
    medium: `Watercolour on paper`,
    description: []
  },
  {
    id: `P85`,
    title: `<span class='italic'>Designs to combine in the same uniform style of architecture, the entrances into Hyde Park, St James’s Park and the Western entrance into the Metropolis. The designs, altered from those exhibited in the Royal Academy in 1817, are inscribed to his Grace the Duke of Wellington.</span>`,
    artist: `Joseph Michael Gandy (1771 - 1843)`,
    date: `1829`,
    link: `http://collections.soane.org/object-p85`,
    medium: `Watercolour on paper`,
    description: []
  },
  {
    id: `P86`,
    title: `<span class='italic'>Views of the Library, Breakfast Room, Study &c. at 13  Lincoln’s Inn Fields, 1822</span>`,
    artist: `Joseph Michael Gandy (1771 - 1843)`,
    date: `March 1822`,
    link: `http://collections.soane.org/object-p86`,
    medium: `Watercolour on paper`,
    description: []
  },
  {
    id: `P87`,
    title: `<span class='italic'>A selection of parts of buildings, public and private, erected from the designs of J. Soane, Esq. R.A. in the metropolis, and in other places of the United Kingdom, between the years 1780 and 1815</span>`,
    artist: `Joseph Michael Gandy (1771 - 1843)`,
    date: `1818`,
    link: `http://collections.soane.org/object-p87`,
    medium: `Watercolour on paper`,
    description: [`This imaginary assemblage of Soane’s built ‘public and private’ works in a Soanean interior, was exhibited at the RA in 1818, two years before its pendant work, <span class='italic'>Visions of early fancy…,</span> which began this tour and which illustrates unbuilt works in an imaginary landscape. `,
      `Here, Gandy uses drawings and models, the essentials of architectural practice, to depict Soane’s well-known buildings including the Bank of England (top centre), Dulwich Picture Gallery, the Royal Hospital at Chelsea and Soane’s own house (left) as well as his less well-known domestic work including Tyringham Hall (right), Pitzhanger Manor (to left of the Bank), McCartney House, Chillington Hall, Betchworth Stables, a family pew at Port Eliot, a church monument to Claude Bosanquet and numerous other projects, large and small. This compendium of Soane’s works is dramatically lit by an oil lamp concealed behind a large reflector bearing Soane’s coat of arms. On the domed ceiling above, based on that of Soane’s Breakfast Room, are two winged figures of Nike – the personification of victory in Greek mythology. In this scene, architecture is victorious.  `,
      `On the left is the Soane tomb, designed after the death of Soane’s wife Eliza, in which Soane and their elder son John are also buried, shown here draped in a black shroud (this watercolour was exhibited only two and a half years after her death).  In the right foreground we see the small figure of John Soane, at a table on which is a model of the Bank of England.  Gandy shows him dwarfed by the achievements of his architectural career – which still had more than a decade to run until his retirement in 1833 – in a work which might be called the apotheosis of Soane and which provides a fitting end to our tour. `]
  },
  {
    id: `P88`,
    title: `<span class='italic'>Design for completing the Board of Trade and new Council offices </span>`,
    artist: `Joseph Michael Gandy (1771 - 1843)`,
    date: `1828 (exhib. RA)`,
    link: `http://collections.soane.org/object-p88`,
    medium: `Watercolour on paper`,
    description: []
  },
  {
    id: `P89`,
    title: `<span class='italic'>The interior of a room erected in the year 1828, for the Society of Freemasons, under the auspices of the Grand Master, His Royal Highness the Duke of Sussex </span>`,
    artist: `Joseph Michael Gandy (1771 - 1843)`,
    date: `1829 (exhib. RA)`,
    medium: `Watercolour on paper`,
    link: `http://collections.soane.org/object-p89`,
    description: []
  },
  {
    id: `P90`,
    title: `<span class='italic'>Sketch of the first design for a New State-Paper Office, to be erected in Duke-street, Westminster, with a section shewing part of the interior, and a perspective plan of the ground floor</span> `,
    artist: `Joseph Michael Gandy (1771 - 1843)`,
    date: `1833 (exhib. RA)`,
    medium: `Watercolour on paper`,
    link: `http://collections.soane.org/object-p90`,
    description: []
  },
  {
    id: `P91`,
    title: `<span class='italic'>Design for a triumphal bridge</span> `,
    artist: `Joseph Michael Gandy (1771 - 1843)`,
    date: `1799`,
    medium: `Watercolour on paper`,
    link: `http://collections.soane.org/object-p91`,
    description: []
  },
  {
    id: `P92`,
    title: `<span class='italic'>Birds-eye view of a Design for a Triumphal Bridge</span>`,
    artist: `Joseph Michael Gandy (1771 - 1843)`,
    date: `<span class='italic'>c.</span>1820s`,
    medium: `Watercolour on paper`,
    link: `http://collections.soane.org/object-p92`,
    description: []
  },
  {
    id: `P93`,
    title: `<span class='italic'>General view of a design for a royal residence</span>`,
    artist: `Joseph Michael Gandy (1771 - 1843)`,
    date: `1827`,
    medium: `Watercolour on paper`,
    link: `http://collections.soane.org/object-p93`,
    description: []
  },
  {
    id: `P94`,
    title: `<span class='italic'>View of the Library and Drawing Room in a Villa at Ealing built by Sir John Soane in the years 1801-3</span>`,
    artist: `Joseph Michael Gandy (1771 - 1843)`,
    date: `1802`,
    medium: `Watercolour on paper: pen, cerulean blue, Antwerp blue, crimson, Potter's pink, Naples yellow, terre verte, Payne's grey, ochre and sepia washes.`,
    link: `http://collections.soane.org/object-p94`,
    description: []
  },
  {
    id: `P95`,
    title: `<span class='italic'>View of the Breakfast Room in a Villa at Ealing built by Sir John Soane in the years 1801-3</span>`,
    artist: `Joseph Michael Gandy (1771 - 1843)`,
    date: `1802`,
    medium: `Watercolour on paper`,
    link: `http://collections.soane.org/object-p95`,
    description: []
  },
  {
    id: `P96`,
    title: `Architectural composition of framed perspectives and models of buildings designed and executed by Sir John Soane`,
    artist: `Joseph Michael Gandy (1771 - 1843)`,
    date: `<span class='italic'>c.</span>1824`,
    medium: `Watercolour on paper`,
    link: `http://collections.soane.org/object-p96`,
    description: []
  },
  {
    id: `P97`,
    title: `<span class='italic'>Interior of a Sepulchral Chamber</span>`,
    artist: `Charles Louis Clérisseau (1721 - 1820)`,
    date: `1773`,
    medium: `Gouache on paper`,
    link: `http://collections.soane.org/object-p97`,
    description: []
  },
  {
    id: `P98`,
    title: `<span class='italic'>Design for a mausoleum for the Earl of Chatham</span>`,
    artist: `Joseph Michael Gandy (1771 - 1843)`,
    date: `1799`,
    link: `http://collections.soane.org/object-p98`,
    medium: `Watercolour on paper`,
    description: []
  },
  {
    id: `P99`,
    title: `<span class='italic'>A Scene from Coriolanus, with a portrait of J P Kemble as Coriolanus</span>`,
    artist: `Sir Peter Francis Bourgeois (1756 - 1811)`,
    date: `1790s`,
    link: `http://collections.soane.org/object-p99`,
    medium: `Oil on canvas`,
    description: [`John Philip Kemble (1757-1823) was the finest actor of his generation, famed for his tragic Shakespearian roles, of which his Coriolanus, which he first played on 7 February 1789, was said to be the best. He was a handsome man with aquiline features, who appeared to advantage in Roman costume. Kemble was an attractive subject for the later Georgian artists who venerated history painting as the highest form of the art and frequently conflated it with portraits of the celebrated personalities of the era.`,
      `This painting of 'Mr. Kemble in the character of Coriolanus’, which was exhibited at the Royal Academy in the 1790s, exemplifies many aspects of Soane’s collecting. The subject matter appealed to him. His love of Shakespeare is reflected in numerous paintings as well as busts, including two of Kemble, his ownership of all the folios of Shakespeare’s works and the creation of a Shakespeare Recess off the staircase at 13 Lincoln’s Inn Fields. `,
      `The artist Sir Francis Bourgeois was a fellow Royal Academician and close friend of John Soane. Soane designed Bourgeois’ combined Mausoleum and Picture Gallery at Dulwich without a fee and a portrait of Bourgeois by Beechey hangs in the Picture Room, above the door, almost on the sight line with <span class='italic'>Kemble as Coriolanus</span> when the south planes are opened, a multi-layered narrative typical of Soane’s strategic acquisition and arrangement of his collection.`]
  },
  {
    id: `P100`,
    title: `Study of a man’s head (one of the Fathers?)`,
    artist: `Unknown Italian artist`,
    date: `1699`,
    link: `http://collections.soane.org/object-p100`,
    medium: `Oil on canvas`,
    description: []
  },
  {
    id: `P101`,
    title: `Dr Messenger Monsey (1693-1788), physician to the Royal Hospital Chelsea`,
    artist: `Dr John Wolcot, also known as "Peter Pindar" (1738-1819) `,
    date: `1788`,
    link: `http://collections.soane.org/object-p101`,
    medium: `Pastel`,
    description: [`This small pastel portrait of Dr Messenger Monsey, Physician to the Royal Hospital at Chelsea for 50 years, hangs in the Picture Room Recess. Numerous stories are recorded of Monsey’s eccentric behaviour: one tells of his hiding bank notes in the fireplace only to find his housekeeper lighting a fire to boil a kettle. He threw water over the fire and the assembled company. He took a boat to the Bank to redeem his notes for cash but dropped them in the river and arrived with a hatful of singed and soaking notes! Monsey bequeathed his body for dissection and wrote a note himself to alert the surgeon a few days before his death.   `,
      `In his memoir <span class='italic'>Records of my Life</span>, Soane’s friend John Taylor, Editor of the Sun, recalls that he introduced the artist Dr Wolcot (also known by the pseudonym ‘Peter Pindar’) to Dr Monsey a few months before Monsey’s death in 1788. Wolcot then painted this portrait which Taylor describes as ‘an admirable likeness’. Taylor goes on to recall that ‘Wolcot and Monsey did not harmonise … Therefore, when the portrait was finished, Monsey desired that I would bring Wolcot no more’.  `,
      `Taylor presented the portrait to Soane many years later, in 1832. Soane probably never met Messenger Monsey but Taylor must have thought his friend would value the Chelsea connection. Soane had been Surveyor to the Royal Hospital since 1807 and his official house there, with a garden, had proved a welcome refuge after the death of his wife, Eliza, in 1815.  Among the buildings Soane designed at Chelsea was a new Infirmary, completed in 1814 and he had become firm friends with William Somerville (1771-1860), a successor to Monsey as the Royal Hospital Physician from 1819, and his wife Mary (1780-1872), the celebrated mathematician and astronomer, as well as others living close to the Hospital. `],
  },
  {
    id: `P102`,
    title: `<span class='italic'>The Remains of the Forum of Nerva, Rome</span>`,
    artist: `Charles Louis Clérisseau (1721 - 1820)`,
    date: `<span class='italic'>c.</span>1760s - 70s`,
    link: `http://collections.soane.org/object-p102`,
    medium: `Gouache on paper`,
    description: [`The French architect Charles Louis Clérisseau moved to Rome in 1749 and had a seminal influence on the evolution of neo-classicism.  Clérisseau taught Robert Adam to draw in the 1750s while he was on his Grand Tour, Adam writing of him: '... [he had] the utmost knowledge of architecture, of perspective, and of designing and colouring I ever saw or had any conception of. He raised my ideas. He created emulation and fire in my breast.' `,
      `Clérisseau produced numerous imaginary assemblages (<span class='italic'>capricci</span>) of Roman ruins but also views showing them as they appeared in his time. This one shows the remains of the Forum of Nerva (96-98 AD), originally a colonnaded piazza 114 metres long but only 45 metres wide. This view shows two surviving columns of the more than 50 that once decorated its long sides. The carved entablature, depicting mythological scenes associated with the Goddess Minerva, originally ran for hundreds of metres round the perimeter.  The female figure with helmet and shield visible above the frieze was thought to be Minerva but is now identified as a personification of the Pirusti, a Balkan people subdued by the Romans. `,
      `Clérisseau shows small figures carrying on their everyday lives dwarfed by the ruins. Labourers are at work, one pushing a wheelbarrow loaded with stone fragments, while two men wearing tricorne hats, perhaps a grand tourist with his cicerone, inspect the Forum.  An illegible sign projecting from a window in the tufa wall, in another version of this drawing (Christie’s 2015) clearly reads ‘VINO’ and advertises a wine bar or shop.  `,
      `The Forum of Nerva is the first known use of columns breaking forward for purely decorative purposes, a feature Soane adapted for the façades of his country house, Pitzhanger Manor and the entrance to the Lothbury Court at the Bank of England.`]
  },
  {
    id: `P103`,
    title: `<span class='italic'>Architectural ruins</span>`,
    artist: `Charles Louis Clérisseau (1721 - 1820)`,
    date: `<span class='italic'>c.</span>1760s - 70s`,
    link: `http://collections.soane.org/object-p103`,
    medium: `Gouache on paper`,
    description: []
  },
  {
    id: `P104`,
    title: `<span class='italic'>Architectural ruins</span>`,
    artist: `Charles Louis Clérisseau (1721 - 1820)`,
    date: `<span class='italic'>c.</span>1760s - 70s`,
    link: `http://collections.soane.org/object-p104`,
    medium: `Gouache on paper`,
    description: []
  },
  {
    id: `P105`,
    title: `<span class='italic'>View of a design for the north-west corner of the Bank of England<s/span>`,
    artist: `Attributed to Joseph Michael Gandy (1771 - 1843)`,
    date: `1803`,
    link: `http://collections.soane.org/object-p105`,
    medium: `Watercolour on paper`,
    description: []
  },
  {
    id: `P106`,
    title: `<span class='italic'>View  of the Bank of England leading from the Pay hall to the Cashiers Office; A design for the Entrance Hall at Macartney House Blackheath, the seat of the Rt Honble G Lyttleton; Another view of the corridor at the Bank of England. </span>[Three Drawings in one frame]. `,
    artist: `Henry Hake Seward (1778 - 1848)`,
    date: `<span class='italic'>c.</span>1802-September 1808`,
    link: `http://collections.soane.org/object-p106`,
    medium: `Watercolour on paper`,
    description: []
  },
  {
    id: `P107`,
    title: `<span class='italic'>View of a design for the Exterior of a new House of Lords made in obedience to an order of the Committee of the House of Lords in 1794</span>`,
    artist: `Henry Hake Seward (1778 - 1848)`,
    date: `1794`,
    link: `http://collections.soane.org/object-p107`,
    medium: `Watercolour on paper`,
    description: []
  },
  {
    id: `P108`,
    title: `<span class='italic'>St Huges denouncing Vengeance on the Shepherd of Cormayer, in the Valley of d’Aoust</span>`,
    artist: `Joseph Mallord William Turner (1775 - 1851)`,
    date: `1802-03 `,
    link: `http://collections.soane.org/object-p108`,
    medium: `Watercolour on paper`,
    description: [`J M W Turner was a close friend of John Soane’s and this is one of three paintings by Turner that Soane owned. Turner took advantage of a hiatus in the Napoleonic Wars in 1802 to travel via Paris to the Alps. During his tour he sketched intensively and upon his return to London used the sketches to inform new large-scale works, of which this was one of the first, and, rather unusually, a watercolour. It shows St Hugues denouncing Vengeance on the Shepherd of Cormayeur, set in the Val d’Aosta, with the town of Cormayeur visible in the background. The day is stormy but beyond the dark swirling clouds Mont Blanc can be seen. `,
      `The title is the one Turner exhibited the painting under at the Royal Academy in 1803 but it is puzzling. St Hugues (1053-1132) was one of the co-founders of the Carthusian order of monks and Cormayeur is some distance from where he was based, nor does the incident appear in records of his life. The scene may be a figment of Turner’s imagination based on St Hugues’ propensity for stamping out abuses in the Church and laity. `,
      `The painting was bought by Mrs Soane in 1804 from Turner’s private gallery for £52 10s, a large sum for a watercolour rather than an oil painting. Soane had travelled through the Alps himself in 1780 and noted in his travel diary the view of ‘black gloomy summits’ covered in ‘perpetual snow’. It may be that Eliza Soane felt that the painting would particularly appeal to Soane given its setting. `,
      `When Eliza died a little over a decade later, Turner had become such a close friend that he was the only person whose company Soane could bear during the Christmas of 1815.`]
  },
  {
    id: `P109`,
    title: `Architectural composition with the pyramid of Caius Cestius, Rome, introduced`,
    artist: `Jean-Baptiste Lallemand (1716 - 1803) previously attributed to Charles Louis Clérisseau (1721 - 1820)`,
    date: `<span class='italic'>c.</span>1746-61`,
    link: `http://collections.soane.org/object-p109`,
    medium: `Gouache on paper`,
    description: []
  },
  {
    id: `P110`,
    title: `Architectural Ruins`,
    artist: `Charles Louis Clérisseau (1721 - 1820)`,
    date: `1765`,
    link: `http://collections.soane.org/object-p110`,
    medium: `Gouache on paper`,
    description: []
  },
  {
    id: `P111`,
    title: `<span class='italic'>L’Accordée du Village</span>`,
    artist: `Antoine Watteau (1684 - 1721)`,
    date: `<span class='italic'>c.</span>1710-15`,
    link: `http://collections.soane.org/object-p111`,
    medium: `Oil on canvas`,
    description: []
  },
  {
    id: `P112`,
    title: `<span class='italic'>Engraving of the transparent painting exhibited on the façade of the Bank of England at the illuminations in celebration of the complete Recovery of George III from madness and his Public Visit to St Paul's Cathedral on 24 April 1789.</span>`,
    artist: `William Hamilton RA (1751 - 1801), Peltro William Tomkins (1760 - 1840), engraver J. F. Tomkins, publisher`,
    date: `1790`,
    link: `http://collections.soane.org/object-p112`,
    medium: `Coloured Engraving`,
    description: []
  },
  {
    id: `P113`,
    title: `<span class='italic'>Views of parts of the Museum &c. in Lincoln’s Inn Fields, as arranged about the year 1822</span>`,
    artist: `Joseph Michael Gandy (1771 - 1843)`,
    date: `1822`,
    link: `http://collections.soane.org/object-p113`,
    medium: `Watercolour on paper`,
    description: [`This composite view of interiors at Soane’s own house was exhibited at the Royal Academy with its pair (SM P86) and depicts areas at the back of the house, which Soane called his ‘Museum’, lit from above through skylights and hung floor to ceiling with plaster casts and marble fragments. Executed in a limited palette of natural earth colours and mixed greys, the sense of depth in the watercolour views is heightened by the solid areas of gold leaf applied around the irregular outlines of the drawings. The gold also helps to create the sense that the spaces are lit by an almost ethereal glowing light.  1822 was the first time any views of the finished interiors at No 13 had been exhibited: the word ‘Museum’ did not appear in the titles which instead emphasised that these were views of a private house, rather coyly not naming Soane. `,
      `The exhibition of these views of Soane’s house in 1822 was prompted by significant changes made at the back of the house in 1821, the previous year, when Soane had abolished his Lower Office, turning what had been work-space into display space (centre top and in the left-hand view).  The roof of the upper office or ‘Students’ Room’ above (shown top right) where clerks and pupils carried out the work of his architectural practice, had been raised. Many of the casts fixed to its walls, beams and ceiling are still in situ.  Soane’s first Picture Room (lower right) was at the back of No. 12 Lincoln’s Inn Fields between 1819 and 1825, painted a Pompeian red colour, like that used at Dulwich Picture Gallery, England’s first public art gallery, which Soane had designed a few years earlier. On its left-hand wall you can glimpse Hogarth’s famous series <span class='italic'>A Rake’s Progress.</span>`]
  },
  {
    id: `P114`,
    title: `<span class='italic'>Perspective view of the east and part of the south front of the Bank of England as rebuilt 1816-18</span>`,
    artist: `Joseph Michael Gandy (1771 - 1843)`,
    date: `<span class='italic'>c.</span>1818-25`,
    link: `http://collections.soane.org/object-p114`,
    medium: `Watercolour on paper`,
    description: []
  },
  {
    id: `P115`,
    title: `<span class='italic'>Temple of Augustus, Pola, Istria</span>`,
    artist: `Charles Louis Clérisseau (1721 - 1820)`,
    date: `<span class='italic'>c.</span>1757`,
    link: `http://collections.soane.org/object-p115`,
    medium: `Gouache on paper`,
    description: []
  },
  {
    id: `P116`,
    title: `<span class='italic'>Venus mourning Adonis</span>`,
    artist: `Richard Cosway RA (1742 - 1821)`,
    date: `<span class='italic'>c.</span>1805-15`,
    link: `http://collections.soane.org/object-p116`,
    medium: `Watercolour over pencil`,
    description: []
  },
  {
    id: `P117`,
    title: `<span class='italic'>Architectural ruins</span>`,
    artist: `Giovanni Paolo Panini (1695 - 1764)`,
    date: `<span class='italic'>c.</span>1705 - 64`,
    link: `http://collections.soane.org/object-p117`,
    medium: `Ink and sepia wash`,
    description: []
  },
  {
    id: `P118`,
    title: `<span class='italic'>View of the Bank of England taken from the north-west angle, as erected in 1805</span>`,
    artist: `Joseph Michael Gandy (1771 - 1843)`,
    date: `1824`,
    link: `http://collections.soane.org/object-p118`,
    medium: `Watercolour on paper`,
    description: [`John Soane was appointed Surveyor to the Bank of England on 16 October 1788 aged just 35.   His name was put forward for this prestigious role through his friendship with Thomas Pitt, a cousin of the Prime Minister William Pitt the Younger.  Soane remained the Bank’s architect for 45 years, retiring in 1833 aged 80.  During this remarkable tenure, he completely rebuilt the Bank, creating its distinctive appearance as a windowless citadel at the heart of the City of London and thereby helping to establish its image as a national institution.  Soane commented that being Surveyor to the Bank was the ‘Pride and Boast of my life’. `,
      `This view, made for him by Gandy, shows the north-west corner of the Bank, at the junction of Lothbury and Princes Street. The low viewpoint emphasises not only the dramatic perspective created by the two façades but also the height of the corner structure, Soane’s famous ‘Tivoli Corner’, designed and built in 1804-05.  The drama is heightened by the monumental austerity of the building, set against a blue sky and without a single human figure in the surrounding streets.  `,
      `The Tivoli Corner was based on the circular Roman Temple of Vesta, at Tivoli, near Rome, which was one of Soane’s favourite classical buildings and one that provided him with rich source material for his own designs. He adapted the Tivoli Corinthian order for use at the Bank and there are many examples of its various elements in his museum, including full-size capitals in the columns in the Monument Court and Monk’s Yard.   `,
      `This view was drawn many years after the Tivoli Corner was constructed, for exhibition at the Royal Academy in 1824 and perhaps with display in the newly constructed Picture Room Recess, directly above a large model of one of the Bank’s façades, in mind.`]
  },
  {
    id: `P119`,
    title: `Architectural ruins: Interior of a sepulchral chamber`,
    artist: `Charles Louis Clérisseau (1721 - 1820)`,
    date: `1773 [signed LH corner according to Green Box and given as 1773 in 1905 and 1910 Description]`,
    link: `http://collections.soane.org/object-p119`,
    medium: `Gouache on paper`,
    description: []
  },
  {
    id: `P120`,
    title: `<span class='italic'>Andromache and Astyanax</span>`,
    artist: `Richard Cosway RA (1742 - 1821)`,
    date: `1789`,
    link: `http://collections.soane.org/object-p120`,
    medium: `Watercolour over pencil on paper`,
    description: []
  },
  {
    id: `P121`,
    title: `<span class='italic'>Architectural ruins</span>`,
    artist: `Giovanni Paolo Panini (1695 - 1764)`,
    date: `<span class='italic'>c.</span>1705 - 64`,
    link: `http://collections.soane.org/object-p121`,
    medium: `Ink and sepia wash`,
    description: []
  },
  {
    id: `P122`,
    title: `<span class='italic'>Interior of a bath</span>`,
    artist: `Charles Louis Clérisseau (1721 - 1820)`,
    date: `<span class='italic'>c.</span>1760s - 70s`,
    link: `http://collections.soane.org/object-p122`,
    medium: `Gouache on paper`,
    description: []
  },
  {
    id: `P123`,
    title: `<span class='italic'>View of a design made by Sir John Soane in the year 1817 for A Royal Residence, and exhibited at the Royal Academy 1821</span>`,
    artist: `Joseph Michael Gandy (1771 - 1843)`,
    date: `1821`,
    link: `http://collections.soane.org/object-p123`,
    medium: `Watercolour on paper`,
    description: []
  },
  {
    id: `P124`,
    title: `<span class='italic'>View of the east and north fronts of the Bank of England</span>`,
    artist: `Joseph Michael Gandy (1771 - 1843)`,
    date: `?1824`,
    link: `http://collections.soane.org/object-p124`,
    medium: `Watercolour on paper`,
    description: []
  },
  {
    id: `P125`,
    title: `Study for <span class='italic'>Différentes vues de Pesto...,</span> Plate IX. The interior of the Basilica, looking east, with the three surviving columns of the <span class='italic'>cella</span> and the <span class='italic'>pronaos</span> in the distance`,
    artist: `Giovanni Battista Piranesi (1720 - 1778)`,
    date: `<span class='italic'>c.</span>1777-78`,
    link: `http://collections.soane.org/object-p125`,
    medium: `Black and red chalk, pencil, brown and grey washes, pen and ink `,
    description: []
  },
  {
    id: `P126`,
    title: `Sketch Design for the ceiling of the Queen's State Bedchamber at Hampton Court Palace`,
    artist: `Sir James Thornhill (1675 - 1734)`,
    date: `1715`,
    link: `http://collections.soane.org/object-p126`,
    medium: `Oil on canvas`,
    description: []
  },
  {
    id: `P127`,
    title: `<span class='italic'>Architectural Ruins: A Vision</span>`,
    artist: `Joseph Michael Gandy (1771 - 1843)`,
    date: `1798`,
    link: `http://collections.soane.org/object-p127`,
    medium: `Pen and watercolour on paper`,
    description: []
  },
  {
    id: `P128`,
    title: `<span class='italic'>Remains of the Temples of Antoninus and Faustina and the three columns in the Campo Vaccino </span>[Forum Romanum], <span class='italic'>Rome</span>`,
    artist: `Charles Louis Clérisseau (1721 - 1820)`,
    date: `<span class='italic'>c.</span>1760s`,
    link: `http://collections.soane.org/object-p128`,
    medium: `Gouache on paper`,
    description: []
  },
  {
    id: `P129`,
    title: `<span class='italic'>Interior of an ancient apartment</span> `,
    artist: `Charles Louis Clérisseau (1721 - 1820)`,
    date: `1763`,
    link: `http://collections.soane.org/object-p129`,
    medium: `Gouache on paper`,
    description: []
  },
  {
    id: `P130`,
    title: `<span class='italic'>Architectural ruins: a composition</span> `,
    artist: `Antonio Zucchi (1726 - 1795)`,
    date: `<span class='italic'>c.</span>1736-95`,
    link: `http://collections.soane.org/object-p130`,
    medium: `Ink, grey wash, sepia and bodycolour`,
    description: []
  },
  {
    id: `P131`,
    title: `<span class='italic'>Architectural ruins</span>`,
    artist: `Antonio Zucchi (1726 - 1795)`,
    date: `<span class='italic'>c.</span>1736-95`,
    link: `http://collections.soane.org/object-p131`,
    medium: `Ink, grey wash, sepia and bodycolour`,
    description: []
  },
  {
    id: `P132`,
    title: `<span class='italic'>Veduta dell' Anfiteatro Flavio, detto il Colosseo from Vedute di Roma</span>`,
    artist: `Giovanni Battista Piranesi (1720 - 1778)`,
    date: `1760-1778`,
    link: `http://collections.soane.org/object-p132`,
    medium: `Etching`,
    description: [`This etching of the Flavian Amphitheatre, known as the Colosseum, is one of a series of plates executed by Piranesi for his <span class='italic'>Vedute di Roma</span> [Views of Rome] which were printed and sold as individual sheets between the 1740s and his death in 1778. It captures the architectural detail and the drama of the ruins, dwarfing the tiny, animated figures of Romans and visitors in the foreground and the adjacent Arch of Constantine visible in the distance on the right. The different elements of the structure are identified with a lettered key in the lower margin.  The Colosseum was one of the first Roman buildings Soane examined after he arrived in Rome on 2 May 1778.  Although an unusual building type it nonetheless provided an important exemplar in the study of Roman architecture that was the basis of his architectural training.  `,
      `Years later, as Professor of Architecture at the Royal Academy, in his fourth lecture, Soane commented to his architectural students that his illustrations could ‘give but faint ideas of the Colosseum, a magnificent and mighty structure in its perfect state, worthy of the majesty of ancient Rome.  What a splendid sight must it have been when eighty thousand spectators were seated within its walls; but how changed, how melancholy in its present wretched state, majestic, however, even in its venerable ruins’. Soane was critical elsewhere in his lectures of the use of half rather than full columns on the amphitheatre, saying that this was architecture in ‘basso relievo’ [low relief] lacking ‘true sublimity and austerity’ and without the ‘majestic and impressive effect’ of the full columns used in ancient temples. He noted that while the superimposition of four classical orders one above the other on the exterior might produce a ‘grand effect’ on a building the size of the Colosseum, when used by later architects on much smaller buildings perhaps only 40 or 50 feet high ‘they look like threadpapers and walking sticks stuck against the wall’!  `]
  },
  {
    id: `P133`,
    title: `Study for <span class='italic'>Différentes vues de Pesto...,</span> Plate VIII. The interior of the Basilica, looking north, with the <span class='italic'>pronaos</span> to the right and the Temple of Neptune in the distance on the left`,
    artist: `Giovanni Battista Piranesi (1720 - 1778)`,
    date: `<span class='italic'>c.</span>1777-78`,
    link: `http://collections.soane.org/object-p133`,
    medium: `Red and black chalk, pencil, brown and grey washes, pen and ink `,
    description: []
  },
  {
    id: `P134`,
    title: `<span class='italic'>View of a Design for an Entrance Gateway to a Park </span>[Bagshot Park, Surrey]`,
    artist: `Joseph Michael Gandy (1771 - 1843)`,
    date: `1799`,
    link: `http://collections.soane.org/object-p134`,
    medium: `Watercolour on paper`,
    description: [`Soane produced designs for the entrance to Bagshot at the request of His Majesty’s Woods and Forests, an official department to which Soane was appointed Deputy Surveyor in 1795. The work was on behalf of the Duke of Clarence, later William IV, who lived at Bagshot Park until 1816. The house itself, south of Windsor within the Great Park, was rebuilt in 1877 and remains a royal residence. `,
      `This watercolour was made for the Royal Academy exhibition in 1799, with its title (as in the catalogue) in severe classical <span class='italic'>sans serif</span> letters on a <span class='italic'>trompe l’oeil</span> recessed stone panel.  The style of frame is that which Soane commonly commissioned in the 1790s: the Office Day Books record that Soane’s own pupils habitually framed works for exhibition in the Office at Lincoln’s Inn Fields. `,
      `This small building demonstrates Soane’s pleasure in the use of simple, often local, materials for picturesque effect. Here black knapped flints are used in the pilasters, making them stand out against the surrounding brick and creating a sense of contrast, movement and variety in the façade.  Soane also uses one of his trademark brick cornices, as at 12 and 14 Lincoln’s Inn Fields. A pleasing geometry is set up by the stylish arch and metal gates, which combine to form a circular opening when closed.  On top is the royal coat-of-arms (a lion and unicorn flanking a central shield) perhaps made of Coade Stone. `,
      `Gandy’s view of this modest structure shows exactly why Soane employed him regularly over three decades to produce watercolours of his designs – he provides a romantic verdant setting for the building, bathed in the golden light of a distant sunrise or sunset.`]
  },
  {
    id: `P135`,
    title: `<span class='italic'>View of Tyringham Hall in Buckinghamshire, the seat of W. Praed Esq.</span>`,
    artist: `Joseph Michael Gandy (1771 - 1843)`,
    date: `<span class='italic'>c.</span>1798`,
    link: `http://collections.soane.org/object-p135`,
    medium: `Watercolour on paper`,
    description: [`The banker William Mackworth Praed (1747-1833) acquired the Tyringham estate in Buckinghamshire on his marriage to Elizabeth Tyringham in 1778.  Soane met Praed through the Marquess of Buckingham (for whom he had built Buckingham House in 1790) who took Soane to visit Tyringham Hall in August 1792.  Soane surveyed the old Elizabethan manor house in September and produced a number of variant designs for its alteration (including one in the Gothic style).  In June 1793 Praed agreed to the construction of a completely new house. Soane was also commissioned to build a bridge, stables and an entrance lodge, which still survive, and designed a chapel which was never built.  The Praeds moved into their new house in 1797.  In July 1798 Gandy visited the Tyringham estate for nine days to make views, of which this is probably one.  Here he depicts Soane’s building with its characteristic incised decoration, giant Ionic order and panels of Greek key decoration, bathed in golden light. `,
      `Soane and Praed obviously formed a close working relationship and when, in 1801, William Praed opened a bank at 189 Fleet Street, London, Soane obtained another commission, designing the new Bank building.  `,
      `Praed’s wife and her sister Sarah inherited estates and enslaved people in Jamaica from their uncle, James Gibbon. The sale of this Jamaican inheritance was intended to fund the construction of Tyringham Hall - its delay explains why Soane, despite completing the work in the 1790s was not paid the final instalment of £13,000 for the house until 1817.  `,
      `In his <span class='italic'>Memoirs</span>, written in the 1830s, Soane wrote of Tyringham: 'This Villa with its numerous offices, greenhouses, hot houses and extensive stabling, the great bridge and the lodge, was completed and occupied in the year 1797, after having engaged a large portion of six of the most happy years of my life.'`],
  },
  {
    id: `P136`,
    title: `Architectural ruins: view of the Temple of Augustus at Pola, (Pula, Croatia)`,
    artist: `Charles Louis Clérisseau (1721 - 1820)`,
    date: `<span class='italic'>c.</span>1760s - 70s`,
    link: `http://collections.soane.org/object-p136`,
    medium: `Gouache on paper`,
    description: []
  },
  {
    id: `P137`,
    title: `<span class='italic'>Architectural ruins</span>`,
    artist: `Charles Louis Clérisseau (1721 - 1820)`,
    date: `1763`,
    link: `http://collections.soane.org/object-p137`,
    medium: `Gouache on paper`,
    description: []
  },
  {
    id: `P138`,
    title: `Architectural ruins: view of a tomb between Caserta and Capua`,
    artist: `Charles Louis Clérisseau (1721 - 1820)`,
    date: `<span class='italic'>c.</span>1760s - 70s`,
    link: `http://collections.soane.org/object-p138`,
    medium: `Gouache on paper`,
    description: [`This view of the so-called <span class='italic'>Carceri Vecchie</span> mausoleum close to the ruins of ancient Capua (Santa Maria Capua Venere) may be based on drawings made by Clérisseau when he was in the area with the Scottish architect Robert Adam in April 1755.  A sketch by Adam of the same mausoleum is in one of the 57 volumes of Adam drawings collected by Soane, along with another drawing of ruins at Capua dated 20 April 1755.  Clérisseau in his role as teacher frequently sat beside Adam making drawings of the same Roman buildings.`,
      `On his own later Grand Tour Soane visited Capua on 29 December 1778 on a journey to Naples with his early patron the Earl-Bishop of Derry and make sketches of the amphitheatre. He does not comment on or sketch this specific tomb but returned to Capua in Spring 1779 and would certainly have noted it as one of two significant ancient tombs in the area.  `,
      `The mausoleum probably dates from the first half of the second century AD and is beside the ancient Via Appia. In his Royal Academy lecture 11, Soane admired ‘the innumerable sepulchral buildings on the Appian Way and other public roads leading to Rome’. In lecture 4 he went further, noting that roads to Rome were ‘lined with magnificent monuments’ and that they were all over the Roman <span class='italic'>Campagna</span> [countryside].  He commented, quoting the poet Alexander Pope, that with the presence of these ‘immense and mighty ruins … Rome her own sad monument appears’.  A footnote to this passage laments: ‘Would to God this practice of placing tombs and sepulchral buildings on the sides of our public road existed amongst us instead of hiding them in Westminster Abbey’.  `]
  },
  {
    id: `P139`,
    title: `Study for <span class='italic'>Différentes vues de Pesto...,</span> Plate XVI. The interior of the Temple of Neptune from within the <span class='italic'>cella</span> area, looking east and showing the internal superimposed colonnades`,
    artist: `Giovanni Battista Piranesi (1720 - 1778)`,
    date: `<span class='italic'>c.</span>1777-78`,
    link: `http://collections.soane.org/object-p139`,
    medium: `Black chalk, pencil, brown and grey washes, pen and ink`,
    description: []
  },
  {
    id: `P140`,
    title: `Study for <span class='italic'>Différentes vues de Pesto...</span>, Plate XVII. The Temple of Neptune looking through the peristyle from the north-west corner showing the internal colonnades, with the Basilica in the distance`,
    artist: `Giovanni Battista Piranesi (1720 - 1778)`,
    date: `<span class='italic'>c.</span>1777-78`,
    link: `http://collections.soane.org/object-p140`,
    medium: `Pencil, brown and grey washes, red chalk, pen and ink `,
    description: []
  },
  {
    id: `P141`,
    title: `<span class='italic'>A Landscape with classical figures</span>`,
    artist: `Francesco Zuccarelli (1702 - 1788)`,
    date: `<span class='italic'>c.</span>1712-88`,
    link: `http://collections.soane.org/object-p141`,
    medium: `Oil on canvas`,
    description: []
  },
  {
    id: `P142`,
    title: `<span class='italic'>Portrait of Sir John Soane, in Masonic Costume, as Grand Superintendent and President of the Board of Works</span>`,
    artist: `John Jackson (1778 - 1831)`,
    date: `1828-29`,
    link: `http://collections.soane.org/object-p142`,
    medium: `Oil on panel`,
    description: [`In 1828 the British Institution commissioned a portrait of John Soane ‘by order of the Directors … as one of its most liberal Benefactors', from the artist John Jackson. It shows Soane as an elderly man in a red velvet coat, looking slightly quizzical, holding his spectacles and with a copy of his Lectures on Architecture delivered at the Royal Academy open in front of him (National Portrait Gallery).  An entry in Soane's Notebook on 12 June 1828 records ‘Mr Jackson at 11 – last sitting'.  However, on the same day, Soane paid the artist £105 for another, smaller, portrait of himself – this one – to commemorate the completion of his new Freemasons’ Hall along with ‘a picture as pendant of Mrs Soane’ (the next work on this tour).  During the remaining months of 1828 and into 1829 Soane records 4 sittings to Jackson specifically for this portrait and the loan to the artist of a box of masonic regalia to work from between sittings (presumably the apron and pendant device).   `,
      `There is no correspondence with Jackson in the Soane archive but, although they were not close friends, they would have known each other through the Academy. Jackson came from humble origins like Soane, being the son of a Yorkshire tailor. He was said to be easily distracted from work, but he had a fine eye for colour. Benjamin Haydon described his portraits as ‘flesh and blood’ contrasting them unfavourably with the fine work of the more famous Sir Thomas Lawrence.  His charges were always modest – about 50 guineas for a half-length portrait - exactly what Soane paid for his two portraits (1 guinea = 1 pound 1 shilling), meaning his income was seldom more than £1,500 per annum.  When he died in June 1831 his family was left penniless. `]
  },
  {
    id: `P143`,
    title: `<span class='italic'>The Italian Count</span>`,
    artist: `Henry Fuseli RA (1741 - 1825)`,
    date: `<span class='italic'>c.</span>1780`,
    link: `http://collections.soane.org/object-p143`,
    medium: `Oil on canvas `,
    description: [`Henry Fuseli was the most learned painter of his age, studying literature, aesthetics, Greek and Latin in his native Switzerland: the <span class='italic'>Iliad</span> and <span class='italic'>Odyssey</span>, the <span class='italic'>Nibelungenlied</span>, Dante's <span class='italic'>Divina commedia</span>, Shakespeare's tragedies, and Milton's <span class='italic'>Paradise Lost</span> provided life-long inspiration for his art. He was both original and influential as well as notorious for his sensational, demonic, or bizarre subjects. The artist Maria Cosway, a friend of Soane and contemporary of Fuseli’s, wrote that ‘Fusely [sic] with his extraordinary visions struck my fancy’. Fuseli's was one of the defining artists of the romantic movement which emphasised the expression of personal feelings and human psychology in art.`,
      `Whilst working in London as a journalist and book illustrator in 1768, Fuseli met Sir Joshua Reynolds who urged him to concentrate on painting. After an extended period studying in Italy, Fuseli returned to London in 1779 and during the 1780s established his reputation as a history painter. Fuseli and Soane were in Italy at the same time. They shared the experience of launching their careers based on connections they made there, and both consolidated their reputations by membership of the Royal Academy where Fuseli was elected Professor of Painting in 1799 and Soane Professor of Architecture in 1806.`,
      `<span class='italic'>The Italian Count or Ezzelin, Count of Ravenna, musing over the body of Meduna, slain by him for infidelity during his absence in the Holy Land</span> was purchased by Soane in July 1802, perhaps prompted by the 1801 publication of the first three of Fuseli’s lectures on painting delivered at the Academy. Fuseli loved the theatre, often cutting short his lectures at the Royal Academy to go and see a play, and his subjects were often presented as if they were actors on a stage, the forms simplified yet theatrically posed. Ezzelin assimilates his murderous action, his head dramatically supported on one hand, the other sits on his thigh drawing attention to the exaggerated rippling muscles of his legs. `]
  },
  {
    id: `P144`,
    title: `<span class='italic'>Portrait of Sir Francis Bourgeois RA</span>`,
    artist: `Sir William Beechey (1753 - 1839)`,
    date: `<span class='italic'>c.</span>1810 `,
    link: `http://collections.soane.org/object-p144`,
    medium: `Oil on canvas `,
    description: []
  },
  {
    id: `P145`,
    title: `<span class='italic'>A Persian lady worshipping the rising sun</span>`,
    artist: `Maria Cosway (1759 - 1838)`,
    date: `1784`,
    link: `http://collections.soane.org/object-p145`,
    medium: `Oil on canvas`,
    description: [`There were two female founding members of the Royal Academy in 1768, Angelica Kaufmann and Mary Moser, but no other women were elected until 1936. However, nothing precluded women from submitting work for display in the Academy’s annual exhibition and many did. This painting by <span class='italic'>Maria Cosway of A Persian Lady worshipping the Rising Sun</span> was exhibited at the Royal Academy in 1784.`,
      `Born Maria Hadfield in Florence, where her family ran a hotel, Maria was a talented artist who studied under Violante Cerroti and Johann Zoffany from 1773. Aged 18 in 1778 she was elected to the Florentine <span class='italic'>Accademia del Disegno.</span> Soane met Maria Hadfield during his Grand Tour of Italy and they became life-long friends. Upon moving to London in 1779 she 'having refused better offers in her better days … from necessity married Cosway the miniature painter'. In 1784 when this picture was painted Richard and Maria Cosway lived within the heart of fashionable Society, in the Prince of Wales’s circle, at Schomberg House in Pall Mall. `,
      `The painting is typical of her work at this period which was inspired by Angelica Kauffman, Sir Joshua Reynolds and Henry Fuseli, and whose subjects were often drawn from mythological, literary, and biblical themes. Cosway wrote to Soane in 1822 describing how, after its initial exhibition at the Academy, she had presented it to ‘her dear friend the Dowager Lady Lyttleton’ who was ‘pleased with it’. The Dowager bequeathed it back to Cosway who then ‘placed’ it with another friend, Mrs George Hardinge, who also subsequently died and ‘the Persian came to me again’. In 1822 Cosway gave the painting to Soane, and hoped that ‘<span class='italic'>there</span> it will remain’, asserting that the picture ‘received its <span class='italic'>value</span> from tokens of <span class='italic'>friendship</span>’.`]
  },
  {
    id: `P146`,
    title: `Study for <span class='italic'>Différentes vues de Pesto...</span> , Plate IV. The Basilica from the north with the Temple of Neptune in the foreground`,
    artist: `Giovanni Battista Piranesi (1720 - 1778)`,
    date: `<span class='italic'>c.</span>1777-78`,
    link: `http://collections.soane.org/object-p146`,
    medium: `Black chalk, pencil, brown and grey washes, pen and ink`,
    description: [`One of 15 drawings of the Greek Doric temples at Paestum, south of Naples, by Giovanni Battista Piranesi, acquired by Soane in 1817.  Piranesi made these preparatory drawings in 1778, for a volume of etchings, <span class='italic'>Différentes vues de Pesto</span> [Different views of Paestum], published posthumously the same year.`,
      `This drawing shows the ‘Basilica’ (so-called because having lost its pediments it looked more like a civic public building than a temple), now identified as the Temple of Hera (6th century BC), and the Temple of Neptune (right foreground). `,
      `Piranesi’s drawings were probably completed in his studio in Rome based on sketches made on the site. In this one a mistake seems to have been made in drawing the Basilica’s interior: the surviving columns of the <span class='italic'>pronaos</span> have been omitted. The horses and riders in the middle foreground are very loosely indicated, perhaps in another hand, and may come from a source such as a print showing cavalry or a hunt. `,
      `The Paestum temples, in an inaccessible area notorious for malarial swamps, were only rediscovered in the 1750s. The area remained difficult to reach by the time Soane made the first of two visits in January 1779. He made a series of measured sketches, including plans, elevations and even details of capitals and entablatures which must have involved climbing to dizzying heights. His survey enabled him to verify the dimensions given in Thomas Major's <span class='italic'>The Ruins of Paestum</span> (1768). Visiting again in February, Soane noted that he found the ‘Architecture of the three Temples ... exceedingly rude … [having] all the parts of the Grecian Doric but not the elegance & taste’. Nonetheless, he was deeply impressed by the massive baseless columns of the Paestum Doric and later used the Paestum Order himself in constructing a <span class='italic'>Barn à la Paestum</span> (1798), at Malvern Hall, Solihull, for Henry Greswold Lewis, a friend he had met on the Grand Tour.  `]
  },
  {
    id: `P147`,
    title: `The Landing of Richard II at Milford Haven `,
    artist: `William Hamilton RA (1751 - 1801)`,
    date: `<span class='italic'>c.</span>1793-1800`,
    link: `http://collections.soane.org/object-p147`,
    medium: `Oil on canvas `,
    description: []
  },
  {
    id: `P148`,
    title: `Portrait of Mrs Soane with 'Fanny' on her lap`,
    artist: `John Jackson (1778 - 1831)`,
    date: `1831`,
    link: `http://collections.soane.org/object-p148`,
    medium: `Oil on Panel  `,
    description: [`Soane met his beloved wife Elizabeth or ‘Eliza’ Smith through her uncle George Wyatt, a wealthy London builder, in 1783.  Her uncle knew George Dance, in whose office Soane had trained. John and Eliza married in August 1784 and went on to have four children, two of whom died in infancy.  Sadly, just two years after they moved into No. 13, Eliza died on 22 November 1815, aged 53.  Soane never entirely recovered from her death and although she had been ill for a while, he blamed the event on the shock of discovering that their younger son George, recently released from debtors’ prison, had written two vicious anonymous articles in <span class='italic'>The Champion</span> criticising his father’s character and architecture.  George was disinherited and the two were never reconciled – one of the early milestones on the way to Soane’s bequest of his house to the nation, rather than to his family.  `,
      `Soane’s decision to commission this posthumous portrait of Eliza demonstrates his wish to preserve her memory and give her a presence in his recently completed Picture Room.  Jackson is said to have been less successful when painting female portraits but for this one he was able to work from two pencil sketches and a miniature of Eliza as well as portraits of her beloved Manchester terrier, Fanny.  Curiously, the drawing of Eliza by John Flaxman, which today hangs in the South Drawing Room, must have been lent to Jackson by the Flaxman family (John having died in 1826) for the purpose, probably in 1828.  When Jackson died suddenly, it was still in his studio and found its way into the sale of his effects at Christie’s in July 1830 where it was bought by Soane’s friend J M W Turner, who then gave it to Soane as a gift. `]
  },
  {
    id: `P149`,
    title: `<span class='italic'>The Cheat Detected</span>`,
    artist: `Edward Bird (1772 - 1819)`,
    date: `1814`,
    link: `http://collections.soane.org/object-p149`,
    medium: `Oil on panel`,
    description: [`Edward Bird had no formal artistic training, being apprenticed aged thirteen to a firm which produced papier mâché japanned wares. Upon completing his apprenticeship, he moved to Bristol around 1794 working as an independent artist, portraitist and book illustrator, and a group of enthusiastic artists, largely amateurs, formed around him. Not being based in London he was forced to be versatile, accepting commissions which varied from altarpieces to theatrical subjects.`,
      `Bird’s first exhibited work at the Royal Academy, a genre painting – that is a scene from everyday life – entitled <span class='italic'>Good News,</span> was acclaimed when it was hung adjacent to a genre painting by the celebrated David Wilkie. The following year, 1810, the Prince Regent bought Bird’s <span class='italic'>The Country Choristers</span> and after exhibiting his first historical scene at the Academy in 1812 Bird was appointed historical painter to Princess Charlotte in 1813.`,
      `Historical subjects were considered more elevated and sophisticated than genre painting but as the Bristol-based art critic the Revd John Eagles wrote, ‘his best scenes were those of the simple, honest dealings and ways of more humble and common life, where there was some sentiment, some moral good’.`,
      `<span class='italic'>The Cheat Detected,</span> exhibited at the Royal Academy in 1814, is a charming example of Bird’s genre style. Set in a dilapidated and poorly furnished room, a shoeless child with an angelic countenance pulls the ace of clubs from the cheat’s pocket and chaos ensues. The cheat’s red-suited opponent leaps forward to punch him, knocking over a woman, perhaps his wife, and a cherubic child whilst the dog snarls at the company. `,
      `Bird died in November 1819 and the picture was purchased by Soane from the artist’s family in January 1820. Bird’s legacy was a thriving ‘Bristol School’ of artists who descended from the amateur group that had formed around him. `]
  },
  {
    id: `P209`,
    title: `<span class='italic'>Architectural ruins</span>`,
    artist: `Charles Louis Clérisseau (1721 - 1820)`,
    date: `1771`,
    link: `http://collections.soane.org/object-p209`,
    medium: `Gouache on paper`,
    description: []
  }
]
