/**
 * Room Component uses Reel.js
 */
import Template from '../templates/room.html';
import SpinLoader from './SpinLoader';
import TrackData from '../data/tracking';
import TargetPoint from './TargetPoint';
import Help from './help';

const path = '/assets/360/';
const aspectRatio = 720 / 1280;
var wait

export default {
    name: 'Room',
    template: Template,
    components: [SpinLoader, TargetPoint, Help],
    props: ['core'],
    route: {
        route: {
            activate: function (transition) {
                if (this.core.videos.isPlaying) {
                    this.core.endVideo('route')
                }
                transition.next()
            }
        },
        // deactivate: function (transition) {
        //     console.log(transition)
        //     console.log('deactivate', transition)
        //     if (wait) clearInterval(wait)
        //     transition.next()
        // }
    },
    data() {
        return {
            models: TrackData[this.$route.params.section].datasets,
            room: {
                label: TrackData[this.$route.params.section].label,
                name: this.$route.params.section,
                about: TrackData[this.$route.params.section].about
            },
            static: false, // static poi in use
            dropdown: false,
            frame: 1,
            loaded: false,
            targets: []
        }
    },
    computed: {
        load() {
            return this.core.videos.isPlaying
        },
        highres() {
            return (this.$parent.core.layout.hires) ? '/hires' : ''
        },
        section() {
            return this.$route.params.section
        },
        show() {
            return (!this.$parent.core.videos.isPlaying && this.loaded)
        },
        potree() {
            return (this.$route.params.pointcloud) ? true : false
        },
        startFrame() {
            return `${path}${this.section}${this.highres}/frame_001.jpg`
        }
    },
    methods: {
        toggleDropdown() {
            this.dropdown = !this.dropdown;
        },
        setCoordinates(frame) {
            if (typeof frame === "object") {
                this.targets = [{
                    transforms: `${this.convertToScaleX(frame.x)}px, ${this.convertToScaleY(frame.y)}px`
                }]
            } else {
                let f = frame - 1;
                this.targets = this.models.map((item) => {
                    return {
                        transforms: this.convertToScaleX(item.coords[f].x) + 'px,' + this.convertToScaleY(item.coords[f].y) + 'px',
                        show: item.coords[f].show
                    }
                })
            }
        },
        convertToScaleX(num) {
            return ~~(num * (window.innerWidth / 1920));
        },
        convertToScaleY(num) {
            let offsetY = (window.innerHeight / 2) - ((window.innerWidth * aspectRatio) / 2);
            return ((window.innerWidth * aspectRatio) * (num / 1080)) + offsetY
        },
        setup() {
            let vm = this;
            $('.r360').reel({
                frames: 100,
                images: `${path}${this.section}${this.highres}/frame_###.jpg`,
                frame: this.frame,
                cursor: 'hand',
                responsive: true,
                inversed: false,
                preload: 'linear',
                preloader: 0,
                stoppable: false,
                steppable: false,
                throwable: true,
                cw: true,
                onPan(frame) {
                    vm.frame = frame;
                }
            }).bind('loaded', function (e) {
                vm.sHeight = vm.$els.reel.clientHeight;
                vm.$nextTick(() => {
                    vm.loaded = true;
                })
            })
            this.$on('scale', () => {
                if (!this.static) this.setCoordinates(this.frame)
                // else this.setCoordinates({
                //     x: 1920 / 2,
                //     y: (1080 / 2) + this.models[0].offsetY
                // })
            })
            // Force
            // This makes it work smoothly
            $('.r360').reel(':loaded', function () {
                this.click()
                vm.loaded = true
                vm.$dispatch('help-deactive')
            })
        }
    },
    beforeCompile() {
        if (this.models.length > 1) this.setCoordinates(this.frame)
        // else if(this.models[0].static) {
        //     this.static = true
        //     this.setCoordinates({
        //         x: 1920 / 2,
        //         y: (1080 / 2) + this.models[0].offsetY
        //     })
        // }
    },
    ready() {
        if (this.core.videos.selected == null) { // not come from the homepage
            if (this.$route.name != 'pointcloud') { // dont load if arrival point is pointcloud inspector
                this.setup()
            } else {
                this.loaded = true
                this.sHeight = this.$els.reel.clientHeight
                this.$dispatch('help-deactive')
            }
        } else {
            this.setup()
            wait = setInterval(() => {
                if (!this.core.videos.isPlaying) {
                    this.$broadcast("help", this.core.state.current);
                    clearInterval(wait);
                }
            }, 250)
        }
    },
    watch: {
        'frame': function (now, prev) {
            if (!this.static) this.setCoordinates(now);
        },
        'load': function (canPlay) {
            if (!canPlay) this.setup()
        }
    }
}