/**
 * Inspector View
 * Loads text content details for relevant Object 
 */
import Template from '../templates/model-inspector.html'
import Content from '../data/content'
import core from '../data/core'
import Poi from './PoiInspector'

import ViewAbout from './ViewAbout'
import ViewFinder from './ViewFinder'

const { layout, state, Downloads } = core

export default {
    name: 'model-inspector',
    template: Template,
    components: [Poi, ViewAbout, ViewFinder],
    props: ['model', 'poi'],
    data() {
        return {
            state,
            layout,
            currentComponent: 'view-about',
            previous: null,
            preview: true,
            dropdown: false,
            related: false,
            download: null
        }
    },
    computed: {
        opaque() {
            return (this.currentComponent == 'view-about')
        },
        hideNav() {
            return layout.modal
        },
        darkenView() {
            return (this.layout.inspecting) ? 'darken' : ''
        },
        download() {
            let match = Downloads.collection.filter((item) => {
                return item.pid === Content.getPid(this.$route.params.pointcloud)
            })
            return match[0]
        }

    },
    watch: {
        'hideNav': function (next, prev) {
            this.$broadcast('model-nav', next);
        }
    },
    methods: {
        close() {
            this.currentComponent = false;
        },
        toggleDropdown() {
            this.dropdown = !this.dropdown
            this.layout.dropdown = this.dropdown
        }
    },
    ready() {

        this.$on('close-model-inspector', () => {
            this.close();
        })
        this.$on('abt', () => {
            this.currentComponent = 'view-about'
            state.set('inspect');
        })
    }
}