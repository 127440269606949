const filename_prefix = "Explore_Soane_"
export default {
  collection: [
        {
            pid: 'pid_09',
            label: "Temple of Vesta (Cork)",
            location: "Model Room",
            summary: "One Soane’s favourite buildings, modelled in cork by Giovanni Altieri.",
            thumb: '/assets/downloads/_thumbs/tivoli_CAM1_460px.jpg',
            url: { // MR2
                model: `assets/downloads/models/${filename_prefix}MR2.zip`,
                image: `assets/downloads/images/${filename_prefix}MR2.zip`
            }
        },
        {
            pid: 'pid_10',
            label: "Temple of Vesta (Plaster)",
            location: "Model Room",
            summary: "A finely detailed Plaster of Paris model, by François Fouquet.",
            thumb: '/assets/downloads/_thumbs/fouquet_CAM1_460px.jpg',
            url: { // MR13
                model: `assets/downloads/models/${filename_prefix}MR13.zip`,
                image: `assets/downloads/images/${filename_prefix}MR13.zip`
            }
        },
        {
            pid: 'pid_18',
            label: "Model of Pompeii",
            location: "Model Room",
            summary: "Showing the city in 1820, following its partial excavation.",
            thumb: '/assets/downloads/_thumbs/pompei_CAM2_460px.jpg',
            url: { // MR1
                model: `assets/downloads/models/${filename_prefix}MR1.zip`,
                image: `assets/downloads/images/${filename_prefix}MR1.zip`
            }
        },
        {
            pid: 'pid_24',
            label: "Model for the Law Courts, London",
            location: "Model Room",
            summary: "Showing Soane’s ingenious design for the courts in Westminster.",
            thumb: '/assets/downloads/_thumbs/law_courts_CAM2_460px.jpg',
            url: { // MR41
                model: `assets/downloads/models/${filename_prefix}MR41.zip`,
                image: `assets/downloads/images/${filename_prefix}MR41.zip`
            }
        },
        {
            pid: 'pid_00',
            label: "Sarcophagus",
            location: "Sepulchral Chamber",
            summary: "The magnificent alabaster sarcophagus of King Seti I.",
            thumb: '/assets/downloads/_thumbs/Seti_CAM2_460px.jpg',
            url: { // M470
                model: `assets/downloads/models/${filename_prefix}M470.zip`,
                image: `assets/downloads/images/${filename_prefix}M470.zip`
            }
        },
        {
            pid: 'pid_28',
            label: "Sarcophagus",
            location: "Sepulchral Chamber",
            summary: "The magnificent alabaster sarcophagus of King Seti I.",
            thumb: '/assets/downloads/_thumbs/Seti_CAM2_460px.jpg',
            url: { // M470
                model: `assets/downloads/models/${filename_prefix}M470.zip`,
                image: `assets/downloads/images/${filename_prefix}M470.zip`
            },
            hide: true
        }
    ]
}